import { SportCategorySettings, CategorySettings } from '@/types/category-setting';

export const sportCategorySettings: SportCategorySettings = {
	0: ['Main', 'EndingGame', 'Full', 'Half', 'Fastmarket', 'Corners', 'Intervals', 'Specials', 'Players', 'Quarter', 'ExtraTime', 'Penalty', 'SingleMatchParlay'],
	1: ['Main', 'Full', 'CorrectScore', 'Half', 'Corners', 'Intervals', 'Specials', 'Players', 'Fastmarket', 'ExtraTime', 'Penalty', 'SingleMatchParlay'],
	6: ['All', 'Match', 'Sets', 'RaceToFifteenPoints', 'Points'],
	9: ['Main', 'Full'],
	43: ['Main', 'Map1', 'Map2', 'Map3', 'Map4', 'Map5', 'Map6', 'Map7', 'Map8', 'Map9', 'Full'],
	50: ['All', 'Player', 'Match'],
	8: ['All', 'Match', 'Innings', 'Hits'],
}

export const categorySettings: CategorySettings = {
	All: {
		title: {
			'0': 'lbl_All'
		},
		betTypeSettings: {
			'50-FX_d': [ //deadball
				501, 9401, 5, 9404, 9405, 9406, 9407, 9410, 9411, 9412,
				9413, 9607, 9608, 9609, 9610, 9484, 9485, 9486, 9487, 9613,
				9614, 9430, 9431, 9490, 9491, 9678, 9688, 9616, 9617, 9428,
				9429, 9436, 9638, 9437, 9639, 9664, 9665, 9676, 9686, 9434,
				9636, 9435, 9637, 9662, 9663, 9447, 9441, 9460, 9461, 9462,
				9463, 9696, 9697, 9446, 9440, 9454, 9455, 9456, 9457, 9449,
				9450, 9448, 9615, 9451, 9452, 9453, 9442, 9443, 9708, 9709,
				9710, 9711, 9670, 9671, 9674, 9675, 9632, 9633, 9626, 9627,
				9668, 9669, 9672, 9673, 9630, 9631, 9624, 9625, 9496, 9497,
				9498, 9499, 9502, 9503, 9504, 9505, 9618, 9619, 9620, 9621,
				9514, 9515, 9516, 9517, 9677, 9687, 9679, 9689, 9528, 9527,
				9532, 9533, 9534, 9535, 9530, 9529, 9531
			],
			'50-FX_l': [
				9609, 9610, 9412, 9413, 9611, 9612, 9414, 9415, 5, 501,
				9607, 9608, 9410, 9411, 9404, 9405, 9406, 9407, 9613, 9614,
				9486, 9487, 9430, 9431, 9682, 9702, 9692, 9703, 9436, 9638,
				9437, 9639, 9664, 9665, 9408, 9409, 9488, 9489, 9432, 9433,
				9684, 9704, 9694, 9705, 9438, 9640, 9439, 9641, 9666, 9667,
				9484, 9485, 9490, 9491, 9616, 9617, 9428, 9429, 9680, 9698,
				9690, 9699, 9434, 9636, 9435, 9637, 9662, 9663, 9447, 9441,
				9460, 9461, 9462, 9463, 9464, 9465, 9446, 9440, 9454, 9455,
				9456, 9457, 9458, 9459, 9449, 9450, 9448, 9615, 9451, 9730,
				9731, 9732, 9733, 9738, 9739, 9652, 9653, 9658, 9659, 9632,
				9633, 9626, 9627, 9498, 9499, 9504, 9505, 9620, 9621, 9516,
				9517, 9683, 9693, 9734, 9735, 9696, 9697, 9654, 9655, 9660,
				9661, 9634, 9635, 9628, 9629, 9500, 9501, 9506, 9507, 9622,
				9623, 9518, 9519, 9685, 9695, 9736, 9737, 9650, 9651, 9656,
				9657, 9630, 9631, 9624, 9625, 9496, 9497, 9502, 9503, 9618,
				9619, 9514, 9515, 9681, 9691, 9528, 9527, 9532, 9533, 9534,
				9535, 9536, 9537, 9530, 9529, 9531
			],
			'50-FC': [
				9538, 9539, 9541, 9542, 9547, 9548, 9722, 9723, 9559, 9560,
				9712, 9717, 9713, 9718, 9714, 9719, 9543, 9544, 9549, 9550,
				9724, 9725, 9561, 9562, 9715, 9720, 9545, 9546, 9551, 9552,
				9726, 9727, 9563, 9564, 9716, 9721, 9567, 9566, 9569, 9570,
				9572, 9573, 9580, 9581, 9574, 9575, 9582, 9583, 9576, 9577,
				9584, 9585, 9578, 9579, 9729, 9728
			],
			'6_l': [
				20, 704, 705, 3918, 219, 220, 3900, 3909, 3903, 3904,
				3905, 401, 402, 3901, 3914, 706, 3907, 3908, 3912, 3913,
				3911, 3910, 3917, 3915, 3916, 3906
			],
			'6_d': [
				20, 704, 705, 3918, 219, 220, 701, 3900, 401, 402,
				3901, 3902, 3914, 706, 3907, 3908, 3912, 3911, 3910, 3915,
				3916, 3906
			],
			'8_l': [
				20, 8800, 8801, 8804, 8806, 8807, 8811, 8812, 8810, 8802
				, 8803, 8805, 8808, 8809, 8822, 8825, 8814, 8813
			],
			'8_d': [
				20, 8800, 8801, 8804, 8806, 8807, 8811, 8812, 8810, 8802,
				8803, 8805, 8808, 8809, 8825, 8826, 8815, 8818, 8819, 8820, 8816, 8817, 8823, 8824, 8821, 8827,
				8828, 8829, 8830, 8832, 8833, 8831, 8834, 8835
			]
		}
	},
	Main: {
		title: {
			'0': 'lbl_TabPop'
		},
		betTypeSettings: {
			'0': [
				501, 458, 459, 4, 413, 30, 414, 405, 416, 467, 493, 468, 469,
				16, 22, 448, 6, 25, 14, 20, 153, 1, 3, 2, 154, 155, 156, 21,
				7, 8, 12, 612, 609, 610, 611, 128, 26, 28, 24, 13, 27, 425,
				171, 408, 401, 402, 403, 404, 9088
			],
			'1': [
				1, 3, 7, 8, 5, 15, 458, 459, 4, 413,
				30, 414, 405, 416, 467, 493, 468, 469, 16, 22,
				448, 6, 25, 14, 20, 2, 21, 128, 26, 28,
				24, 13, 27, 425, 171, 408, 401, 402, 403, 404,
			],
			'2': [
				20, 1, 3, 2, 21, 7, 8, 12, 612, 609, 610, 611
			],
			'9': [
				20, 704, 705, 707, 708, 709, 703,
			],
			'43': [
				20, 1, 3, 9088, 9001
			]
		}
	},
	EndingGame: {
		title: {
			'0': 'lbl_EndingGame'
		},
		betTypeSettings: {
			'0': [
				647, 648, 649, 650
			]
		}

	},
	Full: {
		title: {
			'0': 'lbl_fulltime',
			'9': 'lbl_Others',
			'43': 'lbl_Others'
		},
		betTypeSettings: {
			'0': [
				458, 602, 601, 4, 413, 416, 467, 493, 468, 469, 460, 6, 461, 462,
				16, 22, 448, 14, 2, 128, 25, 450, 26, 28, 13, 24, 27, 425, 408, 406,
				407, 409, 171, 159, 161, 162, 144, 449, 415, 172, 451, 417, 418, 424,
				419, 420, 421, 422, 423, 455, 121, 122, 2809, 2807, 2811, 2812, 401,
				402, 703, 702, 711, 714, 618, 619, 620, 603, 604, 605, 646
			],
			'1': [
				458, 4, 413, 416, 467, 493, 468, 469, 460, 6,
				461, 462, 16, 22, 448, 14, 2, 128, 25, 450,
				26, 28, 13, 24, 27, 425, 408, 406, 407, 409,
				171, 159, 161, 162, 144, 449, 415, 172, 451, 417,
				418, 424, 419, 420, 421, 422, 423, 455, 121, 122,
				401, 402
			],
			'9': [
				701, 702, 706, 710, 711, 712, 713, 714
			]
		}
	},
	CorrectScore: {
		title: {
			'0': 'lbl_CrctScore'
		},
		betTypeSettings: {
			'1': [
				4, 413, 30, 414, 405, 416, 399
			]
		}
	},
	Half: {
		title: {
			'0': 'lbl_1st_half'
		},
		betTypeSettings: {
			'0': [
				21, 7, 8, 12, 459, 30, 414, 405, 463, 464, 127, 126, 426, 456,
				184, 151, 186, 188, 146, 412, 429, 453, 410, 177, 17, 18, 140,
				452, 141, 142, 133, 134, 149, 150, 147, 148, 189, 190, 445, 446,
				447, 191, 185, 608, 403, 404, 606, 607, 2808, 645, 621, 622, 623,
				624, 625, 626
			],
			'1': [
				21, 12, 184, 459, 30, 414, 405, 463, 464,
				127, 126, 426, 456, 151, 186, 188, 146, 412,
				429, 453, 410, 177, 17, 18, 140, 452, 141, 142,
				133, 134, 149, 150, 147, 148, 189, 190, 445, 446,
				447, 191, 185, 403, 404,
			]
		}
	},
	Corners: {
		title: {
			'0': 'lbl_cornersbookings'
		},
		betTypeSettings: {
			'1': [
				206, 208, 197, 198, 195, 196, 194, 207, 209, 204,
				205, 200, 201, 202, 203, 470, 471, 494, 495, 493,
				482, 483, 484, 485, 488, 491, 489, 492, 490, 472,
				479, 481, 473, 474, 475, 476, 486, 487, 477, 478,
				496, 497
			]
		}
	},
	Intervals: {
		title: {
			'0': 'lbl_intervals'
		},
		betTypeSettings: {
			'0': [
				192, 193, 194
			]
		}
	},
	Specials: {
		title: {
			'0': 'lbl_specials'
		},
		betTypeSettings: {
			'0': [
				135, 210, 212, 214, 213, 215, 211
			]
		}
	},
	Players: {
		title: {
			'0': 'lbl_players'
		},
		betTypeSettings: {
			'0': [
				217, 216
			]
		}
	},
	Fastmarket: {
		title: {
			'0': 'lbl_fastmarkets'
		},
		betTypeSettings: {
			'0': [
				226, 227, 221, 222, 223, 224, 225
			]
		}
	},
	Quarter: {
		title: {
			'0': 'lbl_quarter'
		},
		betTypeSettings: {
			'0': [
				612, 609, 610, 611, 614, 615, 616, 613, 627, 628, 629,
				617, 630, 631, 644, 632, 635, 636, 643, 633, 634, 637,
				638, 639, 640, 641, 642
			]
		}
	},
	ExtraTime: {
		title: {
			'0': 'lbl_ExtraTime'
		},
		betTypeSettings: {
			'0': [
				399, 397, 396, 164, 395, 398, 400
			]
		}
	},
	Penalty: {
		title: {
			'0': 'odds_penalty'
		},
		betTypeSettings: {
			'0': [
				376, 392, 387, 386, 389, 388, 393, 394, 391, 390,
				382, 381, 385, 384, 383
			]
		}
	},
	Player: {
		title: {
			'0': 'lbl_Crckt_Player'
		},
		betTypeSettings: {
			'50-FX_d': [ //deadball
				9676, 9686, 9434, 9636, 9435, 9637, 9662, 9663, 9668, 9669,
				9672, 9673, 9678, 9688, 9436, 9638, 9437, 9639, 9664, 9665,
				9670, 9671, 9674, 9675
			],
			'50-FX_l': [
				9680, 9698, 9690, 9699, 9434, 9636, 9435, 9637, 9662, 9663,
				9682, 9702, 9692, 9703, 9436, 9638, 9437, 9639, 9664, 9665,
				9684, 9704, 9694, 9705, 9438, 9640, 9439, 9641, 9666, 9667,
				9730, 9731, 9732, 9733, 9738, 9739, 9652, 9653, 9658, 9659,
				9734, 9735, 9696, 9697, 9654, 9655, 9660, 9661, 9736, 9737,
				9650, 9651, 9656, 9657
			],
			'50-FC': [
				9712, 9717, 9713, 9718, 9714, 9719, 9715, 9720, 9716, 9721
			]
		}
	},
	Match: {
		title: {
			'0': 'lbl_Crckt_Match'
		},
		betTypeSettings: {
			'50-FX_d': [ //deadball
				9447, 9441, 9460, 9461, 9462, 9463, 9696, 9697, 9446, 9440,
				9454, 9455, 9456, 9457, 9449, 9450, 9448, 9615, 9451, 9452,
				9453, 9442, 9443, 9630, 9631, 9624, 9625, 9708, 9709, 9710,
				9711, 9632, 9633, 9626, 9627
			],
			'50-FX_l': [
				9447, 9441, 9460, 9461, 9462, 9463, 9464, 9465, 9446, 9440,
				9454, 9455, 9456, 9457, 9458, 9459, 9449, 9450, 9448, 9615,
				9451, 9630, 9631, 9624, 9625, 9632, 9633, 9626, 9627, 9634,
				9635, 9628, 9629
			],
			'50-FC': [
				9567, 9566, 9569, 9570, 9572, 9573, 9580, 9581, 9574, 9575,
				9582, 9583, 9576, 9577, 9584, 9585, 9578, 9579, 9729, 9728
			],
			'6_l': [
				401, 402, 3900, 3901, 3902, 3914, 706
			],
			'6_d': [
				701, 401, 402, 3900, 3901, 3902, 3914, 706
			],
			'8_l': [
				20, 8800, 8801, 8802, 8803, 8822, 8825
			],
			'8_d': [
				20, 8800, 8801, 8802, 8803, 8825, 8826, 8815, 8818, 8820, 8821, 8827
			]
		}
	},
	RaceToFifteenPoints: {
		title: {
			'0': 'lbl_Tab_Rto15Pts'
		},
		betTypeSettings: {
			'6_l': [
				3903, 3904, 3905
			]
		}
	},
	Points: {
		title: {
			'0': 'lbl_Tab_Points'
		},
		betTypeSettings: {
			'6_l': [
				3909
			]
		}
	},
	Sets: {
		title: {
			'0': 'lbl_Tab_Sets'
		},
		betTypeSettings: {
			'6_l': [
				3918, 219, 220, 3907, 3908, 3912, 3913, 3911, 3910, 3917, 3915, 3916, 3906
			],
			'6_d': [
				3918, 219, 220, 3907, 3908, 3912, 3911, 3910, 3915, 3916, 3906
			]
		}
	},
	SingleMatchParlay: {
		title: {
			'0': 'lbl_SingleMatchParlay'
		},
		betTypeSettings: {}
	},
	Map1: {
		title: {
			'0': 'lbl_map1'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map2: {
		title: {
			'0': 'lbl_map2'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map3: {
		title: {
			'0': 'lbl_map3'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map4: {
		title: {
			'0': 'lbl_map4'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map5: {
		title: {
			'0': 'lbl_map5'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map6: {
		title: {
			'0': 'lbl_map6'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map7: {
		title: {
			'0': 'lbl_map7'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map8: {
		title: {
			'0': 'lbl_map8'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Map9: {
		title: {
			'0': 'lbl_map9'
		},
		betTypeSettings: {
			'43': [
				9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012, 
				9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024, 
				9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037, 
				9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049, 
				9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060, 
				9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077, 
				9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087, 
				9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098, 
				9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 
				9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118, 
				9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128, 
				9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138, 
				9139, 9140, 9141, 9142
			]
		}
	},
	Innings: {
		title: {
			'0': 'moreTab_29'
		},
		betTypeSettings: {
			'8_l': [
				8804, 8806, 8807, 8811, 8812, 8810, 8805, 8808, 8809, 8814, 8813
			],
			'8_d': [
				8804, 8806, 8807, 8811, 8812, 8810, 8805, 8808, 8809, 8819
			]
		}
	},
	Hits: {
		title: {
			'0': 'moreTab_30'
		},
		betTypeSettings: {
			'8_d': [
				8816,
				8817,
				8823,
				8824,
			]
		}
	}
};

export const quarterGroupSettings: Record<string, Record<string, number[]>> = {
	2: { // Basketball
		Main: [612, 609, 610, 611],
		Quarter: [612, 609, 610, 611, 614, 615, 616, 613, 627, 628, 629, 617, 630, 631]
	},
	5: { // Tennis
		Main: [154, 155, 156],
	}
}
