// Don't modify any part of this code file, please

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const Box = x => ({
	ap: other => other.map(x),
	map: fn => Box(fn(x)),
	chain: fn => fn(x),
	fold: fn => fn(x),
	extract: () => x,
	concat: other => Box(x.concat(other.extract()))
});
Box.of = x => Box(x);

export const BoxT = M => {
	const Box = mx => ({
		map: fn => Box(mx.map(fn)),
		chain: fn => Box(mx.chain(x => fn(x).extract())),
		extract: () => mx
	});
	Box.of = x => Box(M.of(x));
	Box.lift = mx => Box(mx);
	return Box;
};

export const Effect = g => ({
	ap: other => Effect(x => other.map(g(x)).run()),
	map: f => Effect(x => f(g(x))),
	chain: f => Effect(x => f(g(x)).run(x)),
	run: x => g(x),
	concat: other => 
		Effect(x => g(x).concat(other.run(x)))
});
Effect.ask = Effect(x => x);
Effect.of = x => Effect(() => x);

export const EffectT = M => {
	const Effect = g => ({
		map: f => Effect(x => g(x).map(f)),
		chain: f => 
			Effect(x => g(x).chain(y => f(y).run(x))),
		concat: other => 
			Effect(x => g(x).concat(other.run(x))),
		run: x => g(x)
	});
	Effect.ask = Effect(x => M.of(x));
	Effect.of = x => Effect(() => M.of(x));
	Effect.lift = mx => Effect(() => mx);
	return Effect;
};

export const IO = f => ({
	map: g => IO(() => g(f())),
	chain: g => IO(() => g(f()).run()),
	concat: other => IO(() => f().concat(other.run())),
	run: () => f()
});
IO.of = x => IO(() => x);

export const Extractor = run => 
	({
		run,
		contramap: f => Extractor(x => run(f(x))),
		map: f => Extractor(x => f(run(x))),
		concat: other =>
			Extractor(x => other.run(run(x)))
	});

type ForkPattern = {
	just: (x: any) => void;
	none: (x: any) => void;
};

export const Right = x => ({
	isLeft: false,
	ap: other => other.map(x),
	map: fn => Right(fn(x)),
	chain: fn => fn(x),
	concat: other => 
		other.fold(
			x => other,
			y => Right(x.concat(y))
		),
	fold: (f, g) => g(x),
	fork: (pattern: ForkPattern) => pattern.just(x)
});

export const Left = x => ({
	isLeft: true,
	ap: other => Left(x),
	map: fn => Left(x),
	chain: fn => Left(x),
	concat: other => Left(x),
	fold: (f, g) => f(x),
	fork: (pattern: ForkPattern) => pattern.none(x)
});

export const Either = (() => {
	const of = Right;

	return { of, Right, Left };
})();

const isNothing = value => typeof value === 'undefined' || value == null;

export const Maybe = x => isNothing(x) ? Left(null) : Right(x);
Maybe.of = x => Maybe(x);

export const MaybeMatch = x => x === true ? Right(x) : Left(x);

export const fromPred = predicate => v => 
	predicate(v) ? Right(v) : Left(v);
	
export const fromPredWithDefault = (predicate, defaultValue) => v => 
	predicate(v) ? Right(v) : Right(defaultValue);

/* matchSome */
const matched = x => ({
	on: () => matched(x),
	otherwise: () => x
});

export const matchSome = x => ({
	on: (predicate, fn) => predicate(x) ? matched(fn(x)) : matchSome(x),
	otherwise: fn => fn(x)
});

export const matchEquals = x => ({
	on: (v, fn) => x === v ? matched(fn(x)) : matchEquals(x),
	otherwise: fn => fn(x)
});

export const identity = v => v;