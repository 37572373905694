import { QueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import type { ApiQueryPromise } from '@/types';
import type { UserInfo, userSettingsData } from '@nf/types/account';
import { getAuthActions } from '@/store/auth';
import { deepMerge } from '@nf/utils-common';
import { accountKeys } from '@/hooks/query/account';
import queryKeys from '@/constants/query-keys';
import {
	AccountSettingsData,
	UpdateSettingData,
	saveAccountSetting,
	updateUserSetting as updateUserSettingApi
} from '@/hooks/query/use-account-settings-data';
import { BetslipStyle } from '@/hooks/query';

/**
 * Update account data optimistically.
 * 
 * ⚠ Notice: You must ensure that the data is saved to the database!
 * @param queryClient - The instance of QueryClient of React Query
 * @param accountDataToUpdate - The account data to be updated
 */
const updateAccountInfo = (queryClient: QueryClient, accountDataToUpdate: Partial<UserInfo>) => {
	queryClient.cancelQueries(accountKeys.info());
	queryClient.setQueryData<ApiQueryPromise<UserInfo> | undefined>(accountKeys.info(), old => {
		return produce(old, draft => {
			if (draft?.data.Data) {
				draft.data.Data = deepMerge(draft.data.Data, accountDataToUpdate);

			}
		});
	});
};

/**
 * Update setting data optimistically.
 * 
 * ⚠ Notice: You must ensure that the data is saved to the database!
 * @param queryClient 
 * @param userSettingDataToUpdate 
 */

const updateUserSetting = (queryClient: QueryClient, userSettingDataToUpdate: Partial<userSettingsData>) => {
	queryClient.cancelQueries([queryKeys.ACCOUNT_SETTINGS]);
	queryClient.setQueryData<ApiQueryPromise<userSettingsData> | undefined>([queryKeys.ACCOUNT_SETTINGS], old => {
		return produce(old, draft => {
			if (draft?.data.Data) {
				draft.data.Data = deepMerge(draft.data.Data, userSettingDataToUpdate);
			}
		});
	});
};

const getUserQuickStake = (quickStake: string) => {
	if (parseInt(quickStake) <= 0 || quickStake.split(':').length < 3) {
		return {
			stake1: '100',
			stake2: '10',
			stake3: '1'
		}
	}
	const [stake1, stake2, stake3] = quickStake.split(':');
	return {
		stake1,
		stake2,
		stake3
	}
}

const getUserSettingDataForDisplay = (userSetting: AccountSettingsData, user: UserInfo) => {
	const { stake1, stake2, stake3 } = getUserQuickStake(user.UserSetting.QuickStake);
	const displayData = {
		DefaultLanguage: userSetting.displayInfo.disLanID,
		DisplayLanguage: userSetting.displayInfo.disLanguage,
		OddsTypeID: userSetting.displayInfo.disOddsTypeKey,
		OddsType: userSetting.displayInfo.disOddsType,
		BetStake: userSetting.betInfo.betStakeValue > 0 ? 1 : userSetting.betInfo.betStakeValue,
		BetterOdds: userSetting.betInfo.betAcceptBetter,
		eventSort: userSetting.betInfo.betOddsSort, // normal sorting or by time 
		oddssort: user?.UserSetting.EventSortType === 1 ? 1 : 2, // normal =1 , by time =2 
		inpbetStake: userSetting.betInfo.betStakeValue,
		quickBet: userSetting.betInfo.betQBet, // betQBet 1為開啟 
		betQ1: userSetting.betInfo.betQ1,
		betQ2: userSetting.betInfo.betQ2,
		betQ3: userSetting.betInfo.betQ3,
		enableCrossSelling: user.UserSetting.EnableCrossSelling ? 1 : 0,
		darkMode: userSetting.displayInfo.openDarkMode ? 1 : 0,
		isSmartLeagueSort: user.UserSetting.EventSortType,
		isSmartSportSort: user?.SSSort ? 1 : 0, //sport menu 排序
		mobileVersion: user?.UserSetting.OddsPageLayoutType,
		sportsQuickBetStake: user?.SportsQuickBetStake,
		BettingMode: user.BettingMode
	}
	return displayData;
}

/**
 * 轉換 Quick Stake 的回存資料格式
 * @param enabled 是否啟用
 * @param stake1 (啟用時必填)
 * @param stake2 (啟用時必填)
 * @param stake3 (啟用時必填)
 */
const formatQuickStake = (enabled: boolean, stake1?: string, stake2?: string, stake3?: string) => {
	return enabled ? `2:${stake1}:${stake2}:${stake3}` : '1:100:10:1';
}

/** User Setting - 將資料格式轉換成回存 api 用 */
const handleUserSettingDataForSave = (settingData: UpdateSettingData, user: UserInfo) => {
	const dfHP = '';
	const qb = settingData.quickBet === 1;
	const q1 = settingData.betQ1;
	const q2 = settingData.betQ2;
	const q3 = settingData.betQ3;
	const qbs = formatQuickStake(qb, q1, q2, q3);

	const othersType = [
		dfHP,
		user?.GB ? user.GB : 0,
		qbs,
		settingData.enableCrossSelling,
		settingData.darkMode,
		settingData.oddssort === 1 ? 1 : 0, //sorting by time =0, normal =1 
		settingData.isSmartSportSort,
		settingData.mobileVersion,
		settingData.sportsQuickBetStake,
		'', // DBSkinMode (lic 尚未啟用)
		user.HasVisitedGalaxy ? 1 : 0,
		user.HasShownGalaxyTutorial ? 1 : 0,
		settingData?.BettingMode ?? 0,
		user.HasShownBettingModePopup ? 1 : 0
	]

	const updateData = {
		DefaultLanguage: settingData.DefaultLanguage,
		OddsType: settingData.OddsTypeID,
		BetStake: settingData.BetStake,
		BetterOdds: settingData.BetterOdds,
		Oddssort: settingData.oddssort,
		InpbetStake: settingData.inpbetStake,
		MobileOthersType: othersType.join(','),
	}
	return updateData
}

/**
 * User Setting - 設定 AI Sorting (for Sports Menu)
 * @param enabled true: use AI sorting / false: use sport order
 */
const setAISorting = (queryClient: QueryClient, enabled: boolean, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	updateAccountInfo(queryClient, {
		SSSort: enabled,
	});
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	return saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

export const BetDefaultStakeType = {
	LastBet: 0,
	Customize: 1,
	Disable: -1
} as const;

/**
 * User Setting - 設定 Default Stake
 * @param defaultStakeId 0: Last Bet / 1: Customize / -1: Disable 
 * @param defaultStakeValue 當啟用 Customize 時自定義的 stake
 */
const setDefaultStake = (queryClient: QueryClient, userSetting: AccountSettingsData, defaultStakeId: number, defaultStakeValue?: number) => {
	const { updateAuthToken } = getAuthActions();

	const newStake = defaultStakeId === BetDefaultStakeType.Customize && defaultStakeValue ? defaultStakeValue : 0;

	updateAccountInfo(queryClient, {
		BetStake: defaultStakeId.toString(),
		InpbetStake: newStake.toString(),
	});
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	displayData.BetStake = defaultStakeId;
	displayData.inpbetStake = newStake;
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
			updateUserSetting(queryClient, {
				betInfo: {
					betStakeID: defaultStakeId.toString(),
					betStakeValue: newStake,
					betDefaultStake: userSetting.betInfo.betStakeList.find(b => b.Value === defaultStakeId.toString())?.Text
				}
			})
		}
	});
}

/**
 * User Setting - 設定 Quick Stake
 * @param enabled 是否啟用
 * @param stake1 (啟用時必填)
 * @param stake2 (啟用時必填)
 * @param stake3 (啟用時必填)
 */
const setQuickStake = (queryClient: QueryClient, userSetting: AccountSettingsData, enabled: boolean, stake1?: string, stake2?: string, stake3?: string) => {
	const { updateAuthToken } = getAuthActions();

	stake1 = stake1 ?? '100';
	stake2 = stake2 ?? '10';
	stake3 = stake3 ?? '1';
	const qbs = formatQuickStake(enabled, stake1, stake2, stake3);

	updateAccountInfo(queryClient, { QBS: qbs });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	displayData.quickBet = enabled ? 1 : 0;
	displayData.betQ1 = stake1;
	displayData.betQ2 = stake2;
	displayData.betQ3 = stake3;
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
			updateUserSetting(queryClient, {
				betInfo: {
					betQBet: displayData.quickBet,
					betQ1: displayData.betQ1,
					betQ2: displayData.betQ2,
					betQ3: displayData.betQ3
				}
			});
		}
	});
}

/** User Setting - 設定 Quick Bet Stake */
const setQuickBetStake = (queryClient: QueryClient, stake: number, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	updateAccountInfo(queryClient, { SportsQuickBetStake: stake });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const DisplayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	const updateData = handleUserSettingDataForSave(DisplayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

/** User Setting - 切換 dark mode / light mode */
const switchDarkMode = (queryClient: QueryClient, isDarkMode: boolean, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const DisplayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	DisplayData.darkMode = isDarkMode ? 1 : 0;
	const updateData = handleUserSettingDataForSave(DisplayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);

		}
		updateUserSetting(queryClient, {
			displayInfo: {
				openDarkMode: isDarkMode,
			}
		})
	});
}

/**
 * User Setting - 切換 card / list (for Odds Table)
 * @param mobileVersion 0: card / 1: list
 * @deprecated Use setOddsPageLayoutType instead
 */
const setMobileVesion = (queryClient: QueryClient, mobileVersion: number, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	updateAccountInfo(queryClient, { MobileVersion: mobileVersion });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const DisplayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	const updateData = handleUserSettingDataForSave(DisplayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

/** User Setting - 切換 Odds Type */
const setOddsType = (queryClient: QueryClient, oddsTypeId: number, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	updateAccountInfo(queryClient, { OType: oddsTypeId });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	displayData.OddsTypeID = oddsTypeId.toString();
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
			updateUserSetting(queryClient, {
				displayInfo: {
					disOddsTypeKey: oddsTypeId.toString(),
					disOddsType: userSetting.displayInfo.oddsTypeList.find(o => o.Id === oddsTypeId)?.name
				}
			});
		}
	});
}

/**
 * User Setting - 切換 Event Sorting
 * @param enabled true: normal sorting / false: sort by time
 * @deprecated Use setEventSortType instead
 */
const setEventSorting = (queryClient: QueryClient, enabled: boolean, userSetting: AccountSettingsData) => {
	const oddsSortId = enabled ? 1 : 2; // normal = 1, sort by time = 2

	const { updateAuthToken } = getAuthActions();
	updateAccountInfo(queryClient, {
		SYT: oddsSortId,
		SLSort: enabled
	});

	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	displayData.oddssort = oddsSortId;
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
			updateUserSetting(queryClient, {
				betInfo: {
					betOddsSortID: oddsSortId,
					betOddsSort: userSetting.betInfo.oddsSortList.find(o => o.Value === oddsSortId.toString())?.Text
				}
			});
		}
	});
}

/** User Setting - 切換 Accept Better Odds */
const setAcceptBetterOdds = (queryClient: QueryClient, enabled: boolean, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	updateAccountInfo(queryClient, { BetterOdds: enabled });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	displayData.BetterOdds = enabled;
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
			updateUserSetting(queryClient, {
				betInfo: {
					betAcceptBetter: enabled
				}
			});
		}
	});
}

/**
 * User Setting - 切換語系
 * @param lang lang key. ex. "en-US"
 */
const setUserLangauage = (queryClient: QueryClient, lang: string, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	updateAccountInfo(queryClient, { Lang: lang });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayLang = userSetting.displayInfo.langList.find(l => l.LanID === lang)?.LanVaue;
	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	displayData.DefaultLanguage = lang;
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
			updateUserSetting(queryClient, {
				displayInfo: {
					disLanID: lang,
					disLanguage: displayLang
				}
			});
		}
	});
}

/** User Setting - 設定此帳號已顯示過 Galaxy 新手教學 */
const setHasShownGalaxyTutorial = (queryClient: QueryClient, userSetting: AccountSettingsData) => {
	const { updateAuthToken } = getAuthActions();

	updateAccountInfo(queryClient, { HasShownGalaxyTutorial: true });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

/**
 * User Setting - 投注模式
 * @param mode 0: 合併投注 / 1: 分開投注
 */
const setBettingMode = (queryClient: QueryClient, userSetting: AccountSettingsData, mode: number) => {
	const { updateAuthToken } = getAuthActions();
	console.log(mode);
	updateAccountInfo(queryClient, { BettingMode: mode, HasShownBettingModePopup: true });
	const newUserinfo = queryClient.getQueryData<ApiQueryPromise<UserInfo>>(accountKeys.info())?.data?.Data;

	if (!newUserinfo) return;

	const displayData = getUserSettingDataForDisplay(userSetting, newUserinfo);
	const updateData = handleUserSettingDataForSave(displayData, newUserinfo);

	saveAccountSetting(updateData).then(res => {
		if (res.data.ErrorCode === 0 && res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const getHasShownFeatureId = (userInfo: UserInfo | null | undefined, featureId: string): boolean => {
	const hasFeatureIds = !!userInfo?.UserSetting.HasShownFeatureIds;
	const featureIds = hasFeatureIds ? userInfo?.UserSetting.HasShownFeatureIds.split(',') : [];

	return featureIds.includes(featureId);
}

const setHasShownFeatureId = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, featureId: string): void => {
	const { updateAuthToken } = getAuthActions();

	const hasFeatureIds = !!userInfo?.UserSetting.HasShownFeatureIds;
	const featureIds = hasFeatureIds ? userInfo?.UserSetting.HasShownFeatureIds.split(',') : [];

	if (!featureIds.includes(featureId)) {
		featureIds.push(featureId);
	}

	const newUserSetting = {
		...userInfo!.UserSetting,
		HasShownFeatureIds: featureIds.join(',')
	};

	updateAccountInfo(queryClient, { UserSetting: newUserSetting });

	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setBetslipStyle = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, betslipStyle: BetslipStyle): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		BetslipStyle: betslipStyle
	};

	updateAccountInfo(queryClient, { UserSetting: newUserSetting });

	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setEnableQuickStake = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, enableQuickStake: boolean): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		EnableQuickStake: enableQuickStake
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setUserSettingQuickStake = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, quickStake: string): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		QuickStake: quickStake
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setEnableDarkMode = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, enableDarkMode: boolean): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		EnableDarkMode: enableDarkMode
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

/**
 * User Setting - 切換 Event Sorting
 * @param eventSortType 0: sort by time / 1: normal sorting
 */
const setEventSortType = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, userSetting: AccountSettingsData, eventSortType: number): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		EventSortType: eventSortType === 1 ? 1 : 0
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
			updateUserSetting(queryClient, {
				betInfo: {
					betOddsSort: userSetting.betInfo.oddsSortList.find(o => o.Value === (eventSortType === 1 ? '1' : '2'))?.Text
				}
			});
		}
	});
}

const setEnableAISportSort = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, enableAISportSort: boolean): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		EnableAISportSort: enableAISportSort
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setOddsPageLayoutType = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, oddsPageLayoutType: number): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		OddsPageLayoutType: oddsPageLayoutType
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setUserSettingQuickBetStake = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, quickBetStake: number): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		QuickBetStake: quickBetStake
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setUserSettingBettingMode = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, bettingMode: number): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		BettingMode: bettingMode
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}
const setUserOddsType = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, oddsType: number): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		BettingMode: oddsType
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setBetterOdds = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, betterOdds: boolean): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		BetterOdds: betterOdds
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

const setDefaultLanguage = (queryClient: QueryClient, userInfo: UserInfo | null | undefined, defaultLanguage: string): void => {
	const { updateAuthToken } = getAuthActions();

	const newUserSetting = {
		...userInfo!.UserSetting,
		DefaultLanguage: defaultLanguage
	};
	updateAccountInfo(queryClient, { UserSetting: newUserSetting });
	updateUserSettingApi(newUserSetting).then(res => {
		if (res.jwtToken) {
			updateAuthToken(res.jwtToken);
		}
	});
}

export const accountService = {
	updateAccountInfo,
	updateUserSetting,
	getUserSettingDataForDisplay,
	handleUserSettingDataForSave,
	setDefaultStake,
	setQuickStake,
	setQuickBetStake,
	switchDarkMode,
	setAISorting,
	setMobileVesion,
	setOddsType,
	setEventSorting,
	setAcceptBetterOdds,
	setUserLangauage,
	setHasShownGalaxyTutorial,
	setBettingMode,
	getHasShownFeatureId,
	setHasShownFeatureId,
	setBetslipStyle,
	setEnableQuickStake,
	setUserSettingQuickStake,
	setEnableDarkMode,
	setEventSortType,
	setEnableAISportSort,
	setOddsPageLayoutType,
	setUserSettingQuickBetStake,
	setUserSettingBettingMode,
	setUserOddsType,
	setBetterOdds,
	setDefaultLanguage
};