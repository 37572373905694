import { ReadonlyURLSearchParams } from 'next/navigation';

import { MaybeMatch } from './fpw/monadic';

export const convertNextSearchParamsToLowerCase = (toHandleSearchParams: ReadonlyURLSearchParams) => {
	const searchParams = new URLSearchParams();
	for (const [name, value] of toHandleSearchParams) {
		searchParams.append(name.toLowerCase(), value);
	}
	return searchParams;
};

export const deleteSearchParamsInsensitive = (deleteKeys: Array<string>, searchParams: URLSearchParams) => {
	const lowerDeleteKey = new Set(deleteKeys.map(x => x.toLowerCase()));
	searchParams.forEach((_, key) => lowerDeleteKey.has(key.toLowerCase()) && searchParams.delete(key));
}

/**
 * e.g., example.com => ['example', 'com']
 * @param hostName Host Name
 * @returns [sub domain, main domain]
 */
export const splitHostNameWithFirstDot = (hostName: string) => {
	const firstDotIndex = hostName.indexOf('.');

	return MaybeMatch(firstDotIndex > -1).fold(
		() => [],
		() => {
			const subDomain = hostName.slice(0, firstDotIndex);
			const mainDomain = hostName.slice(firstDotIndex + 1);

			return [subDomain, mainDomain];
		}
	);
};

/**
 * e.g., goodto => ['good', 'to']
 * @param subDomain Sub Domain
 * @returns [random ID, function ID]
 */
export const extractOneDomain = (subDomain: string) => {
	const randomId = subDomain.slice(0, 4);
	const webFunctionId = subDomain.slice(4, 6);
	const uatEnvironment = subDomain.length > 6 ? subDomain.slice(6, 9) : '';
	return [randomId, webFunctionId, uatEnvironment];
};