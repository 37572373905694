import { 
	from, 
	of, 
	first,
	switchMap, 
	catchError, 
	throwError, 
	Observable 
} from 'rxjs';

import { ApiObservablePromise } from '@/types';

export const getResponseJwtHeader = (res: Response) => 
	res.headers.get('X-Jwtoken');

export const fetchWithReactive = <T>(promise: Promise<Response>) : Observable<Promise<T>> => {
	return from(promise)
		.pipe(
			switchMap(response => {
				if (response.ok) {
					return of(response.json());
				} else {
					return throwError(() => response);
				}
			}),
			first(),
			catchError(err => {
				return throwError(() => err);
			})
		);
};

export const fetchAndGetJwtHeader = <T>(promise: Promise<Response>): Observable<ApiObservablePromise<T>> => {
	return from(promise)
		.pipe(
			switchMap(response => {
				if (response.ok) {
					return of({
						data: response.json(),
						jwtToken: getResponseJwtHeader(response)
					});
				} else {
					return throwError(() => response);
				}
			}),
			first(),
			catchError(err => {
				return throwError(() => err);
			})
		);
};