import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware'
import type { DeepLinkSiteInfo, ApiDomain } from '@nf/types/site';
import { SwitchFromEnum } from '@nf/types/site';
import { BetTypeFilterCriterion } from '@/types/settings';

export interface SiteStore {
	isOddsServiceReady: boolean;
	oddsToken: string;
	currentPage: string;
	isParlayMode: boolean;
	setOddsToken: (token: string) => void;
	setOddsServiceReady: (isReady: boolean) => void;
	betTypeFilterCriterionOfSports: Record<number, BetTypeFilterCriterion>;
	setBetTypeFilterCriterion: (sport: number, betTypes: BetTypeFilterCriterion) => void;
	getBetTypeFilterCriterion: (sport: number) => BetTypeFilterCriterion | undefined;
	filterLeagues: Array<number>;
	setFilterLeagues: (leagueIds: Array<number>) => void;
	isShowBalance: boolean;
	toggleIsShowBalance: () => void;
	isShowEditSport: boolean;
	setIsShowEditSport: (isShow: boolean) => void;
	deepLinkSiteInfo: DeepLinkSiteInfo;
	setDeepLinkSiteInfo: (siteInfo: DeepLinkSiteInfo) => void;
	apiDomain: ApiDomain;
	setApiDomain: (domain: string) => void;
	isOpenTournamentCountry: boolean;
	setIsOpenTournamentCountry: (isOpen: boolean) => void;
	setIsParlayMode: (isParlayMode: boolean) => void;
	setCurrentPage: (page: string) => void;
	ServerGroup:string;
	setServerGroup: (serverGroup: string) => void;
	isOpenExchange: boolean;
	setIsOpenExchange:(value: boolean) => void;
	enableKafa: string;
	setEnableKafka: (enableKafa: string) => void;
	isFilterLiveStreamer: boolean;
	setIsFilterLiveStreamer: (value: boolean) => void;
	isFilterLiveStreaming: boolean;
	setIsFilterLiveStreaming: (value: boolean) => void;
	isFilterLiveMatch: boolean;
	setIsFilterLiveMatch: (value: boolean) => void;
	isFilterStatisticInfo: boolean;
	setIsFilterStatisticInfo: (value: boolean) => void;
	isFilterScoreboard: boolean; 
	setIsFilterScoreboard: (value: boolean) => void;
	isFilterParlay: boolean;
	setIsFilterParlay: (value: boolean) => void;
	isFilterTimeMachine: boolean;
	setIsFilterTimeMachine: (value: boolean) => void;
	isFilterSingleMatchParlay: boolean;
	setIsFilterSingleMatchParlay: (value: boolean) => void;
	isFilterFastMarkets: boolean;
	setIsFilterFastMarkets: (value: boolean) => void;
	isFilterCashout: boolean;
	setIsFilterCashout: (value: boolean) => void;
	resetAllFilter: () => void;
}
type SiteStoreKey = keyof SiteStore;
export const useSiteStore = createWithEqualityFn<SiteStore, [['zustand/persist', SiteStore], ['zustand/immer', never]]>(
	persist(immer<SiteStore>((set, get) => ({
		isOddsServiceReady: false,
		oddsToken: '',
		isParlayMode: false,
		currentPage: 'lbl_Sports',
		isOpenExchange: false,
		setOddsToken: (token: string) => set({ oddsToken: token }),
		setOddsServiceReady: (isReady: boolean) => set(_ => ({
			isOddsServiceReady: isReady
		})),
		betTypeFilterCriterionOfSports: {},
		getBetTypeFilterCriterion: (sport) => {
			return get().betTypeFilterCriterionOfSports[sport] ?? undefined;
		},
		setBetTypeFilterCriterion: (sport, betTypes) => {
			set(params => {
				params.betTypeFilterCriterionOfSports[sport] = betTypes;
			})
		},
		filterLeagues: [],
		setFilterLeagues: leagueIds => {
			set(params => {
				params.filterLeagues = leagueIds;
			})
		},
		isShowBalance: true,
		toggleIsShowBalance: () => set(state => ({ isShowBalance: !state.isShowBalance })),
		deepLinkSiteInfo: {
			homeUrl: '',
			depositUrl: '',
			extendSessionUrl: '',
			loginUrl: '',
			signUpUrl: '',
			game: '',
			gameId: '',
			oddsType: 0,
			skinColor: '',
			skinMode: 0,
			forceDarkMode: false,
			tsId: '',
			switchFrom: SwitchFromEnum.Default,
			isApp: false,
			themePreview: '',
			templateId: '',
			dptoken: '',
			switchFromUrl: '',
			clearCount: 0,
		},
		setDeepLinkSiteInfo: siteInfo =>
			set(_ => ({
				deepLinkSiteInfo: siteInfo
			})),
		apiDomain: {
			ctcdDomain: ''
		},
		setApiDomain: (ctcdDomain: string) =>
			set(_ => ({
				apiDomain: { ctcdDomain }
			})),
		isShowEditSport: false,
		setIsShowEditSport: (isShow: boolean) => set(_ => ({
			isShowEditSport: isShow
		})),
		isOpenTournamentCountry: false,
		setIsOpenTournamentCountry: (isOpen: boolean) => set(_ => ({
			isOpenTournamentCountry: isOpen
		})),
		setIsParlayMode: (isParlayMode: boolean) => set(_ => ({ isParlayMode })),
		setCurrentPage: (page: string) => set(_ => ({ currentPage: page })),
		setIsOpenExchange: (value: boolean) => set(_ => ({ isOpenExchange: value })),
		enableKafa: '',
		setEnableKafka: (enableKafa: string) => set(_ => ({ enableKafa })),
		ServerGroup: '',
		setServerGroup: (serverGroup: string) => set({ ServerGroup: serverGroup }),
		isFilterLiveStreamer: false,
		setIsFilterLiveStreamer: (value: boolean) => set({ isFilterLiveStreamer: value }),
		isFilterLiveStreaming: false,
		setIsFilterLiveStreaming: (value: boolean) => set({ isFilterLiveStreaming: value }),
		isFilterLiveMatch: false,
		setIsFilterLiveMatch: (value: boolean) => set({ isFilterLiveMatch: value }),
		isFilterStatisticInfo: false,
		setIsFilterStatisticInfo: (value: boolean) => set({ isFilterStatisticInfo: value }),
		isFilterScoreboard: false,
		setIsFilterScoreboard: (value: boolean) => set({ isFilterScoreboard: value }),
		isFilterParlay: false,
		setIsFilterParlay: (value: boolean) => set({ isFilterParlay: value }),
		isFilterTimeMachine: false,
		setIsFilterTimeMachine: (value: boolean) => set({ isFilterTimeMachine: value }),
		isFilterSingleMatchParlay: false,
		setIsFilterSingleMatchParlay: (value: boolean) => set({ isFilterSingleMatchParlay: value }),
		isFilterFastMarkets: false,
		setIsFilterFastMarkets: (value: boolean) => set({ isFilterFastMarkets: value }),
		isFilterCashout: false,
		setIsFilterCashout: (value: boolean) => set({ isFilterCashout: value }),
		resetAllFilter: () => {
			set({
				isFilterLiveStreamer: false,
				isFilterLiveStreaming: false,
				isFilterLiveMatch: false,
				isFilterStatisticInfo: false,
				isFilterScoreboard: false,
				isFilterParlay: false,
				isFilterTimeMachine: false,
				isFilterSingleMatchParlay: false,
				isFilterFastMarkets: false,
				isFilterCashout: false,
			});
		}
	})), {
		name: 'site-store-storage',
		/**
		 * add SiteStore key to keep data in browser storage, prevent data missing after refresh
		*/
		partialize: (state) => Object.fromEntries(
			Object.entries(state).filter(([key]) => ([
				'deepLinkSiteInfo',
				'betTypeFilterCriterionOfSports'
			] as SiteStoreKey[]).includes(key as SiteStoreKey))
		) as SiteStore,
	}),
	shallow
);