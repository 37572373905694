'use client';

import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Breakpoint, BreakpointContextType, BreakpointProviderProps } from '..';
import { matchSome } from '@nf/utils-common/fpw/monadic';

const getBreakpoint = (width: number): Breakpoint => {
	return matchSome(width)
		.on((width: number) => width < 500, () => Breakpoint.xs)
		.on((width: number) => width >= 500 && width < 750, () => Breakpoint.sm)
		.on((width: number) => width >= 750 && width < 1024, () => Breakpoint.md)
		.on((width: number) => width >= 1024 && width < 1250, () => Breakpoint.lg)
		.on((width: number) => width >= 1250 && width < 1450, () => Breakpoint.lgx)
		.otherwise(() => Breakpoint.xl)
}

const BreakpointContext = createContext<BreakpointContextType>({
	breakpoint: getBreakpoint(typeof window !== 'undefined' ? window.innerWidth : 0)
});

export const useBreakpoint = (): BreakpointContextType => useContext(BreakpointContext);

export const BreakpointProvider = ({ children }: BreakpointProviderProps) => {
	const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpoint(typeof window !== 'undefined' ? window.innerWidth : 0));
    
	useEffect(() => {
		const handleResize = (entries: ResizeObserverEntry[]) => {
			const { clientWidth } = entries[0].target;
			const scrolbarWidth = window.innerWidth - clientWidth;
			setBreakpoint(getBreakpoint(clientWidth + scrolbarWidth))
		};
		const resizeObserver = new ResizeObserver(handleResize);
		resizeObserver.observe(document.documentElement);
		return () => {
			resizeObserver.disconnect();
		};
	}, [])

	const value: BreakpointContextType = useMemo(() => ({
		breakpoint
	}), [breakpoint]);
	
	return (
		<BreakpointContext.Provider value={value}>
			{ children } 
		</BreakpointContext.Provider>
	);
}

