import { getCustomizeBetChoice, getBetTypeSetting, getCustomizeLayoutType } from '@/services/settings.service';
import { useAppearanceStore } from '@/store/appearance'
import { BetTypeSetting } from '@/types/bet-type-settings';
import { Maybe } from '@nf/utils-common/fpw/monadic';
import { useCallback } from 'react';
import { useStore } from '.';

export const useGetBetTypeSetting = () => {
	const view = useStore(useAppearanceStore, state => state.view);
	return useCallback((betType: number, sport: number = 0) => {
		return Maybe(getBetTypeSetting)
			.fold(
				() => undefined,
				() => {
					/**
                     * Be careful Object.assign() only copy first level struture
                     */
					const defaultBetTypeSetting = getBetTypeSetting(betType)
					const betTypeSetting = Object.assign({} as BetTypeSetting, defaultBetTypeSetting)
					betTypeSetting.betChoices = getCustomizeBetChoice(betType, view) ?? betTypeSetting.betChoices;
					betTypeSetting.layoutType = getCustomizeLayoutType(betType, view, sport) ?? betTypeSetting.layoutType;
					return betTypeSetting;
				}
			) as BetTypeSetting;
	}, [view])
}