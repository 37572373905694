import { betTypeService } from '@/services/bettype.service';
import { TranslateType } from '@/types/bet-type-settings';
import { Market } from '@nf/types/odds';
import { Box, matchEquals } from '@nf/utils-common/fpw/monadic';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import { useAccountInfo } from './query/account';

export const useGetBetTypeName = () => {
	const params = useParams();
	const { user }=useAccountInfo();
	const isSingleMatchPage = params.matchId !== undefined;
	const betTypeTranslate = useTranslations('BetType');
	const t = useTranslations('General');
	return (betTypeId: number, sportId: number, market?: Market) => Box(betTypeService.getBetTypeSettingTitle(betTypeId, sportId, market?.Resourceid ?? '', isSingleMatchPage))
		.map(([label, translateType]: [string, TranslateType]) => matchEquals(translateType)
			.on('General', () => t(label))
			.on('BetType', () => betTypeTranslate(label))
			.otherwise(() => '')
		)
		.map((translatedTitle: string) => market ? betTypeService.getBetTypeReplaceTitle(
			translatedTitle,
			sportId,
			market,
			user?.Lang
		) : translatedTitle)
		.extract()
}