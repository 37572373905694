import { BettingMode } from './enums';

export interface bettingModeSetting {
	mode: BettingMode;
	label: string;
    descriptionLabel: string;
}

export const bettingModeSettings: bettingModeSetting[] = [
	{
		mode: BettingMode.Independent,
		label: 'lbl_MarsIndependentBetting',
		descriptionLabel: 'lbl_BettingMode2'
	},
	{
		mode: BettingMode.Multiple,
		label: 'lbl_MarsMultipleBetting',
		descriptionLabel: 'lbl_BettingMode3'
	},
];