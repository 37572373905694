import { useQuery } from '@tanstack/react-query';
import { ApiQueryPromise } from '@/types';
import { getResponseJwtHeader } from '@/utils/web';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import queryKeys from '@/constants/query-keys';
import { useSiteStore } from '@/store/site';
import { getAuthToken, useAuthActions } from '@/store/auth';
import { useEffect } from 'react';
import { cloudUrlParser } from '@/utils/aws-domain-util';

export interface SaveSportSortResponse {
	TicketNo: number,
	Code: number,
	Message: string
}

export interface GetSportSortResponse {
	list: SportItem[];
	deft: string[];
}

export interface SportItem {
	Posit: number;
	ID: string;
	Name: string;
}

const saveSportSort = async (sportStr: string): Promise<ApiQueryPromise<Array<SaveSportSortResponse>>> => {
	const authToken = getAuthToken();
	const apiDomain = useSiteStore.getState().apiDomain;
	const body = JSON.stringify({ sportStr: sportStr });
	const response = await fetchComposer.postWithBearer(cloudUrlParser(`${apiDomain.ctcdDomain}/Setting/SaveSportSort`), { body: body })(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useSaveSportSortData = (sportStr: string) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.SAVE_SPORT_SORT],
		queryFn: () => saveSportSort(sportStr),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};
		updateJwt();
	}, [data?.jwtToken]);
	return {
		JwtToken: data?.jwtToken,
		data: data?.data.Data,
		isLoading,
		isError,
		error,
		refetch
	};
};

export const getSportSortJson = async (): Promise<ApiQueryPromise<GetSportSortResponse>> => {
	const authToken = getAuthToken();
	const apiDomain = useSiteStore.getState().apiDomain;
	const response = await fetchComposer.postWithBearer(cloudUrlParser(`${apiDomain.ctcdDomain}/Setting/GetSportSortJson`))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useGetSportSortJson = () => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.GET_SPORT_SORT_JSON],
		queryFn: () => getSportSortJson(),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};
		updateJwt();
	}, [data?.jwtToken]);
	return {
		JwtToken: data?.jwtToken,
		data: data?.data.Data,
		isLoading,
		isError,
		error,
		refetch
	};
};