import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { useStore } from '@/hooks';

export interface AuthStore {
	authToken?: string;
	userName?: string;
	siteName?: string;
	uuid?: string;
	actions: {
		signIn: (authToken: string, userName: string, siteName: string, uuid: string) => void;
		updateAuthToken: (authToken: string) => void;
		signOut: () => void;
		setIsRedisTokenChecked: (isRedisTokenChecked: boolean) => void;
	};
	hasHydrated?: boolean;
	isRedisTokenChecked?: boolean;
}

export const useAuthStore = create<
	AuthStore,
	[['zustand/persist', AuthStore], ['zustand/immer', never]]
>(
	persist(
		immer<AuthStore>((set, get) => ({
			authToken: undefined,
			userName: undefined,
			siteName: undefined,
			actions: {
				signIn: (authToken: string, userName: string, siteName: string, uuid: string) => set(_ => ({
					authToken,
					userName,
					siteName,
					uuid,
				})),
				updateAuthToken: (authToken: string) => set({ authToken }),
				signOut: () => set({
					authToken: undefined,
					userName: undefined,
					siteName: undefined,
					uuid: undefined,
				}),
				setIsRedisTokenChecked: (isRedisTokenChecked: boolean) => set({ isRedisTokenChecked })
			},
			hasHydrated: false,
			isRedisTokenChecked: false
		})),
		{
			name: 'persist:authStore',
			partialize: (state) => ({
				userName: state.userName,
				siteName: state.siteName,
				uuid: state.uuid,
			}) as AuthStore,
			onRehydrateStorage: () => (state) => {
				if (state) {
					state.hasHydrated = true;
				}
			}
		}
	)
);

export const useAuthToken = () => useAuthStore(state => state.authToken);

export const getAuthToken = () => useAuthStore.getState().authToken;

export const useAuthData = () => useStore(
	useAuthStore,
	useShallow(
		state => ({
			userName: state.userName,
			siteName: state.siteName,
			uuid: state.uuid,
			hasHydrated: state.hasHydrated,
			/** check redis token to determine whether is before or after  */
			isRedisTokenChecked: state.isRedisTokenChecked
		})
	)
);

export const useAuthActions = () => useAuthStore(state => state.actions);

export const getAuthActions = () => useAuthStore.getState().actions;