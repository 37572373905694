import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware'
import { OddsPageLayout, ViewEnum } from '@/types';

export interface AppearanceStore {
	oddsPageLayout: OddsPageLayout;
	setOddsPageLayout: (theme: OddsPageLayout) => void;
	toggleOddsPageLayout: () => void;
	fontSize: 'lg' | 'base';
	toggleFontSize: () => void;
	view: ViewEnum;
	setViewBySkinMode: (skinMode: number) => void;	
}
type AppearanceStoreKey = keyof AppearanceStore;
export const useAppearanceStore = createWithEqualityFn<AppearanceStore, [['zustand/persist', AppearanceStore], ['zustand/immer', never]]>(
	persist(immer<AppearanceStore>((set, get) => ({
		oddsPageLayout: 'card',
		setOddsPageLayout: layout => set(_ => ({
			oddsPageLayout: layout
		})),
		toggleOddsPageLayout: () => set(state => ({
			oddsPageLayout: state.oddsPageLayout === 'card' ? 'list' : 'card'
		})),
		fontSize: 'lg',
		toggleFontSize: () => set(state => ({
			fontSize: state.fontSize === 'base' ? 'lg' : 'base'
		})),
		view: ViewEnum.galaxy,
		setViewBySkinMode: (skinMode) => set(_ => ({
			view: ViewEnum[skinMode] ? skinMode as ViewEnum : ViewEnum.galaxy
		}))
	})), {
		name: 'appearance-store-storage',
		partialize: (state) => Object.fromEntries(
			Object.entries(state).filter(([key]) => ([
				'oddsPageLayout',
				'fontSize',
				'view'
			] as AppearanceStoreKey[]).includes(key as AppearanceStoreKey))
		) as AppearanceStore,
	}),
	shallow
);