import { useLocale } from 'next-intl';
import { useGetSiteConfigs } from './use-site-configs';

export const useFilterSabaString = () => {
	const locale = useLocale();
	const { SiteConfigsData } = useGetSiteConfigs();
	const filterSabaString = (filterString: string, leagueGroupId?:number) => {
		let newName = filterString;
		const checkLeagueGroupId = (leagueGroupId) ? (SiteConfigsData?.SabaLeagueGroups ?? []).includes(leagueGroupId) : true; //沒傳LeagueGroupId無腦拔
		if (SiteConfigsData?.EnableFilterSaba && checkLeagueGroupId) {
			const language = locale; 
			let pattern = /\bsaba\b/gi;
			if (['cs', 'ch', 'zhcn', 'zh-TW', 'zh-CN', 'zh-Hans'].includes(language)) {
				pattern = /沙巴/g;
			}
			newName = filterString.replace(pattern, '');
		}
		return newName;
	};
	return {
		filterSabaString
	};
}