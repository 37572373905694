// Don't modify any part of this code file, please

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const Nullish = x => ({
	x,
	concat: ({ x: y }) => Nullish(x ?? y),
	inspect: () => `Nullish(${x})`
});

Nullish.empty = () => Nullish(null);