import { useEffect } from 'react';
import { useLocale } from 'next-intl';
import { useRouter, usePathname } from '@/navigation';

import { useGetSiteConfigs } from '@/hooks/query/use-site-configs';
import { getSiteIgnoredCheckPaths } from '@/services/settings.service';
import { convertClientLocale } from '@nf/utils-common/site';

const ignoredCheckPaths = getSiteIgnoredCheckPaths();

export const useRoutePermission = () => {
	const router = useRouter();
	const pathname = usePathname();
	const locale = useLocale();
	const { SiteConfigsData } = useGetSiteConfigs();
	const isIgnoredPath = ignoredCheckPaths.includes(pathname);

	useEffect(() => {
		if (isIgnoredPath || !SiteConfigsData?.SupportLanguages) {
			return;
		}

		const defaultLocale = SiteConfigsData?.SiteDefaultLanguage?.IsoCode ?? 'en-US';
		const allowedLocales = SiteConfigsData?.SupportLanguages ?? [];
		const isAllowedLocale = allowedLocales.some(localeData => convertClientLocale(localeData.IsoCode) === locale);
		
		if (!isAllowedLocale) {
			router.replace('/', { locale: defaultLocale });
		}
	}, [
		locale, 
		isIgnoredPath, 
		SiteConfigsData?.SupportLanguages, 
		SiteConfigsData?.SiteDefaultLanguage?.IsoCode
	]);
};