import { useQuery } from '@tanstack/react-query';
import { ApiQueryPromise } from '@/types';
import queryKeys from '@/constants/query-keys';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { getResponseJwtHeader } from '@/utils/web';
import { BetsFilter, BetsStatusCount } from '@/features/bets/bets-filter-types';
import { BetsInfo } from '@/features/bets/bets-ticket-types';
import { getFormData } from '@nf/utils-common/form';
import { useSiteStore } from '@/store/site';
import { getAuthToken, useAuthToken } from '@/store/auth';
import { cloudUrlParser } from '@/utils/aws-domain-util';

const fetchUnsettledBetListInfo = async (dataType: number | undefined, isSabaCoin : boolean =false): Promise<ApiQueryPromise<BetsInfo>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;

	const options = {
		body: getFormData({ dataType: dataType, isSabaCoin: isSabaCoin })
	};
	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${ctcdDomain}/Running/GetEarly_chJson`), options)(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useUnsettledBetListInfo = (dataType: number | undefined, isSabaCoin : boolean = false) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.UNSETTLED_BET_LIST_INFO, dataType, isSabaCoin],
		queryFn: () => fetchUnsettledBetListInfo(dataType, isSabaCoin),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});

	return {
		bets: data?.data.Data,
		isLoading,
		isError,
		error,
		refetchUnsettledBetList: refetch
	};
};

const fetchUnsettledCategoryInfo = async (): Promise<ApiQueryPromise<BetsFilter>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;

	const response = await fetchComposer.postWithBearer(cloudUrlParser(`${ctcdDomain}/Running/GetRunningOVRJson?RunningType=E`))(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useUnsettledCategoryInfo = (enabled: boolean) => {
	const { data, isLoading, isFetching, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.UNSETTLED_CATEGORY_INFO],
		queryFn: () => fetchUnsettledCategoryInfo(),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled
	});

	return {
		betsUnsettledCategory: data?.data.Data,
		isLoading,
		isFetching,
		isError,
		error,
		refetchUnsettledCategory: refetch
	};
};

const fetchUnsettledStatusCountInfo = async (): Promise<ApiQueryPromise<BetsStatusCount>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;

	const response = await fetchComposer.postWithBearer(cloudUrlParser(`${ctcdDomain}/MyBets/GetStatusCount`))(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useUnsettledStatusCountInfo = (totalWaitingTickets: number) => {
	const authToken = useAuthToken();
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.UNSETTLED_STATUS_COUNT_INFO],
		queryFn: () => fetchUnsettledStatusCountInfo(),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: !!authToken && totalWaitingTickets > 0,
	});

	return {
		statusCount: data?.data.Data,
		isLoading,
		isError,
		error,
		refetchUnsettledStatusCount: refetch
	};
};
