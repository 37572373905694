import { useGetSiteConfigs, useGetSiteConfigsWithSession } from '@/hooks/query/use-site-configs';
import { useGetSkinModeUrl, GetSkinModeUrlRequest } from '@/hooks/query/use-account-view';
import type { SiteConfigsSettingsData } from '@nf/types/site';
import { useAccountInfo } from '@/hooks/query/account';
import { useSiteStore } from '@/store/site';
import { RedirectType, redirect } from 'next/navigation';
import { useEffect, useState, useRef } from 'react';
import { switchViewService } from '@/services/swtich-view.service';

export const useSwitchBackView = () => {
	const { SiteConfigsData } = useGetSiteConfigs();
	const { SiteConfigsWithSessionData } = useGetSiteConfigsWithSession();
	const { user } = useAccountInfo();
	const WebSkinTypeDefault = SiteConfigsWithSessionData?.WebSkinTypeDefault;
	const platFrom = SiteConfigsWithSessionData?.SwitchFrom;
	const [deepLinkSiteInfo] = useSiteStore(
		state => [state.deepLinkSiteInfo]
	);
	const requestData = {
		PlatForm: platFrom,
		SkinMode: WebSkinTypeDefault
	}
	const url = useGetSkinModeUrl(requestData as GetSkinModeUrlRequest, SiteConfigsData?.WebSkinTypeAllow as string);
	const [redirectURL, setRedirectURL] = useState('');
	const ref = useRef<string | null>(null);

	useEffect(() => {
		if (ref.current !== null) {
			redirect(redirectURL, 'replace' as RedirectType);
		}
	
	}, [redirectURL])

	const switchBackView = () => {
		if (url.GetSkinModeUrlData !== undefined) {
			let urlpath = url.GetSkinModeUrlData as string;
			const shortCode = switchViewService.convertLanguageToShortCode(user?.Lang as string, SiteConfigsData as SiteConfigsSettingsData);
			urlpath = switchViewService.appendDeepLink(deepLinkSiteInfo, shortCode, urlpath);
			setRedirectURL(urlpath);
			ref.current = urlpath;
		}
	}

	return {
		switchBackView
	};
};