import { BettingMode } from '@/features/betslip/betting-mode';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { accountService } from '@/services/account.service';
import { useAccountInfo } from './query/account';
import { useGetAccountSetting } from './query/use-account-settings-data';

export const useBettingMode = (initialMode: number, syncOnCondition: boolean) => {
	const [selectedBettingMode, setSelectedBettingMode] = useState<number>(initialMode);
	const { user } = useAccountInfo();
	const { userSetting } = useGetAccountSetting();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (syncOnCondition) {
			setSelectedBettingMode(user?.BettingMode ?? BettingMode.Independent);
		}
	}, [user?.BettingMode, syncOnCondition]);

	const selectBettingMode = (bettingMode: number) => {
		setSelectedBettingMode(bettingMode);
	};

	const saveBettingMode = () => {
		if (user?.BettingMode !== selectedBettingMode) {
			userSetting && accountService.setBettingMode(queryClient, userSetting, selectedBettingMode);
		}
	};

	return {
		selectedBettingMode,
		selectBettingMode,
		saveBettingMode
	};
};