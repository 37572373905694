import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { ViewEnum, type ApiQueryPromise } from '@/types';
import queryKeys from '@/constants/query-keys';
import { getResponseJwtHeader } from '@/utils/web';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { useGetSiteConfigs } from './use-site-configs';
import { useSiteStore } from '@/store/site';
import { getAuthToken, useAuthToken } from '@/store/auth';
import { useAtomValue } from 'jotai';
import { visitorAtom } from '@/atoms/visitor';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { galaxySidebarSwitchTabIndexAtom, marsSidebarSwitchTabIndexAtom } from '@/atoms/io';
import { getDummySetting, isSabaSport } from '@/utils/saba-sports-util';
import { useAppearanceStore } from '@/store/appearance';
import { dummySettings, virtualSportIds } from '@/settings/sports-settings';
import { getForbiddenSport, getMarketPriority, isDummySportCategory, isVirtualSports } from '@/services/settings.service';
import { GalaxySidebarSwitchTabIndex } from '@/types/nav';
import { RawMarketData, RawMenuData, ProvidedMarket, EventMode, EventName, menuDefinition, MarketType, ProvidedMatchCount, PartialRecord, Group } from '@/types/menu-setting';
import { isGroupHasSufficientData, getOrderedSubMarketData, attachLeagueGroupIdToHref } from '@/settings/esport-setting';
import { matchSome } from '@nf/utils-common/fpw/monadic';
import { useFilterSabaString } from './use-saba-filter';

export type MarketData = RawMarketData & {
	HasLive: boolean;
	Name: string;
	GameId: number;
	Icon: string;
	FirstPriorityFilterableMarket: ProvidedMarket;
	LeagueGroupId?: number;
	GameType?: string;
	Sub?: MarketData[];
};
type ReducedMarketData = {
	sportId: number,
	prioritizedMarket: ProvidedMarket,
	matchCount: number
};

type RoutePrefix = 'favorites' | 'live' | 'sports' | `world/${EventName}`;
const predefinedMenuModes = Object.entries(menuDefinition) as [EventMode, EventName][];
export const getEventName = (mode: string): EventName => {
	const modeIndex = predefinedMenuModes.findIndex(keyValuePair => keyValuePair[0] === mode);
	const modeAndName = modeIndex === -1 ? getCorrespondingKeyValuePair(menuDefinition.M0) : predefinedMenuModes[modeIndex];
	return modeAndName[1];
}
const getCorrespondingKeyValuePair = (mode: string): [EventMode, EventName] => {
	const modeIndex = predefinedMenuModes.findIndex(keyValuePair => keyValuePair[1] === mode);
	return modeIndex === -1 ? getCorrespondingKeyValuePair(menuDefinition.M0) : predefinedMenuModes[modeIndex];
};
type MenuDataByMode = Record<EventMode, MarketData[]>;

export interface MenuItems {
	hasLive: boolean;
	icon: string;
	href: string;
	name: string;
	gameId: string;
	matchCount: number;
	subMenu?: MenuItems[];
	isVirtualTournament?: boolean;
}
export interface MarketFilterProperties {
	key: MarketType,
	value: string,
	count: number,
	route: string
}

export const fetchMenu = async (menuAction: string, authToken: string | undefined, isNeedParlayData: boolean): Promise<ApiQueryPromise<Array<RawMenuData>>> => {
	const apiDomain = useSiteStore.getState().apiDomain;
	const requestData = {
		isParlay: isNeedParlayData,
		both: false,
		defaulteuro: false
	};
	const response = await fetchComposer.postWithBearer(cloudUrlParser(`${apiDomain.ctcdDomain}/${menuAction}`),
		{ body: JSON.stringify(requestData) })(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

/**
 * Get all menu data
 * @param isNeedParlayData 是否為 parlay mode
 * @param blockAutoFetch 是否停用 mount 就自動執行 fetch 的功能
 */
export const useOriginMenuData = (isNeedParlayData?: boolean, blockAutoFetch?: boolean) => {
	const isBefore = !Boolean(useAuthToken());
	const menuAction = isBefore ? 'bfmain/GetContributor' : 'main/GetContributor';
	const visitorInfo = useAtomValue(visitorAtom);
	const useToken = isBefore ? visitorInfo?.AccessToken : getAuthToken();

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.MENU_DATA, menuAction, isNeedParlayData],
		queryFn: () => fetchMenu(menuAction, useToken, isNeedParlayData ?? false),
		refetchInterval: 30000,
		cacheTime: 0,
		staleTime: 0,
		refetchOnWindowFocus: false,
		enabled: !blockAutoFetch
	});
	return {
		menuResponse: data?.data.Data || [],
		isLoading,
		isError,
		error,
		refetch
	};
};

interface CurrentProperties {
	eventMode: EventMode,
	eventName: EventName,
	routePrefix: RoutePrefix,
	sport: number,
	market: string,
	leagueGroupId?: number,
	gameType?: string;
}
export const useCurrentProperties = (): CurrentProperties => {
	const params = useParams();
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const leagueGroupId = searchParams.get('lgid');
	const gameType = searchParams.get('gametype');
	const [eventMode, eventName] = getCorrespondingKeyValuePair((!params.mode || Array.isArray(params.mode)) ? menuDefinition.M0 : params.mode);
	const routePrefix: RoutePrefix = pathname.includes('favorites') ? 'favorites' : pathname.includes('live') ? 'live' : pathname.includes('world') ? `world/${eventName}` : 'sports'
	const currentProperties: CurrentProperties = {
		eventMode,
		eventName,
		routePrefix,
		sport: Number(`${params.sport}`),
		market: `${params.market}`
	};
	if (leagueGroupId) {
		currentProperties.leagueGroupId = Number(leagueGroupId);
	}
	if (currentProperties.sport === 50) {
		currentProperties.gameType = gameType ?? 'FX';
	}
	return currentProperties;
};
//Calculate matches' by sport
// Outright(999) 不顯示在 Sports Menu
const useMenuDataByMode = (isNeedParlayData?: boolean, targetView?: ViewEnum) => {
	const t = useTranslations('General');
	const { SiteConfigsData } = useGetSiteConfigs();
	const viewFromStore = useAppearanceStore(state => state.view);
	const view = targetView ?? viewFromStore;
	const { menuResponse } = useOriginMenuData(isNeedParlayData ?? false);
	const [menuDataByMode, setMenuDataByMode] = useState<MenuDataByMode>({} as MenuDataByMode);
	const { filterSabaString } = useFilterSabaString();
	const forbiddenSport = getForbiddenSport(view);
	const marketPriority = getMarketPriority(view, isNeedParlayData);
	const markets = Object.keys(marketPriority) as MarketType[];
	const defaultMarktet = view === ViewEnum.galaxy ? 'T' : 'L';
	const isESportEnabled = !forbiddenSport.includes('43');
	const enablePinGoalSports = SiteConfigsData?.EnablePinGoalMenuSports ?? [];

	useEffect(() => {
		if (menuResponse && menuResponse?.length > 0) {
			const sortedMenuDataWithoutForbiddenSports = menuResponse.filter(menuData => !forbiddenSport.includes(menuData.GameId.toString()));
			const soccerIndex = sortedMenuDataWithoutForbiddenSports.findIndex((rawMenuData) => rawMenuData.GameId === 1); //Soccer
			const sabaSoccerIndex = sortedMenuDataWithoutForbiddenSports.findIndex((rawMenuData) => rawMenuData.GameId === 997); //Saba Soccer
			if (soccerIndex !== -1 && sabaSoccerIndex !== -1) {
				sortedMenuDataWithoutForbiddenSports.splice(soccerIndex + 1, 0, sortedMenuDataWithoutForbiddenSports.splice(sabaSoccerIndex, 1)[0]);
			}
			const enablePinGaolCategory = dummySettings.filter(setting => setting.categoryId === 97 && enablePinGoalSports.includes(setting.dummyId)).length > 0;
			if (enablePinGaolCategory && soccerIndex !== -1 && !isNeedParlayData) {
				sortedMenuDataWithoutForbiddenSports.splice(soccerIndex + (sabaSoccerIndex !== -1 ? 2 : 1), 0, {
					GameId: 97,
					Name: filterSabaString(t('lbl_SabaPinG')),
					M0: {
						'Days': {},
						'L': NaN,
					},
				} as RawMenuData)
			} 
			const basketballIndex = sortedMenuDataWithoutForbiddenSports.findIndex((rawMenuData) => rawMenuData.GameId === 2); //BasketBall
			const sabaBasketballIndex = sortedMenuDataWithoutForbiddenSports.findIndex((rawMenuData) => rawMenuData.GameId === 993); //Saba BasketBall
			if (basketballIndex !== -1 && sabaBasketballIndex !== -1) {
				sortedMenuDataWithoutForbiddenSports.splice(basketballIndex + 1, 0, sortedMenuDataWithoutForbiddenSports.splice(sabaBasketballIndex, 1)[0]);
			}
			const eventModes: EventMode[] = Object.keys(menuDefinition) as EventMode[];
			const virtualSportsList: any[] = [];
			//Todo : 先拔除第1階段上限不支援的sport
			setMenuDataByMode(
				sortedMenuDataWithoutForbiddenSports
					.reduce((reduced: MenuDataByMode, menuData: RawMenuData, index): MenuDataByMode => {
						const { GameId: gameId, Name } = menuData;
						let gameName = gameId !== 997 ? Name : SiteConfigsData?.EnableFilterSaba ? t('lbl_VirtualSoccer') : t('lbl_SabaSoccer')
						if (gameId === 993) {
							gameName = t('lbl_SabaBasketball')
							if (SiteConfigsData?.EnableFilterSaba) gameName = t('lbl_193')
						}
						eventModes.forEach(eventMode => {
							const eventModeData = menuData[eventMode];
							const firstPriorityFilterableMarket = !eventModeData ? undefined : markets.find((filterableMarket: MarketType): boolean => {
								const providedMarket = marketPriority[filterableMarket];
								const marketCount = eventModeData[providedMarket as ProvidedMarket] ?? 0;
								return marketCount > 0 || Number.isNaN(marketCount) || virtualSportIds.includes(gameId.toString())
							});
							
							if (eventModeData && firstPriorityFilterableMarket) {
								const isVirtualSport = gameId === 997 || gameId === 993;
								const marketDataWithGameId: MarketData = {
									...eventModeData,
									HasLive: eventModeData.L > 0 || Number.isNaN(eventModeData.L),
									GameId: gameId,
									Name: gameName,
									Icon: ((isVirtualSport && SiteConfigsData?.EnableFilterSaba) ? gameId === 997 ? '997_special' : '993_special' : `${gameId}`),
									FirstPriorityFilterableMarket: marketPriority[firstPriorityFilterableMarket] ?? defaultMarktet
								};
								if (!isVirtualSport && (eventMode === 'M27' || eventMode === 'M28' || eventMode === 'M29')) {
									return;
								}

								if (gameId === 97) {
									marketDataWithGameId.Sub = dummySettings.filter(setting => setting.categoryId === gameId && enablePinGoalSports.includes(setting.dummyId)).map(setting => {
										const sub = {
											...eventModeData,
											HasLive: true,
											GameId: setting.dummyId,
											Name: filterSabaString(t(setting.label)),
											Icon: `${setting.dummyId}`,
											FirstPriorityFilterableMarket: 'L',
											L: 0,
											T: 0
										};
										setting.market.forEach(x => {
											sub[x] = NaN
										});
										return sub as MarketData;
									})
								}

								if (gameId === 43 && isESportEnabled && isGroupHasSufficientData(eventModeData.Group)) {
									const orderedSubMarketData = getOrderedSubMarketData(eventModeData, marketPriority).map(marketData => ({ ...marketData, Name: filterSabaString(t(marketData.Name)) }));
									marketDataWithGameId.Sub = orderedSubMarketData;
									if (orderedSubMarketData.length > 0) {
										if (orderedSubMarketData[0].L > 0) {
											marketDataWithGameId.HasLive = true;
										}
										markets.forEach(market => {
											const providedMarket = (marketPriority[market] as keyof ProvidedMatchCount);
											marketDataWithGameId[providedMarket] = orderedSubMarketData[0][providedMarket];
										});
									}
								}
								if (gameId === 50 && isGroupHasSufficientData(eventModeData.Group) && view === ViewEnum.mars && !isNeedParlayData) {
									const group = eventModeData.Group as Group;
									const groupNames = Object.keys(group);
									marketDataWithGameId.Sub = groupNames.map(groupName => {
										const groupMarketData = group[groupName];
										const firstPriorityFilterableMarket = markets.find((filterableMarket: string): boolean => ((groupMarketData[filterableMarket as ProvidedMarket] ?? 0) > 0));

										return {
											...groupMarketData,
											HasLive: groupMarketData.L > 0,
											GameId: 50,
											Name: t(groupName === 'FX' ? 'menu_FIXED' : 'menu_FANCY'),
											GameType: groupName,
											Icon: '',
											FirstPriorityFilterableMarket: marketPriority[firstPriorityFilterableMarket as MarketType] ?? marketPriority[markets[0]] as keyof ProvidedMatchCount
										};
									})
								}
								if (isVirtualSports(gameId)) {
									virtualSportsList.push({
										HasLive: false,
										E: 0,
										L: 0,
										T: 0,
										O: 0,
										WO: 0,
										Days: {},
										GameId: gameId,
										Icon: `${gameId}`,
										LeagueGroupId: 99,
										Name: gameName,
										FirstPriorityFilterableMarket: defaultMarktet
									});
									return
								}
								const modeMenuData = reduced[eventMode];
								if (!modeMenuData) {
									reduced[eventMode] = [marketDataWithGameId];
								} else {
									modeMenuData.push(marketDataWithGameId);
								}
							}
						});
						if (virtualSportsList.length > 0 && index === sortedMenuDataWithoutForbiddenSports.length - 1) {
							const virtualSportMenuData: MarketData = {
								HasLive: false,
								E: 0,
								L: 0,
								T: 0,
								O: 0,
								WO: 0,
								Days: {},
								GameId: virtualSportsList[0]['GameId'],
								Name: t('lbl_VirtualSport'),
								Icon: '18x',
								FirstPriorityFilterableMarket: defaultMarktet,
								Sub: virtualSportsList,
							}
							reduced.M0?.push(virtualSportMenuData);
						}
						return reduced;
					}, {} as MenuDataByMode)
			);
		}
	}, [menuResponse, isNeedParlayData, view]);
	return menuDataByMode;
};
const convertMarketDataOfSportIntoSportId_HighestPriorityMarket_MatchCount = (prioritizedMarkets: PartialRecord<MarketType, ProvidedMarket>) => (marketData: MarketData): ReducedMarketData => {
	const marketOrder = Object.keys(prioritizedMarkets) as MarketType[];
	const providedMarketsFromLowestToHighestPriority = marketOrder.slice().reverse();

	return providedMarketsFromLowestToHighestPriority.reduce((reduced, providedMarket) => {
		const menuType = prioritizedMarkets[providedMarket] as keyof ProvidedMatchCount;
		const matchCountOfMarket = marketData[menuType] ?? 0;
		if (matchCountOfMarket > 0) {
			reduced.prioritizedMarket = menuType;
		}
		reduced.matchCount += matchCountOfMarket;
		return reduced;
	}, {
		sportId: marketData.GameId,
		prioritizedMarket: prioritizedMarkets[(providedMarketsFromLowestToHighestPriority[0])] as keyof ProvidedMatchCount,
		matchCount: 0
	});
};
export const useMenuTypeList = (isNeedParlayData?: boolean) => {
	const menuDataByMode = useMenuDataByMode(isNeedParlayData);
	const view = useAppearanceStore(state => state.view);
	const marketPriority = getMarketPriority(view, isNeedParlayData);
	const markets = Object.keys(marketPriority) as MarketType[];

	return Object.entries(menuDataByMode).map(pair => {
		const [eventMode, marketDataList] = pair;

		const modeId = Number(eventMode.substring(1));
		const modeKey = `M${modeId}` as EventMode;

		const routeValue = menuDefinition[eventMode as EventMode];
		const isAllSports = eventMode === 'M0';
		const marketOrder: MarketType[] = isAllSports ? markets : ['L', 'T', 'E', 'U', 'O'];
		const { sportId: sportNavigateTo, prioritizedMarket: marketNavigateTo, matchCount: matchCountOfMode } = marketDataList
			.map(convertMarketDataOfSportIntoSportId_HighestPriorityMarket_MatchCount(marketPriority))
			.reduce((reducedMarketDataOfMenuType, reducedMarketDataOfSport) => {
				const { sportId, prioritizedMarket, matchCount } = reducedMarketDataOfSport;
				const marketType = markets.find(market => marketPriority[market] === prioritizedMarket) as MarketType;
				const takeCurrentDataAsNavigationTarget = !isAllSports ? !reducedMarketDataOfMenuType.sportId : marketOrder.indexOf(reducedMarketDataOfMenuType.prioritizedMarket) > marketOrder.indexOf(marketType);
				if (takeCurrentDataAsNavigationTarget) {
					reducedMarketDataOfMenuType.sportId = sportId;
					reducedMarketDataOfMenuType.prioritizedMarket = marketType;
				}
				reducedMarketDataOfMenuType.matchCount += matchCount;
				return reducedMarketDataOfMenuType;
			}, {
				sportId: 0,
				prioritizedMarket: marketOrder[marketOrder.length - 1],
				matchCount: 0
			});
		return {
			modeId,
			mode: routeValue,
			modeType: menuDefinition[modeKey],
			path: generateRoute(
				!isAllSports ? `world/${routeValue}` : (marketNavigateTo === 'L' && view === ViewEnum.galaxy) ? 'live' : 'sports',
				sportNavigateTo,
				marketNavigateTo
			),
			matchCount: matchCountOfMode
		};
	});
};
type HomePageProductsIncludingParlay = { n?: MarketData[], p?: MarketData[] };
export type HomePageProductsOfEventModes = Record<EventMode, HomePageProductsIncludingParlay>;
export const useHomePageProductsOfEventModes = (): HomePageProductsOfEventModes => {
	const menuDataByMode = useMenuDataByMode();
	const parlayMenuDataByMode = useMenuDataByMode(true);
	const parlayMarketDataOfMode = Object.entries(parlayMenuDataByMode).reduce((homePageParlayData, pair) => {
		const [eventMode, marketData] = pair;
		homePageParlayData[eventMode as EventMode] = { p: marketData };
		return homePageParlayData;
	}, {} as HomePageProductsOfEventModes);
	return Object.entries(menuDataByMode).reduce((homePageData, pair) => {
		const [eventMode, marketData] = pair;
		const dataOfMode = homePageData[eventMode as EventMode];
		if (dataOfMode === undefined) {
			homePageData[eventMode as EventMode] = { n: marketData };
		} else {
			dataOfMode.n = marketData;
		}
		return homePageData;
	}, parlayMarketDataOfMode);
};
export const useCurrentMenuList = (isNeedParlayData?: boolean) => { // get sports of current route
	const { eventMode: currentEventMode, routePrefix: currentRoutePrefix } = useCurrentProperties();
	const menuDataByMode = useMenuDataByMode(isNeedParlayData);
	const [currentData, setCurrentData] = useState<MarketData[]>([]);
	useEffect(() => {
		const dataOfMode = menuDataByMode[currentEventMode];
		const marketDataOfCurrentMode = (!dataOfMode || !Array.isArray(dataOfMode) || !dataOfMode.length) ? [] : dataOfMode;
		setCurrentData(currentRoutePrefix === 'live' ? marketDataOfCurrentMode.filter(sportItem => currentRoutePrefix !== 'live' || sportItem.HasLive) : marketDataOfCurrentMode)
	}, [menuDataByMode, currentEventMode, currentRoutePrefix]);
	return currentData;
}

export const useMenuSportName = (sport: number) => {
	const currentMenuList = useCurrentMenuList();

	return currentMenuList.find(
		element => element.GameId === sport
	)?.Name ?? '';
};

const generateRoute = (routePrefix: RoutePrefix, sport: number, market: MarketType) => {
	const formattedMarket = market === 'O' ? 'outright' : market.toLowerCase();
	return routePrefix === 'favorites' ? `/favorites/${formattedMarket}` : routePrefix === 'live' ? `/${routePrefix}/${sport}` : `/${routePrefix}/${sport}/${formattedMarket}`;
};
export const useCurrentMarketFilters = (marketSequence: MarketType[]): MarketFilterProperties[] => {
	const { routePrefix: currentRoutePrefix, sport: currentSport, leagueGroupId, gameType } = useCurrentProperties();
	const [currentMarketData, setCurrentMarketData] = useState<MarketData | undefined>(undefined);
	const view = useAppearanceStore(state => state.view);
	const isParlayMode = useSiteStore(state => state.isParlayMode);
	const marketPriority = getMarketPriority(view);
	const markets = Object.keys(marketPriority) as MarketType[];
	const marketsHasMatchCount = markets.map(market => marketPriority[market]) as (keyof ProvidedMatchCount)[];
	const currentMenuList = useCurrentMenuList(isParlayMode);
	const gameTypeMarketData = gameType ? ((currentMarketData?.Group ?? {}) as Group)[gameType] : null;

	useEffect(() => {
		if (currentRoutePrefix === 'favorites') {
			setCurrentMarketData(
				currentMenuList.reduce((matchCountOfMarkets, marketDataOfEachSport) => {
					marketsHasMatchCount.forEach(filterableMarket => {
						const matchCount = marketDataOfEachSport[filterableMarket];
						if (matchCount) {
							if (!matchCountOfMarkets[filterableMarket]) {
								matchCountOfMarkets[filterableMarket] = matchCount;
							} else {
								matchCountOfMarkets[filterableMarket] += matchCount;
							}
						}
					})
					return matchCountOfMarkets;
				}, {} as MarketData)
			);
		} else {
			const dummySetting = getDummySetting(currentSport);
			const marketDataOfSport = currentMenuList.find(marketData => marketData.GameId === (dummySetting?.categoryId ?? currentSport));
			if (dummySetting && marketDataOfSport?.Sub) {
				setCurrentMarketData(marketDataOfSport.Sub.find(x => x.GameId === dummySetting.dummyId));
				return;
			}
			setCurrentMarketData(!leagueGroupId ? marketDataOfSport : marketDataOfSport?.Sub?.find(marketDataOfSub => marketDataOfSub.LeagueGroupId === leagueGroupId))
		}
	}, [currentMenuList, currentSport, currentRoutePrefix, leagueGroupId]);
	return !currentMarketData ? []
		: marketSequence
			.filter(market => {
				const isFilterableMarket = markets.includes(market);
				const marketCount = currentMarketData[market === 'U' ? 'WO' : market];
				const hasMatch = marketCount > 0 || Number.isNaN(marketCount);
				return isFilterableMarket && hasMatch;
			})
			.map(market => {
				const providedMarket = marketPriority[market] as keyof ProvidedMatchCount;
				const count = gameTypeMarketData ? gameTypeMarketData[providedMarket] : currentMarketData[providedMarket];
				return {
					key: market,
					value: market === 'O' ? 'outright' : market.toLowerCase(),
					count: count,
					route: generateRoute(currentRoutePrefix, currentSport, market) +
						(!leagueGroupId ? '' : `?lgid=${leagueGroupId}`) +
						(!gameType ? '' : `?gametype=${gameType}`)
				}
			});
};

//Filter no matches' sports
export const useSportMenuItemGetter = (isNeedParlayData?: boolean, targetView?: ViewEnum) => {
	const viewFromStore = useAppearanceStore(state => state.view);
	const view = targetView ?? viewFromStore;
	const sidebarSwitchTabIndex = useAtomValue(view === ViewEnum.galaxy ? galaxySidebarSwitchTabIndexAtom : marsSidebarSwitchTabIndexAtom);
	const menuDataByMode = useMenuDataByMode(isNeedParlayData, view);
	const marketPriority = getMarketPriority(view, isNeedParlayData);
	const markets = Object.keys(marketPriority) as MarketType[];
	const providedMarkets = markets.map(market => marketPriority[market]) as (keyof ProvidedMatchCount)[];
	const defultMarket = view === ViewEnum.galaxy ? 'T' : 'L';

	const getSportMenuItemsOfMode = (mode: EventMode): MenuItems[] => {
		const [, eventName] = getCorrespondingKeyValuePair(menuDefinition[mode]);
		const dataOfMode: MarketData[] = menuDataByMode[mode] ?? [];
		const isCurrentlyLive = mode === 'M0' && (view === ViewEnum.galaxy && sidebarSwitchTabIndex === GalaxySidebarSwitchTabIndex.Live);

		const convertMarketDataToMenuItem = (marketData: MarketData): MenuItems => {
			const marketType = markets.find(market => marketPriority[market] === marketData.FirstPriorityFilterableMarket);

			return {
				hasLive: marketData.HasLive,
				name: marketData.Name,
				icon: marketData.Icon,
				href: generateRoute(
					mode !== 'M0' ? `world/${eventName}` : isCurrentlyLive && !isVirtualSports(marketData.GameId) ? 'live' : 'sports',
					marketData.GameId,
					marketType ?? defultMarket
				),
				gameId: String(marketData.GameId),
				matchCount: providedMarkets
					.filter(market => isCurrentlyLive ? market === 'L' : true)
					.reduce((sum, market) => {
						const matchCountOfMarket = marketData[market];
						if (matchCountOfMarket && typeof marketData[market] === 'number') {
							sum += marketData[market];
						}
						return sum;
					}, 0)
			};
		};

		return dataOfMode
			.filter((marketData: MarketData): boolean => isCurrentlyLive ? (isVirtualSports(marketData.GameId) ? true : marketData.HasLive) : true)
			.map((marketData: MarketData): MenuItems => {
				const menuItem = convertMarketDataToMenuItem(marketData);
				if (marketData.Sub) {
					menuItem.subMenu = marketData.Sub.filter(marketData => !isCurrentlyLive || isVirtualSports(marketData.GameId) ? true : marketData.HasLive)
						.map(marketData => {
							const menuItem = convertMarketDataToMenuItem(marketData);
							return menuItem.gameId === '43' ? attachLeagueGroupIdToHref(menuItem, marketData) :
								menuItem.gameId === '50' ? attachGameTypeToHref(menuItem, marketData) : menuItem;
						});
					if (menuItem.gameId === '97') {
						menuItem.href = menuItem.subMenu[0].href;
					}
				}
				return menuItem;
			});
	};

	return {
		getSportMenuItemsOfMode
	}

};
export const useSportHref = () => {
	const { sport: preferredSport, market, leagueGroupId } = useCurrentProperties();
	const menuDataByMode = useMenuDataByMode();
	const view = useAppearanceStore(state => state.view);
	const marketPriority = getMarketPriority(view);
	const markets = Object.keys(marketPriority) as MarketType[];
	const providedMarkets = markets.map(market => marketPriority[market]) as (keyof ProvidedMatchCount)[];;

	const createSportHref = (fallbackRoute: string) => {
		const preferredMarket: ProvidedMarket = market === 'outright' ? 'O' : market === 'u' ? 'WO' : market.toUpperCase() as ProvidedMarket;
		const fallbackToLive = /^\/live/.test(fallbackRoute);
		const fallbackToSports = /^\/sports/.test(fallbackRoute);
		const [eventMode, eventName] = getCorrespondingKeyValuePair((fallbackToLive || fallbackToSports) ? 'sports' : fallbackRoute.replace(/^\/world\/([a-z\-]+)\/\d+\/\w+/, '$1'));
		const dataOfMode: MarketData[] = menuDataByMode[eventMode] ?? [];
		const marketsToBeVerified: ProvidedMarket[] = fallbackToLive ? ['L'] : providedMarkets;
		const marketDataOfSport: MarketData | undefined = dataOfMode.reduce((result: MarketData | undefined, marketData: MarketData, dataIndex: number): MarketData | undefined => {
			const isSportCandidate = marketData.GameId === preferredSport || dataIndex === 0;
			const hasPositiveMatchCount = marketsToBeVerified.some(market => marketData[market as ProvidedMarket] ?? 0 > 0);
			if (isSportCandidate && hasPositiveMatchCount) {
				result = marketData;
			}
			return result;
		}, undefined);
		if (eventMode !== 'M0' || !marketDataOfSport) {
			return fallbackRoute;
		}
		const destinationMarket = (marketsToBeVerified.includes(preferredMarket) && marketDataOfSport[preferredMarket]) ? preferredMarket : marketsToBeVerified.find(market => marketDataOfSport[market] ?? 0 > 0) ?? providedMarkets[0]
		const hrefToSport = generateRoute(
			fallbackToLive ? 'live' : fallbackToSports ? 'sports' : `world/${eventName}`,
			marketDataOfSport?.GameId,
			destinationMarket === 'WO' ? 'U' : destinationMarket
		);
		if (!leagueGroupId || !marketDataOfSport.Sub) {
			return hrefToSport;
		} else {
			const indexOfSubMarketData = marketDataOfSport.Sub.findLastIndex((subMarket, index) => {
				const isCandidate = index == 0 || subMarket.LeagueGroupId === leagueGroupId;
				const hasPositiveMatchCount = marketsToBeVerified.some(marketToBeVerified => subMarket[marketToBeVerified] ?? 0 > 0);
				return isCandidate && hasPositiveMatchCount;
			});
			if (indexOfSubMarketData === -1) {
				return hrefToSport;
			}
			const subMarketData = marketDataOfSport.Sub[indexOfSubMarketData];
			const _market = (marketsToBeVerified.includes(preferredMarket) && subMarketData[preferredMarket]) ? preferredMarket : marketsToBeVerified.find(m => subMarketData[m] ?? 0 > 0) || marketsToBeVerified[0];
			return indexOfSubMarketData === -1 ? hrefToSport
				: generateRoute(
					fallbackToLive ? 'live' : fallbackToSports ? 'sports' : `world/${eventName}`,
					subMarketData.GameId,
					_market === 'WO' ? 'U' : _market
				)
				+ (!subMarketData.LeagueGroupId ? '' : `?lgid=${subMarketData.LeagueGroupId}`)
				+ (!subMarketData.GameType ? '' : `?gametype=${subMarketData.GameType}`)
		}
	};

	return {
		createSportHref
	};
};
export const useCurrentSportMenuItems = (isNeedParlayData?: boolean) => {
	const [menuItems, setMenuItems] = useState(Array<MenuItems>);
	const currentMenuList = useCurrentMenuList(isNeedParlayData);
	const currentRoutePrefix = useCurrentProperties().routePrefix;
	const view = useAppearanceStore(state => state.view);
	const marketPriority = getMarketPriority(view, isNeedParlayData);
	const markets = Object.keys(marketPriority) as MarketType[];
	const providedMarkets = markets.map(market => marketPriority[market]) as (keyof ProvidedMatchCount)[];

	useEffect(() => {
		setMenuItems(currentMenuList
			.map(sportItem => {
				const marketType = markets.find(market => marketPriority[market] === sportItem.FirstPriorityFilterableMarket) as MarketType;
				isDummySportCategory(sportItem.GameId)
				const href = matchSome(sportItem.GameId)
					.on(isDummySportCategory, () => generateRoute(currentRoutePrefix, sportItem.Sub?.[0].GameId ?? sportItem.GameId, marketType))
					.otherwise(() => generateRoute(currentRoutePrefix, sportItem.GameId, marketType) + (sportItem.GameId === 50 && view === ViewEnum.mars ? `?gametype=${(sportItem.Sub ?? [])[0]?.GameType}` : ''))
				return {
					hasLive: sportItem.HasLive,
					name: sportItem.Name,
					icon: sportItem.Icon,
					href: href,
					gameId: String(sportItem.GameId),
					matchCount: providedMarkets.reduce((sum, providedMarket) => {
						const matchCountOfMarket = sportItem[providedMarket];
						if (matchCountOfMarket && typeof sportItem[providedMarket] === 'number') {
							sum += sportItem[providedMarket];
						}
						return sum;
					}, 0)
				}
			}));
	}, [currentMenuList, currentRoutePrefix]);

	return menuItems;
};

export const useCurrentSubMenuItems = (isNeedParlayData?: boolean): Array<MenuItems> => {
	const { eventMode, sport: currentSport, routePrefix: currentRoutePrefix, market } = useCurrentProperties();
	const sport = getDummySetting(currentSport)?.categoryId ?? currentSport;
	const menuDataByMode = useMenuDataByMode(isNeedParlayData);
	const sportsOfCurrentEventMode = menuDataByMode[eventMode];
	const view = useAppearanceStore(state => state.view);
	const marketPriority = getMarketPriority(view, isNeedParlayData);
	const markets = Object.keys(marketPriority) as MarketType[];
	const providedMarkets = markets.map(market => marketPriority[market]) as (keyof ProvidedMatchCount)[];
	const defaultMarket = view === ViewEnum.galaxy ? 'T' : 'L';

	if (!sportsOfCurrentEventMode) {
		return [];
	}
	const indexOfCurrentSport = sportsOfCurrentEventMode.findIndex(sportOfCurrentEventMode => matchSome(sportOfCurrentEventMode.GameId)
		.on(() => isVirtualSports(sport), isVirtualSports)
		.otherwise(() => sportOfCurrentEventMode.GameId === sport));

	return (indexOfCurrentSport === -1 || !sportsOfCurrentEventMode[indexOfCurrentSport].Sub) ? []
		: (sportsOfCurrentEventMode[indexOfCurrentSport].Sub || [])
			.filter(marketData => currentRoutePrefix !== 'live' || marketData.HasLive)
			.map(marketData => {
				const currentMarket = market as MarketType;
				const currentProvidedMarket = marketPriority[currentMarket];
				const marketType = marketData[currentProvidedMarket ?? defaultMarket] > 0 ? (currentProvidedMarket ? currentMarket : defaultMarket) : markets.find(market => marketPriority[market] === marketData.FirstPriorityFilterableMarket);

				const menuItem = {
					hasLive: marketData.HasLive,
					name: marketData.Name,
					icon: marketData.Icon,
					href: generateRoute(currentRoutePrefix, marketData.GameId, marketType ?? defaultMarket),
					gameId: `${marketData.GameId}`,
					matchCount: currentRoutePrefix === 'live' ? marketData.L : providedMarkets.reduce((sum, providedMarket) => {
						const matchCountOfMarket = marketData[providedMarket];
						if (matchCountOfMarket && typeof marketData[providedMarket] === 'number') {
							sum += marketData[providedMarket];
						}
						return sum;
					}, 0)
				};
				return marketData.GameId === 43 ? attachLeagueGroupIdToHref(menuItem, marketData) :
					marketData.GameId === 50 ? attachGameTypeToHref(menuItem, marketData) : menuItem;
			})
};

export const useSwitchParlayRoute = (isToParlay: boolean) => {
	const { menuResponse } = useOriginMenuData(isToParlay);
	const { sport, market } = useCurrentProperties();
	const view = useAppearanceStore(state => state.view);
	const marketPriority = getMarketPriority(view, isToParlay);
	const markets = Object.keys(marketPriority) as MarketType[];

	const menuData = menuResponse.find(menuData => menuData.GameId === sport);

	if (menuData && !isVirtualSports(sport)) {
		const marketSort = rearrangeArray(markets, markets.indexOf(market.toUpperCase() as MarketType));
		const targetMarket = marketSort.find(market => menuData.M0[marketPriority[market] as keyof ProvidedMatchCount] > 0) as MarketType;
		return { sport, market: (targetMarket ?? 'D').toLowerCase() };
	}

	const defaultMenuData = menuResponse[0];
	if (defaultMenuData) {
		const targetMarket = markets.find(market => defaultMenuData?.M0[marketPriority[market] as keyof ProvidedMatchCount] > 0) as MarketType;

		return { sport: defaultMenuData.GameId, market: (targetMarket ?? 'l').toLowerCase() };
	}

	return { sport: 1, market: 'l' };

	/*const rearrangeSportSort = rearrangeArray(sportSort, index);
	const targetSport = rearrangeSportSort.find(sport => {
		const rawMenuData = targetMenuData.find(rawMenuData => rawMenuData.GameId === sport);

		if (!rawMenuData) {
			return false;
		}
	
		return rawMenuData.M0[marketPriority[market.toUpperCase() as MarketType] as keyof ProvidedMatchCount] > 0
	}) as number;
	
	return { sport: targetSport, market }*/
};

const rearrangeArray = <T>(array: Array<T>, index: number) => {
	return array.slice(index).concat(array.slice(0, index));
}

const attachGameTypeToHref = (menuItem: MenuItems, marketData: MarketData): MenuItems => {
	return !marketData.GameType ? menuItem : { ...menuItem, href: `${menuItem.href}?gametype=${marketData.GameType}` }
}