import { getESportWebUrl } from '@/hooks/query/use-third-party-authorization';
import { checkUmBySportType } from '@/hooks/query/use-um-message';
import { modalService } from './modal.service';
import { MarketLabel } from '@/types';

interface OpenESportProps {
	siteId?: string;
	umSource?: string;
	gameId?: string;
	market?: MarketLabel | null;
	leagueId?: number;
	matchId?: number;
	isDeepLink?: boolean;
}
export const openESport = (openESportProps: OpenESportProps) => {
	if (!!window) {
		const newWindow = openESportProps.isDeepLink ? null : window.open('about:blank', '_blank');
		checkUmBySportType(openESportProps.siteId as string, '43').then(data => {
			if (data.isUM) {
				if (!openESportProps.isDeepLink) {
					newWindow?.close();
				}
				modalService.openMessageModal(openESportProps.umSource ?? 'Under Maintenance');
			} else {
				getESportWebUrl(openESportProps).then(data => {
					if (openESportProps.isDeepLink) {
						window.location.href = data.data.Data ?? '';
						return;
					} else {
						if (newWindow) {
							newWindow.location = data.data.Data ?? '';
							newWindow.focus();
						}
					}
				});
			}
		})
	}
}