const generalProtectedPaths = [
	'/account', 
	'/bets', 
	'/favorites',  
	'/results'
];

export const siteSettings = {
	ignoredCheckPaths: [
		'/deeplink'
	],
	protectedPaths: {
		before: [
			...generalProtectedPaths,
			'/message',
		],
		notDeposit: generalProtectedPaths
	},
};