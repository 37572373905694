import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { useSiteStore } from '@/store/site';
import { getAuthToken } from '@/store/auth';
import type { ApiSuccessResponse } from '@nf/types/api';

const enableDetectorTimeKey : string = 'EnableDetectorTime';
const doLoginDetectorKey : string = 'DoLoginDetector';

export const updateShouldDoLoginDetector = (value: boolean) => {
	localStorage.setItem(doLoginDetectorKey, value.toString());
}

const updateNextDetectorTime = () => {
	const dateTime : Date = new Date()
	dateTime.setHours(dateTime.getHours() + 1);
	localStorage.setItem(enableDetectorTimeKey, dateTime.toString());
}

const getNextDetectorTime = () : Date | null => {
	const dateTime = localStorage.getItem(enableDetectorTimeKey);
	return dateTime == null ? null : new Date(dateTime)
}

const shouldDoLoginDetector = () : boolean => {
	const doLoginDetector = localStorage.getItem(doLoginDetectorKey);
	return doLoginDetector === 'true';
}

const isValidDetectorDomains = (detectorDomains: Array<string> | null | undefined) => {
	return detectorDomains !== null
			&& detectorDomains !== undefined
			&& detectorDomains.length > 0;
}

const enableDetector = () => {
	const enableDetectorTime = getNextDetectorTime();
	return shouldDoLoginDetector()
		&& (enableDetectorTime === null || new Date() >= enableDetectorTime);
}

interface DetectorResponse{
	encrypted: string;
	request_id: string;
	timestamp: string;
}

const recordDetectInfo = async (data: any) => {
	const authToken = getAuthToken();
	const ctcdDomain = useSiteStore.getState().apiDomain.ctcdDomain;

	await fetchComposer.postWithBearer(`${ctcdDomain}/api/Detector/Record`, {
		body: JSON.stringify(data)
	})(authToken);
}

export const detectorDomains = async (custId: number | null | undefined, userIp: string | null | undefined) => {

	try {
		const authToken = getAuthToken();
		const ctcdDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	
		const getDetectorDomains = async (): Promise<ApiSuccessResponse<Array<string>>> => {
			const response = await fetchComposer.postWithBearer(`${ctcdDomain}/api/Detector/GetDomains`, {
				body: JSON.stringify({
					'hostDomain': location.host
				})
			})(authToken);
	
			return response.json();
		}	
	
		if (enableDetector()) {
			updateShouldDoLoginDetector(false);
			const detectorDomains = (await getDetectorDomains()).Data;
	
			if (isValidDetectorDomains(detectorDomains)) {
				updateNextDetectorTime();
	
				setTimeout(async () => {
					detectorDomains?.forEach(async (domain) => {
						const startTime = (new Date()).getTime();
						try {
							const response = await fetchComposer.get(`${domain}/_analytics/_collect/webcheck`);
							const endTime = (new Date()).getTime();
							const headers = new Map();
							response.headers.forEach((value, key) => headers.set(key, value));
							
							if (!response.ok) {
								const recordData = {
									'detection_time': new Date(),
									'Customer_Id': custId,
									'Request_Header_Host': domain,
									'Response_Headers': Object.fromEntries(headers),
									'Http_Status': response.status,
									'Response_Time_Ms': endTime - startTime,
									'User_Agent': navigator.userAgent,
									'Request_Id': 'detector error',
									'X_User_Ip': userIp,
								};

								recordDetectInfo(recordData);
								return;
							}
			
							const responseData = await response.json() as DetectorResponse;
							const recordData = {
								'detection_time': responseData.timestamp,
								'Customer_Id': custId,
								'Request_Header_Host': domain,
								'Response_Headers': Object.fromEntries(headers),
								'Http_Status': response.status,
								'Response_Time_Ms': endTime - startTime,
								'User_Agent': navigator.userAgent,
								'Request_Id': responseData.request_id,
								'X_User_Ip': userIp,
							};
							recordDetectInfo(recordData);

						} catch (error) {
							const endTime = (new Date()).getTime();
							const recordData = {
								'detection_time': new Date(),
								'Customer_Id': custId,
								'Request_Header_Host': domain,
								'Response_Time_Ms': endTime - startTime,
								'User_Agent': navigator.userAgent,
								'Request_Id': 'detector error',
								'X_User_Ip': userIp,
							};
							recordDetectInfo(recordData);
						}
					})
			
				}, 1 * 1000)
			}
		}
	} catch (error) {
		updateShouldDoLoginDetector(false);
	}
} 