import { dummySettings } from '@/settings/sports-settings';
import { matchEquals } from '@nf/utils-common/fpw/monadic';

const SABA_SPORTS = [993, 997];

export const isSabaSport = (sportId: number) => {
	return SABA_SPORTS.includes(sportId);
}

export const convertSabaSportToNormalSport = (sport: number) => matchEquals(sport)	
	.on(997, () => 1)
	.on(993, () => 2)
	.otherwise(() => sport) 
	
export const getDummySetting = (dummyId: number) => dummySettings.find(setting => setting.dummyId === dummyId);