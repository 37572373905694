import { ApiQueryPromise, MarketLabel } from '@/types';
import { getResponseJwtHeader } from '@/utils/web';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { useSiteStore } from '@/store/site';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { getAuthToken } from '@/store/auth';

interface OpenESportProps {
	siteId?: string;
	umSource?: string;
	gameId?: string;
	market?: MarketLabel | null;
	leagueId?: number;
	matchId?: number;
	isDeepLink?: boolean;
}
export const getESportWebUrl = async (openESportProps: OpenESportProps): Promise<ApiQueryPromise<string>> => {
	const { ctcdDomain } = useSiteStore.getState().apiDomain;
	const authToken = getAuthToken();
	const filteredParams = Object.keys(openESportProps).reduce((acc, key) => {
		const value = openESportProps[key as keyof OpenESportProps];
		if (value !== undefined && value !== null) {
			acc[key] = value;
		}
		return acc;
	}, {} as Record<string, any>);
	
	const queryString = Object.keys(filteredParams)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filteredParams[key]))
		.join('&');

	const response = await fetchComposer.getWithBearer(
		cloudUrlParser(`${ctcdDomain}/AuthorizationCustomer/OpenEsportWeb?${queryString}`)
	)(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};