export enum Breakpoint {
    /**
     * width < 500px
     */
    xs,
    /**
     * 750px > width >= 500px
     */
    sm,
    /**
     * 1024px > width >= 750px
     */
    md,
    /**
     * 1250px > width >= 1024px
     */
    lg,
    /**
     * 1450px > width >= 1250px
     */
    lgx,
    /**
     * width >= 1450px
     */
    xl
}