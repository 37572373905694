import { MatchGroup } from '@/features/odds-page/types';
import { betTypesSetWithoutPadZero, moreAisanLinesBetTypes } from '@/settings/bet-type-settings';
import { moreAsianLinesSports } from '@/settings/sports-settings';
import { MarketLabel } from '@/types';
import { Market, SportMatchModel } from '@nf/types/odds';
import { Box, MaybeMatch, matchEquals, matchSome } from '@nf/utils-common/fpw/monadic';
import { stringEmptyCheck } from '@nf/utils-common/fpw/utils';

//浮點數相減
export const floatSubtraction = (arg1: number, arg2: number): string => {
	let r1, r2;
	try {
		r1 = arg1.toString().split('.')[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split('.')[1].length
	} catch (e) {
		r2 = 0
	}
	const m = Math.pow(10, Math.max(r1, r2));
	const n = (r1 >= r2) ? r1 : r2;
	return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
//浮點數相加
export const floatAdd = (arg1: number, arg2: number) => {
	let r1, r2;
	try {
		r1 = arg1.toString().split('.')[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split('.')[1].length;
	} catch (e) {
		r2 = 0;
	}
	const m = Math.pow(10, Math.max(r1, r2));
	return (floatMul(arg1, m) + floatMul(arg2, m)) / m;
}
//浮點數相乘
export const floatMul = (arg1: number, arg2: number) => {
	let m = 0;
	const s1 = arg1.toString(), s2 = arg2.toString();
	try {
		m += s1.split('.')[1].length;
	} catch (e) { }
	try {
		m += s2.split('.')[1].length;
	} catch (e) { }
	return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
}
//浮點數相除
export const floatDiv = (arg1: number, arg2: number) => {
	let t1 = 0, t2 = 0;
	try {
		t1 = arg1.toString().split('.')[1].length
	} catch (e) { }
	try {
		t2 = arg2.toString().split('.')[1].length
	} catch (e) { }
	const r1 = Number(arg1.toString().replace('.', ''))
	const r2 = Number(arg2.toString().replace('.', ''))
	return (r1 / r2) * Math.pow(10, t2 - t1);
}
//取得強弱隊
export const checkFavTeam = (line: string, adjOdds: any) => {
	let fav;
	const lineNumber: number = Number(line);
	if (!isNaN(lineNumber) && lineNumber !== 0) {
		fav = lineNumber > 0 ? 'h' : 'a';
	} else if (lineNumber == 0 && (adjOdds['h'] !== adjOdds['a'])) {
		const h = getConvertedOddsFromMalay(1, adjOdds['h']);
		const a = getConvertedOddsFromMalay(1, adjOdds['a']);
		fav = h < a ? 'h' : 'a';
	} else {
		fav = 'h';
	}
	const weak = fav == 'h' ? 'a' : 'h';
	return { fav, weak }
}
//MY Odds轉換其他OddsType
export const getConvertedOddsFromMalay = (oddstype: number, oddsvalue: number) => {
	let dOdds = oddsvalue;

	switch (oddstype) {
		case 1: // Decimal Odds
			dOdds = decimalOdds(dOdds);
			break;
		case 2: // HK odds
			dOdds = hkOdds(dOdds);
			break;
		case 3: // Indo Odds
			dOdds = Number(indoOdds(dOdds));
			break;
		case 5: // American Odds
			dOdds = americanOdds(dOdds);
			break;
		default:
			dOdds = Number(dOdds.toFixed(2));
			break;
	}
	return dOdds;
}

export const getFromatedOdds = (oddsType: number) => (odds: number): string => {
	return matchEquals(oddsType)
		.on(5, () => {
			return addCommasWithFix(floor(odds, 0), 0);
		})
		.otherwise(() => addCommasWithFix(floor(odds, 2), 2))
}



export const getSpecialOddsForMoneyline = (betType: number) => (odds: number): number => {
	return MaybeMatch([20, 21].includes(betType))
		.fold(
			() => odds,
			() => matchSome(odds)
				.on((odds: number) => odds > 0 && odds < 0.01, () => 0.01)
				.on((odds: number) => odds > -0.04 && odds < 0, () => -0.04)
				.otherwise((odds: number) => odds)
		)
}

export const getDisplayOdds = (odds: number, oddsType: number, priceType: number, betType: number): string => {
	return MaybeMatch(priceType === 0)
		.fold(
			() => Box(odds)
				.map(formatMinus1And100(oddsType))
				.map((odds: number) => floor(odds, 2))
				.map((odds: number) => addCommasWithFix(odds, 2))
				.fold(removePadZeroByBetType(betType)),
			() => Box(odds)
				.map(getSpecialOddsForMoneyline(betType))
				.map((odds: number) => getConvertedOddsFromMalay(oddsType, odds))
				.map(formatMinus1And100(oddsType))
				.map(getFromatedOdds(oddsType))
				.fold(removePadZeroByBetType(betType))
		)
}

export const removePadZeroByBetType = (betType: number) => (displayOdds: string) => MaybeMatch(betTypesSetWithoutPadZero.has(betType))
	.chain(() => stringEmptyCheck(displayOdds))
	.fold(
		() => displayOdds,
		(displayOdds: string) => {
			const convertedOdds = Number(displayOdds.replace(/,/g, ''));
			if (isNaN(convertedOdds)) return displayOdds;
			return addCommas(convertedOdds.toString());
		}
	)

export const formatMinus1And100 = (oddsType: number) => (odds: number) => {
	const useFormat = (odds == -1) || (oddsType == 5 && odds == -100);
	return useFormat ? (0 - odds) : odds;
}

//MY Odds => American Odds
export const americanOdds = (odds: number) => {
	return floor(floatMul(Number(indoOdds(odds)), 100), 0);

}
//MY Odds => Decimal Odds
export const decimalOdds = function (odds: number) {
	return floatAdd(hkOdds(odds), 1);
}
//MY Odds => HK Odds
export const hkOdds = (odds: number) => {
	if (odds < 0) {
		odds = floatDiv(-1, odds);
		odds = floatDiv(floor(floatMul(odds, 100), 0), 100);
	}
	return odds;
}
//MY Odds => Indo Odds
export const indoOdds = (updatedOddsValue: number) => {

	if (updatedOddsValue > 0) {
		if (updatedOddsValue > 0.79) {
			updatedOddsValue = Math.floor(((1 / updatedOddsValue) * 100)) / 100;
		} else {
			updatedOddsValue = Math.ceil(((1 / updatedOddsValue) * 100)) / 100;
		}
		return (-updatedOddsValue).toFixed(2);
	} else {
		if (updatedOddsValue < -0.79) {
			updatedOddsValue = Math.round(Math.floor(Math.abs(1 / updatedOddsValue) * 100)) / 100;
			return updatedOddsValue.toFixed(2);;
		} else {
			updatedOddsValue = Math.round(((Math.ceil(Math.abs((1 / updatedOddsValue)) * 100) + 0.01) / 100) * 100) / 100;
			return updatedOddsValue.toFixed(2);;
		}
	}
}

// Decimal Odds -> MY Odds
export const decimalOddsToMyOdds = (odds: number) => {
	let value = Number(floatSubtraction(odds, 1));

	if (odds > 2) {
		value = floor(floatDiv(-1, value), 2);
	}

	return value;
};

// HK Odds ->
export const hkOddsToMyOdds = (odds: number) => {
	let value = odds;

	if (odds > 1) {
		value = floor(floatDiv(-1, odds), 2);
	}

	return value;
}

// INDO Odds ->
export const indoOddsToMyOdds = (odds: number) => {
	let value = -1 / odds;

	if (value > 0) {
		if (value > 0.79) {
			value = Math.ceil(value * 100) / 100;
		} else {
			value = Math.floor(value * 100) / 100;
		}
	} else {
		if (value < -0.79) {
			value = Math.ceil(value * 100) / 100;
		} else {
			value = Math.floor(value * 100) / 100;
		}
	}
	
	return Number(value.toFixed(2));
}

// US Odds ->
export const usOddsToMyOdds = (odds: number) => {
	let value = -1 / (odds / 100);

	if (value > 0) {
		if (value > 0.79) {
			value = Math.ceil(value * 100) / 100;
		} else {
			value = Math.floor(value * 100) / 100;
		}
	} else {
		if (value < -0.79) {
			value = Math.ceil(value * 100) / 100;
		} else {
			value = Math.floor(value * 100) / 100;
		}
	}

	return Number(value.toFixed(2));
}

//Odds無條件捨去
export const floor = (odds: number, count: number) => {
	const RegexStr = '^([-+]?[0-9]*.[0-9]{' + count + '})[0-9]*$';
	return parseFloat(odds.toString().replace(new RegExp(RegexStr), '$1'));
}
export const roundOff = (odds: number, count: number): number => {
	const multiple = count == null ? 1 : Math.pow(10, count);
	return floatMul(floatDiv(Math.round(floatMul(Math.abs(odds), multiple)), multiple), (odds < 0 ? -1 : 1));
};
//加千分號
export const addCommasWithFix = (value: number, fixCount = 0) => Box(value)
	.map((value: number) => value.toFixed(fixCount))
	.fold(addCommas);

export const addCommas = (value: string) => value.replace(/^(-?\d+)((\.\d*)?)$/, (s, s1, s2) => s1.replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,') + s2)

//移除千分號
export const removeCommas = (value: string) => {
	return value ? value.replace(/,/g, '') : value;
}

/**
 * payout - single ticket
 * @param odds 賠率
 * @param stake 下注金額
 * @param oddsType 賠率類型
 * @param priceType aka pty
 * @returns 贏取金額
 */
export const calculatePayOut = (odds: number, stake: number, oddsType: number, priceType: number = 0) => {
	let tmpPayOut: number;
	if (odds !== 0) {
		const realOddsType = priceType ? 1 : oddsType; //priceType沒傳或為0時使用oddsType, 有值使用1
		switch (realOddsType) {
			case 1: //Decimal
				tmpPayOut = odds * stake;
				break;
			case 2: //HK, CN			
			case 3: //Indo
			case 4: //MY
			case 6: //MMR
				tmpPayOut = (1 + Math.abs(odds)) * stake;
				break;
			case 5: //US
				tmpPayOut = (1 + Math.abs(odds / 100)) * stake;
				break;
			default: //Other
				tmpPayOut = 0;
				break;
		}
	} else {
		tmpPayOut = 0;
	}
	return tmpPayOut;
}

/**
 * payout - parlay ticket
 * @param odds 賠率
 * @param stake 下注金額
 * @returns 贏取金額
 */
export const calculateParlayPayOut = (odds: number, stake: number) => {
	return odds * stake;
}

/**
 * payout - cricket back & lay
 * @param odds 賠率
 * @param stake 下注金額
 * @returns 贏取金額
 */
export const calculateBackLayPayOut = (odds: number, stake: number) => {
	return (Math.abs(1 - odds)) * stake;
}

/**
 * payout - for display
 * @param type 計算類型 (single / parlay / backlay)
 * @param odds 賠率
 * @param stake 下注金額
 * @param oddsType 賠率類型
 * @param priceType aka pty
 * @returns 贏取金額 (加上千分位並四捨五入至小數點後兩位)
 */
export const getDisplayPayOut = (type: 'single' | 'parlay' | 'backlay', odds: number, stake: number, oddsType: number = 4, priceType: number = 0) => {
	let tmpPayOut = 0;

	switch (type) {
		case 'single':
			tmpPayOut = calculatePayOut(odds, stake, oddsType, priceType);
			break;
		case 'parlay':
			tmpPayOut = calculateParlayPayOut(odds, stake);
			break;
		case 'backlay':
			tmpPayOut = calculateBackLayPayOut(odds, stake);
			break;
	}
	return addCommasWithFix(tmpPayOut, 2);
}

//球頭轉換
export const changeLineDisplay = (hdp: number) => {
	let res = hdp.toString();
	const hdpIndex = Math.abs(hdp / 0.25);
	if (Math.abs(hdpIndex % 2) == 1) {
		// (x-1)/2 - (x/2)
		// 1 => 0 - 0.5
		// 3 => 0.5 - 1
		// 5 => 1 - 1.5
		// 7 => 1.5 - 2
		const reIndex = (hdpIndex + 1) / 2;
		res = ((hdp < 0) ? '-' : '') + ((reIndex - 1) / 2) + '/' + reIndex / 2;
	}

	return res;
}

export interface DisplayLineInfo {
	displayLine: string;
	prefixLabel: string;
}
interface getDisplayLineInfoProps {
	line: number;
	betType: number;
	betTeam: string;
	changeLine?: boolean
}

export const getDisplayLineInfo = (props: getDisplayLineInfoProps): DisplayLineInfo => {
	const { betType, line } = props;
	return matchSome(betType)
		.on((betType: number) => new Set([
			1, 7, 17, 153, 155, 219, 229, 609, 637, 701,
			704, 708, 3904, 9002, 9008, 9012, 9018, 9024, 9028, 9034, 
			9040, 9046, 9059, 9077, 9091, 9093, 9116, 8800, 8806, 8811, 8823, 8829
		]).has(betType), () => getHandicapLine(props))
		.on((betType: number) => new Set([646]).has(betType), () => getHandicapOverUnderResultLine(props))
		.on((betType: number) => new Set([3, 8, 18, 144, 220, 418, 449, 450, 461, 462, 705, 709, 9411, 9412, 9413, 9414, 9415
			, 9607, 9608, 9609, 9610, 9611, 9612, 9410, 8801, 8802, 8803, 8807, 8808, 8809, 8812, 8816, 8824, 8830]).has(betType), () => getOverUnderLine(props))
		.on((betType: number) => new Set([28, 453, 477, 478]).has(betType), () => getThreeWayHandicapLine(props))
		.on((betType: number) => new Set([228, 475, 476, 486, 487]).has(betType), () => ({
			displayLine: `${line}`,
			prefixLabel: ''
		}))
		.otherwise(() => ({
			displayLine: '',
			prefixLabel: ''
		}))
}

const getHandicapLine = (props: getDisplayLineInfoProps): DisplayLineInfo => {
	const lineDisplay = props.changeLine ? changeLineDisplay(props.line) : props.line.toString();
	const displayLine = matchSome(props)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line > 0 && betTeam === 'h', () => '-' + lineDisplay)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line > 0 && betTeam === 'a', () => '+' + lineDisplay)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line < 0 && betTeam === 'h', () => '+' + lineDisplay.substring(1))
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line < 0 && betTeam === 'a', () => lineDisplay)
		.otherwise(() => `${props.line}`);
	return {
		displayLine,
		prefixLabel: ''
	}
}

const getHandicapOverUnderResultLine = (props: getDisplayLineInfoProps): DisplayLineInfo => {
	const lineDisplay = props.changeLine ? changeLineDisplay(props.line) : props.line.toString();
	const displayLine = matchSome(props)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line > 0 && betTeam === 'h', () => '+' + lineDisplay)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line > 0 && betTeam === 'a', () => '-' + lineDisplay)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line < 0 && betTeam === 'h', () => lineDisplay)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line < 0 && betTeam === 'a', () => '+' + lineDisplay.substring(1))
		.otherwise(() => `${props.line}`);
	return {
		displayLine,
		prefixLabel: ''
	}
}

const getOverUnderLine = (props: getDisplayLineInfoProps): DisplayLineInfo => {
	const lineDisplay = props.changeLine ? changeLineDisplay(props.line) : props.line.toString();
	const prefixLabel = matchSome(props)
		.on(({ betTeam }: getDisplayLineInfoProps) => ['o', 'h'].includes(betTeam), () => 'lbl_odds_o')
		.on(({ betTeam }: getDisplayLineInfoProps) => ['u', 'a'].includes(betTeam), () => 'lbl_odds_u')
		.otherwise(() => '');
	return {
		displayLine: ` ${lineDisplay}`,
		prefixLabel
	}
}

const getThreeWayHandicapLine = (props: getDisplayLineInfoProps): DisplayLineInfo => {
	const { line } = props;
	return matchSome(props)
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line > 0 && betTeam === '1', () => ({ displayLine: `-${line.toString()}`, prefixLabel: '' }))
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line > 0 && betTeam === 'x', () => ({ displayLine: ` +${line.toString()}`, prefixLabel: 'lbl_away' }))
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line > 0 && betTeam === '2', () => ({ displayLine: `+${line.toString()}`, prefixLabel: '' }))
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line < 0 && betTeam === '1', () => ({ displayLine: `+${line.toString().substring(1)}`, prefixLabel: '' }))
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line < 0 && betTeam === 'x', () => ({ displayLine: ` +${line.toString().substring(1)}`, prefixLabel: 'lbl_home' }))
		.on(({ line, betTeam }: getDisplayLineInfoProps) => line < 0 && betTeam === '2', () => ({ displayLine: `${line.toString()}`, prefixLabel: '' }))
		.otherwise(() => ({
			displayLine: `${line}`,
			prefixLabel: ''
		}));
}

//Format球頭
export const getLineText = (line: number, betType: number, betTeam: string, changeLine = false) => {
	let result = line.toString();
	const lineDisplay = changeLine ? changeLineDisplay(line) : line.toString();
	switch (betType) {
		case 1:
		case 7:
		case 17:
		case 219:
		case 153:
		case 155:
		case 609:
		case 637:
		case 701:
		case 229:
		case 704:
		case 708:
		case 3904: //handicap
			if (line > 0) {
				if (betTeam === 'h') result = '-' + lineDisplay;
				else if (betTeam === 'a') result = '+' + lineDisplay;
			} else if (line < 0) {
				if (betTeam === 'h') result = '+' + lineDisplay.substring(1);
				else if (betTeam === 'a') result = lineDisplay;
			}
			break;
		case 646: //handicap
			if (line > 0) {
				if (betTeam === 'h') result = '-' + lineDisplay;
				else if (betTeam === 'a') result = '+' + lineDisplay;
			} else if (line < 0) {
				if (betTeam === 'h') result = lineDisplay;
				else if (betTeam === 'a') result = '+' + lineDisplay.substring(1);
			}
			break;
		case 3:
		case 8:
		case 18:
		case 144:
		case 418:
		case 449:
		case 450:
		case 705: //Over/Under
			switch (betTeam) {
				case 'h':
				case 'o':
					result = 'o ' + lineDisplay; //lbl_odds_o
					break
				case 'a':
				case 'u':
					result = 'u ' + lineDisplay; //lbl_odds_u
					break;
			}

			break;
		case 28:
		case 453:
		case 477:
		case 478:  //3-Way Handicap
			if (line > 0) {
				switch (betTeam) {
					case '1':
						result = '-' + line.toString();
						break;
					case 'x':
						result = 'Away ' + '+' + line.toString(); //lbl_away
						break;
					case '2':
						result = '+' + line.toString();
						break;
				}
			} else if (line < 0) {
				switch (betTeam) {
					case '1':
						result = '+' + line.toString().substring(1);
						break;
					case 'x':
						result = 'Home ' + '+' + line.toString().substring(1);  //lbl_home
						break;
					case '2':
						result = line.toString();
						break;
				}
			}
			break;
		case 475:
		case 476:
		case 486:
		case 487:
		case 228:
			result = line.toString();
			break;
		default:
			return '';
	}
	return result;
}

export const isMmrOdds = (sport: number, market: Market): boolean => {
	return sport == 1
		&& [1, 3, 7, 8].indexOf(market.BetTypeId) != -1
		&& '3:5' in market.Selections
		&& '4:5' in market.Selections
		&& market.sort === 1;
}

export const getSrcOddsInfo = (market: Market): string => {
	return Object.entries(market.Selections).map(([_, selection]) => {
		return `${market.Line}:${selection.Price.toFixed(2)}`;
	}).join(',')
}
export const getHomeTeamName = (teamId: number, isNeutral: boolean, neutralResource: string, teamNameMap?: Map<number, string>): string => {
	const homeTeamName = getTeamName(teamId, teamNameMap);
	return isNeutral ? `${homeTeamName} ${neutralResource}` : homeTeamName;
}
export const getTeamName = (teamId: number, teamNameMap?: Map<number, string>): string => {
	return teamNameMap?.get(teamId)?.replace(/([^\|]*)\|.*/, '$1') ?? `Unknown:${teamId}`;
}

export const getLeagueName = (leagueId: number, leagueNameMap?: Map<number, string>) => {
	return leagueNameMap?.get(leagueId)?.replace(/([^\|]*)\|.*/, '$1') ?? `Unknown:${leagueId}`;
}

// 取得 odds key
export const getOddsKey = (type: 'OU' | 'OT', oddsId: number, betTeam: string = ''): string => {
	let key: string;
	switch (type) {
		case 'OT':
			key = oddsId.toString();
			break;
		default:
			key = oddsId.toString() + '_' + betTeam.replace(/:|\//gi, '');
			break;
	}

	return key;
}
export const getSortedMatchCollection = (matchCollection: SportMatchModel[], sortNames: Array<string>) => {
	return [...matchCollection].sort((a, b) => getMatchSortValue(sortNames, a) > getMatchSortValue(sortNames, b) ? 1 : -1);
}

const getMatchSortValue = (sortNames: Array<string>, match: SportMatchModel) => {
	return sortNames.map(sortName => {
		return matchEquals(sortName)
			.on('MatchCode', () => match.MatchCode.padEnd(30, ' '))
			.otherwise(() => match[sortName as keyof SportMatchModel]);
	}).toString();
}

export const getGroupLeagues = (leagueNameMap: Map<number, string> | undefined) => (sortedMatchCollection: SportMatchModel[]) => {
	return sortedMatchCollection.reduce((collection: Array<MatchGroup>, match): Array<MatchGroup> => {
		if (match.LeagueId === collection[collection.length - 1]?.LeagueId) {
			collection[collection.length - 1].Matches.push(match);
			return collection;
		}
		const matchGroup: MatchGroup = {
			LeagueGroupId: match.LeagueGroupId,
			LeagueId: match.LeagueId,
			LeagueName: getLeagueName(match.LeagueId, leagueNameMap),
			Matches: [match]
		}
		if (matchGroup.Matches.length > 0) {
			collection.push(matchGroup);
		}
		return collection;
	}, [])
}


export const orderMarkets = (markets: Market[]) => markets.sort((a, b) => `${a.BetTypeId}_${a.sort}_${a.MarketId}` > `${b.BetTypeId}_${b.sort}_${b.MarketId}` ? 1 : -1);

export const filterBetTypesFromMarkes = (betTypesSet: Set<number>, hasMoreLines: boolean = false) => (markets: Market[]) => {
	const existBetTypes: Set<number> = new Set();
	return markets.reduce((accumulator: Market[], currentValue: Market) => {
		if (!betTypesSet.has(currentValue.BetTypeId)) {
			return accumulator;
		}
		if (!hasMoreLines && existBetTypes.has(currentValue.BetTypeId)) {
			return accumulator;
		}
		existBetTypes.add(currentValue.BetTypeId)
		accumulator.push(currentValue);
		return accumulator;
	}, []);
}

export const sortedMarketBetType = (betTypesSet: Set<number>) => (markets: Market[]) => {
	const orderBetTypes = Array.from(betTypesSet.keys());
	return markets.sort((a, b) => orderBetTypes.indexOf(a.BetTypeId) - orderBetTypes.indexOf(b.BetTypeId))
}
export const getSortedMarkets = (betTypeSet: Set<number>, markets: Market[]) => Box.of(markets)
	.map(filterBetTypesFromMarkes(betTypeSet))
	.map(sortedMarketBetType(betTypeSet))
	.extract()
export const getSortedESportMarkets = (betTypeSet: Set<number>, isLiveMatch: boolean, markets: Market[]) => {
	const available9001Markets = markets.filter(market => market.BetTypeId === 9001).sort((marketA, marketB) => Number(marketA.Resourceid) - Number(marketB.Resourceid));
	const marketsOf9001: Market[] = available9001Markets.slice(0, 3);
	const marketsBesides9001: Market[] = [...betTypeSet].filter(betType => betType !== 9001).reduce((availableMarketsBesides9001: Market[], betType: number): Market[] => {
		const indexOfMarket = markets.findIndex(market => market.BetTypeId === betType);
		if (indexOfMarket !== -1) {
			availableMarketsBesides9001.push(markets[indexOfMarket]);
		}
		return availableMarketsBesides9001;
	}, []);
	return isLiveMatch ? [...marketsOf9001, ...marketsBesides9001] : [...marketsBesides9001, ...marketsOf9001];
};

export const getMarketLabel = (market: MarketLabel) => matchEquals(market)
	.on('l', () => 'lbl_live')
	.on('u', () => 'lbl_Upcoming')
	.on('t', () => 'lbl_today')
	.on('e', () => 'lbl_early')
	.on('outright', () => 'lbl_999')
	.otherwise(() => '');

export const hasMoreAsianLines = (sportId: number, markets: Market[]) => {
	if (!moreAsianLinesSports.has(sportId)) {
		return false;
	}
	return markets.find((market, index) => moreAisanLinesBetTypes.has(market.BetTypeId) && markets[index + 1]?.BetTypeId === market.BetTypeId) ?
		true : false;
}

export const isPairOdds = (betType: number): boolean => {
	return [
		1, 2, 3, 7, 8, 12, 17, 18, 20, 21, 81, 82, 83, 84, 85, 86, 87, 88, 183, 184,
		194, 203, 123, 153, 154, 155, 156, 401, 402, 403, 404, 428, 501, 603, 604,
		605, 606, 607, 609, 610, 611, 612, 613, 615, 616, 617
	].includes(betType);
}

export const revertParlayOddsToOriginOdds = (odds: number, betType: number) => {
	return isPairOdds(betType) ? decimalOddsToMyOdds(odds) :odds;
}

export const getOriginOdds = (odds: number, oddsType: number | undefined): number => {
	return matchEquals(oddsType)
		.on(1, () => decimalOddsToMyOdds(odds))
		.on(2, () => hkOddsToMyOdds(odds))
		.on(3, () => indoOddsToMyOdds(odds))
		.on(5, () => usOddsToMyOdds(odds))
		.otherwise(() => odds);
}