import { SportSettings } from '@/types/settings';

export const newbieSportSettings: SportSettings = {
	0: { //defaultS
		oddsPageFilterBetTypes: [
			{ betTypes: [20] }, 
			{ betTypes: [1] },        
			{ betTypes: [3] },         
		]
	},
	1: {
		oddsPageFilterBetTypes: [
			{ betTypes: [5] },
			{ betTypes: [1] },
			{ betTypes: [3] }
		]
	},
	997: {
		oddsPageFilterBetTypes: [
			{ betTypes: [5] },
			{ betTypes: [1] },
			{ betTypes: [3] }
		]
	},
	5: {
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },
			{ betTypes: [153] },
			{ betTypes: [3] }
		]
	},
	6: {
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },
			{ betTypes: [704] },
			{ betTypes: [705] }
		]
	},
	9: {
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },
			{ betTypes: [704] },
			{ betTypes: [705] }
		]
	},
	50: {
		groups: {
			1: 'menu_FIXED',
			2: 'menu_FANCY',
		},
		oddsPageFilterBetTypes: [
			{ betTypes: [501], group: 1 },
			{ betTypes: [5], group: 1 },
			{ 
				betTypes: [9401],
				group: 1,
				limitedMarkets: ['t', 'u', 'e'],
			},
			{ betTypes: [9539], group: 2 }, 
		]
	},
	55: {
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },
			{ betTypes: [2] },
			{ betTypes: [3] }
		]
	},
	8: {
		oddsPageFilterBetTypes: [
			{ betTypes: [20, 8828] },
			{ betTypes: [8800, 8829] },
			{ betTypes: [8801, 8830] }
		]
	},
}