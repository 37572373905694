import { matchEquals } from '@nf/utils-common/fpw/monadic';
import { ViewEnum } from '@/types';

export const GetGUID = () => {
	// b382bd5-becd-4d93-8d3f-98aad78a049e
	let d = Date.now();
	if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
		d += performance.now(); //use high-precision timer if available
	}
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
}
export const TranslateLanguageToDC = (lang: string) => {
	switch (lang.toLowerCase()) {
		case 'zh-tw':
		case 'zh-cn':
		case 'zh-chs':
		case 'zh-hans':
			return 'CN';
		case 'id-id':
			return 'ID';
		case 'th-th':
			return 'TH';
		case 'vi-vn':
			return 'VN';
		default:
			return 'EN';
	};
}

export const convertCultureToRef = (culture: string): string => matchEquals(culture.toLowerCase())
	.on('zh-tw', () => 'ch')
	.on('zh-cn', () => 'cs')
	.on('zh-hans', () => 'zhcn')
	.on('th-th', () => 'th')
	.on('vi-vn', () => 'vn')
	.on('id-id', () => 'id')
	.on('ko-kr', () => 'ko')
	.on('ja-jp', () => 'jp')
	.on('hi-in', () => 'hi')
	.on('my-mm', () => 'mm')
	.on('pt-br', () => 'ptbr')
	.otherwise(() => 'en');

export const getBetFrom = (view: ViewEnum, isDesktop: boolean): string => {
	switch (view) {
		case ViewEnum.galaxy:
			return isDesktop ? 'W001' : 'W002';
		case ViewEnum.mars:
			return isDesktop ? 'W003' : 'W004'
	}
};

export const isSupportedSkinMode = (skin: number): boolean => {
	const supportViewSkinMode = [ViewEnum.galaxy, ViewEnum.mars];
	return supportViewSkinMode.includes(skin);
};

export const GetMarket = (serverDateTime: string) => {
	const serverdt: Date = new Date(serverDateTime + '-04:00');
	const nowdt: Date = new Date();
	const serverToday: string = ((new Date(nowdt.getTime() - 4 * 60 * 60 * 1000)).toISOString()).replace(/(\d{4}-\d{2}-\d{2}T)\d{2}:\d{2}:\d{2}\.\d{3}Z/, (s, s1) => {
		return s1 + '23:59:59'
	});
	const todaydt = new Date(serverToday + '-04:00');
	if (serverdt < nowdt) {
		return 'l';
	} else if (serverdt < todaydt) {
		return 't';
	} else {
		return 'e';
	}
}

export const getKafkaStreamerType = (srcId: number) => {
	if (srcId) {
		if (srcId <= 23) {
			return srcId ? srcId - 19 : 0;
		}
		return (srcId === 28 || srcId === 29) ? 6 : srcId - 23
	}
	return 0;
}