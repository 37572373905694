import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { accountService } from '@/services/account.service';
import { BetslipStyle } from './query';
import { useAccountInfo } from './query/account';

export const useBetslipStyle = (initialStyle: number, syncOnCondition: boolean) => {
	const [selectedBetslipStyle, setSelectedBetslipStyle] = useState<number>(initialStyle);
	const { user } = useAccountInfo();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (syncOnCondition) {
			setSelectedBetslipStyle(user?.UserSetting.BetslipStyle ?? BetslipStyle.basic);
		}
	}, [user?.UserSetting.BetslipStyle, syncOnCondition]);

	const selectBetslipStyle = (betslipStyle: number) => {
		setSelectedBetslipStyle(betslipStyle);
	};

	const saveBetslipStyle = () => {
		if (user?.UserSetting.BetslipStyle !== selectedBetslipStyle) {
			accountService.setBetslipStyle(queryClient, user, selectedBetslipStyle);
		}
	};

	return {
		selectedBetslipStyle,
		selectBetslipStyle,
		saveBetslipStyle
	};
};