export interface ProvidedMatchCount {
	E: number;
	L: number;
	O: number; //Outright
	T: number;
	WO: number; //means 'Starting Soon' (only used in CV)
}

export type MarketType = 'L' | 'U' | 'T' | 'E' | 'O';

export type ProvidedMarket = keyof ProvidedMatchCount;

export type PartialRecord<K extends keyof never, T> = {
	[P in K]?: T;
  };

export const galaxyMarketMenuPriorityMapping: PartialRecord<MarketType, ProvidedMarket> = {
	'T': 'T',
	'E': 'E',
	'L': 'L',
	'U': 'WO',
	'O': 'O'
};

export const marsMarketMenuPriorityMapping: PartialRecord<MarketType, ProvidedMarket> = {
	'L': 'L',
	'U': 'WO',
	'T': 'T',
	'E': 'E',
	'O': 'O'
};

export const marsParlayMarketMenuPriorityMapping: PartialRecord<MarketType, ProvidedMarket> = {
	'L': 'L',
	'T': 'T',
	'E': 'E',
	'O': 'O'
};