import { useParams, usePathname } from 'next/navigation';
import { useQuery } from '@tanstack/react-query';
import { ApiQueryPromise } from '@/types';
import queryKeys from '@/constants/query-keys';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { getResponseJwtHeader } from '@/utils/web';
import { BetsTicketData } from '@/features/bets/bets-ticket-types';
import { SettledBaseInfo, BetsFilter } from '@/features/bets/bets-filter-types';
import { useSiteStore } from '@/store/site';
import { getAuthToken } from '@/store/auth';
import { cloudUrlParser } from '@/utils/aws-domain-util';

const fetchSettledBetListInfo = async (dataType: number | undefined, date: string | undefined, isSabaCoin: boolean): Promise<ApiQueryPromise<BetsTicketData['betList']>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;
	let formattedDate = null;

	if (date) {
		formattedDate = encodeURIComponent(date);
	}

	const response = await fetchComposer.getWithBearer(cloudUrlParser(`${ctcdDomain}/Statement/GetDBetList_ChJson?fdate=${formattedDate}&dataType=${dataType}&isReport=false&isSabaCoin=${isSabaCoin}`))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useSettledBetListInfo = (dataType: number | undefined, date: string | undefined, isSabaCoin: boolean) => {
	const { data, isFetching, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.SETTLED_BET_LIST_INFO, dataType, date, isSabaCoin],
		queryFn: () => fetchSettledBetListInfo(dataType, date, isSabaCoin),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});

	return {
		bets: data?.data.Data,
		isFetching,
		isError,
		error,
		refetchSettledBetList: refetch
	};
};

const fetchAllStatementInfo = async (dataType: number | undefined, beforeDay: number, isSabaCoin: boolean): Promise<ApiQueryPromise<SettledBaseInfo>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;

	const response = await fetchComposer.getWithBearer(cloudUrlParser(`${ctcdDomain}/Statement/GetAllStatement_chJson?datatype=${dataType}&bfday=${beforeDay}&isSabaCoin=${isSabaCoin}`))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useAllStatementInfo = (dataType: number | undefined, beforeDay: number, isSabaCoin: boolean) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.SETTLED_ALL_BETS_INFO, dataType, beforeDay, isSabaCoin],
		queryFn: () => fetchAllStatementInfo(dataType, beforeDay, isSabaCoin),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: dataType !== undefined
	});

	return {
		betsStatementInfo: data?.data.Data,
		isLoadingAllStatement: isLoading,
		isError,
		error,
		refetchAllStatement: refetch
	};
};

const fetchSettledCategory = async (selectDate: number): Promise<ApiQueryPromise<BetsFilter>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;

	// selectDate(bfday) >>>
	// today = 1, 
	// last 2 days = 2, 
	// last 7 days = 7
	const response = await fetchComposer.getWithBearer(cloudUrlParser(`${ctcdDomain}/Statement/GetStatementOVRJson?bfday=${selectDate}`))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};


export const useSettledCategory = (selectDate: number) => {
	const params = useParams();
	const pathname = usePathname();

	const { data, isFetching, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.SETTLED_CATEGORY_INFO, selectDate],
		queryFn: () => fetchSettledCategory(selectDate),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: params.filter === 's' || pathname.includes('/account/history') || params.filter === 'h'
	});

	return {
		betsSettledCategory: data?.data.Data,
		isFetching,
		isError,
		error,
		refetchSettledCategory: refetch
	};
};
