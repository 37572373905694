import type { ApiResponse, ApiSuccessResponse } from '@nf/types/api';

export type ApiQueryBasePromise<T = unknown> = {
	data: T,
	jwtToken: string | null;
};

export type ApiQueryPromise<T = unknown> = {
	data: ApiResponse<T>;
	jwtToken: string | null;
};

export type ApiQuerySuccessPromise<T = unknown> = {
	data: ApiSuccessResponse<T>;
	jwtToken: string | null;
};

export type ApiObservablePromise<T = unknown> = {
	data: Promise<T>;
	jwtToken: string | null;
};

export type OddsPageLayout = 'card' | 'list';
export type View = 'galaxy' | 'mars';

export type MarketLabel = 'l' | 't' | 'u' | 'e' | 'outright' | 'favorite';

export enum ViewEnum {
	galaxy = 7,
	mars = 8
}

export type ViewKeys = keyof typeof ViewEnum;