import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { produce } from 'immer';

import type { VisitorInfo } from '@nf/types/account';

export const visitorAtom = atomWithStorage<VisitorInfo | null>('persist:visitorInfo', null);

export const updateVisitorAtom = atom(null, (get, set, visitor: VisitorInfo) => {
	const prev = get(visitorAtom) || {};
	const newData = produce(prev, draft => {
		draft.AccessToken = visitor.AccessToken;
		if (visitor.OddsServerToken) {
			draft.OddsServerToken = visitor.OddsServerToken;
		}
		if (visitor.OneAccessToken) {
			draft.OneAccessToken = visitor.OneAccessToken;
		}
		if (visitor.OneRefreshToken) {
			draft.OneRefreshToken = visitor.OneRefreshToken;
		}
	});
	set(visitorAtom, newData);
});