import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { useSiteStore } from '@/store/site';
import { getAuthToken } from '@/store/auth';
import { ApiQueryPromise, View, ViewEnum } from '@/types';
import { getFormData } from '@nf/utils-common/form';
import { getResponseJwtHeader } from '@/utils/web';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { KafkaNote } from '@nf/utils-common/constants';
import { useAppearanceStore } from '@/store/appearance';
import { useBreakpoint } from '@/features/breakpoint/contexts';
import { useCallback } from 'react';

export interface setKafkaResponse {
	success: boolean;
}

export interface setKafkaWithStringBody {
	type: number;
	action: string;
	note?: string;
	kType?: string;
	topic?: number;
}

export interface setNormalKafkaBody {
	type: number;
	action: number;
	note?: string;
	kType?: string;
}

export interface setStreamingKafkaBody {
	type: number;
	action: number;
	note: string;
	kType?: string;
	matchId: number;
	streamer_type: number;
}

enum KafkaType {
	Normal = 'Normal',
	Streaming = 'Streaming'
};

type LogItemDescription = 'HomeBanner';
type LogItemAction = 'VirtualCockFighting' | 'EplStreamerPromo' | 'StreamerEntertainment';
type IntegerFixedOrChangeAccordingToView = number | {[key in View]: number};
type LogSetting = {
	Type: IntegerFixedOrChangeAccordingToView;
	Note: string | {[key in View]: string};
	Action: {[key in LogItemAction]: number | IntegerFixedOrChangeAccordingToView[]};
};
const kafkaLogSetting: {[key in LogItemDescription]: LogSetting}= {
	HomeBanner: {
		Type: {
			galaxy: 14,
			mars: 16
		},
		Note: {
			galaxy: KafkaNote.GalaxyV2,
			mars: KafkaNote.MarsV1
		},
		Action: {
			VirtualCockFighting: 1,
			EplStreamerPromo: [
				2, // xs
				2, // sm
				4, // md
				4, // lg
				4, // lgx
				4 // xl 
			],
			StreamerEntertainment: [3, 3, 5, 5, 5, 5]
		}
	}
};
// TODO: WSP-15, extend use-kafka-log to support set-kafka-streaming & set-kafka-with-string
export const useKafkaLog = () => {
	const view = useAppearanceStore(state => state.view);
	const { breakpoint } = useBreakpoint();

	const getCorrespondingValueOfView = <T>(map: {[key in View]: T}, viewEnum: ViewEnum): T | undefined => {
		return map[ViewEnum[viewEnum] as keyof typeof map];
	};
	return useCallback((description: LogItemDescription, action: LogItemAction) => {
		const logSetting = kafkaLogSetting[description];
		if (!logSetting) {
			return;
		}
		
		const type: number | undefined = typeof logSetting.Type !== 'object' ? logSetting.Type : getCorrespondingValueOfView<number>(logSetting.Type, view);
		const note: string | undefined = typeof logSetting.Note !== 'object' ? logSetting.Note : getCorrespondingValueOfView<string>(logSetting.Note, view);
		const actionSetting: number | IntegerFixedOrChangeAccordingToView[] = logSetting.Action[action];
		const actionSettingOfBreakpoint: IntegerFixedOrChangeAccordingToView | undefined = !Array.isArray(actionSetting) ? undefined : actionSetting[breakpoint];
		const kafkaAction: number | undefined = typeof actionSetting === 'number' ? actionSetting
			: !actionSettingOfBreakpoint ? undefined
				: typeof actionSettingOfBreakpoint === 'number' ? actionSettingOfBreakpoint
					: getCorrespondingValueOfView<number>(actionSettingOfBreakpoint, view);
		if (type !== undefined && note !== undefined && kafkaAction !== undefined) {
			// console.log(`Kafka: type=${type}, note=${note}, action=${kafkaAction}`);
			setKafkaNormal({
				type,
				note,
				action: kafkaAction
			});
		}
	}, [view, breakpoint]);
};
export const setKafkaWithStringAction = async (kafkaData: setKafkaWithStringBody): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const apiUrl = new URL('/Default/SetKafkaWithString', apiDomain);

	kafkaData.kType = KafkaType.Normal;
	kafkaData.note = kafkaData.note ?? KafkaNote.GalaxyV2;

	return fetchSetKafka(apiUrl.href, kafkaData);
}

export const setKafkaNormal = async (kafkaData: setNormalKafkaBody, token?: string): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const apiUrl = new URL('/Default/SetKafka', apiDomain);

	kafkaData.kType = KafkaType.Normal;
	kafkaData.note = kafkaData.note ?? KafkaNote.GalaxyV2;

	// 在 mars 先擋掉可能汙染 galaxy 數據的埋點
	const view = useAppearanceStore.getState().view;
	if (view === ViewEnum.mars && kafkaData.note === KafkaNote.GalaxyV2)
		return Promise.resolve({} as ApiQueryPromise<setKafkaResponse>);

	return fetchSetKafka(apiUrl.href, kafkaData, token);
}

export const setKafkaStreaming = async (kafkaData: setStreamingKafkaBody): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const apiUrl = new URL('/Default/SetKafka', apiDomain);
	kafkaData.kType = KafkaType.Streaming;
	return fetchSetKafka(apiUrl.href, kafkaData);
}

const fetchSetKafka = async (apiUrl: string, kafkaData: setKafkaWithStringBody | setNormalKafkaBody | setStreamingKafkaBody, token?: string): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const allowSetKafka = checkAllowSetKafka(kafkaData.kType, kafkaData.note);
	if (!allowSetKafka) {
		return Promise.resolve({} as ApiQueryPromise<setKafkaResponse>);
	}

	const authToken = getAuthToken();
	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl), { body: getFormData(kafkaData) })(token ?? authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
}

const checkAllowSetKafka = (kafkaType?: string, kafkaNote?: string) => {
	const enableKafa = useSiteStore.getState().enableKafa.split(';');
	let allowSetKafka = false;

	if (enableKafa.includes('-1')) { // close all
		// do nothing
	} else if (enableKafa.includes('0')) { // open all
		allowSetKafka = true;
	} else {
		if (enableKafa.includes('1') && kafkaType === KafkaType.Streaming) { // allow streaming
			allowSetKafka = true;
		}
		if (enableKafa.includes('2') && kafkaType === KafkaType.Normal && kafkaNote === KafkaNote.EuroCup) { // allow euro cup promotion
			allowSetKafka = true;
		}
	}

	return allowSetKafka;
}