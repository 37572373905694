import { BetChoice } from '@/features/odds-page/types';
import { betTypeService } from '@/services/bettype.service';
import { DisplayLineInfo, getDisplayLineInfo } from '@/utils/odds-utils';
import { Market } from '@nf/types/odds';
import { Box } from '@nf/utils-common/fpw/monadic';
import { stringEmptyCheck } from '@nf/utils-common/fpw/utils';
import { useTranslations } from 'next-intl';

export const useGetGoal = () => {
	const t = useTranslations('General');
	const getTranslatedLine = ({ prefixLabel, displayLine }: DisplayLineInfo) => stringEmptyCheck(prefixLabel)
		.fold(
			() => displayLine,
			(label: string) => `${t(label)}${displayLine}`
		)
	return (market: Market, choice: BetChoice) => Box.of({
		line: market.Line,
		betType: market.BetTypeId,
		betTeam: choice.betTeam,
	})
		.map(getDisplayLineInfo)
		.map(getTranslatedLine)
		.fold((lineText: string) => stringEmptyCheck(lineText)
			.fold(
				() => t(betTypeService.getBetChoiceDisplaySettingLabel(market.BetTypeId, choice.betTeam)),
				(line: string) => line
			))
}