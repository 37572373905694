import { BehaviorSubject, Subject } from 'rxjs';

const sidebarButton$ = new BehaviorSubject(false);
const toTopButton$ = new BehaviorSubject(false);
const marketPageRefresh$ = new BehaviorSubject(false);
const quickBetButton$ = new BehaviorSubject('');
const leagueFilter$ = new Subject<boolean>();
const tutotialToolTip$ = new Subject<boolean>();

const bookingFeatureTip$ = new Subject();

const controlSidebar = (isOpen: boolean) => sidebarButton$.next(isOpen);

const isShowToTopButton = (y: number) => y > 30;

const displayToTopButton = (y: number) => toTopButton$.next(isShowToTopButton(y));

const onDisplayToTopButton = () => toTopButton$.asObservable();

const spinMarketRefreshIcon = (isStarted: boolean) => marketPageRefresh$.next(isStarted);

const processQuickBetButton = (oddsKey: string) => quickBetButton$.next(oddsKey);

const displayLeagueFilter = (isOpen: boolean) => leagueFilter$.next(isOpen);

const displayTutorialToolTip = () => tutotialToolTip$.next(true);
const displayBookingFeatureTip = (isShow?: boolean) => bookingFeatureTip$.next(isShow);

export const uiService = {
	// for sidebar button
	controlSidebar,
	onControlSidebarButton$: sidebarButton$.asObservable(),
	// for to top button
	displayToTopButton,
	onDisplayToTopButton,
	// for market refresh icon
	spinMarketRefreshIcon,
	get isMarketRefreshIconSpinning() {
		return marketPageRefresh$.value;
	},
	marketPageRefreshIcon$: marketPageRefresh$.asObservable(),
	processQuickBetButton,
	get oddsKeyInQuickBetButton() {
		return quickBetButton$.value;
	},
	// for league filter
	displayLeagueFilter,
	onLeagueFilterDisplay$: leagueFilter$.asObservable(),
	onDisplayTutorialToolTip$: tutotialToolTip$.asObservable(),
	displayTutorialToolTip,
	// for booked bet
	displayBookingFeatureTip,
	onDisplayBookingFeatureTip$: bookingFeatureTip$.asObservable(),
};
