// Don't modify any part of this code file, please

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const compose = (...fns) => (x?: any) => fns.reduceRight((v, f) => f(v), x);

export const pipe = (...fns) => (x?: any) => fns.reduce((v, f) => f(v), x);

export const map = fn => arr => arr.map(fn);

export const filter = fn => arr => arr.filter(fn);

export const not = predicate => value => !predicate(value);