import { useQuery } from '@tanstack/react-query';

import type { ApiSuccessResponse } from '@nf/types/api';
import { SwitchFromEnum, type SiteConfigsSettingsData, type SiteConfigsSettingsWithSessionData } from '@nf/types/site';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import queryKeys from '@/constants/query-keys';
import { useSiteStore } from '@/store/site';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { getAuthToken } from '@/store/auth';
import { useAtomValue } from 'jotai';
import { visitorAtom } from '@/atoms/visitor';

export const getSiteConfigs = async (switchFrom : number): Promise<
	ApiSuccessResponse<SiteConfigsSettingsData>
> => {
	const response = await fetchComposer.get(cloudUrlParser(`${
		useSiteStore.getState().apiDomain.ctcdDomain
	}/api/Config/v2/GetSiteConfigs`)+`?switchFrom=${switchFrom}`);
	
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return await response.json();
}

export const useGetSiteConfigs = () => {
	const switchFrom = useSiteStore.getState().deepLinkSiteInfo.switchFrom;
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.SITE_CONFIGS],
		queryFn: () => getSiteConfigs(switchFrom === SwitchFromEnum.Default ? SwitchFromEnum.Mobile : switchFrom),
		staleTime: 1000 * 30,
		cacheTime: 1000 * 60,
		refetchOnWindowFocus: false,
		enabled: (useSiteStore.getState().apiDomain.ctcdDomain ?? '') != '',
	});
	return {
		SiteConfigsData: data?.Data,
		isLoading,
		isError,
		error,
		refetchSiteConfigs: refetch
	};
};

export const getSiteConfigsWithSession = async (accessToken: string | undefined): Promise<
	ApiSuccessResponse<SiteConfigsSettingsWithSessionData>
> => {
	const response = await fetchComposer.getWithBearer(cloudUrlParser(`${
		useSiteStore.getState().apiDomain.ctcdDomain
	}/api/Config/GetSiteConfigWithSession`))((accessToken));
	
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return await response.json();
}

export const useGetSiteConfigsWithSession = () => {
	const authToken = getAuthToken();
	const isBefore = !Boolean(authToken);
	const visitorInfo = useAtomValue(visitorAtom);
	const useToken = isBefore ? visitorInfo?.AccessToken : authToken;
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.SITE_CONFIGS_WITH_SESSION],
		queryFn: () => getSiteConfigsWithSession(useToken),
		staleTime: 1000 * 30,
		cacheTime: 1000 * 60,
		refetchOnWindowFocus: false,
		enabled: !!useToken,
	});

	return {
		SiteConfigsWithSessionData: data?.Data,
		isLoading,
		isError,
		error,
		refetchSiteConfigs: refetch,
	};
};