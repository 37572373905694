import { choiceSettings } from './type';

export const marsChoiceSettings: choiceSettings = {
	14: [
		{ label: 'lbl_Header_HF', betTeam: '11', betChoiceGroup: 1 },
		{ label: 'lbl_Header_HL', betTeam: '12', betChoiceGroup: 1 },
		{ label: 'lbl_Header_AF', betTeam: '21', betChoiceGroup: 2 },
		{ label: 'lbl_Header_AL', betTeam: '22', betChoiceGroup: 2 },
		{ label: 'lbl_Header_NG', betTeam: '00', betChoiceGroup: 3 }
	],
	127: [
		{ label: 'lbl_Header_HF', betTeam: '11', betChoiceGroup: 1 },
		{ label: 'lbl_Header_HL', betTeam: '12', betChoiceGroup: 1 },
		{ label: 'lbl_Header_AF', betTeam: '21', betChoiceGroup: 2 },
		{ label: 'lbl_Header_AL', betTeam: '22', betChoiceGroup: 2 },
		{ label: 'lbl_Header_NG', betTeam: '00', betChoiceGroup: 3 }
	],
}