import { isPlainObject } from './checks';

function deepClone<T>(source: T) {
	if (!isPlainObject(source)) {
		return source;
	}

	const output: Record<keyof any, unknown> = {};

	Object.keys(source).forEach(key => {
		output[key] = deepClone(source[key]);
	});

	return output;
}

export function deepMerge<T>(target: T, source: unknown): T {
	const output = { ...target };

	if (isPlainObject(source) && Object.keys(source).length === 0) {
		return deepClone(output) as T;
	}

	if (isPlainObject(source) && isPlainObject(target)) {
		Object.keys(source).forEach(key => {
			if (key === '__proto__') {
				return;
			}

			if (isPlainObject(source[key]) && key in target && isPlainObject(target[key])) {
				(output as Record<keyof any, unknown>)[key] = deepMerge(target[key], source[key]);
			} else {
				(output as Record<keyof any, unknown>)[key] = isPlainObject(source[key]) 
					? deepClone(source[key])
					: source[key];
			}
		});
	}
	
	return output;
}