import type { ApiQueryPromise } from '@/types';
import type { SmpTicketModel } from '@nf/types/ticket';
import type { TicketModel } from '@/hooks/query/use-betting-info';
import { getAuthToken, getAuthActions } from '@/store/auth';
import { useSiteStore } from '@/store/site';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { getFormData } from '@nf/utils-common/form';
import { getResponseJwtHeader } from '@/utils/web';
import { cloudUrlParser } from '@/utils/aws-domain-util';

export const fetchSmpTicket = async (
	marketId: number, 
	matchId: number
): Promise<ApiQueryPromise<Array<SmpTicketModel>>> => {
	const authToken = getAuthToken();
	const { apiDomain } = useSiteStore.getState();
	const formData = getFormData({ marketId, matchId });
	const response = await fetchComposer.postWithBearerBase(
		cloudUrlParser(`${apiDomain.ctcdDomain}/BetV2/GetTicketSMP`), 
		{ body: formData }
	)(authToken);
	
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`, { cause: response });
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

const getSmpTicket = async (
	marketId: number, 
	matchId: number
): Promise<Array<SmpTicketModel>> => {
	const { updateAuthToken } = getAuthActions();

	try {
		const result = await fetchSmpTicket(marketId, matchId);

		if (result.data.ErrorCode === 0) {
			result.jwtToken && updateAuthToken(result.jwtToken);
			return result.data.Data ?? [];
		} else {
			throw new Error(`${result.data.ErrorCode}: ${result.data.ErrorMsg}`, { cause: result.data });
		}
	} catch (error: any) {
		console.error(error.message);
	}
	
	return [];
};

const isSmpTicket = (ticket: TicketModel) => ticket.TicketType === 'BB';

export const smpService = {
	getSmpTicket,
	isSmpTicket
};