import { BetChoice } from '@/features/odds-page/types';
import { betTypeService } from '@/services/bettype.service';
import { getBetTypeSetting } from '@/services/settings.service';
import { choiceTranslateReplaceByLineBetTypeIds } from '@/settings/bet-type-settings';
import { Market, SportMatchModel } from '@nf/types/odds';
import { matchEquals } from '@nf/utils-common/fpw/monadic';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';
interface getBetChoiceTitleProps {
    choice: BetChoice;
    market?: Market;
    match: SportMatchModel;
    teamMap?: Map<number, string>
}
export const useGetChoiceTitle = (betType: number) => {
	const { betChoiceTranslateType, betTypesCategory } = useMemo(() => getBetTypeSetting(betType), [betType]);
	const t = useTranslations('General');
	const choiceT = useTranslations('Choice');
	return ({
		choice,
		market,
		match,
		teamMap
	}: getBetChoiceTitleProps) => {
		if (market && match?.GameID === 50 && betTypesCategory && betTypesCategory !== 'overRun') {
			return betTypeService.getBetChoiceDisplaySettingLabelByCricket(betType, market, choice.betTeam, choice.label);
		}
		if (market && choiceTranslateReplaceByLineBetTypeIds.includes(betType)) {
			return t(choice.label, { 0: market.Line });
		}
		const teamName = betTypeService.getBetChoiceDisplayTeamName(
			betType, 
			choice.betTeam, 
			match, 
			t('Neutral_Team'), 
			teamMap
		);
		if (teamName) {
			return teamName;
		}
		if (betChoiceTranslateType) {
			return matchEquals(betChoiceTranslateType)
				.on('General', () => t(choice.label))
				.on('Choice', () => choiceT(choice.label))
				.otherwise(() => t(choice.label))
		} 
		return choice.label;
	}
}