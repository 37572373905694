export enum NavItemType {
	Lobby,
	Search,
	Favorites,
	Live,
	Sports,
	World,
	Parlay,
	Promotion
}
export type NavItem = {
	sportId?: string;
	href: string;
	routePrefix?: string;
	className: string;
	wrapperClassName?: string;
	icon?: string;
	iconType?: 'general' | 'sport';
	type: SidebarItemType;
	title: string;
	amount?: number;
	needAuth?: boolean;
	needPromotion?: boolean;
	selected?: boolean;
	subItems?: NavItems;
	useTip?: boolean;
	hasStreamer?: boolean;
	isNewWindow?: boolean;
	isRedirectToProduct?: boolean;
	hasLive?: boolean;
	isEnable?: boolean;
};
export type NavItems = Array<NavItem>;

export type SidebarItemType = 'general' | 'collapsible' | 'flexible';

export enum GalaxySidebarSwitchTabIndex {
	Live = 0,
	Sports = 1,
}

export enum MarsSidebarSwitchTabIndex {
	Sports = 0,
	Parlay = 1,
}