// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Left, Right, fromPred } from './monadic';
import { not } from './libs';
import { isStringEmpty } from '../checks';

export const log = (msg: string) => x => {
	console.log(msg, ' => ', x);
	return x;
};

export const tryCatch = fn => value => {
	try {
		return Right(fn(value));
	} catch (ex) {
		return Left(ex);
	}
};

export const split = (delimiter: string) => (toSplitText: string) => toSplitText.split(delimiter);

export const getProp = key => obj => 
	key in obj ? Right(obj[key]) : Left(undefined);

export const stringEmptyCheck = (str: string | undefined) => 
	fromPred(not(isStringEmpty))(str);

export const objectEmptyCheck = obj => 
	fromPred(o => Object.keys(o).length > 0)(obj);

export const itemsEmptyCheck = xs => 
	fromPred(x => x.length > 0)(xs);

export const esSetEmptyCheck = (esSet: Set) =>
	fromPred((x: Set) => x.size > 0)(esSet);