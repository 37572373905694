import { setKafkaNormal } from '@/hooks/query/use-kafka-log';
import { ViewEnum } from '@/types';
import type { SiteConfigsSettingsData } from '@nf/types/site';
import type { DeepLinkSiteInfo } from '@nf/types/site';
import { KafkaNote } from '@nf/utils-common/constants';
import { Subject } from 'rxjs';

const appendDeepLink = (deepLinkSiteInfo: DeepLinkSiteInfo, shortCode: string, url: string) => {

	const newQueryParams = new URLSearchParams();
	newQueryParams.set('homeurl', deepLinkSiteInfo.homeUrl)
	newQueryParams.set('deposit', deepLinkSiteInfo.depositUrl)
	newQueryParams.set('extendsessionurl', deepLinkSiteInfo.extendSessionUrl)
	newQueryParams.set('loginurl', deepLinkSiteInfo.loginUrl)
	newQueryParams.set('signupurl', deepLinkSiteInfo.signUpUrl)
	newQueryParams.set('oType', deepLinkSiteInfo.oddsType.toString())
	newQueryParams.set('skincolor', deepLinkSiteInfo.skinColor)
	newQueryParams.set('tsid', deepLinkSiteInfo.tsId)
	newQueryParams.set('lang', shortCode)

	// test data:
	// newQueryParams.set('homeurl', 'homeurl_test')
	// newQueryParams.set('deposit', 'deposit_test')
	// newQueryParams.set('extendsessionurl', 'extendsessionurl_test')
	// newQueryParams.set('loginurl', 'loginurl_test')
	// newQueryParams.set('signupurl', 'signupurl_test')
	// newQueryParams.set('oType', 'oType_test')
	// newQueryParams.set('skincolor', 'skincolor_test')
	// newQueryParams.set('tsid', 'tsid_test')

	return `${url}&${newQueryParams.toString()}`;
}

const convertLanguageToShortCode = (lang: string | null, data: SiteConfigsSettingsData) => {
	let foundLanguage;
	if (lang == 'zh-CHS') {
		foundLanguage = 'cs';
	} else {
		foundLanguage = data?.SupportLanguages.find(language => (
			language.ShortCode === lang || language.IsoCode === lang
		))?.ShortCode;
		foundLanguage = foundLanguage || data?.SupportLanguages[0]?.ShortCode || 'en';
	}
	return foundLanguage;
};

const setEntryKafka = (view: ViewEnum, token: string) => {
	const kafkaData = view === ViewEnum.galaxy
		? { note: KafkaNote.GalaxyTransfer, type: 1, action: 23 }
		: { note: KafkaNote.MarsV1, type: 13, action: 1 };
	setKafkaNormal(kafkaData, token);
}

type SwitchViewParams = {
	view: ViewEnum;
	href: string
}

const onSwitchView$ = new Subject<SwitchViewParams>();
const switchView = (value: SwitchViewParams) => onSwitchView$.next(value);

export const switchViewService = {
	appendDeepLink,
	convertLanguageToShortCode,
	setEntryKafka,
	onSwitchView: onSwitchView$.asObservable(),
	switchView
};