import { RawMarketData, ProvidedMarket, MarketType, PartialRecord, ProvidedMatchCount } from '@/types/menu-setting';
import { MarketData, MenuItems } from '@/hooks/query';
import { ChannelChIcon, ChannelCsIcon, ChannelEnIcon, ChannelIdIcon, ChannelKoIcon, ChannelOtherIcon, ChannelRuIcon, ChannelThIcon, ChannelVnIcon } from './../components/icons/svg-icons/svg-icons';
import { ViewEnum } from '@/types';
import { getMarketPriority } from '@/services/settings.service';

interface ESportSetting {
	globalResourceKey?: string,
}
const supportedGames: { [key: string]: ESportSetting } = {
	6: {}, // OTHERS
	16: { globalResourceKey: 'esport_leaguegroup_16' }, // ARENA_OF_VALOR
	17: {}, //ARTIFACT
	18: { globalResourceKey: 'esport_leaguegroup_18' }, // CALL_OF_DUTY
	20: { globalResourceKey: 'esport_leaguegroup_20' }, // Dota2
	21: {}, // FIFA
	22: {}, // HEARTHSTONE
	23: {}, // HEROS_OF_THE_STORM
	24: { globalResourceKey: 'esport_leaguegroup_24' }, // KING_OF_GLORY
	25: { globalResourceKey: 'esport_leaguegroup_25' }, // LOL
	26: {}, // NBA2K 
	27: { globalResourceKey: 'esport_leaguegroup_27' }, // OVERWATCH
	28: { globalResourceKey: 'esport_leaguegroup_28' }, // PUBG
	29: { globalResourceKey: 'esport_leaguegroup_29' }, // RAInBOW_SIX
	30: { globalResourceKey: 'esport_leaguegroup_30' }, // ROCKET_LEAGUE
	31: {}, // STARCRAFT
	32: { globalResourceKey: 'esport_leaguegroup_32' }, // STARCRAFT2
	33: {}, // SFV
	34: { globalResourceKey: 'esport_leaguegroup_34' }, // WARCRAFT3
	35: {}, // WORLD_OF_WARCRAFT
	36: {}, // PALADINS
	37: { globalResourceKey: 'esport_leaguegroup_37' }, // MOBILE_LEGENDS
	38: {}, // CLASH_ROYALE
	39: {}, // FORTNITE
	40: {}, // GAME_OF_PEACE
	76: { globalResourceKey: 'esport_leaguegroup_76' }, // VALORANT
	109: { globalResourceKey: 'esport_leaguegroup_109' }, // CROSSFIRE
	111: { globalResourceKey: 'esport_leaguegroup_111' }, // LOL_WILD_RIFT
	114: { globalResourceKey: 'esport_leaguegroup_114' }, // CROSSFIRE_MOBILE
	115: { globalResourceKey: 'esport_leaguegroup_115' }, // CALL_OF_DUTY_MOBILE
	116: { globalResourceKey: 'esport_leaguegroup_116' }, // PUBG_MOBILE
	121: { globalResourceKey: 'esport_leaguegroup_121' }, // FREEFIRE
	150: { globalResourceKey: 'esport_leaguegroup_150' }, // APEX_LEGENDS
	151: { globalResourceKey: 'esport_gametype_98' }, // ESPORTS_PINGOAL
	152: { globalResourceKey: 'esport_leaguegroup_152' }, // AGE_OF_EMPIRES
	178: { globalResourceKey: 'esport_leaguegroup_178' }, // BRAWLSTARS
	207: {}, // NARAKA_BLADEPOINT
	208: { globalResourceKey: 'esport_leaguegroup_208' }, // CS2
	209: {}, // STREET_FIGHTER
	210: {}, // TEKKAN
	211: {}, // QUAKE
	212: {}, // HALO
	215: { globalResourceKey: 'esport_gametype_97' }, // ESPORTS_VIRTUAL
};

export const esportOptionLangMapping = {
	1: { sName: 'en', fName: 'English', fName2: '', icon: ChannelEnIcon },
	2: { sName: 'ch', fName: '繁體中文', fName2: 'Traditional Chinese', icon: ChannelChIcon },
	3: { sName: 'cs', fName: '简体中文', fName2: 'Simplified Chinese', icon: ChannelCsIcon },
	4: { sName: 'vn', fName: 'Tiếng Việt', fName2: 'Vietnam', icon: ChannelVnIcon },
	5: { sName: 'th', fName: 'ภาษาไทย', fName2: 'Thai', icon: ChannelThIcon },
	6: { sName: 'id', fName: 'Indonesian', fName2: '', icon: ChannelIdIcon },
	7: { sName: 'ko', fName: '한국어', fName2: '', icon: ChannelKoIcon },
	8: { sName: 'ru', fName: 'русский', fName2: '', icon: ChannelRuIcon },
	99: { sName: 'others', fName: '', fName2: '', icon: ChannelOtherIcon },
};
// const leagueGroupIdsOfOthers: string[] = Object.keys(supportedGames).filter(leagueGroupId => !supportedGames[leagueGroupId].globalResourceKey);
export const leagueGroupIdsInGameOrder = [25, 20, 208, 24, 111, 76, 16, 28, 37, 27, 29, 32, 34, 109, 114, 18, 116, 150, 30, 152, 115, 178, 121, 215, 99, 151];
export const leagueGroupIdsOfOthers = Object.keys(supportedGames).filter(leagueGroupId => leagueGroupIdsInGameOrder.indexOf(Number(leagueGroupId)) === -1);
export const isGroupHasSufficientData = (group: { [key: string]: RawMarketData } | undefined): boolean => {
	return typeof group === 'object' && Object.keys(group).length > 0;
};
export const getOrderedSubMarketData = (marketData: RawMarketData, marketPriority: PartialRecord<MarketType, keyof ProvidedMatchCount>): MarketData[] => {
	const rawMarketData_others: RawMarketData[] = [];
	const subMenu: MarketData[] = [];

	const markets = Object.keys(marketPriority) as MarketType[];
	const marketsHasMatchCount = markets.map(market => marketPriority[market]) as (keyof ProvidedMatchCount)[]; 
	
	if (typeof marketData.Group === 'object' && Object.keys(marketData.Group).length > 0) {
		Object.entries(marketData.Group).forEach(([leagueGroupId, rawMarketData]) => {
			if (leagueGroupId === '99' || leagueGroupIdsOfOthers.includes(leagueGroupId)) {
				rawMarketData_others.push(rawMarketData);
			} else if (leagueGroupIdsInGameOrder.includes(Number(leagueGroupId))) {
				const setting: ESportSetting = supportedGames[leagueGroupId];
				const firstPriorityFilterableMarket = markets.find((filterableMarket: string): boolean => ((rawMarketData[filterableMarket as ProvidedMarket] ?? 0) > 0));
				subMenu.push({
					...rawMarketData,
					HasLive: rawMarketData.L > 0,
					GameId: 43,
					LeagueGroupId: Number(leagueGroupId),
					Name: setting.globalResourceKey || '',
					Icon: `43_${leagueGroupId}`,
					FirstPriorityFilterableMarket: marketPriority[firstPriorityFilterableMarket as MarketType] ?? marketPriority[markets[0]] as keyof ProvidedMatchCount
				});
			}
		});
	}
	if (rawMarketData_others.length) {
		subMenu.push(rawMarketData_others.reduce((marketData_others: MarketData, rawMarketData: RawMarketData) => {
			marketsHasMatchCount.forEach(marketHasMatchCount => {
				if (marketHasMatchCount === 'L' && rawMarketData[marketHasMatchCount] > 0) {
					marketData_others.HasLive = true;
				}
				marketData_others[marketHasMatchCount] += rawMarketData[marketHasMatchCount];
			});
			const firstPriorityFilterableMarket = marketsHasMatchCount.find(providedMarket => rawMarketData[providedMarket as ProvidedMarket] ?? 0 > 0);
			if (firstPriorityFilterableMarket && marketsHasMatchCount.indexOf(firstPriorityFilterableMarket) < marketsHasMatchCount.indexOf(marketData_others.FirstPriorityFilterableMarket)) {
				marketData_others.FirstPriorityFilterableMarket = firstPriorityFilterableMarket;
			}
			const availableDays = Object.keys(rawMarketData.Days);
			if (availableDays.length > 0) {
				availableDays.forEach(availableDay => {
					if (marketData_others.Days[availableDay]) {
						marketData_others.Days[availableDay] += rawMarketData.Days[availableDay];
					} else {
						marketData_others.Days[availableDay] = rawMarketData.Days[availableDay];
					}
				})
			}
			return marketData_others;
		}, {
			HasLive: false,
			E: 0,
			L: 0,
			T: 0,
			O: 0,
			WO: 0,
			Days: {},
			GameId: 43,
			Icon: '43_99',
			LeagueGroupId: 99,
			Name: 'esport_gametype_99',
			FirstPriorityFilterableMarket: marketsHasMatchCount[marketsHasMatchCount.length - 1]
		}));
	}
	return [
		{
			HasLive: false,
			E: subMenu.reduce((sum, marketData) => sum + marketData.E, 0),
			L: subMenu.reduce((sum, marketData) => sum + marketData.L, 0),
			T: subMenu.reduce((sum, marketData) => sum + marketData.T, 0),
			O: marketData.O, //subMenu.reduce((sum, marketData) => sum + marketData.O, 0),
			WO: subMenu.reduce((sum, marketData) => sum + marketData.WO, 0),
			Days: marketData.Days || {},
			GameId: 43,
			LeagueGroupId: 0,
			Name: 'esport_gametype_0',
			Icon: '43_0',
			FirstPriorityFilterableMarket: marketsHasMatchCount.find(providedMarket => marketData[providedMarket as ProvidedMarket] ?? 0 > 0) || marketsHasMatchCount[0]
		},
		...(subMenu.sort((subMenuA: MarketData, subMenuB: MarketData): number => {
			if (!subMenuA.LeagueGroupId || !subMenuB.LeagueGroupId) {
				return 0;
			}
			const indexOfSubMenuA = leagueGroupIdsInGameOrder.indexOf(subMenuA.LeagueGroupId);
			const indexOfSubMenuB = leagueGroupIdsInGameOrder.indexOf(subMenuB.LeagueGroupId);
			return indexOfSubMenuA - indexOfSubMenuB;
		}))
	]
}
export const attachLeagueGroupIdToHref = (menuItem: MenuItems, marketData: MarketData): MenuItems => {
	return !marketData.LeagueGroupId ? menuItem : { ...menuItem, href: `${menuItem.href}?lgid=${marketData.LeagueGroupId}` }
}