const composeFetch = (url: string, option?: RequestInit) => {
	return fetch(url, {
		mode: 'cors',
		...option
	});
};

const get = (url: string, option: RequestInit = {}) => {
	return composeFetch(url, {
		method: 'GET',
		...option
	});
};

const getWithBearer = (url: string, option: RequestInit = {}) => (token?: string) => {
	return composeFetch(url, {
		method: 'GET',
		...option,
		headers: {
			...option.headers,
			...authHeader(token)
		}
	});
};

const post = <TBody>(url: string, body: TBody, option: RequestInit = {}) => {
	return composeFetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		...option,
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			...option.headers,
		}
	});
};

const postWithBearerBase = (url: string, option: RequestInit = {}) => (token?: string) => {
	return composeFetch(url, {
		method: 'POST',
		...option,
		headers: {
			...option.headers,
			...authHeader(token)
		},
	});
};

const postWithBearer = (url: string, option: RequestInit = {}) => (token?: string) => {
	return composeFetch(url, {
		method: 'POST',
		...option,
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			...option.headers,
			...authHeader(token)
		},
	});
};

const authHeader = (token?: string): Record<string, string> => 
	token 
		? { Authorization: `Bearer ${token}` }
		: {};

export const fetchComposer = {
	get,
	getWithBearer,
	post,
	postWithBearerBase,
	postWithBearer
};