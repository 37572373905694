import { switchViewService } from '@/services/swtich-view.service';
import { useAppearanceStore } from '@/store/appearance';
import { convertCultureToRef, getBetFrom } from '@/utils/common-util';
import { UserInfo } from '@nf/types/account';
import { SiteConfigsSettingsData } from '@nf/types/site';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { isDesktop } from 'react-device-detect';
import { v4 as uuid } from 'uuid';

interface CasinoRoyaleResponse {
    Seq: string;
    LaunchUrl: string;
    ErrorCode: number;
    ErrorMsg: string;
    TimeStamp: string;
};

export type DeeplinkExtractInfo = {
	path?: string;
	sportTypeId?: number | string;
	betTypeId?: number | string;
	category?: number | string;
}

export const fetchCasinoRoyaleExecuteUrl = async (
	user?: UserInfo, 
	siteConfig?: SiteConfigsSettingsData,
	isApp?: boolean,
	sportTypeId?: string | null, 
	betTypeId?: string | null, 
	category?: string | null
): Promise<CasinoRoyaleResponse> => {
	const view = useAppearanceStore.getState().view;

	const now = new Date();

	const returnUrl = !isApp ? undefined :
		location.hostname === 'localhost' ? `${location.protocol}//k957gu009.hl5888.com` : 
			`${location.protocol}//${location.hostname}`;


	const request = {
		TimeStamp: now.toISOString(),
		Seq: uuid(),
		CustInfo: {
			SiteId: siteConfig?.GetMessageID,
			Site: user?.AgentSite,
			ObCustId: user?.ID,
			LicenseeCustId: 0,
			TsId: user?.TsId,
			ObCustName: user?.LoginUserName,
			Language: switchViewService.convertLanguageToShortCode(user!.Lang, siteConfig!),
			CurrencyId: user?.CurrId,
			Currency: user?.Curr,
			BetFrom: getBetFrom(view, isDesktop),
			Platform: isDesktop ? 'd' : 'm',
			Ip: user?.Ip,
			BrowserUserAgent: navigator.userAgent,
			Dm: user?.DarkMode
		},
		Additional: {
			Location: user?.FromCountry,
			Gallery: siteConfig?.ImageServerUrl,
			OneDomain: location.hostname === 'localhost' ? 'k957gu009.hl5888.com' : location.hostname,
			OperatorId: user?.OperatorId,
			EnableSingleWallet: siteConfig?.EnableSingleWallet,
			LicenseeApiUrl: siteConfig?.SingleWalletAdapterApi,
			SportTypeId: sportTypeId ?? undefined,
			BetTypeId: betTypeId ?? undefined,
			Category: category ?? undefined,
			ExtendUrl: user?.LicenseeSiteSetting.ExtendSessionUrl,
			IsApp: isApp,
			ReturnUrl: returnUrl
		}
	};

	const options = {
		body: JSON.stringify(request)
	};

	const casinoRoyaleUrl = new URL('/api/Portal/GetLaunchPortalUrl', siteConfig?.SealComponentApiUrl);
	const response = await fetchComposer.postWithBearer(casinoRoyaleUrl.href, options)(user?.AccessToken ?? '');

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return  await response.json();
}

export const openCasinoRoyaleInNewWindow = (
	user?: UserInfo, 
	siteConfig?: SiteConfigsSettingsData,
	isApp?: boolean,
	sportTypeId?: string | null, 
	betTypeId?: string | null, 
	category?: string | null
) => {

	if (isApp) {
		fetchCasinoRoyaleExecuteUrl(
			user,
			siteConfig,
			isApp,
			sportTypeId,
			betTypeId,
			category
		).then(response => {
			location.href = response.LaunchUrl;
		});
	} else {
		const newWindow = window.open('about:blank', '_blank');
	
		if (newWindow) {
			fetchCasinoRoyaleExecuteUrl(
				user,
				siteConfig,
				isApp,
				sportTypeId,
				betTypeId,
				category
			).then(response => {
				newWindow.location = response.LaunchUrl;
				newWindow.focus();
			});
		}
	}
}