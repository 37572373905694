import { ApiQueryBasePromise } from '@/types';
import { getResponseJwtHeader } from '@/utils/web';
import { useQuery } from '@tanstack/react-query';

import { fetchComposer } from '@nf/utils-common/compose-fetch';
import queryKeys from '@/constants/query-keys';
import { useSiteStore } from '@/store/site';
import { getAuthToken, useAuthToken } from '@/store/auth';
import { ApiDomain } from '@nf/types/site';
import { useAtomValue } from 'jotai';
import { visitorAtom } from '@/atoms/visitor';
import { cloudUrlParser } from '@/utils/aws-domain-util';

interface GetSkinModes {
	ErrorCode: number;
	ErrorMessage: string | null;
	Data: SkinModesData;
}

interface SkinModesData {
	SwitchFrom: number;
	Options: number[];
}

interface GetSkinModesUrl {
	ErrorCode: number;
	ErrorMessage: string | null;
	Data: string;
}

export interface GetSkinModeUrlRequest {
	PlatForm: number;
	SkinMode: number;
}


export const fetchGetSkinMode = async (apiDomain: ApiDomain, token:string, reqestData?: GetSkinModeUrlRequest): Promise<ApiQueryBasePromise<GetSkinModesUrl>> => {
	const body = JSON.stringify(reqestData);
	const response = await fetchComposer.postWithBearer(cloudUrlParser(`${apiDomain.ctcdDomain}/api/SwitchMode/GetSkinModeUrl`), { body: body })(token);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
}

/** will be deprecated, directly use fetchGetSkinMode() instead */
export const useGetSkinModeUrl = (requestData?: GetSkinModeUrlRequest, webSkinTypeAllow?: string) => {
	const visitorInfo = useAtomValue(visitorAtom);
	const beforeToken = visitorInfo?.AccessToken;
	const authToken = useAuthToken();

	const token = authToken ?? beforeToken;

	const apiDomain = useSiteStore(
		state => state.apiDomain);

	const { data, isLoading, isError, error } = useQuery({
		queryKey: [queryKeys.SKIN_MODE_URL, apiDomain, token, requestData],
		queryFn: () => fetchGetSkinMode(apiDomain, token as string, requestData),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: requestData !== undefined && !!apiDomain.ctcdDomain && Boolean(token) && !!webSkinTypeAllow
	});

	return {
		JwtToken: data?.jwtToken,
		GetSkinModeUrlData: data?.data.Data,
		isLoading,
		isError,
		error,
	};
}


export const switchSkinMode = async (url: string, skin: string): Promise<void> => {
	const authToken = getAuthToken();
	const response = await fetchComposer.postWithBearerBase(url, { body: JSON.stringify({ skin }) })(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
};