import { BetTypeGroupSetting, BetTypeSettings } from '@/types/bet-type-settings';

export const esportMapsBetTypesList = [
	9001, 9002, 9003, 9005, 9006, 9007, 9008, 9009, 9011, 9012,
	9013, 9015, 9016, 9017, 9018, 9019, 9021, 9022, 9023, 9024,
	9025, 9027, 9028, 9029, 9031, 9032, 9033, 9034, 9035, 9037,
	9038, 9039, 9040, 9041, 9043, 9044, 9045, 9046, 9047, 9049,
	9050, 9051, 9052, 9053, 9055, 9056, 9057, 9058, 9059, 9060,
	9061, 9062, 9063, 9064, 9068, 9070, 9071, 9072, 9073, 9077,
	9078, 9079, 9080, 9081, 9082, 9083, 9084, 9085, 9086, 9087,
	9089, 9090, 9091, 9092, 9093, 9094, 9095, 9096, 9097, 9098,
	9099, 9100, 9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108,
	9109, 9110, 9111, 9112, 9113, 9114, 9115, 9116, 9117, 9118,
	9119, 9120, 9121, 9122, 9123, 9124, 9125, 9126, 9127, 9128,
	9129, 9130, 9131, 9132, 9133, 9134, 9135, 9136, 9137, 9138,
	9139, 9140, 9141, 9142
];
export const specialGroupByResourceIdBetTypes = [
	154, 155, 156, 219, 220, 381, 382, 383, 384, 385,
	609, 610, 611, 612, 614, 615, 616, 617, 627, 628,
	629, 630, 631, 650, 707, 708, 709, 710, 711, 714,
	3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 3915,
	3916, 3918, 9001, 9011, 9027, 9062, 9068, 9070, 9071, 9072, 
	9073, 9077, 9410, 9411, 9412, 9413, 9414, 9415, 9428, 9429, 
	9430, 9431, 9432, 9433, 9434, 9435, 9436, 9437, 9438, 9439, 
	9484, 9485, 9486, 9487, 9488, 9489, 9490, 9491, 9502, 9503, 
	9504, 9505, 9506, 9507, 9514, 9515, 9516, 9517, 9518, 9519, 
	9547, 9548, 9607, 9608, 9609, 9610, 9611, 9612, 9613, 9614, 
	9616, 9617, 9618, 9619, 9620, 9621, 9622, 9623, 9624, 9625, 
	9626, 9627, 9628, 9629, 9630, 9631, 9632, 9633, 9634, 9635, 
	9636, 9637, 9638, 9639, 9640, 9641, 9666, 9667, 9676, 9677, 
	9678, 9679, 9680, 9681, 9682, 9683, 9684, 9685, 9686, 9687, 
	9688, 9689, 9690, 9691, 9692, 9693, 9694, 9695, 9696, 9697, 
	9698, 9699, 9702, 9703, 9704, 9705, 9712, 9714, 9717, 9719, 
	9730, 9731, 9732, 9733, 9734, 9735, 9736, 9737, 9738, 9739,
	8804, 8805, 8806, 8807, 8808, 8809, 8810, 8811, 8812, 8813,
	8814, 8819, 8820, 8823, 8824
];

export const specialGroupBySliceResourceIdBetTypes = [613, 712, 713, 3912];
export const specialGroupByChangeResourceIdBetTypes = [3913];
export const specialGroupByLineBetTypes = [
	221, 222, 223, 224, 225, 226, 227
];

export const betTypesSetWithoutPadZero = new Set([
	4, 30, 152, 158, 165, 166, 392, 399, 405, 413, 414,
	416, 460, 467, 493, 494, 495, 644, 647, 648, 649, 650,
	703, 1204, 1302, 1317, 1325, 2707, 3900, 3910, 3917
]);

export const moreAisanLinesBetTypes = new Set([1, 3, 7, 8]);
export const choiceTranslateReplaceByLineBetTypeIds = [479, 481];
export const asianLineBetTypes = [1, 3, 7, 8];

export const betTypesGroupSetting: BetTypeGroupSetting = {
	//DotaGroup
	1: {
		groupBetTypes: [
			{ groupTitle: 'lbl_MapX_ML', betTypes: [9001] },
			{ groupTitle: 'lbl_MapX_Kill_Duration', betTypes: [9006, 9090, 9007, 9089, 9058, 9005] },
			{ groupTitle: 'lbl_MapX_TtlKills', betTypes: [9091, 9092, 9093, 9094, 9002, 9003] },
			{ groupTitle: 'lbl_MapX_TeamTtlKills', betTypes: [9095, 9096, 9097, 9098, 9099, 9100] },
			{ groupTitle: 'lbl_MapX_Kills', betTypes: [9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 9109, 9110, 9111, 9112] },
			{ groupTitle: 'lbl_MapX_Towers', betTypes: [901101, 901102, 901103, 9134, 9135, 9008, 9009, 9078] },
			{ groupTitle: 'lbl_MapX_Roshans', betTypes: [9015, 9016, 9017, 9136, 9133, 9012, 9013, 9079] },
			{ groupTitle: 'lbl_MapX_Barracks', betTypes: [9021, 9022, 9023, 9137, 9138, 9018, 9019, 9080] },
			{ groupTitle: 'lbl_MapX_Gold', betTypes: [9115, 9116, 9117, 9118, 9119, 9120] }
		]
	},
	//LOLGroup
	2: {
		groupBetTypes: [
			{ groupTitle: 'lbl_MapX_ML', betTypes: [9001] },
			{ groupTitle: 'lbl_MapX_Kill_Duration', betTypes: [9006, 9090, 9007, 9089, 9058, 9005] },
			{ groupTitle: 'lbl_MapX_TtlKills', betTypes: [9091, 9092, 9093, 9094, 9002, 9003] },
			{ groupTitle: 'lbl_MapX_TeamTtlKills', betTypes: [9095, 9096, 9097, 9098, 9099, 9100] },
			{ groupTitle: 'lbl_MapX_Kills', betTypes: [9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 9109, 9110, 9111, 9112] },
			{ groupTitle: 'lbl_MapX_Turrets', betTypes: [902701, 902702, 902703, 9114, 9113, 9024, 9025, 9081] },
			{ groupTitle: 'lbl_MapX_HrldTtlGold', betTypes: [9125, 9126, 9115, 9116, 9117, 9120] },
			{ groupTitle: 'lbl_MapX_Dragons', betTypes: [9031, 9032, 9033, 9131, 9132, 9028, 9029, 9082] },
			{ groupTitle: 'lbl_MapX_Barrons', betTypes: [9037, 9038, 9039, 9127, 9128, 9034, 9035, 9083] },
			{ groupTitle: 'lbl_MapX_Inhibitors', betTypes: [9043, 9044, 9045, 9129, 9130, 9040, 9041, 9084] }
		]
	},
	//CSGroup
	3: {
		groupBetTypes: [
			{ groupTitle: 'lbl_MapX_ML', betTypes: [9001] },
			{ groupTitle: 'lbl_MapX_Rounds', betTypes: [906801, 906205, 906816, 9059, 9060, 9061, 9063, 906210, 9064, 9139, 9140, 9087, 9074] },
			{ groupTitle: 'lbl_MapX_R1Bet', betTypes: [906801, 907201, 907301, 907701, 907001, 907101] },
			{ groupTitle: 'lbl_MapX_R16Bet', betTypes: [906813, 907213, 907313, 907713, 907013, 907113] }
		]
	},
	//KingGroup
	4: {
		groupBetTypes: [
			{ groupTitle: 'lbl_MapX_ML', betTypes: [9001] },
			{ groupTitle: 'lbl_MapX_Kill_Duration', betTypes: [9006, 9090, 9007, 9089, 9058, 9005] },
			{ groupTitle: 'lbl_MapX_TtlKills', betTypes: [9091, 9092, 9093, 9094, 9002, 9003] },
			{ groupTitle: 'lbl_MapX_TeamTtlKills', betTypes: [9095, 9096, 9097, 9098, 9099, 9100] },
			{ groupTitle: 'lbl_MapX_Kills', betTypes: [9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 9109, 9110, 9111, 9112] },
			{ groupTitle: 'lbl_MapX_Towers', betTypes: [901101, 901102, 901103, 9142, 9141, 9008, 9009, 9078] },
			{ groupTitle: 'lbl_MapX_Gold', betTypes: [9115, 9116, 9117, 9118, 9119, 9120] },
			{ groupTitle: 'lbl_MapX_Tyrants', betTypes: [9049, 9050, 9051, 9121, 9122, 9046, 9047, 9085] },
			{ groupTitle: 'lbl_MapX_LordsStormDrgn', betTypes: [9055, 9056, 9057, 9123, 9124, 9052, 9053, 9086] }
		]
	},
	//WildRiftGroup
	5: {
		groupBetTypes: [
			{ groupTitle: 'lbl_MapX_ML', betTypes: [9001] },
			{ groupTitle: 'lbl_MapX_Kill_Duration', betTypes: [9006, 9090, 9007, 9089, 9058, 9005] },
			{ groupTitle: 'lbl_MapX_TtlKills', betTypes: [9091, 9092, 9093, 9094, 9002, 9003] },
			{ groupTitle: 'lbl_MapX_TeamTtlKills', betTypes: [9095, 9096, 9097, 9098, 9099, 9100] },
			{ groupTitle: 'lbl_MapX_Kills', betTypes: [9101, 9102, 9103, 9104, 9105, 9106, 9107, 9108, 9109, 9110, 9111, 9112] },
			{ groupTitle: 'lbl_MapX_Turrets', betTypes: [902701, 902702, 902703, 9114, 9113, 9024, 9025, 9081] },
			{ groupTitle: 'lbl_MapX_HrldTtlGold', betTypes: [9125, 9126, 9115, 9116, 9117, 9120] },
			{ groupTitle: 'lbl_MapX_Dragons', betTypes: [9031, 9032, 9033, 9131, 9132, 9028, 9029, 9082] },
			{ groupTitle: 'lbl_MapX_Barrons', betTypes: [9037, 9038, 9039, 9127, 9128, 9034, 9035, 9083] },
			{ groupTitle: 'lbl_MapX_Inhibitors', betTypes: [9043, 9044, 9045, 9129, 9130, 9040, 9041, 9084] }
		]
	}
};


export const betTypeSettings: BetTypeSettings = {
	1: {
		betTypeFullName: {
			0: 'lbl_Header_FTHDP_OU',
			5: 'lbl_Handicap',
			8: 'lbl_Header_FTRunLine',
		},
		betTypeName: {
			0: 'lbl_FTHDP', // FT. HDP
			5: 'lbl_hdp',		// HDP
			8: 'lbl_hdp',		// HDP
		},
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	2: {
		betTypeFullName: {
			0: 'lbl_Header_1FTOE',
			2: 'lbl_ou3_ft_oe',
		},
		betTypeName: {
			0: 'lbl_FTOE',		// FT. O/E
			5: 'lbl_ShortOE',	// O/E
		},
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'h' },
			{ label: 'lbl_even', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	3: {
		betTypeFullName: {
			0: 'lbl_Header_FTOU_OU',
			5: 'lbl_OverUnder',
			8: 'lbl_Header_FTTotalRun',
		},
		betTypeName: {
			0: 'lbl_FTOU', // FT. O/U
			5: 'lbl_o_u'	 // O/U
		},
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'h' },
			{ label: 'lbl_under', betTeam: 'a' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	5: {
		betTypeName: {
			0: 'lbl_FT1X2',
			50: 'lbl_E1X2'
		},
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw',
			},
			'2': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoiceTranslateType: 'General',
		layoutType: 'Col-3',
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' },
		]
	},
	6: {
		betTypeName: {
			0: 'b6'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet6_01', betTeam: '0-1' },
			{ label: 'bet6_23', betTeam: '2-3' },
			{ label: 'bet6_46', betTeam: '4-6' },
			{ label: 'bet6_7over', betTeam: '7-over' }
		],
		layoutType: 'Col-4'
	},
	7: {
		betTypeFullName: {
			0: 'lbl_Header_1HHDP_OU',
		},
		betTypeName: {
			0: 'lbl_1HHDP', // 1H. HDP
		},
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8: {
		betTypeFullName: {
			0: 'lbl_Header_1HOU_OU',
			8: 'lbl_Header_1HTotalRun',
		},
		betTypeName: {
			0: 'lbl_1HOU', // 1H. O/U
		},
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'h' },
			{ label: 'lbl_under', betTeam: 'a' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	12: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'h' },
			{ label: 'lbl_even', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	13: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet13_hy', betTeam: 'hy' },
			{ label: 'bet13_hn', betTeam: 'hn' },
			{ label: 'bet13_ay', betTeam: 'ay' },
			{ label: 'bet13_an', betTeam: 'an' }
		],
		layoutType: 'Col-4'
	},
	14: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_fglg_firstgoal', betTeam: '11', betChoiceGroup: 1 },
			{ label: 'lbl_fglg_firstgoal', betTeam: '21', betChoiceGroup: 1 },
			{ label: 'lbl_fglg_lastgoal', betTeam: '12', betChoiceGroup: 2 },
			{ label: 'lbl_fglg_lastgoal', betTeam: '22', betChoiceGroup: 2 },
			{ label: 'lbl_fglg_nogoal', betTeam: '00', betChoiceGroup: 3 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	15: {
		betTypeName: {
			0: 'lbl_1H1X2'
		},
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw',
			},
			'2': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoiceTranslateType: 'General',
		layoutType: 'Col-3',
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' },
		]
	},
	16: {
		betTypeName: {
			0: 'lbl_B16'
		},
		betTypeTranslateType: 'General',
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet16_11', betTeam: '11' },
			{ label: 'bet16_10', betTeam: '10' },
			{ label: 'bet16_12', betTeam: '12' },
			{ label: 'bet16_01', betTeam: '01' },
			{ label: 'bet16_00', betTeam: '00' },
			{ label: 'bet16_02', betTeam: '02' },
			{ label: 'bet16_21', betTeam: '21' },
			{ label: 'bet16_20', betTeam: '20' },
			{ label: 'bet16_22', betTeam: '22' }
		],
		layoutType: 'Col-3'
	},
	17: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	18: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	20: {
		betTypeName: {
			0: 'lbl_moneyline'
		},
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	21: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	22: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet22_1', betTeam: '1' },
			{ label: 'bet22_2', betTeam: '2' },
			{ label: 'bet22_x', betTeam: 'x' },
		],
		layoutType: 'Col-3'
	},
	24: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet24_1x', betTeam: '1x' },
			{ label: 'bet24_12', betTeam: '12' },
			{ label: 'bet24_2x', betTeam: '2x' }
		],
		layoutType: 'Col-3-2to1'
	},
	25: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	26: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet26_b', betTeam: 'b' },
			{ label: 'bet26_o', betTeam: 'o' },
			{ label: 'bet26_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	27: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	28: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_away', betTeam: '2' }
		],
		layoutType: 'Col-3'
	},
	126: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet126_01', betTeam: '0-1' },
			{ label: 'bet126_23', betTeam: '2-3' },
			{ label: 'bet126_4over', betTeam: '4-over' }
		],
		layoutType: 'Col-3'
	},
	127: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_fglg_firstgoal', betTeam: '11', betChoiceGroup: 1 },
			{ label: 'lbl_fglg_firstgoal', betTeam: '21', betChoiceGroup: 1 },
			{ label: 'lbl_fglg_lastgoal', betTeam: '12', betChoiceGroup: 2 },
			{ label: 'lbl_fglg_lastgoal', betTeam: '22', betChoiceGroup: 2 },
			{ label: 'lbl_fglg_nogoal', betTeam: '00', betChoiceGroup: 3 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	128: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet128_oo', betTeam: 'oo' },
			{ label: 'bet128_oe', betTeam: 'oe' },
			{ label: 'bet128_eo', betTeam: 'eo' },
			{ label: 'bet128_ee', betTeam: 'ee' }
		],
		layoutType: 'Col-4'
	},
	133: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	134: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	140: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		layoutType: 'Col-3',
		betChoices: [
			{ label: 'bet140_1h', betTeam: '1h' },
			{ label: 'bet140_2h', betTeam: '2h' },
			{ label: 'bet140_tie', betTeam: 'tie' },
		]
	},
	141: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		layoutType: 'Col-3',
		betChoices: [
			{ label: 'bet141_1h', betTeam: '1h' },
			{ label: 'bet141_2h', betTeam: '2h' },
			{ label: 'bet141_tie', betTeam: 'tie' },
		]
	},
	142: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		layoutType: 'Col-3',
		betChoices: [
			{ label: 'bet142_1h', betTeam: '1h' },
			{ label: 'bet142_2h', betTeam: '2h' },
			{ label: 'bet142_tie', betTeam: 'tie' },
		]
	},
	144: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet144_ho', betTeam: 'ho' },
			{ label: 'bet144_do', betTeam: 'do' },
			{ label: 'bet144_ao', betTeam: 'ao' },
			{ label: 'bet144_hu', betTeam: 'hu' },
			{ label: 'bet144_du', betTeam: 'du' },
			{ label: 'bet144_au', betTeam: 'au' }
		],
		layoutType: 'Col-3'
	},
	146: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	147: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	148: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	149: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	150: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	151: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet151_1x', betTeam: '1x' },
			{ label: 'bet151_12', betTeam: '12' },
			{ label: 'bet151_2x', betTeam: '2x' }
		],
		layoutType: 'Col-3-2to1'
	},
	153: {
		betTypeName: {
			0: 'lbl_game_hdp'
		},
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	154: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	155: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	156: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	159: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet159_g0', betTeam: 'g0' },
			{ label: 'bet159_g1', betTeam: 'g1' },
			{ label: 'bet159_g2', betTeam: 'g2' },
			{ label: 'bet159_g3', betTeam: 'g3' },
			{ label: 'bet159_g4', betTeam: 'g4' },
			{ label: 'bet159_g5', betTeam: 'g5' },
			{ label: 'bet159_g6', betTeam: 'g6' }
		],
		layoutType: 'Col-3'
	},
	161: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet161_g0', betTeam: 'g0' },
			{ label: 'bet161_g1', betTeam: 'g1' },
			{ label: 'bet161_g2', betTeam: 'g2' },
			{ label: 'bet161_g3', betTeam: 'g3' }
		],
		layoutType: 'Col-4'
	},
	162: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet162_g0', betTeam: 'g0' },
			{ label: 'bet162_g1', betTeam: 'g1' },
			{ label: 'bet162_g2', betTeam: 'g2' },
			{ label: 'bet162_g3', betTeam: 'g3' }
		],
		layoutType: 'Col-4'
	},
	164: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet164_1', betTeam: '1' },
			{ label: 'bet164_2', betTeam: '2' },
			{ label: 'bet164_x', betTeam: 'x' },
		],
		layoutType: 'Col-3'
	},
	171: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			// 借用其他bettype 適合的翻譯
			{ label: 'bet161_g1', betTeam: 'h1', betChoiceGroup: 1 },
			{ label: 'bet161_g2', betTeam: 'h2', betChoiceGroup: 2 },
			{ label: 'bet161_g3', betTeam: 'h3', betChoiceGroup: 3 },
			{ label: 'bet161_g1', betTeam: 'a1', betChoiceGroup: 1 },
			{ label: 'bet161_g2', betTeam: 'a2', betChoiceGroup: 2 },
			{ label: 'bet161_g3', betTeam: 'a3', betChoiceGroup: 3 },
			{ label: 'bet171_d', betTeam: 'd', betChoiceGroup: 4 },
			{ label: 'bet171_ng', betTeam: 'ng', betChoiceGroup: 5 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	172: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet172_hh', betTeam: 'hh' },
			{ label: 'bet172_dh', betTeam: 'dh' },
			{ label: 'bet172_ah', betTeam: 'ah' },
			{ label: 'bet172_ha', betTeam: 'ha' },
			{ label: 'bet172_da', betTeam: 'da' },
			{ label: 'bet172_aa', betTeam: 'aa' },
			{ label: 'bet172_no', betTeam: 'no' }
		],
		layoutType: 'Col-3'
	},
	177: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		layoutType: 'Col-3',
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw',
			},
			'2': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' },
		]
	},
	184: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	186: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet186_hd', betTeam: 'hd' },
			{ label: 'bet186_ha', betTeam: 'ha' },
			{ label: 'bet186_da', betTeam: 'da' }
		],
		layoutType: 'Col-3-2to1'
	},
	188: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	189: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	190: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	194: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	203: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	219: {
		betTypeName: {
			0: 'bettype219_title'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	220: {
		betTypeName: {
			0: 'bettype220_title'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	221: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'GoalYes', betTeam: '2', line: 'hdp1', betChoiceGroup: 1 },
			{ label: 'GoalNo', betTeam: '-2', line: 'hdp1', betChoiceGroup: 1 },
			{ label: 'CornerYes', betTeam: '4', line: 'hdp2', betChoiceGroup: 2 },
			{ label: 'CornerNo', betTeam: '-4', line: 'hdp2', betChoiceGroup: 2 },
			{ label: 'GoalKickYes', betTeam: '16', line: 'hdp4', betChoiceGroup: 3 },
			{ label: 'GoalKickNo', betTeam: '-16', line: 'hdp4', betChoiceGroup: 3 },
			{ label: 'FreeKickYes', betTeam: '8', line: 'hdp3', betChoiceGroup: 4 },
			{ label: 'FreeKickNo', betTeam: '-8', line: 'hdp3', betChoiceGroup: 4 },
			{ label: 'ThrowInYes', betTeam: '32', line: 'hdp5', betChoiceGroup: 5 },
			{ label: 'ThrowInNo', betTeam: '-32', line: 'hdp5', betChoiceGroup: 5 }
		],
		layoutType: 'Col-2-Fast-Market'
	},
	222: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'GoalYes', betTeam: '2', line: 'hdp1', betChoiceGroup: 1 },
			{ label: 'GoalNo', betTeam: '-2', line: 'hdp1', betChoiceGroup: 1 },
			{ label: 'CornerYes', betTeam: '4', line: 'hdp2', betChoiceGroup: 2 },
			{ label: 'CornerNo', betTeam: '-4', line: 'hdp2', betChoiceGroup: 2 },
			{ label: 'PenaltyYes', betTeam: '128', line: 'hdp3', betChoiceGroup: 3 },
			{ label: '', betTeam: '', betChoiceGroup: 3 },
			{ label: 'GoalKickYes', betTeam: '16', line: 'hdp5', betChoiceGroup: 4 },
			{ label: 'GoalKickNo', betTeam: '-16', line: 'hdp5', betChoiceGroup: 4 },
			{ label: 'FreeKickYes', betTeam: '8', line: 'hdp4', betChoiceGroup: 5 },
			{ label: 'FreeKickNo', betTeam: '-8', line: 'hdp4', betChoiceGroup: 5 },
			{ label: 'ThrowInYes', betTeam: '32', line: 'hdp6', betChoiceGroup: 6 },
			{ label: 'ThrowInNo', betTeam: '-32', line: 'hdp6', betChoiceGroup: 6 }
		],
		layoutType: 'Col-2-Fast-Market'
	},
	223: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'Goal', betTeam: '2' },
			{ label: 'Corner', betTeam: '4' },
			{ label: 'GoalKick', betTeam: '16' },
			{ label: 'FreeKick', betTeam: '8' },
			{ label: 'ThrowIn', betTeam: '32' },
			{ label: 'None', betTeam: '1' },
		],
		layoutType: 'Col-3'
	},
	224: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'Goal', betTeam: '2' },
			{ label: 'Corner', betTeam: '4' },
			{ label: 'Penalty', betTeam: '128' },
			{ label: 'Booking', betTeam: '64' },
			{ label: 'None', betTeam: '1' },
		],
		layoutType: 'Col-3'
	},
	225: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'SetPieceYes', betTeam: '44' },
			{ label: 'SetPieceNo', betTeam: '1' }
		],
		layoutType: 'Col-2'
	},
	226: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_226Choice26', betTeam: '26' },
			{ label: 'lbl_226Choice36', betTeam: '36' },
			{ label: 'lbl_226Choice1', betTeam: '1' },
		],
		layoutType: 'Col-3-2to1'
	},
	227: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_227Choice194', betTeam: '194' },
			{ label: 'lbl_227Choice4', betTeam: '4' },
			{ label: 'lbl_227Choice1', betTeam: '1' },
		],
		layoutType: 'Col-3-2to1'
	},
	228: {
		betChoices: [
			{ label: 'lbl_odds_o', betTeam: 'o' },
			{ label: 'lbl_odds_u', betTeam: 'u' }
		]
	},
	229: {
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		]
	},
	376: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '1', betTeam: 'hs' },
			{ label: '2', betTeam: 'hs' },
			{ label: '3', betTeam: 'hs' },
			{ label: '4', betTeam: 'hs' },
			{ label: '5', betTeam: 'hs' },
			{ label: '1', betTeam: 'hm' },
			{ label: '2', betTeam: 'hm' },
			{ label: '3', betTeam: 'hm' },
			{ label: '4', betTeam: 'hm' },
			{ label: '5', betTeam: 'hm' },
			{ label: '1', betTeam: 'as' },
			{ label: '2', betTeam: 'as' },
			{ label: '3', betTeam: 'as' },
			{ label: '4', betTeam: 'as' },
			{ label: '5', betTeam: 'as' },
			{ label: '1', betTeam: 'am' },
			{ label: '2', betTeam: 'am' },
			{ label: '3', betTeam: 'am' },
			{ label: '4', betTeam: 'am' },
			{ label: '5', betTeam: 'am' }
		],
		layoutType: 'Penalty'
	},
	381: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: 'lbl_Score', betTeam: 's' },
			{ label: 'lbl_Miss', betTeam: 'm' }
		],
		layoutType: 'Col-2'
	},
	382: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: 'lbl_Score', betTeam: 's' },
			{ label: 'lbl_Miss', betTeam: 'm' }
		],
		layoutType: 'Col-2'
	},
	383: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	384: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: 'pop_b', betTeam: 'b' },
			{ label: 'pop_o', betTeam: 'o' },
			{ label: 'pop_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	385: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_1x2_Home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw'
			},
			'2': {
				label: 'lbl_1x2_Away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' }
		],
		layoutType: 'Col-3'
	},
	386: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	387: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	388: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	389: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	390: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	391: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0-4', betTeam: 'g4' },
			{ label: '5', betTeam: 'g5' },
			{ label: '6', betTeam: 'g6' },
			{ label: '7', betTeam: 'g7' },
			{ label: '8', betTeam: 'g8' },
			{ label: '9+', betTeam: 'g9+' }
		],
		layoutType: 'Col-3'
	},
	392: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '1-0', betTeam: '1-0' },
			{ label: 'H.AOS', betTeam: 'haos' },
			{ label: '0-1', betTeam: '0-1' },
			{ label: '2-0', betTeam: '2-0' },
			{ label: 'A.AOS', betTeam: 'aaos' },
			{ label: '0-2', betTeam: '0-2' },
			{ label: '2-1', betTeam: '2-1' },
			{ label: '', betTeam: '' },
			{ label: '1-2', betTeam: '1-2' },
			{ label: '3-0', betTeam: '3-0' },
			{ label: '', betTeam: '' },
			{ label: '0-3', betTeam: '0-3' },
			{ label: '3-1', betTeam: '3-1' },
			{ label: '', betTeam: '' },
			{ label: '1-3', betTeam: '1-3' },
			{ label: '3-2', betTeam: '3-2' },
			{ label: '', betTeam: '' },
			{ label: '2-3', betTeam: '2-3' },
			{ label: '4-2', betTeam: '4-2' },
			{ label: '', betTeam: '' },
			{ label: '2-4', betTeam: '2-4' },
			{ label: '4-3', betTeam: '4-3' },
			{ label: '', betTeam: '' },
			{ label: '3-4', betTeam: '3-4' },
			{ label: '5-4', betTeam: '5-4' },
			{ label: '', betTeam: '' },
			{ label: '4-5', betTeam: '4-5' }
		],
		layoutType: 'Col-3'
	},
	393: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' },
		],
		layoutType: 'Col-2'
	},
	394: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' },
		],
		layoutType: 'Col-2'
	},
	395: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet395_s', betTeam: 's' },
			{ label: 'bet395_h', betTeam: 'h' },
			{ label: 'bet395_p', betTeam: 'p' },
			{ label: 'bet395_fk', betTeam: 'fk' },
			{ label: 'bet395_og', betTeam: 'og' },
			{ label: 'bet395_ng', betTeam: 'ng' }
		],
		layoutType: 'Col-3'
	},
	396: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet396_1', betTeam: '1' },
			{ label: 'bet396_2', betTeam: '2' },
			{ label: 'bet396_x', betTeam: 'x' },
		],
		layoutType: 'Col-3'
	},
	397: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			// 借用其他bettype 適合的翻譯
			{ label: 'bet161_g1', betTeam: 'h1', betChoiceGroup: 1 },
			{ label: 'bet161_g2', betTeam: 'h2', betChoiceGroup: 2 },
			{ label: 'bet161_g3', betTeam: 'h3+', betChoiceGroup: 3 },
			{ label: 'bet161_g1', betTeam: 'a1', betChoiceGroup: 1 },
			{ label: 'bet161_g2', betTeam: 'a2', betChoiceGroup: 2 },
			{ label: 'bet161_g3', betTeam: 'a3+', betChoiceGroup: 3 },
			{ label: 'bet397_d', betTeam: 'd', betChoiceGroup: 4 },
			{ label: 'bet397_ng', betTeam: 'ng', betChoiceGroup: 5 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	398: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet398_1x', betTeam: '1x' },
			{ label: 'bet398_12', betTeam: '12' },
			{ label: 'bet398_2x', betTeam: '2x' },
		],
		layoutType: 'Col-3-2to1'
	},
	399: {
		betTypeName: {
			0: 'b166'
		},
		betTypeTranslateType: 'BetType',
		betChoices: [
			{ label: '1-0', betTeam: '1-0' },
			{ label: '0-0', betTeam: '0-0' },
			{ label: '0-1', betTeam: '0-1' },
			{ label: '2-0', betTeam: '2-0' },
			{ label: '1-1', betTeam: '1-1' },
			{ label: '0-2', betTeam: '0-2' },
			{ label: '2-1', betTeam: '2-1' },
			{ label: '2-2', betTeam: '2-2' },
			{ label: '1-2', betTeam: '1-2' },
			{ label: '3-0', betTeam: '3-0' },
			{ label: 'AOS', betTeam: 'aos' },
			{ label: '0-3', betTeam: '0-3' }
		],
		layoutType: 'Col-3'
	},
	400: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	401: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	402: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	403: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	404: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	405: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '1-0', betTeam: '1:0' },
			{ label: '0-0', betTeam: '0:0' },
			{ label: '0-1', betTeam: '0:1' },
			{ label: '2-0', betTeam: '2:0' },
			{ label: '1-1', betTeam: '1:1' },
			{ label: '0-2', betTeam: '0:2' },
			{ label: '2-1', betTeam: '2:1' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-3', betTeam: '3:3' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: 'AOS', betTeam: 'aos' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: '', betTeam: '' },
			{ label: '2-3', betTeam: '2:3' }
		],
		layoutType: 'Col-3'
	},
	412: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet412_0', betTeam: '0' },
			{ label: 'bet412_1', betTeam: '1' },
			{ label: 'bet412_2', betTeam: '2' },
			{ label: 'bet412_3over', betTeam: '3-over' }
		],
		layoutType: 'Col-4'
	},
	413: {
		betTypeName: {
			0: 'b4'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '1-0', betTeam: '1:0' },
			{ label: '0-0', betTeam: '0:0' },
			{ label: '0-1', betTeam: '0:1' },
			{ label: '2-0', betTeam: '2:0' },
			{ label: '1-1', betTeam: '1:1' },
			{ label: '0-2', betTeam: '0:2' },
			{ label: '2-1', betTeam: '2:1' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-3', betTeam: '3:3' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: '4-4', betTeam: '4:4' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: 'AOS', betTeam: 'aos' },
			{ label: '2-3', betTeam: '2:3' },
			{ label: '4-0', betTeam: '4:0' },
			{ label: '', betTeam: '' },
			{ label: '0-4', betTeam: '0:4' },
			{ label: '4-1', betTeam: '4:1' },
			{ label: '', betTeam: '' },
			{ label: '1-4', betTeam: '1:4' },
			{ label: '4-2', betTeam: '4:2' },
			{ label: '', betTeam: '' },
			{ label: '2-4', betTeam: '2:4' },
			{ label: '4-3', betTeam: '4:3' },
			{ label: '', betTeam: '' },
			{ label: '3-4', betTeam: '3:4' }
		],
		layoutType: 'Col-3'
	},
	414: {
		betTypeName: {
			0: 'b30'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '1-0', betTeam: '1:0' },
			{ label: '0-0', betTeam: '0:0' },
			{ label: '0-1', betTeam: '0:1' },
			{ label: '2-0', betTeam: '2:0' },
			{ label: '1-1', betTeam: '1:1' },
			{ label: '0-2', betTeam: '0:2' },
			{ label: '2-1', betTeam: '2:1' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-3', betTeam: '3:3' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: 'AOS', betTeam: 'aos' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: '', betTeam: '' },
			{ label: '2-3', betTeam: '2:3' }
		],
		layoutType: 'Col-3'
	},
	416: {
		betTypeName: {
			0: 'b152'
		},
		betTypeTranslateType: 'BetType',
		betChoices: [
			{ label: '0-0/0-0', betTeam: '0:0/0:0' },
			{ label: '0-0/0-1', betTeam: '0:0/0:1' },
			{ label: '0-0/0-2', betTeam: '0:0/0:2' },
			{ label: '0-0/0-3', betTeam: '0:0/0:3' },
			{ label: '0-0/0-4', betTeam: '0:0/0:4' },
			{ label: '0-0/1-0', betTeam: '0:0/1:0' },
			{ label: '0-0/1-1', betTeam: '0:0/1:1' },
			{ label: '0-0/1-2', betTeam: '0:0/1:2' },
			{ label: '0-0/1-3', betTeam: '0:0/1:3' },
			{ label: '0-0/1-4', betTeam: '0:0/1:4' },
			{ label: '0-0/2-0', betTeam: '0:0/2:0' },
			{ label: '0-0/2-1', betTeam: '0:0/2:1' },
			{ label: '0-0/2-2', betTeam: '0:0/2:2' },
			{ label: '0-0/2-3', betTeam: '0:0/2:3' },
			{ label: '0-0/2-4', betTeam: '0:0/2:4' },
			{ label: '0-0/3-0', betTeam: '0:0/3:0' },
			{ label: '0-0/3-1', betTeam: '0:0/3:1' },
			{ label: '0-0/3-2', betTeam: '0:0/3:2' },
			{ label: '0-0/3-3', betTeam: '0:0/3:3' },
			{ label: '0-0/3-4', betTeam: '0:0/3:4' },
			{ label: '0-0/4-0', betTeam: '0:0/4:0' },
			{ label: '0-0/4-1', betTeam: '0:0/4:1' },
			{ label: '0-0/4-2', betTeam: '0:0/4:2' },
			{ label: '0-0/4-3', betTeam: '0:0/4:3' },
			{ label: '0-0/4-4', betTeam: '0:0/4:4' },
			{ label: '0-0/AOS', betTeam: '0:0/AOS' },
			{ label: '0-1/0-1', betTeam: '0:1/0:1' },
			{ label: '0-1/0-2', betTeam: '0:1/0:2' },
			{ label: '0-1/0-3', betTeam: '0:1/0:3' },
			{ label: '0-1/0-4', betTeam: '0:1/0:4' },
			{ label: '0-1/1-1', betTeam: '0:1/1:1' },
			{ label: '0-1/1-2', betTeam: '0:1/1:2' },
			{ label: '0-1/1-3', betTeam: '0:1/1:3' },
			{ label: '0-1/1-4', betTeam: '0:1/1:4' },
			{ label: '0-1/2-1', betTeam: '0:1/2:1' },
			{ label: '0-1/2-2', betTeam: '0:1/2:2' },
			{ label: '0-1/2-3', betTeam: '0:1/2:3' },
			{ label: '0-1/2-4', betTeam: '0:1/2:4' },
			{ label: '0-1/3-1', betTeam: '0:1/3:1' },
			{ label: '0-1/3-2', betTeam: '0:1/3:2' },
			{ label: '0-1/3-3', betTeam: '0:1/3:3' },
			{ label: '0-1/3-4', betTeam: '0:1/3:4' },
			{ label: '0-1/4-1', betTeam: '0:1/4:1' },
			{ label: '0-1/4-2', betTeam: '0:1/4:2' },
			{ label: '0-1/4-3', betTeam: '0:1/4:3' },
			{ label: '0-1/4-4', betTeam: '0:1/4:4' },
			{ label: '0-1/AOS', betTeam: '0:1/AOS' },
			{ label: '0-2/0-2', betTeam: '0:2/0:2' },
			{ label: '0-2/0-3', betTeam: '0:2/0:3' },
			{ label: '0-2/0-4', betTeam: '0:2/0:4' },
			{ label: '0-2/1-2', betTeam: '0:2/1:2' },
			{ label: '0-2/1-3', betTeam: '0:2/1:3' },
			{ label: '0-2/1-4', betTeam: '0:2/1:4' },
			{ label: '0-2/2-2', betTeam: '0:2/2:2' },
			{ label: '0-2/2-3', betTeam: '0:2/2:3' },
			{ label: '0-2/2-4', betTeam: '0:2/2:4' },
			{ label: '0-2/3-2', betTeam: '0:2/3:2' },
			{ label: '0-2/3-3', betTeam: '0:2/3:3' },
			{ label: '0-2/3-4', betTeam: '0:2/3:4' },
			{ label: '0-2/4-2', betTeam: '0:2/4:2' },
			{ label: '0-2/4-3', betTeam: '0:2/4:3' },
			{ label: '0-2/4-4', betTeam: '0:2/4:4' },
			{ label: '0-2/AOS', betTeam: '0:2/AOS' },
			{ label: '0-3/0-3', betTeam: '0:3/0:3' },
			{ label: '0-3/0-4', betTeam: '0:3/0:4' },
			{ label: '0-3/1-3', betTeam: '0:3/1:3' },
			{ label: '0-3/1-4', betTeam: '0:3/1:4' },
			{ label: '0-3/2-3', betTeam: '0:3/2:3' },
			{ label: '0-3/2-4', betTeam: '0:3/2:4' },
			{ label: '0-3/3-3', betTeam: '0:3/3:3' },
			{ label: '0-3/3-4', betTeam: '0:3/3:4' },
			{ label: '0-3/4-3', betTeam: '0:3/4:3' },
			{ label: '0-3/4-4', betTeam: '0:3/4:4' },
			{ label: '0-3/AOS', betTeam: '0:3/AOS' },
			{ label: '1-0/1-0', betTeam: '1:0/1:0' },
			{ label: '1-0/1-1', betTeam: '1:0/1:1' },
			{ label: '1-0/1-2', betTeam: '1:0/1:2' },
			{ label: '1-0/1-3', betTeam: '1:0/1:3' },
			{ label: '1-0/1-4', betTeam: '1:0/1:4' },
			{ label: '1-0/2-0', betTeam: '1:0/2:0' },
			{ label: '1-0/2-1', betTeam: '1:0/2:1' },
			{ label: '1-0/2-2', betTeam: '1:0/2:2' },
			{ label: '1-0/2-3', betTeam: '1:0/2:3' },
			{ label: '1-0/2-4', betTeam: '1:0/2:4' },
			{ label: '1-0/3-0', betTeam: '1:0/3:0' },
			{ label: '1-0/3-1', betTeam: '1:0/3:1' },
			{ label: '1-0/3-2', betTeam: '1:0/3:2' },
			{ label: '1-0/3-3', betTeam: '1:0/3:3' },
			{ label: '1-0/3-4', betTeam: '1:0/3:4' },
			{ label: '1-0/4-0', betTeam: '1:0/4:0' },
			{ label: '1-0/4-1', betTeam: '1:0/4:1' },
			{ label: '1-0/4-2', betTeam: '1:0/4:2' },
			{ label: '1-0/4-3', betTeam: '1:0/4:3' },
			{ label: '1-0/4-4', betTeam: '1:0/4:4' },
			{ label: '1-0/AOS', betTeam: '1:0/AOS' },
			{ label: '1-1/1-1', betTeam: '1:1/1:1' },
			{ label: '1-1/1-2', betTeam: '1:1/1:2' },
			{ label: '1-1/1-3', betTeam: '1:1/1:3' },
			{ label: '1-1/1-4', betTeam: '1:1/1:4' },
			{ label: '1-1/2-1', betTeam: '1:1/2:1' },
			{ label: '1-1/2-2', betTeam: '1:1/2:2' },
			{ label: '1-1/2-3', betTeam: '1:1/2:3' },
			{ label: '1-1/2-4', betTeam: '1:1/2:4' },
			{ label: '1-1/3-1', betTeam: '1:1/3:1' },
			{ label: '1-1/3-2', betTeam: '1:1/3:2' },
			{ label: '1-1/3-3', betTeam: '1:1/3:3' },
			{ label: '1-1/3-4', betTeam: '1:1/3:4' },
			{ label: '1-1/4-1', betTeam: '1:1/4:1' },
			{ label: '1-1/4-2', betTeam: '1:1/4:2' },
			{ label: '1-1/4-3', betTeam: '1:1/4:3' },
			{ label: '1-1/4-4', betTeam: '1:1/4:4' },
			{ label: '1-1/AOS', betTeam: '1:1/AOS' },
			{ label: '1-2/1-2', betTeam: '1:2/1:2' },
			{ label: '1-2/1-3', betTeam: '1:2/1:3' },
			{ label: '1-2/1-4', betTeam: '1:2/1:4' },
			{ label: '1-2/2-2', betTeam: '1:2/2:2' },
			{ label: '1-2/2-3', betTeam: '1:2/2:3' },
			{ label: '1-2/2-4', betTeam: '1:2/2:4' },
			{ label: '1-2/3-2', betTeam: '1:2/3:2' },
			{ label: '1-2/3-3', betTeam: '1:2/3:3' },
			{ label: '1-2/3-4', betTeam: '1:2/3:4' },
			{ label: '1-2/4-2', betTeam: '1:2/4:2' },
			{ label: '1-2/4-3', betTeam: '1:2/4:3' },
			{ label: '1-2/4-4', betTeam: '1:2/4:4' },
			{ label: '1-2/AOS', betTeam: '1:2/AOS' },
			{ label: '1-3/1-3', betTeam: '1:3/1:3' },
			{ label: '1-3/1-4', betTeam: '1:3/1:4' },
			{ label: '1-3/2-3', betTeam: '1:3/2:3' },
			{ label: '1-3/2-4', betTeam: '1:3/2:4' },
			{ label: '1-3/3-3', betTeam: '1:3/3:3' },
			{ label: '1-3/3-4', betTeam: '1:3/3:4' },
			{ label: '1-3/4-3', betTeam: '1:3/4:3' },
			{ label: '1-3/4-4', betTeam: '1:3/4:4' },
			{ label: '1-3/AOS', betTeam: '1:3/AOS' },
			{ label: '2-0/2-0', betTeam: '2:0/2:0' },
			{ label: '2-0/2-1', betTeam: '2:0/2:1' },
			{ label: '2-0/2-2', betTeam: '2:0/2:2' },
			{ label: '2-0/2-3', betTeam: '2:0/2:3' },
			{ label: '2-0/2-4', betTeam: '2:0/2:4' },
			{ label: '2-0/3-0', betTeam: '2:0/3:0' },
			{ label: '2-0/3-1', betTeam: '2:0/3:1' },
			{ label: '2-0/3-2', betTeam: '2:0/3:2' },
			{ label: '2-0/3-3', betTeam: '2:0/3:3' },
			{ label: '2-0/3-4', betTeam: '2:0/3:4' },
			{ label: '2-0/4-0', betTeam: '2:0/4:0' },
			{ label: '2-0/4-1', betTeam: '2:0/4:1' },
			{ label: '2-0/4-2', betTeam: '2:0/4:2' },
			{ label: '2-0/4-3', betTeam: '2:0/4:3' },
			{ label: '2-0/4-4', betTeam: '2:0/4:4' },
			{ label: '2-0/AOS', betTeam: '2:0/AOS' },
			{ label: '2-1/2-1', betTeam: '2:1/2:1' },
			{ label: '2-1/2-2', betTeam: '2:1/2:2' },
			{ label: '2-1/2-3', betTeam: '2:1/2:3' },
			{ label: '2-1/2-4', betTeam: '2:1/2:4' },
			{ label: '2-1/3-1', betTeam: '2:1/3:1' },
			{ label: '2-1/3-2', betTeam: '2:1/3:2' },
			{ label: '2-1/3-3', betTeam: '2:1/3:3' },
			{ label: '2-1/3-4', betTeam: '2:1/3:4' },
			{ label: '2-1/4-1', betTeam: '2:1/4:1' },
			{ label: '2-1/4-2', betTeam: '2:1/4:2' },
			{ label: '2-1/4-3', betTeam: '2:1/4:3' },
			{ label: '2-1/4-4', betTeam: '2:1/4:4' },
			{ label: '2-1/AOS', betTeam: '2:1/AOS' },
			{ label: '2-2/2-2', betTeam: '2:2/2:2' },
			{ label: '2-2/2-3', betTeam: '2:2/2:3' },
			{ label: '2-2/2-4', betTeam: '2:2/2:4' },
			{ label: '2-2/3-2', betTeam: '2:2/3:2' },
			{ label: '2-2/3-3', betTeam: '2:2/3:3' },
			{ label: '2-2/3-4', betTeam: '2:2/3:4' },
			{ label: '2-2/4-2', betTeam: '2:2/4:2' },
			{ label: '2-2/4-3', betTeam: '2:2/4:3' },
			{ label: '2-2/4-4', betTeam: '2:2/4:4' },
			{ label: '2-2/AOS', betTeam: '2:2/AOS' },
			{ label: '2-3/2-3', betTeam: '2:3/2:3' },
			{ label: '2-3/2-4', betTeam: '2:3/2:4' },
			{ label: '2-3/3-3', betTeam: '2:3/3:3' },
			{ label: '2-3/3-4', betTeam: '2:3/3:4' },
			{ label: '2-3/4-3', betTeam: '2:3/4:3' },
			{ label: '2-3/4-4', betTeam: '2:3/4:4' },
			{ label: '2-3/AOS', betTeam: '2:3/AOS' },
			{ label: '3-0/3-0', betTeam: '3:0/3:0' },
			{ label: '3-0/3-1', betTeam: '3:0/3:1' },
			{ label: '3-0/3-2', betTeam: '3:0/3:2' },
			{ label: '3-0/3-3', betTeam: '3:0/3:3' },
			{ label: '3-0/3-4', betTeam: '3:0/3:4' },
			{ label: '3-0/4-0', betTeam: '3:0/4:0' },
			{ label: '3-0/4-1', betTeam: '3:0/4:1' },
			{ label: '3-0/4-2', betTeam: '3:0/4:2' },
			{ label: '3-0/4-3', betTeam: '3:0/4:3' },
			{ label: '3-0/4-4', betTeam: '3:0/4:4' },
			{ label: '3-0/AOS', betTeam: '3:0/AOS' },
			{ label: '3-1/3-1', betTeam: '3:1/3:1' },
			{ label: '3-1/3-2', betTeam: '3:1/3:2' },
			{ label: '3-1/3-3', betTeam: '3:1/3:3' },
			{ label: '3-1/3-4', betTeam: '3:1/3:4' },
			{ label: '3-1/4-1', betTeam: '3:1/4:1' },
			{ label: '3-1/4-2', betTeam: '3:1/4:2' },
			{ label: '3-1/4-3', betTeam: '3:1/4:3' },
			{ label: '3-1/4-4', betTeam: '3:1/4:4' },
			{ label: '3-1/AOS', betTeam: '3:1/AOS' },
			{ label: '3-2/3-2', betTeam: '3:2/3:2' },
			{ label: '3-2/3-3', betTeam: '3:2/3:3' },
			{ label: '3-2/3-4', betTeam: '3:2/3:4' },
			{ label: '3-2/4-2', betTeam: '3:2/4:2' },
			{ label: '3-2/4-3', betTeam: '3:2/4:3' },
			{ label: '3-2/4-4', betTeam: '3:2/4:4' },
			{ label: '3-2/AOS', betTeam: '3:2/AOS' },
			{ label: '3-3/3-3', betTeam: '3:3/3:3' },
			{ label: '3-3/3-4', betTeam: '3:3/3:4' },
			{ label: '3-3/4-3', betTeam: '3:3/4:3' },
			{ label: '3-3/4-4', betTeam: '3:3/4:4' },
			{ label: '3-3/AOS', betTeam: '3:3/AOS' }
		],
		layoutType: 'HT-FT-Linked'
	},
	417: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet417_yh', betTeam: 'yh' },
			{ label: 'bet417_yd', betTeam: 'yd' },
			{ label: 'bet417_ya', betTeam: 'ya' },
			{ label: 'bet417_nh', betTeam: 'nh' },
			{ label: 'bet417_nd', betTeam: 'nd' },
			{ label: 'bet417_na', betTeam: 'na' }
		],
		layoutType: 'Col-3'
	},
	418: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet418_yo', betTeam: 'yo' },
			{ label: 'bet418_yu', betTeam: 'yu' },
			{ label: 'bet418_no', betTeam: 'no' },
			{ label: 'bet418_nu', betTeam: 'nu' }
		],
		layoutType: 'Col-4'
	},
	419: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet419_1h', betTeam: '1h' },
			{ label: 'bet419_2h', betTeam: '2h' },
			{ label: 'bet419_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	420: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet420_1h', betTeam: '1h' },
			{ label: 'bet420_2h', betTeam: '2h' },
			{ label: 'bet420_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	421: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet421_1h', betTeam: '1h' },
			{ label: 'bet421_2h', betTeam: '2h' },
			{ label: 'bet421_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	422: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet422_h', betTeam: 'h' },
			{ label: 'bet422_a', betTeam: 'a' },
			{ label: 'bet422_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	423: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet423_h', betTeam: 'h' },
			{ label: 'bet423_a', betTeam: 'a' },
			{ label: 'bet423_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	424: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet424_s', betTeam: 's' },
			{ label: 'bet424_h', betTeam: 'h' },
			{ label: 'bet424_p', betTeam: 'p' },
			{ label: 'bet424_fk', betTeam: 'fk' },
			{ label: 'bet424_og', betTeam: 'og' },
			{ label: 'bet424_ng', betTeam: 'ng' }
		],
		layoutType: 'Col-3'
	},
	425: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: 'h' },
			{ label: 'lbl_1x2_Away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	426: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			// 借用其他bettype 適合的翻譯
			{ label: 'bet187_g1', betTeam: 'h1', betChoiceGroup: 1 },
			{ label: 'bet187_g2', betTeam: 'h2+', betChoiceGroup: 2 },
			{ label: 'bet187_g1', betTeam: 'a1', betChoiceGroup: 1 },
			{ label: 'bet187_g2', betTeam: 'a2+', betChoiceGroup: 2 },
			{ label: 'bet426_d', betTeam: 'd', betChoiceGroup: 4 },
			{ label: 'bet426_ng', betTeam: 'ng', betChoiceGroup: 5 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	429: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet429_0', betTeam: '0' },
			{ label: 'bet429_1', betTeam: '1' },
			{ label: 'bet429_2', betTeam: '2' },
			{ label: 'bet429_3over', betTeam: '3-over' }
		],
		layoutType: 'Col-4'
	},
	445: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet445_yy', betTeam: 'yy' },
			{ label: 'bet445_yn', betTeam: 'yn' },
			{ label: 'bet445_ny', betTeam: 'ny' },
			{ label: 'bet445_nn', betTeam: 'nn' }
		],
		layoutType: 'Col-4'
	},
	446: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet446_yy', betTeam: 'yy' },
			{ label: 'bet446_yn', betTeam: 'yn' },
			{ label: 'bet446_ny', betTeam: 'ny' },
			{ label: 'bet446_nn', betTeam: 'nn' }
		],
		layoutType: 'Col-4'
	},
	447: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet447_yy', betTeam: 'yy' },
			{ label: 'bet447_yn', betTeam: 'yn' },
			{ label: 'bet447_ny', betTeam: 'ny' },
			{ label: 'bet447_nn', betTeam: 'nn' }
		],
		layoutType: 'Col-4'
	},
	448: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet448_h', betTeam: 'h' },
			{ label: 'bet448_a', betTeam: 'a' },
			{ label: 'bet448_ng', betTeam: 'ng' },
		],
		layoutType: 'Col-3'
	},
	449: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet449_1xo', betTeam: '1xo' },
			{ label: 'bet449_12o', betTeam: '12o' },
			{ label: 'bet449_2xo', betTeam: '2xo' },
			{ label: 'bet449_1xu', betTeam: '1xu' },
			{ label: 'bet449_12u', betTeam: '12u' },
			{ label: 'bet449_2xu', betTeam: '2xu' }
		],
		layoutType: 'Col-3'
	},
	450: {
		betChoiceTranslateType: 'Choice',
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: 'bet450_oo', betTeam: 'oo' },
			{ label: 'bet450_ou', betTeam: 'ou' },
			{ label: 'bet450_eo', betTeam: 'eo' },
			{ label: 'bet450_eu', betTeam: 'eu' }
		],
		layoutType: 'Col-4'
	},
	451: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet451_y1x', betTeam: 'y1x' },
			{ label: 'bet451_y12', betTeam: 'y12' },
			{ label: 'bet451_y2x', betTeam: 'y2x' },
			{ label: 'bet451_n1x', betTeam: 'n1x' },
			{ label: 'bet451_n12', betTeam: 'n12' },
			{ label: 'bet451_n2x', betTeam: 'n2x' }
		],
		layoutType: 'Col-3-2to1'
	},
	452: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_firsthalf', betTeam: '1h' },
			{ label: 'lbl_secondhalf', betTeam: '2h' }
		],
		layoutType: 'Col-2'
	},
	453: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_away', betTeam: '2' }
		],
		layoutType: 'Col-3'
	},
	455: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet455_110', betTeam: '1-10' },
			{ label: 'bet455_1120', betTeam: '11-20' },
			{ label: 'bet455_2130', betTeam: '21-30' },
			{ label: 'bet455_3140', betTeam: '31-40' },
			{ label: 'bet455_4150', betTeam: '41-50' },
			{ label: 'bet455_5160', betTeam: '51-60' },
			{ label: 'bet455_6170', betTeam: '61-70' },
			{ label: 'bet455_7180', betTeam: '71-80' },
			{ label: 'bet455_8190', betTeam: '81-90' },
			{ label: 'bet455_ng', betTeam: 'ng' },
		],
		layoutType: 'Col-3-2to1'
	},
	456: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet456_yh', betTeam: 'yh' },
			{ label: 'bet456_yd', betTeam: 'yd' },
			{ label: 'bet456_ya', betTeam: 'ya' },
			{ label: 'bet456_nh', betTeam: 'nh' },
			{ label: 'bet456_nd', betTeam: 'nd' },
			{ label: 'bet456_na', betTeam: 'na' }
		],
		layoutType: 'Col-3'
	},
	458: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		layoutType: 'Col-3',
		betChoices: [
			{ label: 'bet458_1', betTeam: '1' },
			{ label: 'bet458_x', betTeam: 'x' },
			{ label: 'bet458_2', betTeam: '2' },
		]
	},
	459: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		layoutType: 'Col-3',
		betChoices: [
			{ label: 'bet459_1', betTeam: '1' },
			{ label: 'bet459_x', betTeam: 'x' },
			{ label: 'bet459_2', betTeam: '2' },
		]
	},
	460: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		layoutType: 'Col-2',
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: 'h' },
			{ label: 'lbl_1x2_Away', betTeam: 'a' },
		]
	},
	461: {
		betTypeName: {
			0: 'b401'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	462: {
		betTypeName: {
			0: 'b402'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	463: {
		betTypeName: {
			0: 'b403'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	464: {
		betTypeName: {
			0: 'b404'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	468: {
		betTypeName: {
			0: 'b468'
		},
		betTypeTranslateType: 'BetType',
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: 'H1', betTeam: 'H' },
			{ label: 'H2', betTeam: 'H' },
			{ label: 'H3', betTeam: 'H' },
			{ label: 'H4', betTeam: 'H' },
			{ label: 'H5', betTeam: 'H' },
			{ label: 'A1', betTeam: 'A' },
			{ label: 'A2', betTeam: 'A' },
			{ label: 'A3', betTeam: 'A' },
			{ label: 'A4', betTeam: 'A' },
			{ label: 'A5', betTeam: 'A' },
		],
		layoutType: 'Score-Box',
	},
	469: {
		betTypeName: {
			0: 'b469'
		},
		betTypeTranslateType: 'BetType',
		betChoices: [
			{ label: 'O1', betTeam: 'O' },
			{ label: 'O2', betTeam: 'O' },
			{ label: 'O3', betTeam: 'O' },
			{ label: 'O4', betTeam: 'O' },
			{ label: 'O5', betTeam: 'O' },
			{ label: 'U1', betTeam: 'U' },
			{ label: 'U2', betTeam: 'U' },
			{ label: 'U3', betTeam: 'U' },
			{ label: 'U4', betTeam: 'U' },
			{ label: 'U5', betTeam: 'U' },
		],
		layoutType: 'Score-Box',
	},
	467: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0/0', betTeam: 'G0/G0' },
			{ label: '0/1', betTeam: 'G0/G1' },
			{ label: '0/2', betTeam: 'G0/G2' },
			{ label: '0/3', betTeam: 'G0/G3' },
			{ label: '0/4', betTeam: 'G0/G4' },
			{ label: '0/5', betTeam: 'G0/G5' },
			{ label: '0/6', betTeam: 'G0/G6' },
			{ label: '0/7+', betTeam: 'G0/G7+' },
			{ label: '1/1', betTeam: 'G1/G1' },
			{ label: '1/2', betTeam: 'G1/G2' },
			{ label: '1/3', betTeam: 'G1/G3' },
			{ label: '1/4', betTeam: 'G1/G4' },
			{ label: '1/5', betTeam: 'G1/G5' },
			{ label: '1/6', betTeam: 'G1/G6' },
			{ label: '1/7+', betTeam: 'G1/G7+' },
			{ label: '2/2', betTeam: 'G2/G2' },
			{ label: '2/3', betTeam: 'G2/G3' },
			{ label: '2/4', betTeam: 'G2/G4' },
			{ label: '2/5', betTeam: 'G2/G5' },
			{ label: '2/6', betTeam: 'G2/G6' },
			{ label: '2/7+', betTeam: 'G2/G7+' },
			{ label: '3/3', betTeam: 'G3/G3' },
			{ label: '3/4', betTeam: 'G3/G4' },
			{ label: '3/5', betTeam: 'G3/G5' },
			{ label: '3/6', betTeam: 'G3/G6' },
			{ label: '3/7+', betTeam: 'G3/G7+' },
			{ label: '4/4', betTeam: 'G4/G4' },
			{ label: '4/5', betTeam: 'G4/G5' },
			{ label: '4/6', betTeam: 'G4/G6' },
			{ label: '4/7', betTeam: 'G4/G7' },
			{ label: '4/8+', betTeam: 'G4/G8+' },
			{ label: '5/5', betTeam: 'G5/G5' },
			{ label: '5/6', betTeam: 'G5/G6' },
			{ label: '5/7', betTeam: 'G5/G7' },
			{ label: '5/8', betTeam: 'G5/G8' },
			{ label: '5/9+', betTeam: 'G5/G9+' },
			{ label: '6/6', betTeam: 'G6/G6' },
			{ label: '6/7', betTeam: 'G6/G7' },
			{ label: '6/8', betTeam: 'G6/G8' },
			{ label: '6/9', betTeam: 'G6/G9' },
			{ label: '6/10+', betTeam: 'G6/G10+' },
			{ label: '7+/7+', betTeam: 'G7+/G7+' }
		],
		layoutType: 'HT-FT-Linked'
	},
	472: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' },
		],
		layoutType: 'Col-2'
	},
	473: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0-5', betTeam: '0-5' },
			{ label: '6-8', betTeam: '6-8' },
			{ label: '9-11', betTeam: '9-11' },
			{ label: '12-14', betTeam: '12-14' },
			{ label: '15+', betTeam: '15+' }
		],
		layoutType: 'Col-3'
	},
	474: {
		betTypeName: {
			0: 'b202'
		},
		betTypeTranslateType: 'BetType',
		betChoices: [
			{ label: '0-2', betTeam: '0-2' },
			{ label: '3-4', betTeam: '3-4' },
			{ label: '5-6', betTeam: '5-6' },
			{ label: '7+', betTeam: '7+' }
		],
		layoutType: 'Col-3'
	},
	475: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet475_o', betTeam: 'o' },
			{ label: 'bet475_e', betTeam: 'e' },
			{ label: 'bet475_u', betTeam: 'u' }
		],
		layoutType: 'Col-3'
	},
	476: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet476_o', betTeam: 'o' },
			{ label: 'bet476_e', betTeam: 'e' },
			{ label: 'bet476_u', betTeam: 'u' }
		],
		layoutType: 'Col-3'
	},
	477: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_away', betTeam: '2' }
		],
		layoutType: 'Col-3'
	},
	478: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_away', betTeam: '2' }
		],
		layoutType: 'Col-3'
	},
	479: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_CornerBefore', betTeam: 'y' },
			{ label: 'lbl_NoCornerBefore', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	481: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_CornerBefore', betTeam: 'y' },
			{ label: 'lbl_NoCornerBefore', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	482: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	483: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	484: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	485: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	486: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet486_h', betTeam: 'h' },
			{ label: 'bet486_a', betTeam: 'a' },
			{ label: 'bet486_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	487: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet487_h', betTeam: 'h' },
			{ label: 'bet487_a', betTeam: 'a' },
			{ label: 'bet487_n', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	488: {
		betTypeName: {
			0: 'b206'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	489: {
		betTypeName: {
			0: 'b207'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	490: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	491: {
		betTypeName: {
			0: 'b208'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	492: {
		betTypeName: {
			0: 'b209'
		},
		betTypeTranslateType: 'BetType',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	493: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0/0', betTeam: '0/0' },
			{ label: '0/1', betTeam: '0/1' },
			{ label: '0/2', betTeam: '0/2' },
			{ label: '0/3', betTeam: '0/3' },
			{ label: '0/4', betTeam: '0/4' },
			{ label: '0/5', betTeam: '0/5' },
			{ label: '0/6', betTeam: '0/6' },
			{ label: '0/7', betTeam: '0/7' },
			{ label: '0/8+', betTeam: '0/8+' },
			{ label: '1/1', betTeam: '1/1' },
			{ label: '1/2', betTeam: '1/2' },
			{ label: '1/3', betTeam: '1/3' },
			{ label: '1/4', betTeam: '1/4' },
			{ label: '1/5', betTeam: '1/5' },
			{ label: '1/6', betTeam: '1/6' },
			{ label: '1/7', betTeam: '1/7' },
			{ label: '1/8', betTeam: '1/8' },
			{ label: '1/9+', betTeam: '1/9+' },
			{ label: '2/2', betTeam: '2/2' },
			{ label: '2/3', betTeam: '2/3' },
			{ label: '2/4', betTeam: '2/4' },
			{ label: '2/5', betTeam: '2/5' },
			{ label: '2/6', betTeam: '2/6' },
			{ label: '2/7', betTeam: '2/7' },
			{ label: '2/8', betTeam: '2/8' },
			{ label: '2/9', betTeam: '2/9' },
			{ label: '2/10+', betTeam: '2/10+' },
			{ label: '3/3', betTeam: '3/3' },
			{ label: '3/4', betTeam: '3/4' },
			{ label: '3/5', betTeam: '3/5' },
			{ label: '3/6', betTeam: '3/6' },
			{ label: '3/7', betTeam: '3/7' },
			{ label: '3/8', betTeam: '3/8' },
			{ label: '3/9', betTeam: '3/9' },
			{ label: '3/10', betTeam: '3/10' },
			{ label: '3/11+', betTeam: '3/11+' },
			{ label: '4/4', betTeam: '4/4' },
			{ label: '4/5', betTeam: '4/5' },
			{ label: '4/6', betTeam: '4/6' },
			{ label: '4/7', betTeam: '4/7' },
			{ label: '4/8', betTeam: '4/8' },
			{ label: '4/9', betTeam: '4/9' },
			{ label: '4/10', betTeam: '4/10' },
			{ label: '4/11', betTeam: '4/11' },
			{ label: '4/12+', betTeam: '4/12+' },
			{ label: '5/5', betTeam: '5/5' },
			{ label: '5/6', betTeam: '5/6' },
			{ label: '5/7', betTeam: '5/7' },
			{ label: '5/8', betTeam: '5/8' },
			{ label: '5/9', betTeam: '5/9' },
			{ label: '5/10', betTeam: '5/10' },
			{ label: '5/11', betTeam: '5/11' },
			{ label: '5/12', betTeam: '5/12' },
			{ label: '5/13+', betTeam: '5/13+' },
			{ label: '6/6', betTeam: '6/6' },
			{ label: '6/7', betTeam: '6/7' },
			{ label: '6/8', betTeam: '6/8' },
			{ label: '6/9', betTeam: '6/9' },
			{ label: '6/10', betTeam: '6/10' },
			{ label: '6/11', betTeam: '6/11' },
			{ label: '6/12', betTeam: '6/12' },
			{ label: '6/13', betTeam: '6/13' },
			{ label: '6/14+', betTeam: '6/14+' },
			{ label: '7/7', betTeam: '7/7' },
			{ label: '7/8', betTeam: '7/8' },
			{ label: '7/9', betTeam: '7/9' },
			{ label: '7/10', betTeam: '7/10' },
			{ label: '7/11', betTeam: '7/11' },
			{ label: '7/12', betTeam: '7/12' },
			{ label: '7/13', betTeam: '7/13' },
			{ label: '7/14', betTeam: '7/14' },
			{ label: '7/15+', betTeam: '7/15+' },
			{ label: '8/8', betTeam: '8/8' },
			{ label: '8/9', betTeam: '8/9' },
			{ label: '8/10', betTeam: '8/10' },
			{ label: '8/11', betTeam: '8/11' },
			{ label: '8/12', betTeam: '8/12' },
			{ label: '8/13', betTeam: '8/13' },
			{ label: '8/14', betTeam: '8/14' },
			{ label: '8/15+', betTeam: '8/15+' },
			{ label: '9/9', betTeam: '9/9' },
			{ label: '9/10', betTeam: '9/10' },
			{ label: '9/11', betTeam: '9/11' },
			{ label: '9/12', betTeam: '9/12' },
			{ label: '9/13', betTeam: '9/13' },
			{ label: '9/14', betTeam: '9/14' },
			{ label: '9/15+', betTeam: '9/15+' },
			{ label: 'AOS', betTeam: 'AOS' }
		],
		layoutType: 'HT-FT-Linked'
	},
	494: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '1-0', betTeam: '1-0' },
			{ label: '0-0', betTeam: '0-0' },
			{ label: '0-1', betTeam: '0-1' },
			{ label: '2-0', betTeam: '2-0' },
			{ label: '1-1', betTeam: '1-1' },
			{ label: '0-2', betTeam: '0-2' },
			{ label: '2-1', betTeam: '2-1' },
			{ label: '2-2', betTeam: '2-2' },
			{ label: '1-2', betTeam: '1-2' },
			{ label: '3-0', betTeam: '3-0' },
			{ label: '3-3', betTeam: '3-3' },
			{ label: '0-3', betTeam: '0-3' },
			{ label: '3-1', betTeam: '3-1' },
			{ label: '4-4', betTeam: '4-4' },
			{ label: '1-3', betTeam: '1-3' },
			{ label: '3-2', betTeam: '3-2' },
			{ label: 'AOS', betTeam: 'AOS' },
			{ label: '2-3', betTeam: '2-3' },
			{ label: '4-0', betTeam: '4-0' },
			{ label: '', betTeam: '' },
			{ label: '0-4', betTeam: '0-4' },
			{ label: '4-1', betTeam: '4-1' },
			{ label: '', betTeam: '' },
			{ label: '1-4', betTeam: '1-4' },
			{ label: '4-2', betTeam: '4-2' },
			{ label: '', betTeam: '' },
			{ label: '2-4', betTeam: '2-4' },
			{ label: '4-3', betTeam: '4-3' },
			{ label: '', betTeam: '' },
			{ label: '3-4', betTeam: '3-4' },
			{ label: '5-0', betTeam: '5-0' },
			{ label: '', betTeam: '' },
			{ label: '0-5', betTeam: '0-5' },
			{ label: '5-1', betTeam: '5-1' },
			{ label: '', betTeam: '' },
			{ label: '1-5', betTeam: '1-5' },
			{ label: '5-2', betTeam: '5-2' },
			{ label: '', betTeam: '' },
			{ label: '2-5', betTeam: '2-5' },
			{ label: '5-3', betTeam: '5-3' },
			{ label: '', betTeam: '' },
			{ label: '3-5', betTeam: '3-5' },
			{ label: '5-4', betTeam: '5-4' },
			{ label: '', betTeam: '' },
			{ label: '4-5', betTeam: '4-5' }
		],
		layoutType: 'Col-3'
	},
	495: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '1-0', betTeam: '1-0' },
			{ label: '0-0', betTeam: '0-0' },
			{ label: '0-1', betTeam: '0-1' },
			{ label: '2-0', betTeam: '2-0' },
			{ label: '1-1', betTeam: '1-1' },
			{ label: '0-2', betTeam: '0-2' },
			{ label: '2-1', betTeam: '2-1' },
			{ label: '2-2', betTeam: '2-2' },
			{ label: '1-2', betTeam: '1-2' },
			{ label: '3-0', betTeam: '3-0' },
			{ label: '3-3', betTeam: '3-3' },
			{ label: '0-3', betTeam: '0-3' },
			{ label: '3-1', betTeam: '3-1' },
			{ label: '4-4', betTeam: '4-4' },
			{ label: '1-3', betTeam: '1-3' },
			{ label: '3-2', betTeam: '3-2' },
			{ label: 'AOS', betTeam: 'AOS' },
			{ label: '2-3', betTeam: '2-3' },
			{ label: '4-0', betTeam: '4-0' },
			{ label: '', betTeam: '' },
			{ label: '0-4', betTeam: '0-4' },
			{ label: '4-1', betTeam: '4-1' },
			{ label: '', betTeam: '' },
			{ label: '1-4', betTeam: '1-4' },
			{ label: '4-2', betTeam: '4-2' },
			{ label: '', betTeam: '' },
			{ label: '2-4', betTeam: '2-4' },
			{ label: '4-3', betTeam: '4-3' },
			{ label: '', betTeam: '' },
			{ label: '3-4', betTeam: '3-4' },
			{ label: '5-0', betTeam: '5-0' },
			{ label: '', betTeam: '' },
			{ label: '0-5', betTeam: '0-5' },
			{ label: '5-1', betTeam: '5-1' },
			{ label: '', betTeam: '' },
			{ label: '1-5', betTeam: '1-5' },
			{ label: '5-2', betTeam: '5-2' },
			{ label: '', betTeam: '' },
			{ label: '2-5', betTeam: '2-5' },
			{ label: '5-3', betTeam: '5-3' },
			{ label: '', betTeam: '' },
			{ label: '3-5', betTeam: '3-5' },
			{ label: '5-4', betTeam: '5-4' },
			{ label: '', betTeam: '' },
			{ label: '4-5', betTeam: '4-5' }
		],
		layoutType: 'Col-3'
	},
	496: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet496_1h', betTeam: '1H' },
			{ label: 'bet496_2h', betTeam: '2H' },
			{ label: 'bet496_tie', betTeam: 'Tie' },
		],
		layoutType: 'Col-3'
	},
	497: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_firsthalf', betTeam: '1H' },
			{ label: 'lbl_secondhalf', betTeam: '2H' }
		],
		layoutType: 'Col-2'
	},
	501: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	601: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_ToWinByX', betTeam: 'H1-2', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'A1-2', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'H3-6', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'A3-6', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'H7-9', betChoiceGroup: 3 },
			{ label: 'lbl_ToWinByX', betTeam: 'A7-9', betChoiceGroup: 3 },
			{ label: 'lbl_ToWinByX', betTeam: 'H10-13', betChoiceGroup: 4 },
			{ label: 'lbl_ToWinByX', betTeam: 'A10-13', betChoiceGroup: 4 },
			{ label: 'lbl_ToWinByX', betTeam: 'H14-16', betChoiceGroup: 5 },
			{ label: 'lbl_ToWinByX', betTeam: 'A14-16', betChoiceGroup: 5 },
			{ label: 'lbl_ToWinByX', betTeam: 'H17-20', betChoiceGroup: 6 },
			{ label: 'lbl_ToWinByX', betTeam: 'A17-20', betChoiceGroup: 6 },
			{ label: 'lbl_ToWinByX', betTeam: 'H21+', betChoiceGroup: 7 },
			{ label: 'lbl_ToWinByX', betTeam: 'A21+', betChoiceGroup: 7 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	602: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_ToWinByX', betTeam: 'H1-5', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'A1-5', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'H6-10', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'A6-10', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'H11-15', betChoiceGroup: 3 },
			{ label: 'lbl_ToWinByX', betTeam: 'A11-15', betChoiceGroup: 3 },
			{ label: 'lbl_ToWinByX', betTeam: 'H16-20', betChoiceGroup: 4 },
			{ label: 'lbl_ToWinByX', betTeam: 'A16-20', betChoiceGroup: 4 },
			{ label: 'lbl_ToWinByX', betTeam: 'H21-25', betChoiceGroup: 5 },
			{ label: 'lbl_ToWinByX', betTeam: 'A21-25', betChoiceGroup: 5 },
			{ label: 'lbl_ToWinByX', betTeam: 'H26+', betChoiceGroup: 6 },
			{ label: 'lbl_ToWinByX', betTeam: 'A26+', betChoiceGroup: 6 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	603: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	604: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	605: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	606: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	607: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	608: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_ToWinByX', betTeam: 'H1-5', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'A1-5', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'H6-10', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'A6-10', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'H11-15', betChoiceGroup: 3 },
			{ label: 'lbl_ToWinByX', betTeam: 'A11-15', betChoiceGroup: 3 },
			{ label: 'lbl_ToWinByX', betTeam: 'H16-20', betChoiceGroup: 4 },
			{ label: 'lbl_ToWinByX', betTeam: 'A16-20', betChoiceGroup: 4 },
			{ label: 'lbl_ToWinByX', betTeam: 'H21-25', betChoiceGroup: 5 },
			{ label: 'lbl_ToWinByX', betTeam: 'A21-25', betChoiceGroup: 5 },
			{ label: 'lbl_ToWinByX', betTeam: 'H26+', betChoiceGroup: 6 },
			{ label: 'lbl_ToWinByX', betTeam: 'A26+', betChoiceGroup: 6 },
			{ label: 'lbl_draw', betTeam: 'D', betChoiceGroup: 7 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	609: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	610: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	611: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	612: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	613: {
		betTypeName: {
			2: 'lbl_XRaceTo'
		},
		betTypeNameReplaceType: 'replaceBetTypeRaceToResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	614: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_ToWinByX', betTeam: 'H1-4', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'A1-4', betChoiceGroup: 1 },
			{ label: 'lbl_ToWinByX', betTeam: 'H5-8', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'A5-8', betChoiceGroup: 2 },
			{ label: 'lbl_ToWinByX', betTeam: 'H9+', betChoiceGroup: 3 },
			{ label: 'lbl_ToWinByX', betTeam: 'A9+', betChoiceGroup: 3 },
			{ label: 'lbl_draw', betTeam: 'D', betChoiceGroup: 4 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	615: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	616: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	617: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	618: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	619: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	620: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	621: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	622: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	623: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	624: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	625: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	626: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	627: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	628: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	629: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3', betTeam: '3' },
			{ label: '4', betTeam: '4' },
			{ label: '5', betTeam: '5' },
			{ label: '6', betTeam: '6' },
			{ label: '7', betTeam: '7' },
			{ label: '8', betTeam: '8' },
			{ label: '9', betTeam: '9' },
		],
		layoutType: 'Col-3-2to1'
	},
	630: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw',
			},
			'2': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' },
		],
		layoutType: 'Col-3',
	},
	631: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet631_hd', betTeam: 'hd' },
			{ label: 'bet631_da', betTeam: 'da' },
			{ label: 'bet631_ha', betTeam: 'ha' }
		],
		layoutType: 'Col-3-2to1'
	},
	632: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet632_oooo', betTeam: 'oooo' },
			{ label: 'bet632_ooeo', betTeam: 'ooeo' },
			{ label: 'bet632_eooo', betTeam: 'eooo' },
			{ label: 'bet632_eeeo', betTeam: 'eeeo' },
			{ label: 'bet632_eoee', betTeam: 'eoee' },
			{ label: 'bet632_ooee', betTeam: 'ooee' },
			{ label: 'bet632_eoeo', betTeam: 'eoeo' },
			{ label: 'bet632_eooe', betTeam: 'eooe' },
			{ label: 'bet632_oooe', betTeam: 'oooe' },
			{ label: 'bet632_oeoo', betTeam: 'oeoo' },
			{ label: 'bet632_eeee', betTeam: 'eeee' },
			{ label: 'bet632_eeoe', betTeam: 'eeoe' },
			{ label: 'bet632_oeee', betTeam: 'oeee' },
			{ label: 'bet632_oeoe', betTeam: 'oeoe' },
			{ label: 'bet632_eeoo', betTeam: 'eeoo' },
			{ label: 'bet632_oeeo', betTeam: 'oeeo' }
		],
		layoutType: 'Col-3-2to1'
	},
	633: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet633_hh', betTeam: 'hh' },
			{ label: 'bet633_hd', betTeam: 'hd' },
			{ label: 'bet633_ha', betTeam: 'ha' },
			{ label: 'bet633_dh', betTeam: 'dh' },
			{ label: 'bet633_dd', betTeam: 'dd' },
			{ label: 'bet633_da', betTeam: 'da' },
			{ label: 'bet633_ah', betTeam: 'ah' },
			{ label: 'bet633_ad', betTeam: 'ad' },
			{ label: 'bet633_aa', betTeam: 'aa' }
		],
		layoutType: 'Col-3'
	},
	634: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet634_hh', betTeam: 'hh' },
			{ label: 'bet634_hd', betTeam: 'hd' },
			{ label: 'bet634_ha', betTeam: 'ha' },
			{ label: 'bet634_dh', betTeam: 'dh' },
			{ label: 'bet634_dd', betTeam: 'dd' },
			{ label: 'bet634_da', betTeam: 'da' },
			{ label: 'bet634_ah', betTeam: 'ah' },
			{ label: 'bet634_ad', betTeam: 'ad' },
			{ label: 'bet634_aa', betTeam: 'aa' }
		],
		layoutType: 'Col-3'
	},
	635: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	636: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	637: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	638: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	639: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	640: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1+', betTeam: '1' }
		],
		layoutType: 'Col-3-2to1',
	},
	641: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	642: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet642_h12', betTeam: 'h12' },
			{ label: 'bet642_a12', betTeam: 'a12' },
			{ label: 'bet642_aos12', betTeam: 'aos12' },
			{ label: 'bet642_h13', betTeam: 'h13' },
			{ label: 'bet642_a13', betTeam: 'a13' },
			{ label: 'bet642_aos13', betTeam: 'aos13' },
			{ label: 'bet642_h14', betTeam: 'h14' },
			{ label: 'bet642_a14', betTeam: 'a14' },
			{ label: 'bet642_aos14', betTeam: 'aos14' },
			{ label: 'bet642_h23', betTeam: 'h23' },
			{ label: 'bet642_a23', betTeam: 'a23' },
			{ label: 'bet642_aos23', betTeam: 'aos23' },
			{ label: 'bet642_h24', betTeam: 'h24' },
			{ label: 'bet642_a24', betTeam: 'a24' },
			{ label: 'bet642_aos24', betTeam: 'aos24' },
			{ label: 'bet642_h34', betTeam: 'h34' },
			{ label: 'bet642_a34', betTeam: 'a34' },
			{ label: 'bet642_aos34', betTeam: 'aos34' },
		],
		layoutType: 'Col-3-2to1'
	},
	643: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet643_1q', betTeam: '1q' },
			{ label: 'bet643_2q', betTeam: '2q' },
			{ label: 'bet643_3q', betTeam: '3q' },
			{ label: 'bet643_4q', betTeam: '4q' },
			{ label: 'bet643_tie', betTeam: 'tie' }
		],
		layoutType: 'Col-3-2to1',
	},
	644: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '2-1', betTeam: '2:1' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: '4-0', betTeam: '4:0' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '0-4', betTeam: '0:4' },
			{ label: '', betTeam: '' },
			{ label: 'AOS', betTeam: 'aos' },
			{ label: '', betTeam: '' },
		],
		layoutType: 'Col-3'
	},
	645: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betChoices: [
			{ label: 'bet645_hh', betTeam: 'hh' },
			{ label: 'bet645_ha', betTeam: 'ha' },
			{ label: 'bet645_da', betTeam: 'da' },
			{ label: 'bet645_ad', betTeam: 'ad' },
			{ label: 'bet645_hd', betTeam: 'hd' },
			{ label: 'bet645_dh', betTeam: 'dh' },
			{ label: 'bet645_ah', betTeam: 'ah' },
			{ label: 'bet645_aa', betTeam: 'aa' }
		],
		layoutType: 'Col-3'
	},
	646: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_B646_O', betTeam: 'ho' },
			{ label: 'lbl_B646_U', betTeam: 'hu' },
			{ label: 'lbl_B646_O', betTeam: 'ao' },
			{ label: 'lbl_B646_U', betTeam: 'au' },
		],
		layoutType: 'Col-3-2to1'
	},
	647: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0-0', betTeam: '0:0' },
			{ label: '0-1', betTeam: '0:1' },
			{ label: '0-2', betTeam: '0:2' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '0-4', betTeam: '0:4' },
			{ label: '0-5', betTeam: '0:5' },
			{ label: '0-6', betTeam: '0:6' },
			{ label: '0-7', betTeam: '0:7' },
			{ label: '0-8', betTeam: '0:8' },
			{ label: '0-9', betTeam: '0:9' },
			{ label: '1-0', betTeam: '1:0' },
			{ label: '1-1', betTeam: '1:1' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '1-4', betTeam: '1:4' },
			{ label: '1-5', betTeam: '1:5' },
			{ label: '1-6', betTeam: '1:6' },
			{ label: '1-7', betTeam: '1:7' },
			{ label: '1-8', betTeam: '1:8' },
			{ label: '1-9', betTeam: '1:9' },
			{ label: '2-0', betTeam: '2:0' },
			{ label: '2-1', betTeam: '2:1' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '2-3', betTeam: '2:3' },
			{ label: '2-4', betTeam: '2:4' },
			{ label: '2-5', betTeam: '2:5' },
			{ label: '2-6', betTeam: '2:6' },
			{ label: '2-7', betTeam: '2:7' },
			{ label: '2-8', betTeam: '2:8' },
			{ label: '2-9', betTeam: '2:9' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: '3-3', betTeam: '3:3' },
			{ label: '3-4', betTeam: '3:4' },
			{ label: '3-5', betTeam: '3:5' },
			{ label: '3-6', betTeam: '3:6' },
			{ label: '3-7', betTeam: '3:7' },
			{ label: '3-8', betTeam: '3:8' },
			{ label: '3-9', betTeam: '3:9' },
			{ label: '4-0', betTeam: '4:0' },
			{ label: '4-1', betTeam: '4:1' },
			{ label: '4-2', betTeam: '4:2' },
			{ label: '4-3', betTeam: '4:3' },
			{ label: '4-4', betTeam: '4:4' },
			{ label: '4-5', betTeam: '4:5' },
			{ label: '4-6', betTeam: '4:6' },
			{ label: '4-7', betTeam: '4:7' },
			{ label: '4-8', betTeam: '4:8' },
			{ label: '4-9', betTeam: '4:9' },
			{ label: '5-0', betTeam: '5:0' },
			{ label: '5-1', betTeam: '5:1' },
			{ label: '5-2', betTeam: '5:2' },
			{ label: '5-3', betTeam: '5:3' },
			{ label: '5-4', betTeam: '5:4' },
			{ label: '5-5', betTeam: '5:5' },
			{ label: '5-6', betTeam: '5:6' },
			{ label: '5-7', betTeam: '5:7' },
			{ label: '5-8', betTeam: '5:8' },
			{ label: '5-9', betTeam: '5:9' },
			{ label: '6-0', betTeam: '6:0' },
			{ label: '6-1', betTeam: '6:1' },
			{ label: '6-2', betTeam: '6:2' },
			{ label: '6-3', betTeam: '6:3' },
			{ label: '6-4', betTeam: '6:4' },
			{ label: '6-5', betTeam: '6:5' },
			{ label: '6-6', betTeam: '6:6' },
			{ label: '6-7', betTeam: '6:7' },
			{ label: '6-8', betTeam: '6:8' },
			{ label: '6-9', betTeam: '6:9' },
			{ label: '7-0', betTeam: '7:0' },
			{ label: '7-1', betTeam: '7:1' },
			{ label: '7-2', betTeam: '7:2' },
			{ label: '7-3', betTeam: '7:3' },
			{ label: '7-4', betTeam: '7:4' },
			{ label: '7-5', betTeam: '7:5' },
			{ label: '7-6', betTeam: '7:6' },
			{ label: '7-7', betTeam: '7:7' },
			{ label: '7-8', betTeam: '7:8' },
			{ label: '7-9', betTeam: '7:9' },
			{ label: '8-0', betTeam: '8:0' },
			{ label: '8-1', betTeam: '8:1' },
			{ label: '8-2', betTeam: '8:2' },
			{ label: '8-3', betTeam: '8:3' },
			{ label: '8-4', betTeam: '8:4' },
			{ label: '8-5', betTeam: '8:5' },
			{ label: '8-6', betTeam: '8:6' },
			{ label: '8-7', betTeam: '8:7' },
			{ label: '8-8', betTeam: '8:8' },
			{ label: '8-9', betTeam: '8:9' },
			{ label: '9-0', betTeam: '9:0' },
			{ label: '9-1', betTeam: '9:1' },
			{ label: '9-2', betTeam: '9:2' },
			{ label: '9-3', betTeam: '9:3' },
			{ label: '9-4', betTeam: '9:4' },
			{ label: '9-5', betTeam: '9:5' },
			{ label: '9-6', betTeam: '9:6' },
			{ label: '9-7', betTeam: '9:7' },
			{ label: '9-8', betTeam: '9:8' },
			{ label: '9-9', betTeam: '9:9' },
		],
		layoutType: 'EndingGame'
	},
	648: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0-0', betTeam: '0:0' },
			{ label: '0-1', betTeam: '0:1' },
			{ label: '0-2', betTeam: '0:2' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '0-4', betTeam: '0:4' },
			{ label: '0-5', betTeam: '0:5' },
			{ label: '0-6', betTeam: '0:6' },
			{ label: '0-7', betTeam: '0:7' },
			{ label: '0-8', betTeam: '0:8' },
			{ label: '0-9', betTeam: '0:9' },
			{ label: '1-0', betTeam: '1:0' },
			{ label: '1-1', betTeam: '1:1' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '1-4', betTeam: '1:4' },
			{ label: '1-5', betTeam: '1:5' },
			{ label: '1-6', betTeam: '1:6' },
			{ label: '1-7', betTeam: '1:7' },
			{ label: '1-8', betTeam: '1:8' },
			{ label: '1-9', betTeam: '1:9' },
			{ label: '2-0', betTeam: '2:0' },
			{ label: '2-1', betTeam: '2:1' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '2-3', betTeam: '2:3' },
			{ label: '2-4', betTeam: '2:4' },
			{ label: '2-5', betTeam: '2:5' },
			{ label: '2-6', betTeam: '2:6' },
			{ label: '2-7', betTeam: '2:7' },
			{ label: '2-8', betTeam: '2:8' },
			{ label: '2-9', betTeam: '2:9' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: '3-3', betTeam: '3:3' },
			{ label: '3-4', betTeam: '3:4' },
			{ label: '3-5', betTeam: '3:5' },
			{ label: '3-6', betTeam: '3:6' },
			{ label: '3-7', betTeam: '3:7' },
			{ label: '3-8', betTeam: '3:8' },
			{ label: '3-9', betTeam: '3:9' },
			{ label: '4-0', betTeam: '4:0' },
			{ label: '4-1', betTeam: '4:1' },
			{ label: '4-2', betTeam: '4:2' },
			{ label: '4-3', betTeam: '4:3' },
			{ label: '4-4', betTeam: '4:4' },
			{ label: '4-5', betTeam: '4:5' },
			{ label: '4-6', betTeam: '4:6' },
			{ label: '4-7', betTeam: '4:7' },
			{ label: '4-8', betTeam: '4:8' },
			{ label: '4-9', betTeam: '4:9' },
			{ label: '5-0', betTeam: '5:0' },
			{ label: '5-1', betTeam: '5:1' },
			{ label: '5-2', betTeam: '5:2' },
			{ label: '5-3', betTeam: '5:3' },
			{ label: '5-4', betTeam: '5:4' },
			{ label: '5-5', betTeam: '5:5' },
			{ label: '5-6', betTeam: '5:6' },
			{ label: '5-7', betTeam: '5:7' },
			{ label: '5-8', betTeam: '5:8' },
			{ label: '5-9', betTeam: '5:9' },
			{ label: '6-0', betTeam: '6:0' },
			{ label: '6-1', betTeam: '6:1' },
			{ label: '6-2', betTeam: '6:2' },
			{ label: '6-3', betTeam: '6:3' },
			{ label: '6-4', betTeam: '6:4' },
			{ label: '6-5', betTeam: '6:5' },
			{ label: '6-6', betTeam: '6:6' },
			{ label: '6-7', betTeam: '6:7' },
			{ label: '6-8', betTeam: '6:8' },
			{ label: '6-9', betTeam: '6:9' },
			{ label: '7-0', betTeam: '7:0' },
			{ label: '7-1', betTeam: '7:1' },
			{ label: '7-2', betTeam: '7:2' },
			{ label: '7-3', betTeam: '7:3' },
			{ label: '7-4', betTeam: '7:4' },
			{ label: '7-5', betTeam: '7:5' },
			{ label: '7-6', betTeam: '7:6' },
			{ label: '7-7', betTeam: '7:7' },
			{ label: '7-8', betTeam: '7:8' },
			{ label: '7-9', betTeam: '7:9' },
			{ label: '8-0', betTeam: '8:0' },
			{ label: '8-1', betTeam: '8:1' },
			{ label: '8-2', betTeam: '8:2' },
			{ label: '8-3', betTeam: '8:3' },
			{ label: '8-4', betTeam: '8:4' },
			{ label: '8-5', betTeam: '8:5' },
			{ label: '8-6', betTeam: '8:6' },
			{ label: '8-7', betTeam: '8:7' },
			{ label: '8-8', betTeam: '8:8' },
			{ label: '8-9', betTeam: '8:9' },
			{ label: '9-0', betTeam: '9:0' },
			{ label: '9-1', betTeam: '9:1' },
			{ label: '9-2', betTeam: '9:2' },
			{ label: '9-3', betTeam: '9:3' },
			{ label: '9-4', betTeam: '9:4' },
			{ label: '9-5', betTeam: '9:5' },
			{ label: '9-6', betTeam: '9:6' },
			{ label: '9-7', betTeam: '9:7' },
			{ label: '9-8', betTeam: '9:8' },
			{ label: '9-9', betTeam: '9:9' },
		],
		layoutType: 'EndingGame'
	},
	649: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '0-0', betTeam: '0:0' },
			{ label: '0-1', betTeam: '0:1' },
			{ label: '0-2', betTeam: '0:2' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '0-4', betTeam: '0:4' },
			{ label: '0-5', betTeam: '0:5' },
			{ label: '0-6', betTeam: '0:6' },
			{ label: '0-7', betTeam: '0:7' },
			{ label: '0-8', betTeam: '0:8' },
			{ label: '0-9', betTeam: '0:9' },
			{ label: '1-0', betTeam: '1:0' },
			{ label: '1-1', betTeam: '1:1' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '1-4', betTeam: '1:4' },
			{ label: '1-5', betTeam: '1:5' },
			{ label: '1-6', betTeam: '1:6' },
			{ label: '1-7', betTeam: '1:7' },
			{ label: '1-8', betTeam: '1:8' },
			{ label: '1-9', betTeam: '1:9' },
			{ label: '2-0', betTeam: '2:0' },
			{ label: '2-1', betTeam: '2:1' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '2-3', betTeam: '2:3' },
			{ label: '2-4', betTeam: '2:4' },
			{ label: '2-5', betTeam: '2:5' },
			{ label: '2-6', betTeam: '2:6' },
			{ label: '2-7', betTeam: '2:7' },
			{ label: '2-8', betTeam: '2:8' },
			{ label: '2-9', betTeam: '2:9' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: '3-3', betTeam: '3:3' },
			{ label: '3-4', betTeam: '3:4' },
			{ label: '3-5', betTeam: '3:5' },
			{ label: '3-6', betTeam: '3:6' },
			{ label: '3-7', betTeam: '3:7' },
			{ label: '3-8', betTeam: '3:8' },
			{ label: '3-9', betTeam: '3:9' },
			{ label: '4-0', betTeam: '4:0' },
			{ label: '4-1', betTeam: '4:1' },
			{ label: '4-2', betTeam: '4:2' },
			{ label: '4-3', betTeam: '4:3' },
			{ label: '4-4', betTeam: '4:4' },
			{ label: '4-5', betTeam: '4:5' },
			{ label: '4-6', betTeam: '4:6' },
			{ label: '4-7', betTeam: '4:7' },
			{ label: '4-8', betTeam: '4:8' },
			{ label: '4-9', betTeam: '4:9' },
			{ label: '5-0', betTeam: '5:0' },
			{ label: '5-1', betTeam: '5:1' },
			{ label: '5-2', betTeam: '5:2' },
			{ label: '5-3', betTeam: '5:3' },
			{ label: '5-4', betTeam: '5:4' },
			{ label: '5-5', betTeam: '5:5' },
			{ label: '5-6', betTeam: '5:6' },
			{ label: '5-7', betTeam: '5:7' },
			{ label: '5-8', betTeam: '5:8' },
			{ label: '5-9', betTeam: '5:9' },
			{ label: '6-0', betTeam: '6:0' },
			{ label: '6-1', betTeam: '6:1' },
			{ label: '6-2', betTeam: '6:2' },
			{ label: '6-3', betTeam: '6:3' },
			{ label: '6-4', betTeam: '6:4' },
			{ label: '6-5', betTeam: '6:5' },
			{ label: '6-6', betTeam: '6:6' },
			{ label: '6-7', betTeam: '6:7' },
			{ label: '6-8', betTeam: '6:8' },
			{ label: '6-9', betTeam: '6:9' },
			{ label: '7-0', betTeam: '7:0' },
			{ label: '7-1', betTeam: '7:1' },
			{ label: '7-2', betTeam: '7:2' },
			{ label: '7-3', betTeam: '7:3' },
			{ label: '7-4', betTeam: '7:4' },
			{ label: '7-5', betTeam: '7:5' },
			{ label: '7-6', betTeam: '7:6' },
			{ label: '7-7', betTeam: '7:7' },
			{ label: '7-8', betTeam: '7:8' },
			{ label: '7-9', betTeam: '7:9' },
			{ label: '8-0', betTeam: '8:0' },
			{ label: '8-1', betTeam: '8:1' },
			{ label: '8-2', betTeam: '8:2' },
			{ label: '8-3', betTeam: '8:3' },
			{ label: '8-4', betTeam: '8:4' },
			{ label: '8-5', betTeam: '8:5' },
			{ label: '8-6', betTeam: '8:6' },
			{ label: '8-7', betTeam: '8:7' },
			{ label: '8-8', betTeam: '8:8' },
			{ label: '8-9', betTeam: '8:9' },
			{ label: '9-0', betTeam: '9:0' },
			{ label: '9-1', betTeam: '9:1' },
			{ label: '9-2', betTeam: '9:2' },
			{ label: '9-3', betTeam: '9:3' },
			{ label: '9-4', betTeam: '9:4' },
			{ label: '9-5', betTeam: '9:5' },
			{ label: '9-6', betTeam: '9:6' },
			{ label: '9-7', betTeam: '9:7' },
			{ label: '9-8', betTeam: '9:8' },
			{ label: '9-9', betTeam: '9:9' },
		],
		layoutType: 'EndingGame'
	},
	650: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: '0-0', betTeam: '0:0' },
			{ label: '0-1', betTeam: '0:1' },
			{ label: '0-2', betTeam: '0:2' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '0-4', betTeam: '0:4' },
			{ label: '0-5', betTeam: '0:5' },
			{ label: '0-6', betTeam: '0:6' },
			{ label: '0-7', betTeam: '0:7' },
			{ label: '0-8', betTeam: '0:8' },
			{ label: '0-9', betTeam: '0:9' },
			{ label: '1-0', betTeam: '1:0' },
			{ label: '1-1', betTeam: '1:1' },
			{ label: '1-2', betTeam: '1:2' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '1-4', betTeam: '1:4' },
			{ label: '1-5', betTeam: '1:5' },
			{ label: '1-6', betTeam: '1:6' },
			{ label: '1-7', betTeam: '1:7' },
			{ label: '1-8', betTeam: '1:8' },
			{ label: '1-9', betTeam: '1:9' },
			{ label: '2-0', betTeam: '2:0' },
			{ label: '2-1', betTeam: '2:1' },
			{ label: '2-2', betTeam: '2:2' },
			{ label: '2-3', betTeam: '2:3' },
			{ label: '2-4', betTeam: '2:4' },
			{ label: '2-5', betTeam: '2:5' },
			{ label: '2-6', betTeam: '2:6' },
			{ label: '2-7', betTeam: '2:7' },
			{ label: '2-8', betTeam: '2:8' },
			{ label: '2-9', betTeam: '2:9' },
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: '3-3', betTeam: '3:3' },
			{ label: '3-4', betTeam: '3:4' },
			{ label: '3-5', betTeam: '3:5' },
			{ label: '3-6', betTeam: '3:6' },
			{ label: '3-7', betTeam: '3:7' },
			{ label: '3-8', betTeam: '3:8' },
			{ label: '3-9', betTeam: '3:9' },
			{ label: '4-0', betTeam: '4:0' },
			{ label: '4-1', betTeam: '4:1' },
			{ label: '4-2', betTeam: '4:2' },
			{ label: '4-3', betTeam: '4:3' },
			{ label: '4-4', betTeam: '4:4' },
			{ label: '4-5', betTeam: '4:5' },
			{ label: '4-6', betTeam: '4:6' },
			{ label: '4-7', betTeam: '4:7' },
			{ label: '4-8', betTeam: '4:8' },
			{ label: '4-9', betTeam: '4:9' },
			{ label: '5-0', betTeam: '5:0' },
			{ label: '5-1', betTeam: '5:1' },
			{ label: '5-2', betTeam: '5:2' },
			{ label: '5-3', betTeam: '5:3' },
			{ label: '5-4', betTeam: '5:4' },
			{ label: '5-5', betTeam: '5:5' },
			{ label: '5-6', betTeam: '5:6' },
			{ label: '5-7', betTeam: '5:7' },
			{ label: '5-8', betTeam: '5:8' },
			{ label: '5-9', betTeam: '5:9' },
			{ label: '6-0', betTeam: '6:0' },
			{ label: '6-1', betTeam: '6:1' },
			{ label: '6-2', betTeam: '6:2' },
			{ label: '6-3', betTeam: '6:3' },
			{ label: '6-4', betTeam: '6:4' },
			{ label: '6-5', betTeam: '6:5' },
			{ label: '6-6', betTeam: '6:6' },
			{ label: '6-7', betTeam: '6:7' },
			{ label: '6-8', betTeam: '6:8' },
			{ label: '6-9', betTeam: '6:9' },
			{ label: '7-0', betTeam: '7:0' },
			{ label: '7-1', betTeam: '7:1' },
			{ label: '7-2', betTeam: '7:2' },
			{ label: '7-3', betTeam: '7:3' },
			{ label: '7-4', betTeam: '7:4' },
			{ label: '7-5', betTeam: '7:5' },
			{ label: '7-6', betTeam: '7:6' },
			{ label: '7-7', betTeam: '7:7' },
			{ label: '7-8', betTeam: '7:8' },
			{ label: '7-9', betTeam: '7:9' },
			{ label: '8-0', betTeam: '8:0' },
			{ label: '8-1', betTeam: '8:1' },
			{ label: '8-2', betTeam: '8:2' },
			{ label: '8-3', betTeam: '8:3' },
			{ label: '8-4', betTeam: '8:4' },
			{ label: '8-5', betTeam: '8:5' },
			{ label: '8-6', betTeam: '8:6' },
			{ label: '8-7', betTeam: '8:7' },
			{ label: '8-8', betTeam: '8:8' },
			{ label: '8-9', betTeam: '8:9' },
			{ label: '9-0', betTeam: '9:0' },
			{ label: '9-1', betTeam: '9:1' },
			{ label: '9-2', betTeam: '9:2' },
			{ label: '9-3', betTeam: '9:3' },
			{ label: '9-4', betTeam: '9:4' },
			{ label: '9-5', betTeam: '9:5' },
			{ label: '9-6', betTeam: '9:6' },
			{ label: '9-7', betTeam: '9:7' },
			{ label: '9-8', betTeam: '9:8' },
			{ label: '9-9', betTeam: '9:9' },
		],
		layoutType: 'EndingGame'
	},
	701: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	702: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	703: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '2-0', betTeam: '2-0' },
			{ label: '2-1', betTeam: '2-1' },
			{ label: '0-2', betTeam: '0-2' },
			{ label: '1-2', betTeam: '1-2' }
		],
		layoutType: 'Col-4'
	},
	704: {
		betTypeName: {
			0: 'lbl_MatchPHDP'
		},
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	705: {
		betTypeName: {
			0: 'lbl_MatchPointOU'
		},
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	706: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' },
		],
		layoutType: 'Col-2'
	},
	707: {
		betTypeName: {
			0: 'bettype707_title'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	708: {
		betTypeName: {
			0: 'bettype708_title'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	709: {
		betTypeName: {
			0: 'bettype709_title'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	710: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' },
		],
		layoutType: 'Col-2'
	},
	711: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoices: [
			{ label: '1-2', betTeam: 'H1-2', betChoiceGroup: 1 },
			{ label: '1-2', betTeam: 'A1-2', betChoiceGroup: 1 },
			{ label: '3-5', betTeam: 'H3-5', betChoiceGroup: 2 },
			{ label: '3-5', betTeam: 'A3-5', betChoiceGroup: 2 },
			{ label: '6-8', betTeam: 'H6-8', betChoiceGroup: 3 },
			{ label: '6-8', betTeam: 'A6-8', betChoiceGroup: 3 },
			{ label: '9+', betTeam: 'H9+', betChoiceGroup: 4 },
			{ label: '9+', betTeam: 'A9+', betChoiceGroup: 4 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	712: {
		betTypeName: {
			0: 'lbl_GameXRTo'
		},
		betTypeNameReplaceType: 'replaceBetTypeRaceToResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	713: {
		betTypeName: {
			0: 'lbl_GameXPWinner'
		},
		betTypeNameReplaceType: 'replaceBetTypeRaceToResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	714: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_strNo', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	3900: {
		betTypeName: {
			0: 'b4'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '3-0', betTeam: '3:0' },
			{ label: '3-1', betTeam: '3:1' },
			{ label: '3-2', betTeam: '3:2' },
			{ label: '0-3', betTeam: '0:3' },
			{ label: '1-3', betTeam: '1:3' },
			{ label: '2-3', betTeam: '2:3' }
		],
		layoutType: 'Col-3'
	},
	3901: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	3902: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	3903: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	3904: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	3905: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	3906: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	3907: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	3908: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	3909: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	3910: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: '25-23', betTeam: '25:23', betChoiceGroup: 1 },
			{ label: '25-22', betTeam: '25:22', betChoiceGroup: 1 },
			{ label: '25-21', betTeam: '25:21', betChoiceGroup: 1 },
			{ label: '25-20', betTeam: '25:20', betChoiceGroup: 1 },
			{ label: '25-19', betTeam: '25:19', betChoiceGroup: 1 },
			{ label: '25-18', betTeam: '25:18', betChoiceGroup: 1 },
			{ label: '25-17', betTeam: '25:17', betChoiceGroup: 1 },
			{ label: '25-16', betTeam: '25:16', betChoiceGroup: 1 },
			{ label: '25-15', betTeam: '25:15', betChoiceGroup: 1 },
			{ label: '25-14', betTeam: '25:14', betChoiceGroup: 1 },
			{ label: '25-13', betTeam: '25:13', betChoiceGroup: 1 },
			{ label: '25-12', betTeam: '25:12', betChoiceGroup: 1 },
			{ label: '25-11', betTeam: '25:11', betChoiceGroup: 1 },
			{ label: '25-10', betTeam: '25:10', betChoiceGroup: 1 },
			{ label: '25-9', betTeam: '25:9', betChoiceGroup: 1 },
			{ label: '25-8', betTeam: '25:8', betChoiceGroup: 1 },
			{ label: '25-7', betTeam: '25:7', betChoiceGroup: 1 },
			{ label: '25-6', betTeam: '25:6', betChoiceGroup: 1 },
			{ label: '25-5', betTeam: '25:5', betChoiceGroup: 1 },
			{ label: '25-4', betTeam: '25:4', betChoiceGroup: 1 },
			{ label: '25-3', betTeam: '25:3', betChoiceGroup: 1 },
			{ label: '25-2', betTeam: '25:2', betChoiceGroup: 1 },
			{ label: '25-1', betTeam: '25:1', betChoiceGroup: 1 },
			{ label: '25-0', betTeam: '25:0', betChoiceGroup: 1 },
			{ label: '23-25', betTeam: '23:25', betChoiceGroup: 2 },
			{ label: '22-25', betTeam: '22:25', betChoiceGroup: 2 },
			{ label: '21-25', betTeam: '21:25', betChoiceGroup: 2 },
			{ label: '20-25', betTeam: '20:25', betChoiceGroup: 2 },
			{ label: '19-25', betTeam: '19:25', betChoiceGroup: 2 },
			{ label: '18-25', betTeam: '18:25', betChoiceGroup: 2 },
			{ label: '17-25', betTeam: '17:25', betChoiceGroup: 2 },
			{ label: '16-25', betTeam: '16:25', betChoiceGroup: 2 },
			{ label: '15-25', betTeam: '15:25', betChoiceGroup: 2 },
			{ label: '14-25', betTeam: '14:25', betChoiceGroup: 2 },
			{ label: '13-25', betTeam: '13:25', betChoiceGroup: 2 },
			{ label: '12-25', betTeam: '12:25', betChoiceGroup: 2 },
			{ label: '11-25', betTeam: '11:25', betChoiceGroup: 2 },
			{ label: '10-25', betTeam: '10:25', betChoiceGroup: 2 },
			{ label: '9-25', betTeam: '9:25', betChoiceGroup: 2 },
			{ label: '8-25', betTeam: '8:25', betChoiceGroup: 2 },
			{ label: '7-25', betTeam: '7:25', betChoiceGroup: 2 },
			{ label: '6-25', betTeam: '6:25', betChoiceGroup: 2 },
			{ label: '5-25', betTeam: '5:25', betChoiceGroup: 2 },
			{ label: '4-25', betTeam: '4:25', betChoiceGroup: 2 },
			{ label: '3-25', betTeam: '3:25', betChoiceGroup: 2 },
			{ label: '2-25', betTeam: '2:25', betChoiceGroup: 2 },
			{ label: '1-25', betTeam: '1:25', betChoiceGroup: 2 },
			{ label: '0-25', betTeam: '0:25', betChoiceGroup: 2 },
			{ label: 'AOS', betTeam: 'AOS', betChoiceGroup: 2 }
		],
		layoutType: 'Point-Correct-Score'
	},
	3911: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: '2', betTeam: 'h2', betChoiceGroup: 1 },
			{ label: '2', betTeam: 'a2', betChoiceGroup: 1 },
			{ label: '3-4', betTeam: 'h3-4', betChoiceGroup: 2 },
			{ label: '3-4', betTeam: 'a3-4', betChoiceGroup: 2 },
			{ label: '5-7', betTeam: 'h5-7', betChoiceGroup: 3 },
			{ label: '5-7', betTeam: 'a5-7', betChoiceGroup: 3 },
			{ label: '8-11', betTeam: 'h8-11', betChoiceGroup: 4 },
			{ label: '8-11', betTeam: 'a8-11', betChoiceGroup: 4 },
			{ label: '12+', betTeam: 'h12+', betChoiceGroup: 5 },
			{ label: '12+', betTeam: 'a12+', betChoiceGroup: 5 }
		],
		layoutType: 'Col-3-Goal-Options'
	},
	3912: {
		betTypeName: {
			0: 'bettype3912_title'
		},
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRaceToResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	3913: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_VL3913_O', betTeam: 'ho' },
			{ label: 'lbl_VL3913_U', betTeam: 'hu' },
			{ label: 'lbl_VL3913_O', betTeam: 'ao' },
			{ label: 'lbl_VL3913_U', betTeam: 'au' }
		],
		layoutType: 'Col-3-2to1'
	},
	3914: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	3915: {
		betTypeName: {
			0: 'b3916'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'd': {
				label: 'lbl_draw',
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_draw', betTeam: 'd' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-3'
	},
	3916: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	3917: {
		betTypeName: {
			0: 'b3910'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: '15-13', betTeam: '15:13', betChoiceGroup: 1 },
			{ label: '15-12', betTeam: '15:12', betChoiceGroup: 1 },
			{ label: '15-11', betTeam: '15:11', betChoiceGroup: 1 },
			{ label: '15-10', betTeam: '15:10', betChoiceGroup: 1 },
			{ label: '15-9', betTeam: '15:9', betChoiceGroup: 1 },
			{ label: '15-8', betTeam: '15:8', betChoiceGroup: 1 },
			{ label: '15-7', betTeam: '15:7', betChoiceGroup: 1 },
			{ label: '15-6', betTeam: '15:6', betChoiceGroup: 1 },
			{ label: '15-5', betTeam: '15:5', betChoiceGroup: 1 },
			{ label: '15-4', betTeam: '15:4', betChoiceGroup: 1 },
			{ label: '15-3', betTeam: '15:3', betChoiceGroup: 1 },
			{ label: '15-2', betTeam: '15:2', betChoiceGroup: 1 },
			{ label: '15-1', betTeam: '15:1', betChoiceGroup: 1 },
			{ label: '15-0', betTeam: '15:0', betChoiceGroup: 1 },
			{ label: '13-15', betTeam: '13:15', betChoiceGroup: 2 },
			{ label: '12-15', betTeam: '12:15', betChoiceGroup: 2 },
			{ label: '11-15', betTeam: '11:15', betChoiceGroup: 2 },
			{ label: '10-15', betTeam: '10:15', betChoiceGroup: 2 },
			{ label: '9-15', betTeam: '9:15', betChoiceGroup: 2 },
			{ label: '8-15', betTeam: '8:15', betChoiceGroup: 2 },
			{ label: '7-15', betTeam: '7:15', betChoiceGroup: 2 },
			{ label: '6-15', betTeam: '6:15', betChoiceGroup: 2 },
			{ label: '5-15', betTeam: '5:15', betChoiceGroup: 2 },
			{ label: '4-15', betTeam: '4:15', betChoiceGroup: 2 },
			{ label: '3-15', betTeam: '3:15', betChoiceGroup: 2 },
			{ label: '2-15', betTeam: '2:15', betChoiceGroup: 2 },
			{ label: '1-15', betTeam: '1:15', betChoiceGroup: 2 },
			{ label: '0-15', betTeam: '0:15', betChoiceGroup: 2 },
			{ label: 'AOS', betTeam: 'AOS', betChoiceGroup: 2 }
		],
		layoutType: 'Point-Correct-Score'
	},
	3918: {
		betTypeName: {
			0: 'bettype3918_title'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	9001: {
		betTypeName: {
			0: 'lbl_MapX_ML'
		},
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9002: {
		betTypeName: {
			0: 'b9002'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9003: {
		betTypeName: {
			0: 'b9003'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9005: {
		betTypeName: {
			0: 'b9005'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9006: {
		betTypeName: {
			0: 'b9006'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9007: {
		betTypeName: {
			0: 'b9007'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9008: {
		betTypeName: {
			0: 'b9008'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9009: {
		betTypeName: {
			0: 'b9009'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9011: {
		betTypeName: {
			0: 'b9011'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9012: {
		betTypeName: {
			0: 'b9012'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9013: {
		betTypeName: {
			0: 'b9013'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9015: {
		betTypeName: {
			0: 'b9015'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9016: {
		betTypeName: {
			0: 'b9016'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9017: {
		betTypeName: {
			0: 'b9017'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9018: {
		betTypeName: {
			0: 'b9018'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9019: {
		betTypeName: {
			0: 'b9019'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9021: {
		betTypeName: {
			0: 'b9021'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9022: {
		betTypeName: {
			0: 'b9022'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9023: {
		betTypeName: {
			0: 'b9023'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9024: {
		betTypeName: {
			0: 'b9024'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9025: {
		betTypeName: {
			0: 'b9025'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9027: {
		betTypeName: {
			0: 'b9027'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9028: {
		betTypeName: {
			0: 'b9028'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9029: {
		betTypeName: {
			0: 'b9029'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9031: {
		betTypeName: {
			0: 'b9031'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9032: {
		betTypeName: {
			0: 'b9032'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9033: {
		betTypeName: {
			0: 'b9033'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9034: {
		betTypeName: {
			0: 'b9034'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9035: {
		betTypeName: {
			0: 'b9035'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9037: {
		betTypeName: {
			0: 'b9037'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9038: {
		betTypeName: {
			0: 'b9038'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9039: {
		betTypeName: {
			0: 'b9039'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9040: {
		betTypeName: {
			0: 'b9040'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9041: {
		betTypeName: {
			0: 'b9041'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9043: {
		betTypeName: {
			0: 'b9043'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9044: {
		betTypeName: {
			0: 'b9044'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9045: {
		betTypeName: {
			0: 'b9045'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9046: {
		betTypeName: {
			0: 'b9046'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9047: {
		betTypeName: {
			0: 'b9047'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9049: {
		betTypeName: {
			0: 'b9049'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9052: {
		betTypeName: {
			0: 'b9052'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9053: {
		betTypeName: {
			0: 'b9053'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9055: {
		betTypeName: {
			0: 'b9055'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9058: {
		betTypeName: {
			0: 'b9058'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9059: {
		betTypeName: {
			0: 'b9059'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9060: {
		betTypeName: {
			0: 'b9060'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9061: {
		betTypeName: {
			0: 'b9061'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9062: {
		betTypeName: {
			0: 'b9062'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9063: {
		betTypeName: {
			0: 'b9063'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9064: {
		betTypeName: {
			0: 'b9064'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9068: {
		betTypeName: {
			0: 'b9068'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9070: {
		betTypeName: {
			0: 'b9070'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9071: {
		betTypeName: {
			0: 'b9071'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9072: {
		betTypeName: {
			0: 'b9072'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9073: {
		betTypeName: {
			0: 'b9073'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	9077: {
		betTypeName: {
			0: 'b9077'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9078: {
		betTypeName: {
			0: 'b9078'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9079: {
		betTypeName: {
			0: 'b9079'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9080: {
		betTypeName: {
			0: 'b9080'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9081: {
		betTypeName: {
			0: 'b9081'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9082: {
		betTypeName: {
			0: 'b9082'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9083: {
		betTypeName: {
			0: 'b9083'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9084: {
		betTypeName: {
			0: 'b9084'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9085: {
		betTypeName: {
			0: 'b9085'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9086: {
		betTypeName: {
			0: 'b9086'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9087: {
		betTypeName: {
			0: 'b9087'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	9088: {
		betTypeName: {
			0: 'b9088'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '2-0', betTeam: '2:0', bof: '2' },
			{ label: '1-1', betTeam: '1:1', bof: '2' },
			{ label: '0-2', betTeam: '0:2', bof: '2' },
			{ label: '2-0', betTeam: '2:0', bof: '3' },
			{ label: '2-1', betTeam: '2:1', bof: '3' },
			{ label: '0-2', betTeam: '0:2', bof: '3' },
			{ label: '1-2', betTeam: '1:2', bof: '3' },
			{ label: '3-0', betTeam: '3:0', bof: '5' },
			{ label: '3-1', betTeam: '3:1', bof: '5' },
			{ label: '3-2', betTeam: '3:2', bof: '5' },
			{ label: '0-3', betTeam: '0:3', bof: '5' },
			{ label: '1-3', betTeam: '1:3', bof: '5' },
			{ label: '2-3', betTeam: '2:3', bof: '5' },
			{ label: '4-0', betTeam: '4:0', bof: '7' },
			{ label: '4-1', betTeam: '4:1', bof: '7' },
			{ label: '4-2', betTeam: '4:2', bof: '7' },
			{ label: '4-3', betTeam: '4:3', bof: '7' },
			{ label: '0-4', betTeam: '0:4', bof: '7' },
			{ label: '1-4', betTeam: '1:4', bof: '7' },
			{ label: '2-4', betTeam: '2:4', bof: '7' },
			{ label: '3-4', betTeam: '3:4', bof: '7' }
		],
		layoutType: 'Col-3-2to1'
	},
	9089: {
		betTypeName: {
			0: 'b9089'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9090: {
		betTypeName: {
			0: 'b9090'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9091: {
		betTypeName: {
			0: 'b9091'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9092: {
		betTypeName: {
			0: 'b9092'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9093: {
		betTypeName: {
			0: 'b9093'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9094: {
		betTypeName: {
			0: 'b9094'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9095: {
		betTypeName: {
			0: 'b9095'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9096: {
		betTypeName: {
			0: 'b9096'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9097: {
		betTypeName: {
			0: 'b9097'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9098: {
		betTypeName: {
			0: 'b9098'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9099: {
		betTypeName: {
			0: 'b9099'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9100: {
		betTypeName: {
			0: 'b9100'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9101: {
		betTypeName: {
			0: 'b9101'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9102: {
		betTypeName: {
			0: 'b9102'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9103: {
		betTypeName: {
			0: 'b9103'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9104: {
		betTypeName: {
			0: 'b9104'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9105: {
		betTypeName: {
			0: 'b9105'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9106: {
		betTypeName: {
			0: 'b9106'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9107: {
		betTypeName: {
			0: 'b9107'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9108: {
		betTypeName: {
			0: 'b9108'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9109: {
		betTypeName: {
			0: 'b9109'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9110: {
		betTypeName: {
			0: 'b9110'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9111: {
		betTypeName: {
			0: 'b9111'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9112: {
		betTypeName: {
			0: 'b9112'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9113: {
		betTypeName: {
			0: 'b9113'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_LaneTop', betTeam: 't' },
			{ label: 'lbl_LaneBottom', betTeam: 'b' },
		],
		layoutType: 'Col-2'
	},
	9114: {
		betTypeName: {
			0: 'b9114'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_LaneTnM', betTeam: 'nb' },
			{ label: 'lbl_LaneBottom', betTeam: 'b' },
		],
		layoutType: 'Col-2'
	},
	9115: {
		betTypeName: {
			0: 'b9115'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9116: {
		betTypeName: {
			0: 'b9116'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9117: {
		betTypeName: {
			0: 'b9117'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9118: {
		betTypeName: {
			0: 'b9118'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9119: {
		betTypeName: {
			0: 'b9119'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9120: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9121: {
		betTypeName: {
			0: 'b9121'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9122: {
		betTypeName: {
			0: 'b9122'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9123: {
		betTypeName: {
			0: 'b9123'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9124: {
		betTypeName: {
			0: 'b9124'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	9125: {
		betTypeName: {
			0: 'b9125'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	9126: {
		betTypeName: {
			0: 'b9126'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9127: {
		betTypeName: {
			0: 'b9127'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9128: {
		betTypeName: {
			0: 'b9128'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9129: {
		betTypeName: {
			0: 'b9129'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9130: {
		betTypeName: {
			0: 'b9130'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_LaneMid', betTeam: 'm' },
			{ label: 'lbl_LaneTnB', betTeam: 'nm' },
		],
		layoutType: 'Col-2'
	},
	9131: {
		betTypeName: {
			0: 'b9131'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9132: {
		betTypeName: {
			0: 'b9132'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	9133: {
		betTypeName: {
			0: 'b9133'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' },
		],
		layoutType: 'Col-2'
	},
	9134: {
		betTypeName: {
			0: 'b9134'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9135: {
		betTypeName: {
			0: 'b9135'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_LaneTop', betTeam: 't' },
			{ label: 'lbl_LaneMnB', betTeam: 'nt' },
		],
		layoutType: 'Col-2'
	},
	9136: {
		betTypeName: {
			0: 'b9136'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9137: {
		betTypeName: {
			0: 'b9137'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9138: {
		betTypeName: {
			0: 'b9138'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_LaneMid', betTeam: 'm' },
			{ label: 'lbl_LaneTnB', betTeam: 'nm' },
		],
		layoutType: 'Col-2'
	},
	9139: {
		betTypeName: {
			0: 'b9139'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9140: {
		betTypeName: {
			0: 'b9140'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9141: {
		betTypeName: {
			0: 'b9141'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_Overlord', betTeam: 'o' },
			{ label: 'lbl_Tyrant', betTeam: 't' },
		],
		layoutType: 'Col-2'
	},
	9142: {
		betTypeName: {
			0: 'b9142'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceEsportMapNameResource',
		betChoices: [
			{ label: 'lbl_OlordnM', betTeam: 'nt' },
			{ label: 'lbl_Tyrant', betTeam: 't' },
		],
		layoutType: 'Col-2'
	},
	9401: {
		betTypeName: {
			0: 'lbl_NWinCoinToss'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	9404: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9405: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9406: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9407: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9408: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9409: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9410: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9411: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9412: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9413: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9414: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9415: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9428: {
		betTypeName: {
			0: 'lbl_FallofWicket'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9429: {
		betTypeName: {
			0: 'lbl_FallofWicket'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9430: {
		betTypeName: {
			0: 'lbl_FallofWicket'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9431: {
		betTypeName: {
			0: 'lbl_FallofWicket'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9432: {
		betTypeName: {
			0: 'lbl_FallofWicket'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9433: {
		betTypeName: {
			0: 'lbl_FallofWicket'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9434: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_NCaught', betTeam: 'nc' }
		],
		layoutType: 'Col-2'
	},
	9435: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_NCaught', betTeam: 'nc' }
		],
		layoutType: 'Col-2'
	},
	9436: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_NCaught', betTeam: 'nc' }
		],
		layoutType: 'Col-2'
	},
	9437: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_NCaught', betTeam: 'nc' }
		],
		layoutType: 'Col-2'
	},
	9438: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_NCaught', betTeam: 'nc' }
		],
		layoutType: 'Col-2'
	},
	9439: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_NCaught', betTeam: 'nc' }
		],
		layoutType: 'Col-2'
	},
	9440: {
		betTypeName: {
			0: 'lbl_FT1X2'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_1x2_Home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw',
			},
			'2': {
				label: 'lbl_1x2_Away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' },
		],
		layoutType: 'Col-3'
	},
	9441: {
		betTypeName: {
			0: 'lbl_FT1X2'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_1x2_Home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw',
			},
			'2': {
				label: 'lbl_1x2_Away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' },
		],
		layoutType: 'Col-3'
	},
	9442: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	9443: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	9446: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9447: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9448: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9449: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9450: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9451: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9452: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9453: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9454: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9455: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9456: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9457: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9458: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9459: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9460: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9461: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9462: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9463: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9464: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9465: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9484: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '0' },
			{ label: '', betTeam: '1' },
			{ label: '', betTeam: '2' },
			{ label: '', betTeam: '3' },
			{ label: '', betTeam: '4' },
			{ label: '', betTeam: '5' },
			{ label: '', betTeam: '6' },
			{ label: '', betTeam: '7' },
		],
		betTypesCategory: 'exactRun',
		layoutType: 'Col-3-2to1'
	},
	9485: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '0' },
			{ label: '', betTeam: '1' },
			{ label: '', betTeam: '2' },
			{ label: '', betTeam: '3' },
			{ label: '', betTeam: '4' },
			{ label: '', betTeam: '5' },
			{ label: '', betTeam: '6' },
			{ label: '', betTeam: '7' },
		],
		betTypesCategory: 'exactRun',
		layoutType: 'Col-3-2to1'
	},
	9486: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '0' },
			{ label: '', betTeam: '1' },
			{ label: '', betTeam: '2' },
			{ label: '', betTeam: '3' },
			{ label: '', betTeam: '4' },
			{ label: '', betTeam: '5' },
			{ label: '', betTeam: '6' },
			{ label: '', betTeam: '7' },
		],
		betTypesCategory: 'exactRun',
		layoutType: 'Col-3-2to1'
	},
	9487: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '0' },
			{ label: '', betTeam: '1' },
			{ label: '', betTeam: '2' },
			{ label: '', betTeam: '3' },
			{ label: '', betTeam: '4' },
			{ label: '', betTeam: '5' },
			{ label: '', betTeam: '6' },
			{ label: '', betTeam: '7' },
		],
		betTypesCategory: 'exactRun',
		layoutType: 'Col-3-2to1'
	},
	9488: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '0' },
			{ label: '', betTeam: '1' },
			{ label: '', betTeam: '2' },
			{ label: '', betTeam: '3' },
			{ label: '', betTeam: '4' },
			{ label: '', betTeam: '5' },
			{ label: '', betTeam: '6' },
			{ label: '', betTeam: '7' },
		],
		betTypesCategory: 'exactRun',
		layoutType: 'Col-3-2to1'
	},
	9489: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '0' },
			{ label: '', betTeam: '1' },
			{ label: '', betTeam: '2' },
			{ label: '', betTeam: '3' },
			{ label: '', betTeam: '4' },
			{ label: '', betTeam: '5' },
			{ label: '', betTeam: '6' },
			{ label: '', betTeam: '7' },
		],
		betTypesCategory: 'exactRun',
		layoutType: 'Col-3-2to1'
	},
	9490: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'bet9490_0', betTeam: '0' },
			{ label: 'bet9490_1', betTeam: '1' },
			{ label: 'bet9490_2', betTeam: '2' },
			{ label: 'bet9490_3', betTeam: '3' },
			{ label: 'bet9490_4', betTeam: '4' },
			{ label: 'bet9490_ao', betTeam: 'AO' }
		],
		layoutType: 'Col-3-2to1'
	},
	9491: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'Choice',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'bet9490_0', betTeam: '0' },
			{ label: 'bet9490_1', betTeam: '1' },
			{ label: 'bet9490_2', betTeam: '2' },
			{ label: 'bet9490_3', betTeam: '3' },
			{ label: 'bet9490_4', betTeam: '4' },
			{ label: 'bet9490_ao', betTeam: 'AO' }
		],
		layoutType: 'Col-3-2to1'
	},
	9496: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9497: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9498: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9499: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9500: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9501: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9502: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-2'
	},
	9503: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-2'
	},
	9504: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-2'
	},
	9505: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-2'
	},
	9506: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-2'
	},
	9507: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-2'
	},
	9514: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9515: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9516: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9517: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9518: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9519: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9527: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9528: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9529: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9530: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9531: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9532: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9533: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9534: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9535: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9536: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9537: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9538: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceDisplaySetting: {
			'1b': {
				label: 'lbl_Crkt_Back',
				betTypeClassName: 'Back',
				betTeamType: 'home'
			},
			'1l': {
				label: 'lbl_Crkt_Lay',
				betTypeClassName: 'Lay',
				betTeamType: 'home'
			},
			'2b': {
				label: 'lbl_Crkt_Back',
				betTypeClassName: 'Back',
				betTeamType: 'away'
			},
			'2l': {
				label: 'lbl_Crkt_Lay',
				betTypeClassName: 'Lay',
				betTeamType: 'away'
			},
			'xb': {
				label: 'lbl_draw',
				betTypeClassName: 'Back'
			},
			'xl': {
				label: 'lbl_draw',
				betTypeClassName: 'Lay'
			}
		},
		betChoices: [
			{ label: '', betTeam: '1b', betChoiceGroup: 1 },
			{ label: '', betTeam: '1l', betChoiceGroup: 1 },
			{ label: '', betTeam: '2b', betChoiceGroup: 2 },
			{ label: '', betTeam: '2l', betChoiceGroup: 2 },
			{ label: '', betTeam: 'xb', betChoiceGroup: 3 },
			{ label: '', betTeam: 'xl', betChoiceGroup: 3 }
		],
		betTypesCategory: 'fancyBackLay',
		layoutType: 'Cricket-Fancy'
	},
	9539: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceDisplaySetting: {
			'hb': {
				label: 'lbl_Crkt_Back',
				betTypeClassName: 'Back',
				betTeamType: 'home'
			},
			'hl': {
				label: 'lbl_Crkt_Lay',
				betTypeClassName: 'Lay',
				betTeamType: 'home'
			},
			'ab': {
				label: 'lbl_Crkt_Back',
				betTypeClassName: 'Back',
				betTeamType: 'away'
			},
			'al': {
				label: 'lbl_Crkt_Lay',
				betTypeClassName: 'Lay',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'hb', betChoiceGroup: 1 },
			{ label: '', betTeam: 'hl', betChoiceGroup: 1 },
			{ label: '', betTeam: 'ab', betChoiceGroup: 2 },
			{ label: '', betTeam: 'al', betChoiceGroup: 2 }
		],
		betTypesCategory: 'fancyBackLay',
		layoutType: 'Cricket-Fancy'
	},
	9540: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceDisplaySetting: {
			'hb': {
				label: 'lbl_Crkt_Back',
				betTypeClassName: 'Back',
				betTeamType: 'home'
			},
			'hl': {
				label: 'lbl_Crkt_Lay',
				betTypeClassName: 'Lay',
				betTeamType: 'home'
			},
			'ab': {
				label: 'lbl_Crkt_Back',
				betTypeClassName: 'Back',
				betTeamType: 'away'
			},
			'al': {
				label: 'lbl_Crkt_Lay',
				betTypeClassName: 'Lay',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'hb', betChoiceGroup: 1 },
			{ label: '', betTeam: 'hl', betChoiceGroup: 1 },
			{ label: '', betTeam: 'ab', betChoiceGroup: 2 },
			{ label: '', betTeam: 'al', betChoiceGroup: 2 }
		],
		betTypesCategory: 'fancyBackLay',
		layoutType: 'Cricket-Fancy'
	},
	9541: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9542: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9543: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9544: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9545: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9546: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9547: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Cricket-Fancy'
	},
	9548: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Cricket-Fancy'
	},
	9549: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Cricket-Fancy'
	},
	9550: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Cricket-Fancy'
	},
	9551: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Cricket-Fancy'
	},
	9552: {
		betTypeName: {
			0: 'lbl_GroupRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Cricket-Fancy'
	},
	9559: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9560: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9561: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9562: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9563: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9564: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9566: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9567: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9569: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9570: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9572: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9573: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9574: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9575: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9576: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9577: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9578: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9579: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9580: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9581: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9582: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9583: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9584: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9585: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9607: {
		betTypeName: {
			0: 'lbl_OverRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9608: {
		betTypeName: {
			0: 'lbl_OverRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9609: {
		betTypeName: {
			0: 'lbl_OverRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9610: {
		betTypeName: {
			0: 'lbl_OverRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9611: {
		betTypeName: {
			0: 'lbl_OverRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9612: {
		betTypeName: {
			0: 'lbl_OverRuns'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		betTypesCategory: 'overRun',
		layoutType: 'Col-3-Goal-Odds'
	},
	9613: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9614: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9615: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'1': {
				label: 'lbl_1x2_Home',
				betTeamType: 'home'
			},
			'x': {
				label: 'lbl_draw',
			},
			'2': {
				label: 'lbl_1x2_Away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_1x2_Home', betTeam: '1' },
			{ label: 'lbl_draw', betTeam: 'x' },
			{ label: 'lbl_1x2_Away', betTeam: '2' }
		],
		layoutType: 'Col-3'
	},
	9616: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9617: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9618: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9619: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9620: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9621: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9622: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9623: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9624: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9625: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9626: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9627: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9628: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9629: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9630: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9631: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9632: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9633: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9634: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9635: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	9636: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_Bowled', betTeam: 'b' },
			{ label: 'lbl_Crkt_LBW', betTeam: 'lbw' },
			{ label: 'lbl_Crkt_RunOut', betTeam: 'ro' },
			{ label: 'lbl_Crkt_Stumped', betTeam: 's' },
			{ label: 'lbl_Crkt_Others', betTeam: 'ot' }
		],
		layoutType: 'Col-3-2to1'
	},
	9637: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_Bowled', betTeam: 'b' },
			{ label: 'lbl_Crkt_LBW', betTeam: 'lbw' },
			{ label: 'lbl_Crkt_RunOut', betTeam: 'ro' },
			{ label: 'lbl_Crkt_Stumped', betTeam: 's' },
			{ label: 'lbl_Crkt_Others', betTeam: 'ot' }
		],
		layoutType: 'Col-3-2to1'
	},
	9638: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_Bowled', betTeam: 'b' },
			{ label: 'lbl_Crkt_LBW', betTeam: 'lbw' },
			{ label: 'lbl_Crkt_RunOut', betTeam: 'ro' },
			{ label: 'lbl_Crkt_Stumped', betTeam: 's' },
			{ label: 'lbl_Crkt_Others', betTeam: 'ot' }
		],
		layoutType: 'Col-3-2to1'
	},
	9639: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_Bowled', betTeam: 'b' },
			{ label: 'lbl_Crkt_LBW', betTeam: 'lbw' },
			{ label: 'lbl_Crkt_RunOut', betTeam: 'ro' },
			{ label: 'lbl_Crkt_Stumped', betTeam: 's' },
			{ label: 'lbl_Crkt_Others', betTeam: 'ot' }
		],
		layoutType: 'Col-3-2to1'
	},
	9640: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_Bowled', betTeam: 'b' },
			{ label: 'lbl_Crkt_LBW', betTeam: 'lbw' },
			{ label: 'lbl_Crkt_RunOut', betTeam: 'ro' },
			{ label: 'lbl_Crkt_Stumped', betTeam: 's' },
			{ label: 'lbl_Crkt_Others', betTeam: 'ot' }
		],
		layoutType: 'Col-3-2to1'
	},
	9641: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_Crkt_Caught', betTeam: 'c' },
			{ label: 'lbl_Crkt_Bowled', betTeam: 'b' },
			{ label: 'lbl_Crkt_LBW', betTeam: 'lbw' },
			{ label: 'lbl_Crkt_RunOut', betTeam: 'ro' },
			{ label: 'lbl_Crkt_Stumped', betTeam: 's' },
			{ label: 'lbl_Crkt_Others', betTeam: 'ot' }
		],
		layoutType: 'Col-3-2to1'
	},
	9650: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9651: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9652: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9653: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9654: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9655: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9656: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9657: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9658: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9659: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9660: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9661: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9662: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-2'
	},
	9663: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-2'
	},
	9664: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-2'
	},
	9665: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-2'
	},
	9666: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-2'
	},
	9667: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-2'
	},
	9668: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9669: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9670: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9671: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9672: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9673: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9674: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9675: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: '', betTeam: '01' },
			{ label: '', betTeam: '02' },
			{ label: '', betTeam: '03' },
			{ label: '', betTeam: '04' },
			{ label: '', betTeam: '05' },
			{ label: '', betTeam: '06' },
			{ label: '', betTeam: '07' },
			{ label: '', betTeam: '08' },
			{ label: '', betTeam: '09' },
			{ label: '', betTeam: '10' },
			{ label: '', betTeam: '11' },
			{ label: '', betTeam: '12' },
			{ label: '', betTeam: '13' },
			{ label: '', betTeam: '14' },
			{ label: '', betTeam: '15' },
			{ label: '', betTeam: '16' },
			{ label: '', betTeam: '17' },
			{ label: '', betTeam: '18' },
			{ label: '', betTeam: '19' },
			{ label: '', betTeam: '20' }
		],
		betTypesCategory: 'innsPlay',
		layoutType: 'Col-3-2to1'
	},
	9676: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9677: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9678: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9679: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9680: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9681: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9682: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9683: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9684: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9685: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9686: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9687: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9688: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9689: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9690: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9691: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9692: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9693: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9694: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9695: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_odd', betTeam: 'o' },
			{ label: 'lbl_even', betTeam: 'e' }
		],
		layoutType: 'Col-2'
	},
	9696: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9697: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9698: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9699: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9702: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9703: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9704: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9705: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9708: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9709: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' }
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9710: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	9711: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'h' },
			{ label: '', betTeam: 'a' }
		],
		layoutType: 'Col-2'
	},
	9712: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9713: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9714: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9715: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9716: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9717: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9718: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9719: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9720: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9721: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9722: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9723: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9724: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9725: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9726: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9727: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9728: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9729: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceCricketResource',
		betChoiceDisplaySetting: {
			'n': {
				label: 'lbl_no',
				betTypeClassName: 'No',
				betLine: 'hdp1'
			},
			'y': {
				label: 'lbl_yes',
				betTypeClassName: 'Yes',
				betLine: 'hdp2'
			}
		},
		betChoices: [
			{ label: '', betTeam: 'n' },
			{ label: '', betTeam: 'y' }
		],
		layoutType: 'Cricket-Fancy'
	},
	9730: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9731: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9732: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9733: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9734: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9735: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9736: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9737: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9738: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	9739: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'getBetTypeNamePlayer',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8800: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8801: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8804: {
		betTypeName: {
			0: 'lbl_Licbet8804_01'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		betTypesCategory: 'EarlyInningsMoneyLine',
		layoutType: 'Col-2'
	},
	8806: {
		betTypeName: {
			0: 'lbl_Licbet8806_01'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		betTypesCategory: 'EarlyInningsRunLine',
		layoutType: 'Col-2'
	},
	8807: {
		betTypeName: {
			0: 'lbl_Licbet8807_01'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		betTypesCategory: 'EarlyInningsTotalRuns',
		layoutType: 'Col-3-Goal-Odds'
	},
	8811: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8812: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8810: { 
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'd': {
				label: 'lbl_draw',
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_draw', betTeam: 'd' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-3' 
	},
	8802: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8803: {
		isBetTypeNameTranslateFromBetType: true,
		
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8805: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'd': {
				label: 'lbl_draw',
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_draw', betTeam: 'd' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-3'
	},
	8808: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8809: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceDoubleDigitsResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8825: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_VL3913_O', betTeam: 'ho' },
			{ label: 'lbl_VL3913_U', betTeam: 'hu' },
			{ label: 'lbl_VL3913_O', betTeam: 'ao' },
			{ label: 'lbl_VL3913_U', betTeam: 'au' },
		],
		layoutType: 'Col-3-2to1'
	},
	8826: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'bet8826_12', betTeam: '1-2' },
			{ label: 'bet8826_34', betTeam: '3-4' },
			{ label: 'bet8826_56', betTeam: '5-6' },
			{ label: 'bet8826_78', betTeam: '7-8' },
			{ label: 'bet8826_910', betTeam: '9-10' },
			{ label: 'bet8826_1112', betTeam: '11-12' },
			{ label: 'bet8826_1314', betTeam: '13-14' },
			{ label: 'bet8826_15', betTeam: '15+' },
		],
		layoutType: 'Col-2-Choice-Options'
	},
	8815: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	8818: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'd': {
				label: 'lbl_draw',
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_draw', betTeam: 'd' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-3'
	},
	8819: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'd': {
				label: 'lbl_draw',
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_draw', betTeam: 'd' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-3'
	}, 
	8820: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			},
			'n': {
				label: 'pop_neither',
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
			{ label: 'pop_neither', betTeam: 'n' }
		],
		layoutType: 'Col-3'
	},
	8816: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8817: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'd': {
				label: 'lbl_draw',

			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_draw', betTeam: 'd' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-3'
	}, 
	8823: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8824: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8821: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8827: {
		betTypeName: {
			0: 'b448'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8822: {
		betTypeName: {
			0: 'b171'
		},
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_Licbet8822_01', betTeam: 'h1', betChoiceGroup: 1 },
			{ label: 'lbl_Licbet8822_02', betTeam: 'h2', betChoiceGroup: 2 },
			{ label: 'lbl_Licbet8822_03', betTeam: 'h3', betChoiceGroup: 3 },
			{ label: 'lbl_Licbet8822_04', betTeam: 'h4+', betChoiceGroup: 4 },
			{ label: 'lbl_Licbet8822_01', betTeam: 'a1', betChoiceGroup: 1 },
			{ label: 'lbl_Licbet8822_02', betTeam: 'a2', betChoiceGroup: 2 },
			{ label: 'lbl_Licbet8822_03', betTeam: 'a3', betChoiceGroup: 3 },
			{ label: 'lbl_Licbet8822_04', betTeam: 'a4+', betChoiceGroup: 4 },
		],
		layoutType: 'Col-3-Goal-Options'
	},
	8814: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_yes', betTeam: 'y' },
			{ label: 'lbl_no', betTeam: 'n' }
		],
		layoutType: 'Col-2'
	},
	8813: {
		betTypeName: {
			0: 'b8813'
		},
		isBetTypeNameTranslateFromBetType: true,
		// betChoiceTranslateType: 'Choice',
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoices: [
			{ label: '0', betTeam: '0' },
			{ label: '1', betTeam: '1' },
			{ label: '2', betTeam: '2' },
			{ label: '3+', betTeam: '3+' },
		],
		layoutType: 'Col-4'
	},
	8828: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8832: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8830: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoices: [
			{ label: 'lbl_over', betTeam: 'o' },
			{ label: 'lbl_under', betTeam: 'u' },
		],
		layoutType: 'Col-3-Goal-Odds'
	},
	8829: {
		isBetTypeNameTranslateFromBetType: true,
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceTranslateType: 'General',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_away', betTeam: 'a' },
		],
		layoutType: 'Col-2'
	},
	8833: {
		isBetTypeNameTranslateFromBetType: true,
		betChoiceTranslateType: 'General',
		betTypeNameReplaceType: 'replaceBetTypeRoundBaseballResource',
		betChoiceDisplaySetting: {
			'h': {
				label: 'lbl_home',
				betTeamType: 'home'
			},
			'd': {
				label: 'lbl_draw',
			},
			'a': {
				label: 'lbl_away',
				betTeamType: 'away'
			}
		},
		betChoices: [
			{ label: 'lbl_home', betTeam: 'h' },
			{ label: 'lbl_draw', betTeam: 'd' },
			{ label: 'lbl_away', betTeam: 'a' }
		],
		layoutType: 'Col-3'
	},  
	8831: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '3-0', betTeam: '3-0' },
			{ label: '0-3', betTeam: '0-3' },
			{ label: '3-1', betTeam: '3-1' },
			{ label: '1-3', betTeam: '1-3' },
			{ label: '3-2', betTeam: '3-2' },
			{ label: '2-3', betTeam: '2-3' },
			{ label: '4-0', betTeam: '4-0' },
			{ label: '0-4', betTeam: '0-4' },
			{ label: '4-1', betTeam: '4-1' },
			{ label: '1-4', betTeam: '1-4' },
			{ label: '4-2', betTeam: '4-2' },
			{ label: '2-4', betTeam: '2-4' },
			{ label: '4-3', betTeam: '4-3' },
			{ label: '3-4', betTeam: '3-4' },
		],
		layoutType: 'Col-2-Choice-Options'
	},
	8834: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '2-1', betTeam: '2-1' },
			{ label: '1-2', betTeam: '1-2' },
			{ label: '3-0', betTeam: '3-0' },
			{ label: '0-3', betTeam: '0-3' },
		],
		layoutType: 'Col-2-Choice-Options'
	},
	8835: {
		isBetTypeNameTranslateFromBetType: true,
		betChoices: [
			{ label: '3-1', betTeam: '3-1' },
			{ label: '2-2', betTeam: '2-2' },
			{ label: '1-3', betTeam: '1-3' },
			{ label: '4-0', betTeam: '4-0' },
			{ label: '', betTeam: '' },
			{ label: '0-4', betTeam: '0-4' },
		],
		layoutType: 'Col-3'
	}
}

