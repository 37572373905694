import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { UserInfo } from '@nf/types/account';
import { SiteConfigsSettingsData } from '@nf/types/site';
import { v4 as uuid } from 'uuid';
import queryKeys from '@/constants/query-keys';
import { fetchThirdPartyToken, useAccountInfo } from './account';
import { useGetSiteConfigs } from './use-site-configs';
import { useQuery } from '@tanstack/react-query';
import { isDesktop } from 'react-device-detect';
import { convertCultureToRef, getBetFrom } from '@/utils/common-util';
import { useAppearanceStore } from '@/store/appearance';
import { switchViewService } from '@/services/swtich-view.service';

export interface ExchangeBalanceResponse {
	Balance: number;
    Outstanding: number;
    Timestamp: string;
    Seq: string;
    ErrorCode: number;
    ErrorDescription: string;
}

export interface ExchangeTransferResponse {
	TransID: number;
	Success: boolean;
    Timestamp: string;
    Seq: string;
    ErrorCode: number;
    ErrorDescription: string;
}

export interface GetExchangeUrlResponse {
	GameUrl: string;
    ErrorCode: number;
}

export interface CheckTransferResponse {
    ErrorCode: number;
}

export enum Direction {
	SportbookToExchange = 0,
	ExchangeToSportbook = 1
}

const getCurrentDateTime = () => {
	const now = new Date();
	const offset = now.getTimezoneOffset();
	const offsetHours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
	const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
	const offsetSign = offset >= 0 ? '-' : '+';
	const formattedDateTime = `${now.getFullYear()}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getDate().toString().padStart(2, '0')}T${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}.${now.getMilliseconds().toString().padStart(7, '0')}${offsetSign}${offsetHours}:${offsetMinutes}`;
  
	return formattedDateTime;
}

export const transfer = async (amount: number, direction: Direction, user?: UserInfo | null, siteConfig?: SiteConfigsSettingsData | null): Promise<ExchangeTransferResponse> => {	
	const data = {
		Timestamp: getCurrentDateTime(),
		Seq: uuid(),
		SiteId: siteConfig?.GetMessageID,
		SiteName: siteConfig?.SiteName,
		SportType: 221,
		ObCustId: user?.ID,
		Amount: amount,
		Direction: direction,
		Platform: isDesktop ? 'D' : 'M',
		IP: user?.Ip
	};

	const option = {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		},
		body: JSON.stringify(data)
	}

	const response =
		await fetchComposer.postWithBearerBase(`${siteConfig?.SealComponentAPIUrlForClient}/api/CheckSecurity/Transfer`, option)
		(user?.AccessToken ?? '');

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return await response.json();
}

export const getExchangeBalance = async (user?: UserInfo | null, siteConfig?: SiteConfigsSettingsData | null): Promise<ExchangeBalanceResponse> => {
	const data = {
		Timestamp: getCurrentDateTime(),
		Seq: uuid(),
		SiteName: siteConfig?.SiteName,
		SportType: 221,
		ObCustId: user?.ID
	};

	const option = {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		},
		body: JSON.stringify(data)
	}

	const response =
		await fetchComposer.postWithBearerBase(`${siteConfig?.SealComponentAPIUrlForClient}/api/CheckSecurity/GetBalance`, option)
		(user?.AccessToken ?? '');

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return await response.json();
}

export const checkTransfer = async (transId: number, user?: UserInfo | null, siteConfig?: SiteConfigsSettingsData | null): Promise<CheckTransferResponse>  => {
	const data = {
		Timestamp: getCurrentDateTime(),
		Seq: uuid(),
		SiteName: siteConfig?.SiteName,
		SportType: 221,
		ObCustId: user?.ID,
		TransID: transId
	};

	const option = {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		},
		body: JSON.stringify(data)
	}

	const response =
		await fetchComposer.postWithBearerBase(`${siteConfig?.SealComponentAPIUrlForClient}/api/CheckSecurity/CheckTransfer`, option)
		(user?.AccessToken ?? '');

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return await response.json();
}

export const getExchangeUrl = async (isShowBackButton: boolean, user?: UserInfo | null, siteConfig?: SiteConfigsSettingsData | null): Promise<GetExchangeUrlResponse>  => {
	const view = useAppearanceStore.getState().view;
	const thirdPartyTokenResponse = await fetchThirdPartyToken();
	
	const data = {
		Timestamp: getCurrentDateTime(),
		Seq: uuid(),
		ProductInfo: {
			SportTypeId: 221,
			BetTypeId: 0,
			VendorId: 31
		},
		CustInfo: {
			SiteId: siteConfig?.GetMessageID,
			ObCustId: user?.ID,
			Currency: user?.Curr,
			ObCustName: user?.LoginUserName,
			Language: switchViewService.convertLanguageToShortCode(user!.Lang, siteConfig!),
			Site: siteConfig?.SiteName,
			CurrencyId: user?.CurrId,
			BetFrom: getBetFrom(view, isDesktop),
			Platform: isDesktop ? 'D' : 'M',
			Token: '',
			Host: location.hostname,
			Ip: user?.Ip,
			BrowserUserAgent: navigator.userAgent,
		},
		HomeURL: isShowBackButton ? window.location.origin : undefined,
		EnableSingleWallet: user?.EnableSingleWallet,
		Tsid: user?.TsId,
		Additional: {
			OffSetTime: (0 - new Date().getTimezoneOffset() / 60) + 4,
			Location: thirdPartyTokenResponse.data.Data?.CountryShort,
			SessionId: thirdPartyTokenResponse.data.Data?.SessionId
		}
	};

	const option = {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		},
		body: JSON.stringify(data)
	}

	const response =
		await fetchComposer.postWithBearerBase(`${siteConfig?.SealComponentAPIUrlForClient}/api/CheckSecurity/Login`, option)
		(thirdPartyTokenResponse.data.Data?.AccessToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return await response.json();
}

export const useExchangeBalance = () => {
	const { user } = useAccountInfo();
	const { SiteConfigsData } = useGetSiteConfigs();

	const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.EXCHANGE_BALANCE],
		queryFn: () => getExchangeBalance(user, SiteConfigsData),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});

	return {
		balance: data?.Balance,
		isFetching,
		isLoading,
		isError,
		error,
		refetch
	};
}

export const useRetrieveAll = () => {
	const { user } = useAccountInfo();
	const { SiteConfigsData } = useGetSiteConfigs();

	const { balance } = useExchangeBalance();

	const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.EXCHANGE_RETRIEVE],
		queryFn: () => transfer(balance ?? 0, Direction.ExchangeToSportbook, user, SiteConfigsData),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});

	return {
		data: data,
		isFetching,
		isLoading,
		isError,
		error,
		refetch
	};
}

export const useTransferIn = (amount: number) => {
	const { user } = useAccountInfo();
	const { SiteConfigsData } = useGetSiteConfigs();

	const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.EXCHANGE_TRANSFER_IN],
		queryFn: () => transfer(amount, Direction.SportbookToExchange, user, SiteConfigsData),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});

	return {
		data: data,
		isFetching,
		isLoading,
		isError,
		error,
		refetch
	};
}