// Don't modify any part of this code file, please

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

interface Array {
	foldMap(f: any, empty?: any): any;
	traverse(point, f): any;
}

Array.prototype.foldMap = function(f: any, empty?: any): any {
	return empty != null
		? this.reduce((acc, x, i) => acc.concat(f(x, i)), empty)
		: this.map(f).reduce((acc, x) => acc.concat(x));
};

Array.prototype.traverse = function(point, f) {
	return this.reduce(
		(acc, n) => acc.map(x => y => x.concat([y])).ap(f(n)),
		point([])
	);
};