import queryKeys from '@/constants/query-keys';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { useQuery } from '@tanstack/react-query';
import { useSiteStore } from '@/store/site';
import { ApiDomain } from '@nf/types/site';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { useAppearanceStore } from '@/store/appearance';
import { ApiQueryPromise } from '@/types';
import { getAuthToken } from '@/store/auth';
import { getResponseJwtHeader } from '@/utils/web';

// 這支 API 沒有餵 session
// 因為理論上應該要達到沒有登入也可以拿到資料的效果

export interface UmMessage {
    Data: {
        StartTime: string;
        EndTime: string;
        SabaSportsUrl: {
            [key: string]: string;
        };
        RedirectPage: number;
    };
    Success: boolean;
    Code: string;
    Message: string;
}

interface SportUmData {
	isUM: boolean;
}

export const checkUmBySportType = async (siteId: string, sportId: string): Promise<SportUmData> => {
	const { ctcdDomain } = useSiteStore.getState().apiDomain;
	const authToken = getAuthToken();
	
	const body = JSON.stringify({ mesId: siteId, sportType: sportId });

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${ctcdDomain}/UMInfo/GetSportTypeUMInfo`), { body })(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return response.json();
};

const fetchUmMessage = async (
	apiDomain: ApiDomain,
	skinMode: string
): Promise<UmMessage> => {
	const options = {
		headers: {
			SkinMode: skinMode
		}
	};

	const response = await fetchComposer.get(cloudUrlParser(`${apiDomain.ctcdDomain}/api/Um/UmInfo`), options);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return await response.json();
}

export const useUmMessage = () => {
	const apiDomain = useSiteStore(state => state.apiDomain);
	const skinMode: number = useAppearanceStore(state => state.view);

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.UM_MESSAGE, apiDomain],
		queryFn: () => fetchUmMessage(apiDomain, skinMode.toString()),
		refetchOnWindowFocus: false,
		enabled: true,
		cacheTime: 0
	});

	return {
		umData: data?.Data,
		isLoading,
		isError,
		error,
		refetch
	};
}