export interface ProvidedMatchCount {
	E: number;
	L: number;
	O: number; //Outright
	T: number;
	WO: number; //means 'Starting Soon' (only used in CV)
}

export type MarketType = 'L' | 'U' | 'T' | 'E' | 'O';

export type ProvidedMarket = keyof ProvidedMatchCount;
export type PartialRecord<K extends keyof never, T> = {
	[P in K]?: T;
};

export const galaxyMarketMenuPriorityMapping: PartialRecord<MarketType, ProvidedMarket> = {
	'T': 'T',
	'E': 'E',
	'L': 'L',
	'U': 'WO',
	'O': 'O'
};

export const marsMarketMenuPriorityMapping: PartialRecord<MarketType, ProvidedMarket> = {
	'L': 'L',
	'U': 'WO',
	'T': 'T',
	'E': 'E',
	'O': 'O'
};

export const marsParlayMarketMenuPriorityMapping: PartialRecord<MarketType, ProvidedMarket> = {
	'L': 'L',
	'T': 'T',
	'E': 'E',
	'O': 'O'
};

export type RawMarketData = ProvidedMatchCount & {
	Days: Day;
	Group?: Group;
}

export type Day = {
	[key: string]: number;
}

export type Group = {
	[key: string]: RawMarketData;
}

export const menuDefinition = {
	M0: 'sports',
	M1: 'olympics',
	M2: 'world-cup',
	M4: 'winter-olympics',
	M3: 'euro-cup',
	M29: 'virtual-epl',		
	M27: 'virtual-euro',			//set order after M3 
	M5: 'copa-america',
	M28: 'virtual-copa',			//set order after M5
	M6: 'womens-world-cup',
	M7: 'womens-euro',
	M8: 'asia-games',
	M9: 'asian-cup',
	M10: 'womens-asian-cup',
	M11: 'fiba-world-cup',
	M12: 'womens-fiba-world-cup',
	M13: 'youth-olympics-games',
	M14: 'sea-games',
	M15: 'ameri-cup',
	M16: 'womens-eurobasket',
	M17: 'womens-americup',
	M18: 'womens-afrobasket',
	M19: 'eurobasket',
	M20: 'afrobasket',
	M21: 'commonwealth-games',
	M22: 'nations-league',
	M23: 'aff-cup',
	M24: 'womens-aff-cup',
	M25: 'asia-cup',
	M26: 'womens-asia-cup'
} as const;

export type EventMode = keyof typeof menuDefinition;
export type EventName = (typeof menuDefinition)[keyof typeof menuDefinition];
export type RawMenuData = Record<EventMode, RawMarketData> & {
	GameId: number;
	Name: string;
};