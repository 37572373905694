import type { SmpTicketModel, SmpOddsTicketModel } from '@nf/types/ticket';

import { BackLayPayoutSetting, OddsTicketModel, ParlayTicketModel, TicketModel, BetTicketModel, BETTING_CODE_MAP, BettingCodeMap, BetMsgModel } from '@/hooks/query';
import { ViewEnum } from '@/types';
import { getOddsKey, removeCommas, calculatePayOut, calculateParlayPayOut, calculateBackLayPayOut } from '@/utils/odds-utils';
import { KafkaNote } from '@nf/utils-common/constants';
import { setKafkaNormal } from '@/hooks/query/use-kafka-log';

const getPayoutByTicket = (ticket: TicketModel, oddsType: number): number => {
	let payout = 0;

	if (ticket.stake) {
		const type = getPayoutType(ticket.Bettype, ticket.Betteam);

		payout = ['profit', 'liability'].includes(type)
			? calculateBackLayPayOut(Number(ticket.DisplayOdds), ticket.stake)
			: calculatePayOut(
				Number(ticket.DisplayOdds),
				ticket.stake,
				ticket.isMMR ? 6 : oddsType || 1,
				ticket.PriceType);
	}

	return payout;
}

const getPayoutByParlayTicket = (ticket: ParlayTicketModel): number => {
	return ticket.stake ? calculateParlayPayOut(ticket.Odds, ticket.stake) : 0;
}

const getPayoutType = (betType: string, betTeam: string): '' | 'profit' | 'liability' => {
	const isCricketBackLay = BackLayPayoutSetting.betType.includes(betType);
	const isProfit = BackLayPayoutSetting.profitBetTeam.includes(betTeam);
	const isLiability = BackLayPayoutSetting.liabiltyBetTeam.includes(betTeam);

	if (isCricketBackLay) {
		if (isProfit) return 'profit';
		if (isLiability) return 'liability';
	}

	return '';
}

const getTicketModel = (ticket: OddsTicketModel): TicketModel => {
	if (ticket.type === 'OU') {
		let line = '';
		if (ticket.Line) {
			line = ticket.Line;

			if ([28, 453, 477, 478].includes(ticket.bettype)) {
				if (ticket.betteam === 'x') {
					line = line.substring(line.length - 1, 1);
				}
			} else {
				line = line.replace(/\(|\)/gi, '');
			}
		}

		let matchId = ticket.gameid === 45 ? ticket.Parentid : ticket.Parentmatchid;
		matchId = matchId || ticket.Matchid;

		let Hdp1 = 0;
		let Hdp2 = 0;

		const floatLine = parseFloat(ticket.srcLine.toString());

		if (!isNaN(floatLine)) {
			Hdp1 = floatLine;
			if (floatLine < 0) {
				Hdp1 = 0;
				Hdp2 = Math.abs(floatLine);
			}
		} else {
			Hdp1 = ticket.Hdp1 || 0;
			Hdp2 = ticket.Hdp2 || 0;
		}

		const LeagueGroupId = ticket.LeagueGroupId ? Number(ticket.LeagueGroupId) : -1;

		if (ticket.bettype === 646) {
			Hdp2 = ticket.OtherHdp as number;
		}
		
		return {
			key: getOddsKey(ticket.type, ticket.oddsid, ticket.betteam),
			TicketType: ticket.type,
			SportType: ticket.gameid,
			Bettype: ticket.bettype.toString(),
			BettypeName: '',
			OddsID: ticket.oddsid,
			Betteam: ticket.betteam,
			PreDisplayOdds: '',
			DisplayOdds: ticket.odds,
			PreDisplayParlayOdds: '',
			DisplayParlayOdds: '',
			DisplayOddsPair: '',
			DisplayHDP: line,
			Matchid: matchId,
			HomeName: ticket.home,
			AwayName: ticket.away,
			LiveHomeScore: ticket.Hscore,
			LiveAwayScore: ticket.Ascore,
			//QuickBet: ticket.QuickBet, get from account model
			ChoiceValue: (ticket.ChoiceValue || '').toString(),
			isMMR: ticket.isMMR,
			MRPercentage: ticket.MRPercentage ? (ticket.MRPercentage as number).toString() : '',
			hasCashOut: !ticket.isMMR && ticket.isMatchHasCashOut,
			PriceType: ticket.pty,
			Hdp1: Hdp1,
			Hdp2: Hdp2,
			SrcOddsInfo: ticket.SrcOddsInfo,
			LeagueId: ticket.LeagueId,
			LeagueGroupId: LeagueGroupId,
			//default value
			stake: 0,
			payout: 0,
			minBet: 0,
			maxBet: 0,
			oldOdds: '',
			newOdds: '',
			AutoAcceptSec: '',
			AwayId: ticket.AwayId,
			Bet: '',
			BetHintMsg: '',
			BetID: '',
			BetRecommends: '',
			BetTimeConstraint: { EndTimestamp: 0 },
			BonusID: 0,
			BonusType: 0,
			Code: -99,
			DisplayTime: '',
			ErrorCode: 0,
			GameName: '',
			Guid: '',
			HasParlay: false,
			HomeId: ticket.HomeId,
			IsInPlay: false,
			IsLive: false,
			LeagueName: '',
			Line: line,
			LiveScore: false,
			MatchCode: '',
			Message: '',
			Nolan: false,
			OddsBeforeOddsBoost: '',
			OddsBoost: false,
			OddsInfo: '',
			OddsStatus: '',
			originalPrice: ticket.originalPrice,
			PDHyalpsiD: '',
			ParentMatchid: ticket.Parentmatchid,
			QuickBet: '1:100:10:1',
			RecommendType: 0,
			SeqNo: 0,
			SportName: '',
			SuggestStake: 0,
			Tenet: false,
			TicketTime: 0,
			UseBonus: 0,
			isLineChange: false,
			isOddsChange: false,
			isScoreChange: false,
			riaPsddOyalpsiD: '',
			sddOyalpsiD: '',
			sinfo: '',
			Maxbet: '',
			Minbet: '',
			CheckWaitingTicket: false,
			sync: false
		};
	} else {
		/** Outright */
		return {
			key: getOddsKey(ticket.type, ticket.oddsid, ticket.betteam),
			TicketType: ticket.type,
			SportType: ticket.gameid,
			BettypeName: '',
			OddsID: ticket.oddsid,
			PreDisplayOdds: '',
			DisplayOdds: ticket.odds,
			PreDisplayParlayOdds: '',
			DisplayParlayOdds: '',
			DisplayOddsPair: '',
			HomeName: ticket.home,
			stake: 0,
			QuickBet: '1:100:10:1',
			ChoiceValue: (ticket.ChoiceValue || '').toString(),
			Bettype: '10',
			Betteam: '',
			DisplayHDP: '',
			Matchid: ticket.oddsid,
			AwayName: '',
			LiveHomeScore: 0,
			LiveAwayScore: 0,
			isMMR: ticket.isMMR,
			MRPercentage: ticket.MRPercentage ? (ticket.MRPercentage as number).toString() : '',
			hasCashOut: !ticket.isMMR && ticket.isMatchHasCashOut,
			PriceType: ticket.pty,
			Hdp1: 0,
			Hdp2: 0,
			SrcOddsInfo: ticket.SrcOddsInfo,
			LeagueId: ticket.LeagueId,
			LeagueGroupId: -1,
			//default value
			payout: 0,
			minBet: 0,
			maxBet: 0,
			oldOdds: '',
			newOdds: '',
			AutoAcceptSec: '',
			AwayId: ticket.AwayId,
			Bet: '',
			BetHintMsg: '',
			BetID: '',
			BetRecommends: '',
			BetTimeConstraint: { EndTimestamp: 0 },
			BonusID: 0,
			BonusType: 0,
			Code: -99,
			DisplayTime: '',
			ErrorCode: 0,
			GameName: '',
			Guid: '',
			HasParlay: false,
			HomeId: ticket.HomeId,
			IsInPlay: false,
			IsLive: false,
			LeagueName: '',
			Line: '0',
			LiveScore: false,
			MatchCode: '',
			Message: '',
			Nolan: false,
			OddsBeforeOddsBoost: '',
			OddsBoost: false,
			OddsInfo: '',
			OddsStatus: '',
			originalPrice: ticket.originalPrice,
			PDHyalpsiD: '',
			ParentMatchid: 0,
			RecommendType: 0,
			SeqNo: 0,
			SportName: '',
			SuggestStake: 0,
			Tenet: false,
			TicketTime: 0,
			UseBonus: 0,
			isLineChange: false,
			isOddsChange: false,
			isScoreChange: false,
			riaPsddOyalpsiD: '',
			sddOyalpsiD: '',
			sinfo: '',
			Maxbet: '',
			Minbet: '',
			CheckWaitingTicket: false,
			sync: false
		};
	}
}

const createSmpTicketModel = (ticket: SmpTicketModel, oddsTicket: SmpOddsTicketModel): TicketModel => {
	const stake = ticket.Bet ? Number(ticket.Bet) : 0;

	return {
		key: getOddsKey('OT', ticket.MarketId),
		TicketType: oddsTicket.type,
		SportType: oddsTicket.gameId,
		Bettype: String(oddsTicket.betType),
		BettypeName: ticket.BettypeName,
		OddsID: ticket.MarketId,
		Betteam: '',
		PreDisplayOdds: '',
		DisplayOdds: ticket.DisplayOdds,
		PreDisplayParlayOdds: '',
		DisplayParlayOdds: '',
		DisplayOddsPair: '',
		DisplayHDP: '',
		Matchid: ticket.Matchid,
		HomeName: ticket.HomeName,
		AwayName: ticket.AwayName,
		LiveHomeScore: ticket.LiveHomeScore,
		LiveAwayScore: ticket.LiveAwayScore,
		ChoiceValue: '',
		specialChoices: ticket.ChoiceValue,
		isMMR: false,
		MRPercentage: '',
		hasCashOut: false,
		PriceType: oddsTicket.pty,
		Hdp1: 0,
		Hdp2: 0,
		LeagueId: oddsTicket.leagueId,
		LeagueGroupId: 0,
		// default
		stake,
		payout: calculatePayOut(Number(ticket.DisplayOdds), stake, oddsTicket.pty),
		minBet: Number(removeCommas(ticket.Minbet)),
		maxBet: Number(removeCommas(ticket.Maxbet)),
		oldOdds: '',
		newOdds: '',
		AutoAcceptSec: '',
		AwayId: 0,
		Bet: ticket.Bet,
		BetHintMsg: '',
		BetID: '',
		BetRecommends: '',
		BetTimeConstraint: { EndTimestamp: 0 },
		BonusID: 0,
		BonusType: 0,
		Code: ticket.Code,
		DisplayTime: '',
		ErrorCode: 0,
		GameName: '',
		Guid: ticket.Guid,
		HasParlay: false,
		HomeId: 0,
		IsInPlay: false,
		IsLive: ticket.IsLive,
		LeagueName: ticket.LeagueName,
		Line: '',
		LiveScore: ticket.IsLive,
		MatchCode: '',
		Message: ticket.Message,
		Nolan: false,
		OddsBeforeOddsBoost: '',
		OddsBoost: false,
		OddsInfo: '',
		OddsStatus: '',
		originalPrice: Number(ticket.DisplayOdds),
		PDHyalpsiD: '',
		ParentMatchid: 0,
		QuickBet: ticket.QuickBet,
		RecommendType: 0,
		SeqNo: 0,
		SportName: ticket.SportName,
		SuggestStake: 0,
		Tenet: false,
		TicketTime: 0,
		UseBonus: 0,
		isLineChange: false,
		isOddsChange: false,
		isScoreChange: false,
		riaPsddOyalpsiD: '',
		sddOyalpsiD: '',
		sinfo: '',
		Maxbet: '',
		Minbet: '',
		CheckWaitingTicket: false,
		sync: false
	};
};

const patchComboParlayTicketModel = (key: string, comboParlayTicket: ParlayTicketModel | undefined, dbComboParlayTicket: ParlayTicketModel, view: number): ParlayTicketModel => {
	const parlayName = dbComboParlayTicket?.Name;
	const newParlayName = view === ViewEnum.galaxy ? parlayName.substring(0, parlayName.indexOf(' (')) : parlayName;

	return {
		...dbComboParlayTicket,
		key,
		stake: dbComboParlayTicket?.Stake ? dbComboParlayTicket?.Stake : comboParlayTicket?.stake ?? 0,
		Name: newParlayName
	};
}

const patchTicketModel = (ticket: TicketModel, dbTicket: TicketModel): TicketModel => {
	const stake = ticket.sync ? ticket.stake : Number(dbTicket.Bet);

	if (dbTicket.TicketType === 'OT') {
		return {
			...ticket,
			Code: dbTicket.Code,
			Common: dbTicket.Common,
			Message: dbTicket.Message,
			isOddsChange: dbTicket.isOddsChange,
			TicketType: dbTicket.TicketType,
			OddsID: dbTicket.OddsID,
			PreDisplayOdds: ticket.DisplayOdds,
			DisplayOdds: dbTicket.DisplayOdds ? removeCommas(dbTicket.DisplayOdds) : ticket.DisplayOdds,
			PreDisplayParlayOdds: ticket.DisplayParlayOdds,
			// 會在betslip store的流程用parlay ticket的值回填
			DisplayParlayOdds: '',
			stake: stake,
			QuickBet: dbTicket.QuickBet,
			ChoiceValue: dbTicket.ChoiceValue,
			SportType: dbTicket.SportType,
			SportName: dbTicket.SportName ? dbTicket.SportName : '',
			sinfo: dbTicket.sinfo,
			Guid: dbTicket.Guid,
			TicketTime: dbTicket.TicketTime,
			RecommendType: dbTicket.RecommendType,
			minBet: Number(removeCommas(dbTicket.Minbet)),
			maxBet: Number(removeCommas(dbTicket.Maxbet)),
			sync: true,
			IsCashoutEnabled: dbTicket.IsCashoutEnabled ?? false
		};
	} else {
		return {
			...ticket,
			Code: dbTicket.Code,
			Common: dbTicket.Common,
			Message: dbTicket.Message,
			BettypeName: dbTicket.BettypeName,
			TicketType: dbTicket.TicketType,
			Bettype: dbTicket.Bettype,
			OddsID: dbTicket.OddsID,
			PreDisplayOdds: ticket.DisplayOdds,
			DisplayOdds: dbTicket.DisplayOdds ? removeCommas(dbTicket.DisplayOdds) : ticket.DisplayOdds,
			DisplayHDP: dbTicket.DisplayHDP,
			Line: dbTicket.DisplayHDP,
			LiveScore: dbTicket.LiveScore,
			LiveHomeScore: dbTicket.LiveHomeScore,
			LiveAwayScore: dbTicket.LiveAwayScore,
			Matchid: dbTicket.Matchid,
			Betteam: dbTicket.Betteam,
			stake: stake,
			QuickBet: dbTicket.QuickBet,
			ChoiceValue: dbTicket.ChoiceValue,
			HomeName: dbTicket.HomeName,
			AwayName: dbTicket.AwayName,
			SportType: dbTicket.SportType,
			isMMR: dbTicket.isMMR,
			MRPercentage: dbTicket.MRPercentage,
			SportName: dbTicket.SportName,
			IsInPlay: dbTicket.IsInPlay,
			IsLive: dbTicket.IsLive,
			OddsInfo: dbTicket.OddsInfo,
			SrcOddsInfo: dbTicket.SrcOddsInfo,
			Hdp1: dbTicket.Hdp1,
			Hdp2: dbTicket.Hdp2,
			BetID: dbTicket.BetID,
			BetTimeConstraint: dbTicket.BetTimeConstraint,
			BonusID: dbTicket.BonusID,
			BonusType: dbTicket.BonusType,
			sinfo: dbTicket.sinfo,
			RecommendType: dbTicket.RecommendType,
			GameName: dbTicket.GameName,
			Guid: dbTicket.Guid,
			HasParlay: dbTicket.HasParlay,
			TicketTime: dbTicket.TicketTime,
			minBet: Number(removeCommas(dbTicket.Minbet)),
			maxBet: Number(removeCommas(dbTicket.Maxbet)),
			LeagueGroupId: (dbTicket.LeagueGroupId || dbTicket.LeagueGroupId === 0) ? dbTicket.LeagueGroupId : -1,
			isLineChange: dbTicket.isLineChange,
			isOddsChange: dbTicket.isOddsChange,
			OddsBoost: dbTicket.OddsBoost,
			OddsBeforeOddsBoost: dbTicket.OddsBeforeOddsBoost,
			sync: true,
			IsCashoutEnabled: dbTicket.IsCashoutEnabled ?? false
		};
	}
}

const patchBetTicketModel = (ticket: TicketModel, dbTicket: BetTicketModel): TicketModel => {
	return {
		...ticket,
		ACCode: dbTicket.ACCode,
		Code: dbTicket.Code,
		Message: dbTicket.Message,
		PreDisplayOdds: ticket.DisplayOdds,
		DisplayOdds: dbTicket.DisplayOdds ? removeCommas(dbTicket.DisplayOdds) : ticket.DisplayOdds,
		DisplayHDP: dbTicket.DisplayHDP,
		Line: dbTicket.DisplayHDP,
		LiveHomeScore: dbTicket.LiveHomeScore,
		LiveAwayScore: dbTicket.LiveAwayScore,
		IsInPlay: dbTicket.IsInPlay,
		IsLive: dbTicket.IsLive !== undefined ? dbTicket.IsLive : ticket.IsLive, // betting res 可能無此欄位, 此時沿用原始資料
		SrcOddsInfo: dbTicket.SrcOddsInfo,
		Hdp1: dbTicket.Hdp1,
		Hdp2: dbTicket.Hdp2,
		sinfo: dbTicket.sinfo,
		LeagueGroupId: (dbTicket.LeagueGroupId || dbTicket.LeagueGroupId === 0) ? dbTicket.LeagueGroupId : -1,
		CheckWaitingTicket: dbTicket.CheckWaitingTicket,
		Common: dbTicket.Common,
		ErrorCode: dbTicket.ErrorCode,
		TransId: dbTicket.TransId_Cash,
		isLineChange: dbTicket.isLineChange,
		isOddsChange: dbTicket.isOddsChange,
		OddsBeforeOddsBoost: dbTicket.OddsBeforeOddsBoost,
		RewardModel: dbTicket.UnderOverTicket?.RewardModel,
		sync: true
	};
}

const addCanBetMsg = (msgArray: BetMsgModel[], code: number, msg?: string, ticketKey?: string, skipShowToast: boolean = true) => {
	if (BETTING_CODE_MAP.has(code)) {
		const setting = BETTING_CODE_MAP.get(code) as BettingCodeMap;

		if (skipShowToast && setting.showToast) return;

		msgArray.push({
			ticketKey: ticketKey ?? '',
			code,
			msg: msg || setting.msg || '',
			msgResourceKey: setting.msgResourceKey,
			canBet: setting.canBet,
			cssStatus: setting.cssStatus
		});
	}
}

/** kafka - mars parlay bet (when call place bet api) */
const setMarsParlayBetKafka = (view: ViewEnum, isParlayMode: boolean) => {
	if (view !== ViewEnum.mars) return;

	const kafkaData = {
		note: KafkaNote.MarsV1,
		type: 14,
		action: isParlayMode ? 2 : 1
	};
	setKafkaNormal(kafkaData);
}

/** kafka - mars bet button */
const setMarsBetKafka = (view: ViewEnum, isParlayMode: boolean) => {
	if (view !== ViewEnum.mars) return;

	const kafkaData = {
		note: KafkaNote.MarsV1,
		type: isParlayMode ? 11 : 10,
		action: isParlayMode ? 6 : 9
	}
	setKafkaNormal(kafkaData);
}

export const BetslipService = {
	getPayoutByTicket,
	getPayoutByParlayTicket,
	getPayoutType,
	getTicketModel,
	createSmpTicketModel,
	patchTicketModel,
	patchBetTicketModel,
	patchComboParlayTicketModel,
	addCanBetMsg,
	setMarsParlayBetKafka,
	setMarsBetKafka
}
