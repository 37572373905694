import { ProductItem } from '@/types/lottery-setting';

const kenoSettings: ProductItem = {
	labelKey: 'lbl_Lottery',
	subItems: [
		{
			labelKey: 'lbl_Keno',
			permissionKey: 'Keno',
			imagePath: 'lottery202',
			route: '202,0,8'
		},
		{
			labelKey: 'sport237',
			permissionKey: 'MaxGame',
			imagePath: 'lottery237',
			route: '237,0,8'
		},
		{
			labelKey: 'lbl_KenoLottery',
			permissionKey: 'KL',
			imagePath: 'lottery220',
			route: '220,0,8'
		},
		{
			labelKey: 'lbl_TableGame',
			permissionKey: 'TG',
			imagePath: 'lottery222',
			route: '222,0,8'
		}
	]
};

const sportLotterySettings: ProductItem = {
	labelKey: 'sport169',
	subItems: [
		{
			labelKey: 'lbl_SoccerLottery',
			permissionKey: 'CanSeeSportsLottery',
			imagePath: 'lottery169',
			route: '169,3612'
		},
		{
			labelKey: 'lbl_NBALottery',
			permissionKey: 'NBALotteryStatus',
			imagePath: 'lottery169-3',
			route: '169,3614'
		},
		{
			labelKey: 'lbl_TennisLottery',
			permissionKey: 'TennisLotteryStatus',
			imagePath: 'lottery169-4',
			route: '169,3615'
		},
		{
			labelKey: 'lbl_CricketLottery',
			permissionKey: 'CricketLotteryStatus',
			imagePath: 'lottery169-5',
			route: '169,3616'
		},
		{
			labelKey: 'lbl_eSportsLottery',
			permissionKey: 'ESportsLotteryStatus',
			imagePath: 'lottery169-2',
			route: '169,3613'
		}
	]
};

export {
	kenoSettings,
	sportLotterySettings
};