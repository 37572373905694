interface betTypeTutorialSetting {
	title: string;
	description?: string; 
}
export const betTypeTutorialSettings: Record<number, betTypeTutorialSetting> = {
	1: { // FT HDP
		title: 'lbl_NPredictWinningTeams',
		description: 'lbl_NBettingGuideHDP',
	},
	2: { // FT OE
		title: 'lbl_NFullTimeOE',
	},
	3: { // FT OU
		title: 'lbl_NPredictTotalScore',
		description: 'lbl_NBettingGuideOU',
	},
	5: { // 1x2
		title: 'lbl_NPredictWinning',
		description: 'lbl_NBettingGuideW',
	},
	20: { // Moneyline
		title: 'lbl_NPredictWinning',
		description: 'lbl_NBettingGuideWinlose',
	},
	153: { // Game Hdp
		title: 'lbl_NpredictFavorable',
	},
	501: { // Match Winner
		title: 'lbl_NPredictWinning',
		description: 'lbl_NBettingGuideWinlose',
	},
	701: { // Match Handicap
		title: 'lbl_NPredictWinningTeams',
		description: 'lbl_NBettingGuideHDP',
	},
	704: { // Match Points Handicap
		title: 'lbl_NPredictWinningTeams',
		description: 'lbl_NBettingGuideHDP',
	},
	705: { // // Match Points Over/Under
		title: 'lbl_NPredictTotalScore',
		description: 'lbl_NBettingGuideOU',
	},
	9401: { // Toss Winner
		title: 'lbl_NPredictTeamWinsCoin',
	},
};
