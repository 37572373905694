import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import type { BookingData } from '@/types/booked-bet';
import type { SmpTransactionTicket } from '@nf/types/ticket';
import { not } from '@nf/utils-common/fpw/libs';
import { BetslipService } from '@/services/betslip.service';
import { smpService } from '@/features/single-match-parlay/services/smp.service';
import {
	BetslipType,
	ParlayTicketModel,
	ParlayTicketsModel,
	TicketModel,
	BetTicketModel,
	BetParlayTicketsModel,
	OddsTicketModel,
	BetMsgModel,
	CanBetResultModel,
	BETTING_CODE,
	VoucherType,
	BetAction,
	BetSourceType,
	selectTicketMaxLangth,
	BetslipTab,
	ChildTabType
} from '@/hooks/query';
import { ViewEnum } from '@/types';
import { BetDefaultStakeType } from '@/services/account.service';
import { getDisplayOdds, getOriginOdds, isPairOdds, revertParlayOddsToOriginOdds } from '@/utils/odds-utils';

const CONFIRM_BET_SIMPLE_MODE = 'isConfirmBetSimpleMode';

export interface BetslipStore {
	betslipKey: string;
	betslipType: BetslipType;
	sourceStorage: Map<BetSourceType, Map<ChildTabType, Array<string>>>;
	multiStorage: Map<string, Array<TicketModel>>;
	parlayStorage: Map<string, ParlayTicketsModel>;
	canBetResult: CanBetResultModel;
	tooltipMap: Map<string, Record<string, { value?: string | number }>>;
	currentMultiTickets: Array<TicketModel>;
	currentParlayTicketModel: ParlayTicketsModel | null;
	isParlayMode: boolean;
	bookingData: BookingData | null;
	isBookingBet: boolean;
	isConfirmBet: boolean;
	isQuickBetSettingModalOpen: boolean;
	isQuickbetExpand: boolean;
	isExpand: boolean;
	isQuickBet: boolean;
	isConfirmBetSimpleMode: boolean;
	selectAllOddsKey: string;
	focusOddsKey: string;
	multiStakePerBet: number;
	parlayStakePerBet: number;
	quickBetTicket: TicketModel | null;
	useVoucher: VoucherType;
	useFreeRiskFreeVoucher: boolean;
	useSabaCoin: boolean;
	betAction: number;
	enableQuickBet: boolean;
	betslipTab: BetslipTab;
	betTimer: number;
	clearTooltip: (key?: string) => void;
	onOddsButtonClick: (model: OddsTicketModel, oddsKey: string, childTab: ChildTabType, sourceType: BetSourceType, isParlayMode: boolean) => string | undefined;
	onSmpTrigger: (ticket: TicketModel) => string | undefined;
	setIsParlayMode: (value: boolean) => void;
	setIsBookingBet: (value: boolean) => void;
	toggleBookingBet: () => void;
	setBookingData: (data: BookingData) => void,
	setIsConfirmBet: (value: boolean) => void;
	setBetslipKey: (key: string) => void;
	getBetslipType: () => BetslipType;
	checkCanBet: (hasClickBet: boolean, doubleBetMsg?: string) => void;
	getMsgByTickets: () => BetMsgModel[];
	getTotalBet: (isIndependentMode: boolean, isConfirmBet?: boolean) => number;
	getTotalStake: (isIndependentMode: boolean, isConfirmBet?: boolean) => number;
	getTotalPayout: (isIndependentMode: boolean) => number;
	getTicketByKey: (key: string, isParlay: boolean) => TicketModel | ParlayTicketModel | undefined;
	updateTicket: (value: TicketModel | ParlayTicketModel, isParlay: boolean, oddsType?: number) => void;
	updateMultiTicket: (value: TicketModel, oddsType: number) => void;
	updateParlayItemTicket: (value: TicketModel) => void;
	updateParlayTicket: (value: ParlayTicketModel) => void;
	removeTicket: (oddsKey: string | undefined, childTab: ChildTabType, sourceType?: BetSourceType) => void;
	removeAllTicket: () => void;
	syncTicket: (multiTickets: Array<TicketModel>, parlayData: ParlayTicketsModel, oddsType: number, view: number) => void;
	syncBetTicket: (multiTickets: Array<BetTicketModel>, parlayData: BetParlayTicketsModel, oddsType: number, view: number) => void;
	syncSmpTicket: (betTicket: SmpTransactionTicket) => void;
	setIsExpand: (value: boolean) => void,
	toggleIsExpand: () => void;
	setIsQuickBet: (value: boolean) => void;
	setIsQuickBetSettingModalOpen: (value: boolean) => void;
	toggleIsQuickBet: () => void;
	toggleIsQuickBetExpand: () => void;
	changeLastBet: (value: number) => void;
	setSelectAllOddsKey: (value: string) => void;
	setFocusOddsKey: (value: string) => void;
	setMultiStakePerBet: (value: number) => void;
	setParlayStakePerBet: (value: number) => void;
	checkStake: (isIndependentMode: boolean, oddsType: number) => boolean;
	betAgain: (oddsType: number, defaultStakeId: string, InpbetStake: string, view: number) => void;
	setQuickBetTicket: (model: OddsTicketModel) => string | undefined;
	clearQuickBetTicket: () => void;
	initConfirmBetSimpleMode: (view: number) => void;
	toggleIsConfirmBetSimpleMode: () => void;
	setVoucher: (voucherType: VoucherType) => void;
	setUseFreeRiskFreeVocher: (value: boolean) => void;
	setUseSabaCoin: (value: boolean) => void;
	setBetAction: (betAction: number) => void;
	setSourceStorage: (sourceStorage: Map<BetSourceType, Map<ChildTabType, Array<string>>>) => void;
	setEnableQuickBet: (value: boolean) => void;
	setBetslipTab: (value: BetslipTab) => void;
	setBetTimer: (value: number) => void;
}

export const useBetslipStore = create<BetslipStore, [['zustand/immer', never]]>(
	immer<BetslipStore>((set, get) => ({
		betslipKey: 'all', // 用於區分 Sports 及 VirtualSports, 待確認 VS 是否要再細分暫存
		betslipType: 'empty',
		sourceStorage: new Map<BetSourceType, Map<ChildTabType, Array<string>>>(),
		multiStorage: new Map<string, Array<TicketModel>>(),
		parlayStorage: new Map<string, ParlayTicketsModel>(),
		currentMultiTickets: new Array<TicketModel>(),
		currentParlayTicketModel: null,
		canBetResult: { innerMsg: [], outerMsg: [], canBet: true, confirmBetMsg: [] },
		tooltipMap: new Map<string, Record<string, { value?: string | number }>>(),
		bookingData: null,
		isBookingBet: false,
		isExpand: false,
		isQuickBet: false,
		isQuickbetExpand: false,
		isParlayMode: false,
		isConfirmBet: false,
		isQuickBetSettingModalOpen: false,
		focusOddsKey: '',
		selectAllOddsKey: '',
		multiStakePerBet: 0,
		parlayStakePerBet: 0,
		quickBetTicket: null,
		isConfirmBetSimpleMode: false,
		useVoucher: 'None',
		useFreeRiskFreeVoucher: false,
		useSabaCoin: false,
		betAction: 0,
		enableQuickBet: true,
		betslipTab: 'single',
		betTimer: 0,
		clearTooltip: (key?: string) => {
			const tooltipMap = get().tooltipMap;
			if (key) {
				tooltipMap.delete(key);

				set({ tooltipMap });
			} else {
				tooltipMap.clear();

				set({ tooltipMap });
			}
		},
		syncTicket: (multiTickets: Array<TicketModel>, parlayData: ParlayTicketsModel, oddsType: number, view: number) => {

			// multi bet
			const tickets = get().currentMultiTickets;
			const useSabaCoin = get().useSabaCoin;

			const newTickets = tickets.map((ticket, index) => {
				const dbTicket = multiTickets ? multiTickets[index] : null;
				const newTicket = dbTicket && dbTicket.OddsID == ticket.OddsID ? BetslipService.patchTicketModel(ticket, dbTicket) : ticket;

				const dbParlayTicket = parlayData?.Tickets[index];

				if (dbParlayTicket) {
					// 將db parlay ticket的值塞回singleTicket上
					newTicket.PreDisplayParlayOdds = newTicket.DisplayParlayOdds;

					if (dbParlayTicket.HasParlay) {
						// 如果該ticket支援parlay的時候直接使用ticket上的值
						newTicket.DisplayParlayOdds = dbParlayTicket.DisplayOdds;
					} else {
						// 沒有的話用user odds 轉decimal odds(不支援parlay的話肯定不能下, 僅單純用於顯示
						const isPair = isPairOdds(Number(newTicket.Bettype));

						let parlayOdds = newTicket.DisplayOdds;
						if (isPair) {
							const originOdds = getOriginOdds(Number(parlayOdds), oddsType);
							parlayOdds = getDisplayOdds(originOdds, 1, ticket.PriceType, Number(ticket.Bettype));;
						}

						newTicket.DisplayParlayOdds = parlayOdds;
					}
				}

				newTicket.payout = BetslipService.getPayoutByTicket(newTicket, oddsType)
				if (useSabaCoin) {
					newTicket.CustomCurrencyName = 'SBC';
				} else if (newTicket.CustomCurrencyName == 'SBC') {
					newTicket.CustomCurrencyName = undefined;
				}
				return newTicket;
			});

			// parlay
			let newParlayData = null;
			let newComboParlayTickets: Array<ParlayTicketModel> = [];

			if (parlayData?.comboData?.length) {
				const parlayTicket = get().currentParlayTicketModel;

				newComboParlayTickets = parlayData.comboData.map(dbComboParlayTicket => {
					const key = `${parlayData.comboData.length}-${dbComboParlayTicket.Type}`;

					const comboParlayTicket = (parlayTicket?.comboData.length === parlayData?.comboData.length)
						? parlayTicket.comboData.find(comboParlayTicket => comboParlayTicket.key === key)
						: undefined;

					const newTicket = BetslipService.patchComboParlayTicketModel(key, comboParlayTicket, dbComboParlayTicket, view);
					newTicket.payout = BetslipService.getPayoutByParlayTicket(newTicket);
					return newTicket;
				});
			}
			newParlayData = {
				...parlayData,
				comboData: newComboParlayTickets
			};

			set({ currentMultiTickets: newTickets, currentParlayTicketModel: newParlayData });

			get().checkCanBet(false);
		},
		syncBetTicket: (multiTickets: Array<BetTicketModel>, parlayData: BetParlayTicketsModel, oddsType: number, view: number) => {
			// QuickBet 模式: 若背景仍有在 Betslip 送出下注的票, 收到 api res 後應直接清掉
			if (get().isQuickBet) {
				get().removeAllTicket();
				return;
			}

			// multi bet
			const tickets = get().currentMultiTickets;

			const newTickets = tickets.map(ticket => {
				const dbTicket = multiTickets?.find(multiTicket => multiTicket.Key == ticket.key);
				const newTicket = dbTicket ? BetslipService.patchBetTicketModel(ticket, dbTicket) : ticket;
				newTicket.payout = BetslipService.getPayoutByTicket(newTicket, oddsType);
				return newTicket;
			});

			// parlay
			const parlayTicket = get().currentParlayTicketModel;

			let newParlayData = null;
			let newComboParlayTickets: Array<ParlayTicketModel> = [];
			const newParlayTickets = parlayTicket?.Tickets?.map((ticket, index) => {
				const dbParlayTicket = parlayData?.ItemList[index];
				if (dbParlayTicket) {
					const dbSingleTicket = multiTickets?.find(multiTicket => multiTicket.sinfo === dbParlayTicket.sinfo);
					// parlay ItemList ticket key就是matchId
					const singleTicket = newTickets.find(ticket => ticket.Matchid === Number(dbParlayTicket.Key));


					if (singleTicket) {
						const bettype = Number(singleTicket.Bettype);
						// WEB-12051 如果用戶只下parlay沒有下對應的single ticket
						// 且下注沒有成功, 新增流程將parlay的資料加回single
						if (!dbSingleTicket && ![0, 1].includes(parlayData.Code)) {
							const originOdds = revertParlayOddsToOriginOdds(Number(dbParlayTicket.DisplayOdds), bettype);
							const odds = getDisplayOdds(originOdds, oddsType, singleTicket.PriceType, bettype);

							let code = dbParlayTicket.Code;

							if (dbParlayTicket.Code === 15) {
								singleTicket.isOddsChange = true;
								singleTicket.PreDisplayOdds = singleTicket.DisplayOdds;
								// code = -98;
							} else if (dbParlayTicket.Code === -99) {
								code = 0
							}

							singleTicket.DisplayOdds = odds;
							singleTicket.Code = code;
							singleTicket.Message = dbParlayTicket.Message;
						}

						// 將db parlay ticket的值塞回singleTicket上
						singleTicket.PreDisplayParlayOdds = singleTicket.DisplayParlayOdds;

						if (dbParlayTicket.HasParlay) {
							// 如果該ticket支援parlay的時候直接使用ticket上的值
							singleTicket.DisplayParlayOdds = dbParlayTicket.DisplayOdds;
						} else {
							// 沒有的話用user odds 轉decimal odds(不支援parlay的話肯定不能下, 僅單純用於顯示
							const isPair = isPairOdds(bettype);

							let parlayOdds = singleTicket.DisplayOdds;
							if (isPair) {
								const originOdds = getOriginOdds(Number(parlayOdds), oddsType);
								parlayOdds = getDisplayOdds(originOdds, 1, ticket.PriceType, Number(ticket.Bettype));;
							}

							singleTicket.DisplayParlayOdds = parlayOdds;
						}
					}

					return BetslipService.patchBetTicketModel(ticket, dbParlayTicket);
				}
				return ticket;
			}) || [];

			const useSabaCoin = get().useSabaCoin;

			if (parlayData?.comboData?.length) {
				newComboParlayTickets = parlayData.comboData.map(dbComboParlayTicket => {
					const key = `${parlayData.comboData.length}-${dbComboParlayTicket.Type}`;

					const comboParlayTicket = (parlayTicket?.comboData.length === parlayData?.comboData.length)
						? parlayTicket.comboData.find(comboParlayTicket => comboParlayTicket.key === key)
						: undefined;

					const newTicket = BetslipService.patchComboParlayTicketModel(key, comboParlayTicket, dbComboParlayTicket, view);
					newTicket.payout = BetslipService.getPayoutByParlayTicket(newTicket);

					if (useSabaCoin) {
						newTicket.CustomCurrencyName = 'SBC';
					} else if (newTicket.CustomCurrencyName == 'SBC') {
						newTicket.CustomCurrencyName = undefined;
					}
					return newTicket;
				});
			}

			newParlayData = {
				...parlayData,
				Tickets: newParlayTickets,
				comboData: newComboParlayTickets
			};

			set({ currentMultiTickets: newTickets, currentParlayTicketModel: newParlayData });

			// 因 Betting Code 65 回傳資料極少, 無法與 ticket mapping, 故額外取得
			const doubleBetMsg = multiTickets?.find(multiTicket => multiTicket.Code === 65)?.Message;
			get().checkCanBet(true, doubleBetMsg);
		},
		syncSmpTicket: (betTicket: SmpTransactionTicket) => {
			const tickets = get().currentMultiTickets;
			const smpTicket = tickets[0];
			const newTicket: TicketModel = {
				...smpTicket,
				ACCode: betTicket.ACCode,
				Code: betTicket.Code,
				Common: betTicket.Common,
				TransId: betTicket.TransId_Cash,
				ErrorCode: betTicket.ErrorCode,
			};
			set({ currentMultiTickets: [newTicket] });
		},
		setIsParlayMode: (value: boolean) => {
			if (value && get().isQuickBet) {
				get().toggleIsQuickBet();
			}

			set({
				isParlayMode: value,
				betslipTab: value ? 'parlay' : 'single'
			});

			const betslipType = get().getBetslipType();
			set({ betslipType });
		},
		setIsBookingBet: (value: boolean) => set({ isBookingBet: value }),
		toggleBookingBet: () => set(state => ({ isBookingBet: !state.isBookingBet })),
		setBookingData: (data: BookingData) => set({ bookingData: data }),
		setIsConfirmBet: (value: boolean) => {
			set({ isConfirmBet: value });
		},
		setBetslipKey: (key: string) => { // 切換 Sports 及 VirtualSports
			const currentKey = get().betslipKey;

			const multiStorage = {
				...get().multiStorage,
				[currentKey]: get().currentMultiTickets
			};
			const parlayStorage = {
				...get().parlayStorage,
				[currentKey]: get().currentParlayTicketModel
			};

			const currentMultiTicketModel = multiStorage.get(key) ?? new Array<TicketModel>();
			const currentParlayTicketModel = parlayStorage.get(key) ?? null;

			set({
				betslipKey: key,
				multiStorage,
				parlayStorage,
				currentMultiTickets: currentMultiTicketModel,
				currentParlayTicketModel
			});
		},
		getBetslipType: () => { // 取得 UI 元件類型
			const length = get().currentMultiTickets.length;
			let type: BetslipType;

			if (!get().isParlayMode && get().isQuickBet) return 'quickbet';

			switch (length) {
				case 0:
					type = 'empty';
					break;
				case 1:
					type = 'single';
					break;
				default:
					type = 'multi';
					break;
			}

			return type;
		},
		checkCanBet: (hasClickBet: boolean, doubleBetMsg?: string) => {
			// 內層提示訊息
			const innerMsg: BetMsgModel[] = get().getMsgByTickets();

			// 外層提示訊息
			const outerMsg: BetMsgModel[] = [];

			// same match
			const multiTickets = get().currentMultiTickets;
			for (const ticket of multiTickets) {
				const sameMatchTicketCount = multiTickets.filter(t => t.Matchid === ticket.Matchid || t.ParentMatchid === ticket.Matchid).length;
				if (sameMatchTicketCount > 1) {
					BetslipService.addCanBetMsg(outerMsg, BETTING_CODE.SameMatch);
					break;
				}
			}

			// parlay 外層 error (parlay min odds 不顯示)
			const parlayData = get().currentParlayTicketModel;
			if (parlayData) {
				const parlayErrorCode = parlayData.Code;
				const parlayErrorMessage = parlayData.Message;

				if (parlayErrorCode === BETTING_CODE.DisableBetProblem) {
					BetslipService.addCanBetMsg(outerMsg, parlayErrorCode, parlayErrorMessage);
				}

				const parlayCommonErrorCode = parlayData?.Common?.ErrorCode;
				const parlayCommonErrorMsg = parlayData?.Message ?? parlayData?.Common?.ErrorMsg;

				if (parlayCommonErrorCode) {
					switch (parlayCommonErrorCode) {
						case BETTING_CODE.ParlayMinOdds:
						case BETTING_CODE.ParlayComboByPool:
							// do nothing
							break;
						case BETTING_CODE.BlockDoubleBet:
							doubleBetMsg = parlayCommonErrorMsg;
							break;
						default:
							BetslipService.addCanBetMsg(outerMsg, parlayCommonErrorCode, parlayCommonErrorMsg);
							break;
					}
				}
			}

			// 是否全部不可下注
			const allTicketCount = multiTickets.length + (parlayData?.comboData.length ?? 0);
			const canNotBetCount = innerMsg.filter(msg => !msg.canBet).length;
			const canBet = allTicketCount !== canNotBetCount;

			// ConfirmBet 提示訊息
			const isConfirmBet = get().isConfirmBet;
			const confirmBetMsg: BetMsgModel[] = [];

			if (hasClickBet) {
				if (doubleBetMsg) {
					document.dispatchEvent(new CustomEvent('show-toast-msg', {
						detail: { msg: doubleBetMsg }
					}));
				}

				// 下注後的提示訊息 ($$ 相關)
				const moneyRelatedCode = [BETTING_CODE.CreditProblem, BETTING_CODE.OverMaxStakePerMatch, BETTING_CODE.OverMaxPayoutPerMatch];
				moneyRelatedCode.forEach(code => {
					const errorTicket = multiTickets.find(ticket => ticket.Code === code);
					let errorMsg;

					if (errorTicket && errorTicket.Message) { // multi has error
						errorMsg = errorTicket.Message;
					} else if (parlayData && parlayData.Code === code) { // parlay has error
						errorMsg = parlayData.Message;
					}

					if (errorMsg) {
						if (isConfirmBet) {
							BetslipService.addCanBetMsg(confirmBetMsg, code, errorMsg, '', false);
						} else {
							document.dispatchEvent(new CustomEvent('show-toast-msg', {
								detail: { msg: errorMsg }
							}));
						}
					}
				});
			}

			const canBetResult = { innerMsg, outerMsg, canBet, confirmBetMsg };
			set({ canBetResult });
		},
		getMsgByTickets: () => {
			const msgArray: BetMsgModel[] = [];

			const { currentMultiTickets: multiTickets, betslipTab } = get();
			const checkSupportParlay = multiTickets.length > 1;
			multiTickets.map(ticket => {
				// # 1.1 odds change 且正在parlay tab, 需僅顯示Odds change error
				if (ticket.Code === 15 && betslipTab === 'parlay') {
					BetslipService.addCanBetMsg(msgArray, -98, '', ticket.key);
				} else if (ticket.Code !== 0) {
					// #1.2 處理 betting error
					BetslipService.addCanBetMsg(msgArray, ticket.Code, ticket.Message ?? '', ticket.key);
				}
				// #2 再處理是否支援串關
				if (checkSupportParlay && !ticket.HasParlay) {
					BetslipService.addCanBetMsg(msgArray, BETTING_CODE.NotSupportParlay, '', ticket.key);
				}
			});

			const parlayData = get().currentParlayTicketModel;
			if (parlayData) {
				// parlay 內層 error
				const parlayTickets = parlayData?.comboData;

				parlayTickets.map(ticket => {
					if (ticket.ErrorCode === 0) return;	// success
					BetslipService.addCanBetMsg(msgArray, ticket.ErrorCode, ticket.ErrorMsg, ticket.key);
				});
			}

			return msgArray;
		},
		onOddsButtonClick: (model: OddsTicketModel, oddsKey: string, childTab: ChildTabType, sourceType: BetSourceType, isParlayMode: boolean) => {
			const isConfirmBet = get().isConfirmBet;

			if (isConfirmBet) {
				get().removeAllTicket();
				set({
					isConfirmBet: false,
					isBookingBet: false,
				});
			}

			const ticket = BetslipService.getTicketModel(model);

			const multiTickets = get().currentMultiTickets;

			// default mode: parent match / child match 視為不同場, 依各自 matchId 判定是否需顯示 same match 提示
			// parlay mode: parent match / child match 視為同場需互斥, 若有則以 parentMatchId 為判定標準
			const matchId = get().isParlayMode && (ticket.SportType === 1 || ticket.SportType === 50) && ticket.ParentMatchid ? ticket.ParentMatchid : ticket.Matchid;
			const arrayWithoutCurrentMatch = multiTickets.filter(ticket => ticket.ParentMatchid ? ticket.ParentMatchid !== matchId : ticket.Matchid !== matchId);
			const arrayWithCurrentMatch = multiTickets.filter(ticket => ticket.ParentMatchid ? ticket.ParentMatchid === matchId : ticket.Matchid === matchId);
			const isParlaySameMatch = isParlayMode && arrayWithCurrentMatch.length > 0;

			// 上限20張
			if (!isParlaySameMatch && multiTickets.length >= selectTicketMaxLangth) {
				const canBetResult = get().canBetResult;
				const outerMsg = canBetResult.outerMsg;

				const key = BETTING_CODE.ExceedLimitSelection;
				const isMsgExist = outerMsg.find(msg => msg.code === key);

				if (!isMsgExist) {
					BetslipService.addCanBetMsg(outerMsg, key);
					set({ canBetResult });
				}

				return undefined;
			}

			let newTickets;
			if (isParlaySameMatch) { // 在 parlay 模式會後踢前
				newTickets = [
					...arrayWithoutCurrentMatch,
					ticket
				];
			} else {
				// 相同 matchId 的票要篩選出來一起移到 betslip 最下面
				// If the ticket is from SMP, remove it
				newTickets = [
					...arrayWithoutCurrentMatch,
					...arrayWithCurrentMatch,
					ticket
				].filter(not(smpService.isSmpTicket));
			}

			set({ currentMultiTickets: newTickets });

			const betslipType = get().getBetslipType();
			set({
				betslipType,
				useSabaCoin: false,
			});

			if (sourceType) {
				const addTicketStorage = get().sourceStorage.get(sourceType) ?? new Map<ChildTabType, Array<string>>();
				const childTabStorage = addTicketStorage.get(childTab) ?? [];
				childTabStorage.push(oddsKey);
				addTicketStorage.set(childTab, childTabStorage);
				get().setSourceStorage(new Map<BetSourceType, Map<ChildTabType, Array<string>>>().set(sourceType, addTicketStorage ?? []));
			}

			return ticket.key;
		},
		onSmpTrigger: (ticket: TicketModel) => {
			set({
				isQuickBet: false,
				betslipType: 'single',
				currentMultiTickets: [ticket],
				currentParlayTicketModel: null,
			});
			return '';
		},
		getTotalBet: (isIndependentMode: boolean, isConfirmBet?: boolean) => {
			const betslipTab = get().betslipTab;
			const canCountSingle = !isIndependentMode || betslipTab === 'single';
			const canCountParlay = !isIndependentMode || betslipTab === 'parlay';

			let totalBet = 0;

			if (canCountSingle) {
				const multiTickets = get().currentMultiTickets;
				totalBet += multiTickets.filter(ticket => {
					const canBetResult = get().canBetResult;
					return ticket.stake > 0 && !canBetResult.innerMsg.some(msg => !isConfirmBet && msg.ticketKey == ticket.key && !msg.canBet);
				}).length;
			}

			if (canCountParlay) {
				const parlayTickets = get().currentParlayTicketModel?.comboData;
				parlayTickets?.filter(ticket => {
					const canBetResult = get().canBetResult;
					const canBet = (!isConfirmBet && !canBetResult.innerMsg.some(msg => msg.ticketKey == ticket.key && !msg.canBet)) || (isConfirmBet && ticket.DoPlaceBet);
					return ticket.stake > 0 && canBet;
				})?.map(ticket => totalBet += ticket.BetCount);
			}

			return totalBet;
		},
		getTotalStake: (isIndependentMode: boolean, isConfirmBet?: boolean) => {
			const betslipTab = get().betslipTab;
			const canCountSingle = !isIndependentMode || betslipTab === 'single';
			const canCountParlay = !isIndependentMode || betslipTab === 'parlay';

			let totalStake = 0;

			if (canCountSingle) {
				const multiTickets = get().currentMultiTickets;
				multiTickets.filter(ticket => {
					const canBetResult = get().canBetResult;
					return !canBetResult.innerMsg.some(msg => !isConfirmBet && msg.ticketKey == ticket.key && !msg.canBet);
				}).map(ticket => totalStake += ticket.stake || 0);
			}

			if (canCountParlay) {
				const parlayTickets = get().currentParlayTicketModel?.comboData;
				parlayTickets?.filter(ticket => {
					const canBetResult = get().canBetResult;
					return !canBetResult.innerMsg.some(msg => !isConfirmBet && msg.ticketKey == ticket.key && !msg.canBet);
				})?.map(ticket => totalStake += ticket.stake * ticket.BetCount);
			}

			return totalStake;
		},
		getTotalPayout: (isIndependentMode: boolean) => {
			const betslipTab = get().betslipTab;
			const canCountSingle = !isIndependentMode || betslipTab === 'single';
			const canCountParlay = !isIndependentMode || betslipTab === 'parlay';

			let totalPayout = 0;

			const multiTickets = get().currentMultiTickets;
			const parlayTickets = get().currentParlayTicketModel?.comboData;

			if (canCountSingle) {
				multiTickets.filter(ticket => {
					const canBetResult = get().canBetResult;
					return !canBetResult.innerMsg.some(msg => msg.ticketKey == ticket.key && !msg.canBet);
				}).map(ticket => {
					if (ticket.payout) {
						totalPayout += ticket.payout;
					}
				});
			}

			if (canCountParlay) {
				parlayTickets?.filter(ticket => {
					const canBetResult = get().canBetResult;
					return !canBetResult.innerMsg.some(msg => msg.ticketKey == ticket.key && !msg.canBet);
				})?.map(ticket => {
					if (ticket.payout) {
						totalPayout += ticket.payout;
					}
				});
			}

			return totalPayout;
		},
		getTicketByKey: (key: string, isParlay: boolean) => {
			if (!isParlay) {
				const tickets = get().currentMultiTickets;
				return tickets.find(ticket => ticket.key === key);
			} else {
				const parlayTicketModel = get().currentParlayTicketModel;
				if (parlayTicketModel) {
					const tickets = parlayTicketModel.comboData;
					return tickets.find(ticket => ticket.key === key);
				}
			}
		},
		updateTicket: (value: TicketModel | ParlayTicketModel, isParlay: boolean, oddsType?: number) => {
			if (!isParlay) {
				get().updateMultiTicket(value as TicketModel, oddsType ?? 0);
			} else {
				get().updateParlayTicket(value as ParlayTicketModel);
			}
		},
		updateMultiTicket: (value: TicketModel, oddsType: number) => {
			value.payout = BetslipService.getPayoutByTicket(value, oddsType);

			const tickets = get().currentMultiTickets;
			const index = tickets.findIndex(ticket => ticket.key === value.key);
			const newTickets = [...tickets.slice(0, index), value, ...tickets.slice(index + 1, tickets.length)];

			set({ currentMultiTickets: newTickets });
		},
		updateParlayItemTicket: (value: TicketModel) => {
			const parlayTicketModel = get().currentParlayTicketModel;

			if (parlayTicketModel) {
				const tickets = parlayTicketModel.Tickets;

				// parlay ticket model come from api without key
				const index = tickets.findIndex(ticket => ticket.sinfo === value.sinfo);

				const newTickets = [...tickets.slice(0, index), value, ...tickets.slice(index + 1, tickets.length)];

				const newModel: ParlayTicketsModel = {
					...parlayTicketModel,
					Tickets: newTickets
				};

				set({ currentParlayTicketModel: newModel });
			}
		},
		updateParlayTicket: (value: ParlayTicketModel) => {
			const parlayTicketModel = get().currentParlayTicketModel;

			if (parlayTicketModel) {
				value.payout = BetslipService.getPayoutByParlayTicket(value);

				const tickets = parlayTicketModel.comboData;
				const index = tickets.findIndex(ticket => ticket.key === value.key);
				const newTickets = [...tickets.slice(0, index), value, ...tickets.slice(index + 1, tickets.length)];

				const newModel: ParlayTicketsModel = {
					...parlayTicketModel,
					comboData: newTickets
				};

				set({ currentParlayTicketModel: newModel });
			}
		},
		removeTicket: (oddsKey: string | undefined, childTab: ChildTabType, sourceType?: BetSourceType) => {
			const tickets = get().currentMultiTickets;
			const newTickets = tickets.filter(ticket => ticket.key !== oddsKey);
			set({ currentMultiTickets: newTickets });

			const betslipType = get().getBetslipType();
			set({ betslipType });

			if (sourceType) {
				const removeTicketStorage = get().sourceStorage.get(sourceType) ?? new Map<ChildTabType, Array<string>>();
				const removeTicketChildTabStorage = removeTicketStorage?.get(childTab)?.filter(oddskey => oddskey !== oddsKey);
				removeTicketStorage.set(childTab, removeTicketChildTabStorage ?? []);
				get().setSourceStorage(new Map<BetSourceType, Map<ChildTabType, Array<string>>>().set(sourceType, removeTicketStorage));
			} else {
				const removeTicketStorage = get().sourceStorage.get('promotion') ?? new Map<ChildTabType, Array<string>>();
				if (removeTicketStorage.size > 0) {
					let foundInTab: ChildTabType | undefined;
					removeTicketStorage.forEach((values, childTab) => {
						if (values.includes(oddsKey ?? '')) {
							foundInTab = childTab;
						}
					}); 
					if (foundInTab) {
						const removeTicketChildTabStorage = removeTicketStorage?.get(foundInTab)?.filter(oddskey => oddskey !== oddsKey);
						removeTicketStorage.set(foundInTab, removeTicketChildTabStorage ?? []);
						get().setSourceStorage(new Map<BetSourceType, Map<ChildTabType, Array<string>>>().set('promotion', removeTicketStorage));
					}
				}
			}
		},
		removeAllTicket: () => {
			const tickets = get().currentMultiTickets;
			tickets.map(ticket => get().removeTicket(ticket.key, 'null'));
			set({ currentParlayTicketModel: null });
		},
		setIsExpand: (value: boolean) => {
			get().setFocusOddsKey('');
			get().setSelectAllOddsKey('');
			set({ isExpand: value });
		},
		toggleIsExpand: () => {
			get().setFocusOddsKey('');
			get().setSelectAllOddsKey('');
			set({ isExpand: !get().isExpand });
		},
		setIsQuickBet: (value: boolean) => {
			set({ isQuickBet: value });

			const betslipType: BetslipType = get().getBetslipType();

			set({
				betslipType,
				focusOddsKey: '',
				selectAllOddsKey: '',
				isQuickbetExpand: true
			});
		},
		setIsQuickBetSettingModalOpen: (value: boolean) => {
			set({ isQuickBetSettingModalOpen: value });
		},
		toggleIsQuickBet: () => {
			set({ isQuickBet: !get().isQuickBet });

			const betslipType: BetslipType = get().getBetslipType();

			set({
				betslipType,
				focusOddsKey: '',
				selectAllOddsKey: '',
				isQuickbetExpand: true
			});
		},
		toggleIsQuickBetExpand: () => {
			const isQuickbetExpand = !get().isQuickbetExpand;

			set({
				isQuickbetExpand,
				focusOddsKey: '',
				selectAllOddsKey: '',
			});
		},
		changeLastBet: (value: number) => set(_ => ({
			lastBet: value
		})),
		setFocusOddsKey: (value: string) => set(_ => ({
			focusOddsKey: value
		})),
		setSelectAllOddsKey: (value = '') => set(_ => ({
			selectAllOddsKey: value
		})),
		setQuickBetTicket: (model: OddsTicketModel) => {
			const ticket = BetslipService.getTicketModel(model);

			set({ quickBetTicket: ticket });
			return ticket.key;
		},
		clearQuickBetTicket: () => {
			set({ quickBetTicket: null });
		},
		checkStake: (isIndependentMode: boolean, oddsType: number): boolean => {
			const {
				betslipType,
				betslipTab,
				currentMultiTickets,
				currentParlayTicketModel
			} = get();

			const currentParlayTickets = currentParlayTicketModel?.comboData;

			const bettingMultiTickets = currentMultiTickets.filter(ticket => ticket.stake);
			const bettingParlayTickets = currentParlayTickets?.filter(parlayTicket => parlayTicket.stake);

			const shouldCheckSingle = !isIndependentMode || betslipTab === 'single' || betslipType === 'single';
			const shouldCheckParlay = (!isIndependentMode || betslipTab === 'parlay') && betslipType === 'multi';

			let isSuccess = true;
			const tooltipMap = new Map<string, Record<string, { value?: string | number }>>();
			// 一旦重新檢查, 先清空tool tip
			set({ tooltipMap });

			// 完全沒有填入Stake, 或是填入的stake不是當前分離模式的tab
			if ((!bettingMultiTickets.length || !shouldCheckSingle) && (!bettingParlayTickets?.length || !shouldCheckParlay)) {
				if (betslipType === 'single') {
					const ticket = currentMultiTickets[0];

					tooltipMap.set(ticket.key, { 'lbl_MinStakeErrMsg': { value: ticket.minBet } });

					get().updateMultiTicket({
						...ticket,
						stake: ticket.minBet
					}, oddsType);

					isSuccess = false;
				} else if (betslipType === 'multi') {
					// 有parlay票的狀況 //TODO: 當有parlay票但全部不可下注時，應套用multi bet的流程
					if (currentParlayTickets?.length && shouldCheckParlay) {
						const parlayTicket = currentParlayTickets.find(parlayTicket => parlayTicket.isMixParlay);
						const ticketMsg = get().canBetResult.innerMsg.find(msg => msg.ticketKey === parlayTicket?.key);
						const canBet = !ticketMsg || (ticketMsg && ticketMsg.canBet);

						if (canBet) {
							tooltipMap.set((parlayTicket as ParlayTicketModel).key, { 'lbl_MinStakeErrMsg': { value: currentParlayTicketModel?.MinBet } });

							get().updateParlayTicket({
								...parlayTicket,
								stake: Number(currentParlayTicketModel?.MinBet)
							} as ParlayTicketModel);
						}

						isSuccess = false;
					} else {
						currentMultiTickets.forEach(ticket => {
							const ticketMsg = get().canBetResult.innerMsg.find(msg => msg.ticketKey === ticket?.key);
							const canBet = !ticketMsg || (ticketMsg && ticketMsg.canBet);

							if (canBet) {
								tooltipMap.set(ticket.key, { 'lbl_MinStakeErrMsg': { value: ticket.minBet } });

								get().updateMultiTicket({
									...ticket,
									stake: ticket.minBet
								}, oddsType);
							}
						});

						isSuccess = false;
					}
				}
			} else {
				// 檢測所有有填stake 的ticket
				if (shouldCheckSingle) {
					bettingMultiTickets.forEach(ticket => {
						if (ticket.stake > ticket.maxBet) {
							isSuccess = false;
							tooltipMap.set(ticket.key, { 'lbl_MaxStakeErrMsg': { value: ticket.maxBet } });

							get().updateMultiTicket({
								...ticket,
								stake: ticket.maxBet
							}, oddsType);
						} else if (ticket.stake < ticket.minBet) {
							isSuccess = false;
							tooltipMap.set(ticket.key, { 'lbl_MinStakeErrMsg': { value: ticket.minBet } });

							get().updateMultiTicket({
								...ticket,
								stake: ticket.minBet
							}, oddsType);
						}
					});
				}

				if (shouldCheckParlay) {
					bettingParlayTickets?.forEach(parlayTicket => {
						if (parlayTicket.stake > parlayTicket.MaxBet) {
							isSuccess = false;
							tooltipMap.set(parlayTicket.key, { 'lbl_MaxStakeErrMsg': { value: parlayTicket.MaxBet } });

							get().updateParlayTicket({
								...parlayTicket,
								stake: parlayTicket.MaxBet
							});
						} else if (parlayTicket.stake < Number(currentParlayTicketModel?.MinBet)) {
							isSuccess = false;
							tooltipMap.set(parlayTicket.key, { 'lbl_MinStakeErrMsg': { value: currentParlayTicketModel?.MinBet } });

							get().updateParlayTicket({
								...parlayTicket,
								stake: Number(currentParlayTicketModel?.MinBet)
							});
						}
					});
				}
			}

			set({ tooltipMap });
			return isSuccess;
		},
		setMultiStakePerBet: (value: number) => {
			set({ multiStakePerBet: value });
		},
		setParlayStakePerBet: (value: number) => {
			set({ parlayStakePerBet: value });
		},
		betAgain: (oddsType: number, defaultStakeId: string, InpbetStake: string, view: number) => {
			const currentMultiTickets = get().currentMultiTickets;
			const currentParlayTicketModel = get().currentParlayTicketModel;
			const stake = parseInt(defaultStakeId) === BetDefaultStakeType.Customize && InpbetStake ? parseInt(InpbetStake) : 0;
			currentMultiTickets.forEach(ticket => {
				get().updateMultiTicket({
					...ticket,
					stake: stake === 0 && parseInt(defaultStakeId) === BetDefaultStakeType.LastBet ? ticket.stake : stake,
					ErrorCode: 0,
					Message: '',
					TransId: '',
					Common: {
						ErrorCode: 0,
						ErrorMsg: ''
					}
				}, oddsType);
			});

			currentParlayTicketModel?.comboData?.forEach(parlayTicket => {
				if (parlayTicket.isMixParlay) {
					get().updateParlayTicket({
						...parlayTicket,
						stake: view === ViewEnum.galaxy ? (defaultStakeId === '0' ? parlayTicket.stake : 0) : (stake === 0 && parseInt(defaultStakeId) === BetDefaultStakeType.LastBet) ? parlayTicket.stake : stake,
						ErrorCode: 0,
						ErrorMsg: ''
					});
				} else {
					get().updateParlayTicket({
						...parlayTicket,
						stake: 0,
						ErrorCode: 0,
						ErrorMsg: ''
					});
				}
			})

			set({
				multiStakePerBet: 0,
				parlayStakePerBet: 0,
				isConfirmBet: false,
				isBookingBet: false,
				useSabaCoin: false,
				selectAllOddsKey: '',
				focusOddsKey: '',
				betAction: BetAction.BetAgain
			});
		},
		initConfirmBetSimpleMode: (view: number) => {
			const isConfirmBetSimpleMode = view === ViewEnum.galaxy ? localStorage.getItem(CONFIRM_BET_SIMPLE_MODE) === 'true' : false;

			set({ isConfirmBetSimpleMode });
		},
		toggleIsConfirmBetSimpleMode: () => {
			const isConfirmBetSimpleMode = !get().isConfirmBetSimpleMode;

			localStorage.setItem(CONFIRM_BET_SIMPLE_MODE, isConfirmBetSimpleMode.toString());

			set({ isConfirmBetSimpleMode });
		},
		setVoucher: (voucherType: VoucherType) => {
			set({ useVoucher: voucherType });
		},
		setUseFreeRiskFreeVocher: (value: boolean) => set({ useFreeRiskFreeVoucher: value }),
		setUseSabaCoin: (value: boolean) => set({ useSabaCoin: value }),
		setBetAction: (betAction: number) => {
			set({ betAction });
		},
		setSourceStorage: (sourceStorage: Map<BetSourceType, Map<ChildTabType, Array<string>>>) => {
			set({ sourceStorage: new Map(sourceStorage) });
		},
		setEnableQuickBet: (value: boolean) => {
			set({ enableQuickBet: value });
		},
		setBetslipTab: (value: BetslipTab) => {
			set({ betslipTab: value });
		},
		setBetTimer: (value: number) => {
			set({ betTimer: value });
		}
	}))
);