
export function cloudUrlParser(
	apiUrl:string
) {
	if (window.location.hostname.includes('localhost') || !isValidUrl(apiUrl)) {
		return apiUrl;
	}
	const paths:Array<string> = [];
	let urlHeader = '';
	if (paths.length > 0) {
		for (let i = 0; i < paths.length; i++) {
			if (apiUrl.toLowerCase().includes(paths[i])) {
				const domainSplit = new URL(apiUrl).hostname.split('.');
				const header = domainSplit[0].toLowerCase();
				urlHeader = header;
				if (header.includes('mc')) { //aws prod
					const randomId = header.slice(0, -2);
					if (randomId.length > 1) {
						urlHeader = randomId + 'pl.' + getLocalHost();
					} else {
						urlHeader = 'smart';
					}
				} else if (header.includes('om00')) { //aws uat
					if (header.slice(0, 3) == 'qom') {
						urlHeader = header.replace('qom', 'qaml');
					} else {
						urlHeader = header.replace('om', 'ul') + '.' + getLocalHost();
					}
				} else {
					const flg = header.substring(4, 2);
					if (flg === 'pa') urlHeader = header.substring(0, 4) + 'pl.' + getLocalHost();
					if (flg === 'ua') urlHeader = header.substring(0, 4) + 'ul.' + getLocalHost();
				}
				break;
			}
		}
	}
	if (urlHeader.length > 0) {
		let baseUrl;
		if (apiUrl.startsWith('http')) {
			baseUrl = new URL(apiUrl);
		} else {
			baseUrl = new URL(`${window.location.protocol}//${window.location.hostname}/${apiUrl.replace(/^\//i, '')}`);
		}
		const mainDomain = getMainDomain(baseUrl.hostname);

		let hostname = `${urlHeader}${mainDomain}`;
		if (urlHeader.includes('.')) {
			hostname = urlHeader;
		}
		const port = location.port.length > 0 ? ':' + location.port : '';

		const newUrl = `${baseUrl.protocol}//${hostname}${port}${baseUrl.pathname}${baseUrl.search}`;
		return newUrl;
	}

	return apiUrl;
}

function getMainDomain(hostname:string) {
	let mainDomain = '';
	const domainSplit = hostname.split('.');
	for (let i = 1; i < domainSplit.length; i++) {
		mainDomain += '.' + domainSplit[i];
	}
	return mainDomain;
}

function getLocalHost() {
	const ctcdrefer = localStorage.getItem('ctcdrefer');
	if (ctcdrefer == null) {
		const mainDomain = getMainDomain(window.location.hostname);
		const mainDomainLower = mainDomain.toLowerCase();
		const checkDomianArray = ['.guc9273.net', '.kyk5180.net', '.nya1640.net', '.sxh9225.net', '.ubereatpro.com', '.xzx7520.net'];
		if (checkDomianArray.includes(mainDomainLower)) {
			return 'foodpandaoh.com';
		} else {
			return 'gogoroviva.com';
		}
	}
	return ctcdrefer;
}

function isValidUrl (urlString:string) {
	const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
	return !!urlPattern.test(urlString);
}