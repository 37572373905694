import { useState, useEffect } from 'react'
/**
 * prevent SSR hydration by browser storage error, check zustand document below
 * https://docs.pmnd.rs/zustand/integrations/persisting-store-data#usage-in-next.js
 */
export const useStore = <T, F>(
	store: (callback: (state: T) => unknown) => unknown,
	callback: (state: T) => F
) => {
	const result = store(callback) as F
	const [data, setData] = useState<F>()

	useEffect(() => {
		setData(result)
	}, [result])

	return data
}
