import { oddsButtonVariantsProps } from '@/components/widgets/odds-button';
import { useAccountInfo } from '@/hooks/query/account';
import {
	specialGroupByResourceIdBetTypes,
	specialGroupBySliceResourceIdBetTypes,
	betTypeSettings,
	specialGroupByChangeResourceIdBetTypes,
	specialGroupByLineBetTypes
} from '@/settings/bet-type-settings';
import { ResourceMarketTuple, TranslateType } from '@/types/bet-type-settings';
import { filterBetTypesFromMarkes, getHomeTeamName, getTeamName } from '@/utils/odds-utils';
import { Market, SportMatchModel } from '@nf/types/odds';
import { Box, matchSome } from '@nf/utils-common/fpw/monadic';

const replaceBetTypeRaceToResource = (resourceId: string, name: string) => {
	const rid = resourceId.substring(0, 2);
	return name.replace('{0}', ` ${parseInt(rid)}`);
}

const replaceEsportMapNameResource = (resourceId: string, name: string) => {
	const rid = resourceId.substring(0, 2);
	return name.replace('{0}', ` ${parseInt(rid)}`).replace('X', `${parseInt(rid)}`);
}

const replaceBetTypeRoundResource = (betTypeId: number, resourceId: string, name: string) => {
	const basketballSpecialBetType = [606, 607];

	if (basketballSpecialBetType.includes(betTypeId)) {
		return name.split('X')[0]; // 截斷 'X' 後的內容
	} else {
		// 用quarter or round取代 'X'，385是1X2做特別處理
		const rid = parseInt(resourceId).toString();
		switch (betTypeId) {
			case 707:
			case 708:
			case 709:
			case 710:
			case 711:
			case 714:
				return name.replace(' X ', ` ${rid} `).replace(' X', ` ${rid}`);
			case 385:
				return name.replace(' X', ` ${rid}`);
			case 155:
			case 156:
				return name.replace('x', `${rid}`);
			default:
				return name.replace('X', rid);
		}
	}
}

const replaceBetTypeRoundBaseballResource = (BetTypeId: number, resourceId: string, name: string, lang?: string) => {
	const rid = parseInt(resourceId);
	name = name.replace(/\(.*?\)/g, '');
	if (!lang) return  name.replace('X', rid.toString());
	
	const SpecialLang = ['zh-tw', 'zh-cn', 'zh-hans', 'id-id', 'ja-jp', 'ko-kr', 'th-th', 'vi-vn', 'zh-chs'];
	if (lang && SpecialLang.includes(lang?.toLowerCase())) {
		if (BetTypeId === 8813) {
			return name.replace('X', rid.toString())
		}
		return name.replace('X', rid.toString()).replace('x', rid.toString());
	}

	switch (rid) {
		case 1:
			if (BetTypeId === 8813) {
				return name.replace('X', 'First')
			}
			return name.replace('X', 'First').replace('x', 'First');
		case 2:
			if (BetTypeId === 8813) {
				return name.replace('X', '2nd')
			}
			return name.replace('X', '2nd').replace('x', '2nd');
		case 3:
			if (BetTypeId === 8813) {
				return name.replace('X', '3rd')
			}
			return name.replace('X', '3rd').replace('x', '3rd');
		default:
			if (BetTypeId === 8813) {
				return name.replace('X', `${rid}th`)
			}
			return name.replace('X', `${rid}th`).replace('x', `${rid}th`);
	}
}

const replaceCricketResource = (resourceId: string, name: string, sportId: number) => {
	if (sportId === 50) {
		const X: number = parseInt(resourceId.substring(0, 3));
		const Y: number = parseInt(resourceId.substring(3, 5));
		return name.replace(/X/, X.toString()).replace(/Y/, Y.toString());
	}
	return '';
}

const replaceDoubleDigitsResource = function (resourceId: string, name: string) {
	const X = resourceId.substring(0, 2);
	const Y = resourceId.substring(2, 4);
	return name.replace(' X', ` ${parseInt(X)}`).replace(' Y', ` ${parseInt(Y)}`);
}

const getCricketSelection = (market: Market, betTeam: string) => {
	return market.Selections[betTeam];
}

const getCricketPlayerName = (market: Market, betTeam: string) => {
	const selections = getCricketSelection(market, betTeam);
	return selections.Info ? selections.Info.split(',')[1] : '';
};

const getCricketInfo = (market: Market, betTeam: string) => {
	return getCricketSelection(market, betTeam).Info ?? '';
};

const getBetTypeNamePlayer = (resourceId: string, betTypeName: string, sportId: number, player?: string) => {
	betTypeName = replaceCricketResource(resourceId, betTypeName, sportId);
	return player ? `${betTypeName} - ${player}` : `${betTypeName}`;
}

const getBetTypeSettingTitle = (betTypeId: number, sportId: number, resourceId = '', isNeedShowFullName?: boolean): [string, TranslateType] => {

	const betTypeSetting = betTypeSettings[betTypeId];
	if (betTypeSetting === undefined) {
		return ['', 'General'];
	}
	
	if (betTypeSetting?.isBetTypeNameTranslateFromBetType) {
		if (sportId === 50 || [219, 220, 707, 708, 709, 714, 3918, 8804, 8806, 8807].includes(betTypeId)) {
			if ((sportId === 50 && betTypeSetting.betTypesCategory === 'overRun') || sportId !== 50) {
				if (resourceId !== '') {
					return [`b${betTypeId}`, 'BetType'];
				}
				return getBetTypeName(sportId, betTypeId) ?? ['', 'General'];
			}
			return [`b${betTypeId}`, 'BetType'];
		}
		
		return getBetTypeName(sportId, betTypeId, 'BetType') ?? [`b${betTypeId}`, 'BetType'];
	} else {
		return getBetTypeName(sportId, betTypeId, betTypeSetting.betTypeTranslateType ?? betTypeSetting.betChoiceTranslateType, isNeedShowFullName) ?? ['', 'General'];
	}
};

const getBetTypeReplaceTitle = (betTypeTitle: string, sportId: number, market: Market, lang?: string) => {
	const { BetTypeId, Resourceid, plr } = market;
	const betTypeSetting = betTypeSettings[BetTypeId];
	if (betTypeTitle === '' || betTypeSetting === undefined) {
		return '';
	}
	switch (betTypeSetting.betTypeNameReplaceType) {
		case 'replaceBetTypeRaceToResource':
			return replaceBetTypeRaceToResource(Resourceid, betTypeTitle);
		case 'replaceBetTypeRoundResource':
			return replaceBetTypeRoundResource(BetTypeId, Resourceid, betTypeTitle);
		case 'replaceCricketResource':
			return replaceCricketResource(Resourceid, betTypeTitle, sportId);
		case 'replaceDoubleDigitsResource':
			return replaceDoubleDigitsResource(Resourceid, betTypeTitle);
		case 'getBetTypeNamePlayer':
			return getBetTypeNamePlayer(Resourceid, betTypeTitle, sportId, plr?.player.split(',')[1]);
		case 'replaceEsportMapNameResource':
			return replaceEsportMapNameResource(Resourceid, betTypeTitle);
		case 'replaceBetTypeRoundBaseballResource':
			return replaceBetTypeRoundBaseballResource(BetTypeId, Resourceid, betTypeTitle, lang);
		default:
			return betTypeTitle;
	}
}

const getBetTypeName = (sportId: number, betTypeId: number, translateType: TranslateType = 'General', isNeedShowFullName?: boolean): [string, TranslateType] | undefined => {
	const betTypeSetting = betTypeSettings[betTypeId];
	if (betTypeSetting === undefined || betTypeSetting.betTypeName === undefined) {
		return undefined;
	}
	
	if (betTypeSetting.betTypeName?.[sportId]) {
		if (isNeedShowFullName && betTypeSetting.betTypeFullName?.[sportId]) {
			return [betTypeSetting.betTypeFullName?.[sportId], translateType];
		}
		return [betTypeSetting.betTypeName?.[sportId], translateType];
	} else if (betTypeSetting.betTypeName[0]) {
		if (isNeedShowFullName && betTypeSetting.betTypeFullName?.[0]) {
			return [betTypeSetting.betTypeFullName?.[0], translateType];
		}
		return [betTypeSetting.betTypeName[0], translateType];
	} else {
		return undefined;
	}
}

const getBetChoiceDisplaySettingLabelByCricket = (betTypeId: number, market: Market, betTeam: string, label: string) => {
	const betTypeSetting = betTypeSettings[betTypeId];
	if (betTypeSetting === undefined || betTypeSetting.betChoices === undefined) {
		return `Unknown[${betTypeId}]`;
	}

	if (betTypeSetting.betChoices && betTypeSetting.betTypesCategory) {
		switch (betTypeSetting.betTypesCategory) {
			case 'innsPlay':
				return getCricketPlayerName(market, betTeam);
			case 'exactRun':
				return getCricketInfo(market, betTeam);
			default:
				break;
		}
	}

	return label ?? `Unknown[${betTypeId}]`;
}

/**
 * 依據 BetChoiceDisplaySetting 設定, 決定是否轉換 TeamName 回傳
 * @param {Map<number, string> | undefined} teamNameMap - If this parameter existed, use it first for child match
 * @returns TeamName - (home 包含中立隊名, 若不需轉換則回傳 undefined)
 */
const getBetChoiceDisplayTeamName = (betTypeId: number, betTeam: string, matchModel: SportMatchModel, neutralTeamName: string, teamNameMap?: Map<number, string>) => {
	const betTypeSetting = betTypeSettings[betTypeId];
	const betTeamType = betTypeSetting.betChoiceDisplaySetting?.[betTeam].betTeamType;
	if (betTeamType) {
		return betTeamType === 'home'
			? getHomeTeamName(matchModel.TeamId1, matchModel.Neu, neutralTeamName, teamNameMap)
			: getTeamName(matchModel.TeamId2, teamNameMap);
	}
	return undefined;
}

const getBetChoiceDisplaySettingLabel = (betTypeId: number, betTeam: string) => {
	const betTypeSetting = betTypeSettings[betTypeId];
	if (betTypeSetting === undefined) {
		return `Unknown[${betTypeId}]`;
	}
	return betTypeSetting.betChoiceDisplaySetting?.[betTeam].label ??
		betTypeSetting.betChoices.find(betChoice => betChoice.betTeam === betTeam)?.label ?? `Unknown[${betTypeId}]`;
}


const getBetChoiceDisplaySettingBetTypeClassName = (betTypeId: number, betTeam: string, defaultClassName: oddsButtonVariantsProps = 'default') => {
	const betTypeSetting = betTypeSettings[betTypeId];
	const betTypeClassName = betTypeSetting.betChoiceDisplaySetting?.[betTeam].betTypeClassName;
	if (betTypeClassName === undefined) {
		return defaultClassName;
	}
	return matchSome(betTypeClassName)
		.on((className: string) => ['Back', 'Yes'].includes(className), () => 'cricketBackYes')
		.on((className: string) => ['Lay', 'No'].includes(className), () => 'cricketLayNo')
		.otherwise(() => defaultClassName);
}

const splitSpecialBetTypeGroups = (markets: Market[]): ResourceMarketTuple[] | undefined => {
	if (!markets || !markets.length) {
		return undefined;
	}

	const betTypeId = markets[0].BetTypeId;
	let result: ResourceMarketTuple[] | undefined = undefined;

	if (specialGroupByResourceIdBetTypes.includes(betTypeId) || specialGroupByChangeResourceIdBetTypes.includes(betTypeId)) {
		result = markets.reduce((result, market) => {
			const existGroup = result.find(tuple => tuple.resourceId == market.Resourceid);

			if (existGroup) {
				existGroup.markets.push(market);
			} else {
				result.push({
					resourceId: market.Resourceid,
					markets: [market]
				});
			}

			return result;
		}, new Array<ResourceMarketTuple>);
	} else if (specialGroupBySliceResourceIdBetTypes.includes(betTypeId)) {
		result = markets.reduce((result, market) => {
			const resourceId = market.Resourceid.substring(0, 2);
			const existGroup = result.find(tuple => tuple.resourceId == resourceId);

			if (existGroup) {
				existGroup.markets.push(market);
			} else {
				result.push({
					resourceId: resourceId,
					markets: [market]
				});
			}

			return result;
		}, new Array<ResourceMarketTuple>);
	} else if (specialGroupByLineBetTypes.includes(betTypeId)) {
		result = markets.reduce((result, market) => {
			const existGroup = result.find(tuple => tuple.resourceId == market.Line.toString());

			if (existGroup) {
				existGroup.markets.push(market);
			} else {
				result.push({
					resourceId: market.Line.toString(),
					markets: [market]
				});
			}

			return result;
		}, new Array<ResourceMarketTuple>);
	}

	if (result && result.length) {
		result.sort((a, b) => {
			return Number(a.resourceId) - Number(b.resourceId);
		})
	}

	return result;
}

const getFastMarketTimer = (start: number, end: number): string => {
	const startTime = `0${start}`.slice(-2) + ':00';

	let endTime = '';

	if (end != 0) {
		const endMinute = end + start - 1;
		endTime = `0${endMinute}`.slice(-2) + ':59';
	}

	return end ? `${startTime} - ${endTime}` : startTime;
}

const convertSpecialBetType = (betType: string | number): [number, string | undefined] => {
	if (typeof betType === 'number') return [betType, undefined];
	const betTypeSplit = betType.split(':');
	return [Number(betTypeSplit[0]), betTypeSplit[1]];
}
const getMainGroupMap = (markets: Market[], mainBetTypes: number[]): Map<number, Market | undefined> => Box.of(markets)
	.map(filterBetTypesFromMarkes(new Set(mainBetTypes)))
	.map((markets: Market[]) => {
		const mainGroup = new Map<number, Market | undefined>();
		mainBetTypes.forEach(betType => mainGroup.set(betType, markets.find(market => market.BetTypeId === betType)))
		return [...mainGroup.values()].some(market => market) ? mainGroup : new Map();
	})
	.extract()

const getOtherMainGroupMap = (markets: Market[], mainBetTypes: (number | string)[][]): Map<string | number, Market | undefined> => {
	const otherMainGroup = new Map<string | number, Market | undefined>();
	mainBetTypes.forEach(betTypes => {
		if (otherMainGroup.size > 0) return;
		const betTypeResourceGroup = betTypes.map(convertSpecialBetType);
		const filteredMarkets: Market[] = Box.of(markets)
			.map((markets: Market[]) => markets.filter(market => betTypeResourceGroup.some(([betType, resourceId]) => {
				return market.BetTypeId === betType && (resourceId === undefined || resourceId === market.Resourceid);
			})))
			.extract();
		if (filteredMarkets.length === 0) return;
		betTypeResourceGroup.forEach(([betType, resourceId]) => otherMainGroup.set(resourceId ? `${betType}:${resourceId}` : betType, markets.find(market => market.BetTypeId === betType)))
	})
	return otherMainGroup
}

const getMoreGroup = (markets: Market[], moreBetTypes: (number | string)[][]) => {
	const moreGroupMap: Map<string | number, Market | undefined>[] = [];
	moreBetTypes.forEach(betTypes => {
		const betTypeResourceGroup = betTypes.map(convertSpecialBetType);
		while (markets.some(market => betTypeResourceGroup.some(([betType, resourceId]) => betType === market.BetTypeId && (resourceId === undefined || resourceId === market.Resourceid)))) {
			const group = new Map<string | number, Market | undefined>();
			betTypeResourceGroup.forEach(([betType, resourceId]) => {
				const marketIndex = markets.findIndex(market => market.BetTypeId === betType && (resourceId === undefined || market.Resourceid === resourceId));
				group.set(resourceId ? `${betType}:${resourceId}` : betType, markets[marketIndex]);
				marketIndex > -1 && markets.splice(marketIndex, 1)
			})
			moreGroupMap.push(group)
		}
		
	})
	return moreGroupMap;
}
export const betTypeService = {
	getBetTypeName,
	getBetTypeSettingTitle,
	getBetTypeReplaceTitle,
	getBetTypeNamePlayer,
	getCricketPlayerName,
	getCricketInfo,
	replaceCricketResource,
	getBetChoiceDisplaySettingLabelByCricket,
	getBetChoiceDisplayTeamName,
	getBetChoiceDisplaySettingLabel,
	getBetChoiceDisplaySettingBetTypeClassName,
	splitSpecialBetTypeGroups,
	getFastMarketTimer,
	convertSpecialBetType,
	getMainGroupMap,
	getOtherMainGroupMap,
	getMoreGroup,
	replaceBetTypeRoundBaseballResource
};