import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiQueryPromise } from '@/types';
import { SabaCoinWallet, UserInfo } from '@nf/types/account';
import { getResponseJwtHeader } from '@/utils/web';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import queryKeys from '@/constants/query-keys';
import { accountService } from '@/services/account.service';
import { useSiteStore } from '@/store/site';
import { getAuthToken } from '@/store/auth';

export type UpdateBalanceResponse = {
	BCredit: string,
	OutSd: string,
	SabaCoinWallet: SabaCoinWallet | null;
}

export const updateBalance = async (): Promise<ApiQueryPromise<UpdateBalanceResponse>> => {
	const { ctcdDomain } = useSiteStore.getState().apiDomain;
	const authToken = getAuthToken();
	const response = await fetchComposer.getWithBearer(`${ctcdDomain}/api/Customer/UpdateBalance`)(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useAccountBalance = () => {
	const { data, isFetching, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.UPDATE_BALANCE],
		queryFn: updateBalance,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: false
	});

	const queryClient = useQueryClient();
	useEffect(() => {
		const balanceData = data?.data.Data;
		if (balanceData) {
			const accountDataToUpdate: Partial<UserInfo> = {
				BCredit: balanceData?.BCredit,
				OutSd: balanceData?.OutSd,
			};
			const sabaCoinWallet = balanceData?.SabaCoinWallet;
			if (sabaCoinWallet && sabaCoinWallet.AvailableFund && sabaCoinWallet.AvailableFund) {
				accountDataToUpdate.SabaCoinWallet = {
					AvailableFund: sabaCoinWallet.AvailableFund,
					PendingFund: sabaCoinWallet.PendingFund,
					ActStatus: sabaCoinWallet.ActStatus
				};
			}
			accountService.updateAccountInfo(queryClient, accountDataToUpdate);
		}
	}, [data]);

	return {
		data: data?.data.Data,
		isFetching,
		isError,
		error,
		refetch
	};
};